import React, { Component } from "react";

import * as moment from "moment-timezone";

import { Link } from "react-router-dom";

import Layout from "../../components/Layout";

import api from "../../services/api";

import Swal from "sweetalert2";

import Select from "react-select";

export default class ClientsList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            clients: [],
            clientsToShow: [],
            qtdPages: 0,
            loaded: false,
        };
    }

    componentWillMount = async () => {
        const dataClients = await api.get(`/clients/today-paginated/1`);

        this.setState((prevState) => ({
            ...prevState,
            loaded: true,
            clients: dataClients.data.clients,
            clientsToShow: dataClients.data.clients,
            qtdPages: Math.round(
                this.props.location.state.qtdClientsToday / 20
            ),
        }));
    };

    componentDidMount = async function () {
        this.setState((prevState) => ({
            ...prevState,
        }));
    };

    changePage(page) {
        const loadData = async (page) => {
            this.setState((prevState) => ({
                ...prevState,
                loaded: false,
            }));

            let dataClients = await api.get(`/clients/today-paginated/${page}`);

            this.setState((prevState) => ({
                ...prevState,
                loaded: true,
                clients: dataClients.data.clients,
                clientsToShow: dataClients.data.clients,
                page: page,
            }));
        };
        loadData(page);
    }

    render() {
        let { clientsToShow } = this.state;

        return (
            <Layout menuActive="dashboard" pageTitle="Clientes Hoje">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="card-title">
                                    <small>
                                        <Link
                                            className="label label-default"
                                            to="/dashboard"
                                        >
                                            Dashboard
                                        </Link>
                                    </small>{" "}
                                    <span className="label label-success">
                                        Clientes hoje
                                    </span>
                                </div>
                            </div>
                            <div
                                className={`card-body table-responsive ${
                                    this.state.loaded ? "" : "__loading"
                                }`}
                            >
                                <div className="loader-container text-center">
                                    <div className="icon">
                                        <div className="sk-wave">
                                            <div className="sk-rect sk-rect1"></div>
                                            <div className="sk-rect sk-rect2"></div>
                                            <div className="sk-rect sk-rect3"></div>
                                            <div className="sk-rect sk-rect4"></div>
                                            <div className="sk-rect sk-rect5"></div>
                                        </div>
                                    </div>
                                    <div className="title">Carregando</div>
                                </div>

                                <div className="row">
                                    <div className="col-xs-12">
                                        <table className="table table-striped table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th className="right">
                                                        Nome Cliente
                                                    </th>
                                                    <th className="right">
                                                        CPF
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {clientsToShow.map(
                                                    (client, index) => (
                                                        <tr key={index}>
                                                            <td>
                                                                {client.name}
                                                            </td>
                                                            <td>
                                                                {client.cpf}
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {this.state.qtdPages !== 0 ? (
                                    <div className="row">
                                        <div className="col-xs-12">
                                            <ul className="pagination">
                                                {Array(this.state.qtdPages)
                                                    .fill()
                                                    .map((_, page) => (
                                                        <li
                                                            className={
                                                                page + 1 ===
                                                                this.state.page
                                                                    ? "active"
                                                                    : ""
                                                            }
                                                        >
                                                            <a
                                                                href="#"
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.preventDefault();
                                                                    this.changePage(
                                                                        page + 1
                                                                    );
                                                                }}
                                                            >
                                                                {page + 1}
                                                            </a>
                                                        </li>
                                                    ))}
                                            </ul>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}
