import React, { useState, useRef, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";

import Layout from "../../components/Layout";

import api from "../../services/api";

import Swal from "sweetalert2";
import Autocomplete from "react-autocomplete";

const SquareArtCampaignNew = (props) => {
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState({});
    const [name, setName] = useState("");
    const [text, setText] = useState("");
    const [category, setCategory] = useState("");
    const [categories, setCategories] = useState([]);
    const textRef = useRef();
    const fileRef = useRef(null);

    useEffect(() => {
        const load = async () => {
            setLoaded(true);
            const categories = await api.get("/square-art/campaign/categories");
            setCategories(categories.data.data);
        };
        load();
    }, []);

    function handleCancelButton(event) {
        if (name !== "") {
            Swal.fire({
                title: "Tem certeza que deseja cancelar?",
                text: "Cancelar fará você perder todas as modificações feitas até o momento!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sim, quero cancelar!",
                cancelButtonText: "Cancelar",
            }).then((result) => {
                if (result.value) {
                    Swal.fire(
                        "Cancelado!",
                        "Você irá voltar para a tela de listagem.",
                        "success"
                    );

                    props.history.push("/arte-quadrada-campanhas");
                }
            });
        } else {
            props.history.push("/arte-quadrada-campanhas");
        }
    }

    async function handleSubmitForm(event) {
        event.preventDefault();

        setLoaded(false);

        try {
            const formData = new FormData();
            formData.append("name", name);
            formData.append("text", textRef.current.value);
            formData.append("category", category);
            formData.append(
                "image_square_art_campaign",
                fileRef.current.files[0]
            );

            const createdCampaign = await api.post(
                "/square-art/campaign",
                formData
            );

            setLoaded(true);
            if (createdCampaign.status === 201) {
                setLoaded(true);

                Swal.fire({
                    type: "success",
                    title: "Salvo!",
                    text: "A campanha foi salva com sucesso!",
                }).then(() => props.history.push("/arte-quadrada-campanhas"));
            } else {
                Swal.fire({
                    type: "error",
                    title: "Oops...",
                    text: "Tivemos um problema ao salvar essa nova campanha. Contate o suporte!",
                });

                setLoaded(true);
            }
        } catch (e) {
            Swal.fire({
                type: "error",
                title: "Oops...",
                text: "Tivemos um problema ao salvar essa nova campanha. Contate o suporte!",
            });

            setLoaded(true);
        }
    }

    async function addFormatText(format) {
        const selectionStart = textRef.current.selectionStart;
        const selectionEnd = textRef.current.selectionEnd;

        let newValue =
            text.substring(0, selectionStart) +
            format +
            text.substring(selectionEnd, text.length);
        setText(newValue);
        textRef.current.focus();
    }

    return (
        <Layout
            menuActive="registers"
            pageTitle="Arte Quadrada - Nova Campanha"
        >
            <div className="row">
                <div className="col-xs-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">
                                Arte Quadrada - Nova Campanha
                            </div>
                        </div>
                        <div
                            className={`card-body ${loaded ? "" : "__loading"}`}
                        >
                            <div className="loader-container text-center">
                                <div className="icon">
                                    <div className="sk-wave">
                                        <div className="sk-rect sk-rect1"></div>
                                        <div className="sk-rect sk-rect2"></div>
                                        <div className="sk-rect sk-rect3"></div>
                                        <div className="sk-rect sk-rect4"></div>
                                        <div className="sk-rect sk-rect5"></div>
                                    </div>
                                </div>
                                <div className="title">Carregando</div>
                            </div>

                            <form
                                className="form form-horizontal"
                                onSubmit={handleSubmitForm}
                            >
                                <div className="section">
                                    <div className="section-title">
                                        Informações Básicas
                                    </div>
                                    <div className="section-body">
                                        <div
                                            className={
                                                "form-group " +
                                                (error.name ? "has-error" : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Nome
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder=""
                                                    onChange={(e) =>
                                                        setName(e.target.value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                "form-group " +
                                                (error.name ? "has-error" : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Atalhos
                                            </label>
                                            <div className="col-md-9">
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        addFormatText(
                                                            "{%validade%}"
                                                        );
                                                    }}
                                                >
                                                    Validade
                                                </button>
                                                <button
                                                    className="btn btn-primary"
                                                    style={{
                                                        marginLeft: 10,
                                                    }}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        addFormatText(
                                                            "{%parcelas%}"
                                                        );
                                                    }}
                                                >
                                                    Parcelas
                                                </button>
                                                <button
                                                    className="btn btn-primary"
                                                    style={{
                                                        marginLeft: 10,
                                                    }}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        addFormatText(
                                                            "{%preco_avista%}"
                                                        );
                                                    }}
                                                >
                                                    Preço à Vista
                                                </button>
                                                <button
                                                    className="btn btn-primary"
                                                    style={{
                                                        marginLeft: 10,
                                                    }}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        addFormatText(
                                                            "{%total_aprazo%}"
                                                        );
                                                    }}
                                                >
                                                    Total à Prazo
                                                </button>
                                                <button
                                                    className="btn btn-primary"
                                                    style={{
                                                        marginLeft: 10,
                                                    }}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        addFormatText(
                                                            "{%valor_parcela%}"
                                                        );
                                                    }}
                                                >
                                                    Valor da Parcela
                                                </button>
                                                <button
                                                    className="btn btn-primary"
                                                    style={{
                                                        marginLeft: 10,
                                                    }}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        addFormatText(
                                                            "{%produto%}"
                                                        );
                                                    }}
                                                >
                                                    Produto
                                                </button>
                                                <button
                                                    className="btn btn-primary"
                                                    style={{
                                                        marginLeft: 10,
                                                    }}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        addFormatText(
                                                            "{%juros%}"
                                                        );
                                                    }}
                                                >
                                                    Sem juros
                                                </button>
                                                <button
                                                    className="btn btn-primary"
                                                    style={{
                                                        marginLeft: 10,
                                                    }}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        addFormatText(
                                                            "{%entrada%}"
                                                        );
                                                    }}
                                                >
                                                    Sem entrada
                                                </button>
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        addFormatText(
                                                            "{%carne%}"
                                                        );
                                                    }}
                                                >
                                                    Carnê
                                                </button>
                                                <button
                                                    className="btn btn-primary"
                                                    style={{
                                                        marginLeft: 10,
                                                    }}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        addFormatText(
                                                            "{%cartao_credito%}"
                                                        );
                                                    }}
                                                >
                                                    Cartão de Crédito
                                                </button>
                                                <button
                                                    className="btn btn-primary"
                                                    style={{
                                                        marginLeft: 10,
                                                    }}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        addFormatText(
                                                            "{%link_whatsapp%}"
                                                        );
                                                    }}
                                                >
                                                    Link WhatsApp
                                                </button>
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                "form-group " +
                                                (error.name ? "has-error" : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Texto
                                            </label>
                                            <div className="col-md-9">
                                                <textarea
                                                    type="text"
                                                    className="form-control"
                                                    placeholder=""
                                                    rows={5}
                                                    ref={textRef}
                                                    value={text}
                                                    onChange={(e) =>
                                                        setText(e.target.value)
                                                    }
                                                ></textarea>
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                "form-group " +
                                                (error.file ? "has-error" : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Arquivo
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    placeholder=""
                                                    ref={fileRef}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                "form-group " +
                                                (error.name ? "has-error" : "")
                                            }
                                            style={{
                                                height: 200,
                                            }}
                                        >
                                            <label className="col-md-3 control-label">
                                                Família/Categoria da Campanha
                                            </label>
                                            <div className="col-md-9">
                                                <Autocomplete
                                                    getItemValue={(item) =>
                                                        item.category
                                                    }
                                                    items={categories}
                                                    renderInput={(props) => {
                                                        return (
                                                            <input
                                                                {...props}
                                                                className="form-control"
                                                                style={{
                                                                    width: "100%",
                                                                }}
                                                            />
                                                        );
                                                    }}
                                                    menuStyle={{
                                                        borderRadius: "3px",
                                                        boxShadow:
                                                            "0 2px 12px rgba(0, 0, 0, 0.1)",
                                                        background:
                                                            "rgba(255, 255, 255, 1)",
                                                        padding: "5px 5px",
                                                        fontSize: "90%",
                                                        position: "fixed",
                                                        overflow: "auto",
                                                        maxHeight: "50%", // TODO: don't cheat, let it flow to the bottom
                                                        cursor: "pointer",
                                                    }}
                                                    renderItem={(
                                                        item,
                                                        isHighlighted
                                                    ) => (
                                                        <div
                                                            style={{
                                                                background:
                                                                    isHighlighted
                                                                        ? "lightgray"
                                                                        : "white",
                                                                zIndex: 999999,
                                                            }}
                                                        >
                                                            {item.category}
                                                        </div>
                                                    )}
                                                    value={category}
                                                    onChange={(e) => {
                                                        setCategory(
                                                            e.target.value
                                                        );
                                                    }}
                                                    onSelect={(val) => {
                                                        setCategory(val);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-footer">
                                    <div className="form-group">
                                        <div className="col-md-9 col-md-offset-3">
                                            <button
                                                type="submit"
                                                className="btn btn-primary"
                                            >
                                                Salvar
                                            </button>
                                            <button
                                                type="button"
                                                onClick={handleCancelButton}
                                                className="btn btn-default"
                                            >
                                                Cancelar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default withRouter(SquareArtCampaignNew);
