/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";

import PropTypes from "prop-types";

import { Link, Redirect } from "react-router-dom";

import {
    logout,
    getUserName,
    TYPE_REGISTER,
    getType,
    TYPE_GENERAL_MANAGER,
    TYPE_GENERAL_BRANCH,
    TYPE_GENERAL_REGIONAL,
    TYPE_GAZINBOOK,
    TYPE_ADMIN,
    TYPE_REPORT,
    TYPE_SELLER,
    TYPE_CONTECOMIGO,
    TYPE_AFTERSALE,
    TYPE_CURRENT_PROMOTIONS,
} from "../../services/auth";

export default class Layout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            logout: false,
        };

        this.logout = this.logout.bind(this);
    }

    static propTypes = {
        // ...prop type definitions here
        menuActive: PropTypes.string.isRequired,
        pageTitle: PropTypes.string.isRequired,
    };

    logout(e) {
        e.preventDefault();
        this.setState({ logout: true });
        logout();
    }

    render() {
        return (
            <div className="app app-blue">
                {this.state.logout ? (
                    <Redirect to="/" push />
                ) : (
                    <React.Fragment>
                        <aside className="app-sidebar" id="sidebar">
                            <div className="sidebar-header">
                                <a className="sidebar-brand" href="#">
                                    <span className="highlight">Cartão</span>{" "}
                                    Express
                                </a>
                                <button
                                    type="button"
                                    className="sidebar-toggle"
                                >
                                    <i className="fa fa-times"></i>
                                </button>
                            </div>

                            {getType() === TYPE_CURRENT_PROMOTIONS ? (
                                <div className="sidebar-menu">
                                    <ul className="sidebar-nav">
                                        <li
                                            className={
                                                this.props.menuActive ===
                                                "current-promotions"
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            <Link to="/promocoes-vigentes">
                                                <div className="icon">
                                                    <i
                                                        className="fa fa-list"
                                                        aria-hidden="true"
                                                    ></i>
                                                </div>
                                                <div className="title">
                                                    Promoções Vigentes
                                                </div>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            ) : getType() === TYPE_AFTERSALE ? (
                                <div className="sidebar-menu">
                                    <ul className="sidebar-nav">
                                        <li
                                            className={
                                                this.props.menuActive ===
                                                "direct-mail"
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            <Link to="/malas-diretas">
                                                <div className="icon">
                                                    <i
                                                        className="fa fa-suitcase"
                                                        aria-hidden="true"
                                                    ></i>
                                                </div>
                                                <div className="title">
                                                    Mala Direta
                                                </div>
                                            </Link>
                                        </li>

                                        <li
                                            className={
                                                this.props.menuActive ===
                                                "forms"
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            <Link to="/formularios">
                                                <div className="icon">
                                                    <i
                                                        className="fa fa-list-alt"
                                                        aria-hidden="true"
                                                    ></i>
                                                </div>
                                                <div className="title">
                                                    Formulários de Pesquisa
                                                </div>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            ) : getType() === TYPE_GAZINBOOK ? (
                                <div className="sidebar-menu">
                                    <ul className="sidebar-nav">
                                        <li
                                            className={
                                                this.props.menuActive ===
                                                "posts"
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            <Link to="/posts">
                                                <div className="icon">
                                                    <i
                                                        className="fa fa-list"
                                                        aria-hidden="true"
                                                    ></i>
                                                </div>
                                                <div className="title">
                                                    Posts
                                                </div>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            ) : getType() === TYPE_REPORT ? (
                                <div className="sidebar-menu">
                                    <ul className="sidebar-nav">
                                        <li
                                            className={
                                                this.props.menuActive ===
                                                "report-by-date"
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            <Link to="/report-by-date">
                                                <div className="icon">
                                                    <i
                                                        className="fa fa-table"
                                                        aria-hidden="true"
                                                    ></i>
                                                </div>
                                                <div className="title">
                                                    Relatório Cartões
                                                </div>
                                            </Link>
                                        </li>

                                        <li
                                            className={
                                                this.props.menuActive ===
                                                "report-ranking-products"
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            <Link to="/report-ranking-products">
                                                <div className="icon">
                                                    <i
                                                        className="fa fa-table"
                                                        aria-hidden="true"
                                                    ></i>
                                                </div>
                                                <div className="title">
                                                    Relatório Ranking de
                                                    Produtos
                                                </div>
                                            </Link>
                                        </li>

                                        <li
                                            className={
                                                this.props.menuActive ===
                                                "report-all-users"
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            <Link to="/report-all-users">
                                                <div className="icon">
                                                    <i
                                                        className="fa fa-table"
                                                        aria-hidden="true"
                                                    ></i>
                                                </div>
                                                <div className="title">
                                                    Relatório Todos os Usuários
                                                </div>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            ) : getType() === TYPE_CONTECOMIGO ? (
                                <div className="sidebar-menu">
                                    <ul className="sidebar-nav">
                                        <li
                                            className={
                                                this.props.menuActive ===
                                                "contecomigo"
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            <Link to="/contecomigo">
                                                <div className="icon">
                                                    <i
                                                        className="fa fa-list"
                                                        aria-hidden="true"
                                                    ></i>
                                                </div>
                                                <div className="title">
                                                    Tabloide Conte Comigo
                                                </div>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            ) : (
                                <div className="sidebar-menu">
                                    <ul className="sidebar-nav">
                                        <li
                                            className={
                                                this.props.menuActive ===
                                                "dashboard"
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            <Link to="/">
                                                <div className="icon">
                                                    <i
                                                        className="fa fa-tasks"
                                                        aria-hidden="true"
                                                    ></i>
                                                </div>
                                                <div className="title">
                                                    Dashboard
                                                </div>
                                            </Link>
                                        </li>
                                        {getType() !== TYPE_GENERAL_MANAGER &&
                                        getType() !== TYPE_GENERAL_BRANCH &&
                                        getType() !== TYPE_GENERAL_REGIONAL ? (
                                            <li
                                                className={
                                                    this.props.menuActive ===
                                                    "new-card"
                                                        ? "active"
                                                        : ""
                                                }
                                            >
                                                <Link to="/novo-cartao">
                                                    <div className="icon">
                                                        <i
                                                            className="fa  fa-plus"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </div>
                                                    <div className="title">
                                                        Criar Cartão
                                                    </div>
                                                </Link>
                                            </li>
                                        ) : null}
                                        {getType() !== TYPE_GENERAL_MANAGER &&
                                        getType() !== TYPE_GENERAL_BRANCH &&
                                        getType() !== TYPE_GENERAL_REGIONAL &&
                                        getType() !== TYPE_SELLER ? (
                                            <li
                                                className={
                                                    this.props.menuActive ===
                                                    "registers"
                                                        ? "dropdown active"
                                                        : "dropdown"
                                                }
                                            >
                                                <a
                                                    href="#"
                                                    className="dropdown-toggle"
                                                    data-toggle="dropdown"
                                                >
                                                    <div className="icon">
                                                        <i
                                                            className="fa fa-file-o"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </div>
                                                    <div className="title">
                                                        Cadastros
                                                    </div>
                                                </a>
                                                <div className="dropdown-menu">
                                                    <ul>
                                                        <li className="section">
                                                            <i
                                                                className="fa fa-file-o"
                                                                aria-hidden="true"
                                                            ></i>{" "}
                                                            Sistema
                                                        </li>
                                                        <li>
                                                            <Link to="/produtos">
                                                                Produtos
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/filiais">
                                                                Filiais
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/produtos-oferta">
                                                                Produtos em
                                                                Oferta
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/user-change-password">
                                                                Trocar senha
                                                                usuário
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/ativar-usuario-por-cpf">
                                                                Ativar Usuário
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/campanhas">
                                                                Campanhas
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/anuncios">
                                                                Anúncios
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/mala-direta-tabloides">
                                                                Mala Direta
                                                                Tabloides
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/musicas-fundo">
                                                                Músicas de Fundo
                                                                (Se vira nos 30)
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/capas-campanhas">
                                                                Capas das
                                                                Campanhas
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/vinhetas">
                                                                Vinhetas
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/projetos-tabloide">
                                                                Projetos de
                                                                Tabloide
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/arte-quadrada-campanhas">
                                                                Campanhas Arte
                                                                Quadrada
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/arte-quadrada-anuncios">
                                                                Anuncios Arte
                                                                Quadrada
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/aprenda-mais">
                                                                Aprenda Mais
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/funil-vendas">
                                                                Funil de Vendas
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/malas-diretas-crm">
                                                                Malas Diretas
                                                                CRM
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/ofertas-vigentes/SOCIAL_MEDIA">
                                                                Redes Sociais
                                                                Vigentes
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/ofertas-vigentes/VT">
                                                                VTs Vigentes
                                                            </Link>
                                                        </li>
                                                        {/* <li><a href="uikits/card.html">Tabela de Preço</a></li> */}
                                                        {/* <li className="line"></li>
                                                            <li className="section"><i className="fa fa-file-o" aria-hidden="true"></i> Advanced Components
                                                            </li>
                                                            <li><a href="uikits/pricing-table.html">Pricing Table</a></li>
                                                            <li><a href="uikits/chart.html">Chart</a></li> */}
                                                    </ul>
                                                </div>
                                            </li>
                                        ) : null}
                                        {getType() !== TYPE_REGISTER &&
                                        getType() !== TYPE_SELLER ? (
                                            <li
                                                className={
                                                    this.props.menuActive ===
                                                    "notification-branch"
                                                        ? "active"
                                                        : ""
                                                }
                                            >
                                                <Link to="/notificar-filial">
                                                    <div className="icon">
                                                        <i
                                                            className="fa  fa-bell"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </div>
                                                    <div className="title">
                                                        Notificar Filial
                                                    </div>
                                                </Link>
                                            </li>
                                        ) : null}
                                        {getType() !== TYPE_REGISTER &&
                                        getType() !== TYPE_SELLER &&
                                        getType() !== TYPE_GENERAL_MANAGER &&
                                        getType() !== TYPE_GENERAL_BRANCH &&
                                        getType() !== TYPE_GENERAL_REGIONAL ? (
                                            <li
                                                className={
                                                    this.props.menuActive ===
                                                    "notification-state"
                                                        ? "active"
                                                        : ""
                                                }
                                            >
                                                <Link to="/notificar-estado">
                                                    <div className="icon">
                                                        <i
                                                            className="fa  fa-bell"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </div>
                                                    <div className="title">
                                                        Notificar Estado
                                                    </div>
                                                </Link>
                                            </li>
                                        ) : null}
                                        {getType() !== TYPE_REGISTER &&
                                        getType() !== TYPE_SELLER &&
                                        getType() !== TYPE_GENERAL_MANAGER &&
                                        getType() !== TYPE_GENERAL_BRANCH &&
                                        getType() !== TYPE_GENERAL_REGIONAL ? (
                                            <li
                                                className={
                                                    this.props.menuActive ===
                                                    "cpfs-authorized"
                                                        ? "active"
                                                        : ""
                                                }
                                            >
                                                <Link to="/cpfs-autorizado">
                                                    <div className="icon">
                                                        <i
                                                            className="fa  fa-lock"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </div>
                                                    <div className="title">
                                                        CPFs Autorizados
                                                    </div>
                                                </Link>
                                            </li>
                                        ) : null}

                                        {getType() !== TYPE_REGISTER &&
                                        getType() !== TYPE_SELLER &&
                                        getType() !== TYPE_GENERAL_MANAGER &&
                                        getType() !== TYPE_GENERAL_BRANCH &&
                                        getType() !== TYPE_GENERAL_REGIONAL ? (
                                            <li
                                                className={
                                                    this.props.menuActive ===
                                                    "report-by-date"
                                                        ? "active"
                                                        : ""
                                                }
                                            >
                                                <Link to="/report-by-date">
                                                    <div className="icon">
                                                        <i
                                                            className="fa fa-table"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </div>
                                                    <div className="title">
                                                        Relatório Cartões
                                                    </div>
                                                </Link>
                                            </li>
                                        ) : null}

                                        {getType() !== TYPE_REGISTER &&
                                        getType() !== TYPE_SELLER ? (
                                            <li
                                                className={
                                                    this.props.menuActive ===
                                                    "products-offer"
                                                        ? "active"
                                                        : ""
                                                }
                                            >
                                                <Link to="/produtos-oferta">
                                                    <div className="icon">
                                                        <i
                                                            className="fa fa-product-hunt"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </div>
                                                    <div className="title">
                                                        Produtos em Oferta
                                                    </div>
                                                </Link>
                                            </li>
                                        ) : null}

                                        {getType() !== TYPE_REGISTER &&
                                        getType() !== TYPE_SELLER ? (
                                            <li
                                                className={
                                                    this.props.menuActive ===
                                                    "report-all-sent-by-date"
                                                        ? "active"
                                                        : ""
                                                }
                                            >
                                                {/* <Link to="/report-all-sent-by-date">
                                                    <div className="icon">
                                                        <i
                                                            className="fa fa-table"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </div>
                                                    <div className="title">
                                                        Relatório de Envios CRM
                                                    </div>
                                                </Link> */}
                                            </li>
                                        ) : null}

                                        {getType() !== TYPE_REGISTER &&
                                        getType() !== TYPE_SELLER &&
                                        getType() !== TYPE_GENERAL_MANAGER &&
                                        getType() !== TYPE_GENERAL_BRANCH &&
                                        getType() !== TYPE_GENERAL_REGIONAL ? (
                                            <li
                                                className={
                                                    this.props.menuActive ===
                                                    "report-ranking-products"
                                                        ? "active"
                                                        : ""
                                                }
                                            >
                                                <Link to="/report-ranking-products">
                                                    <div className="icon">
                                                        <i
                                                            className="fa fa-table"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </div>
                                                    <div className="title">
                                                        Relatório Ranking de
                                                        Produtos
                                                    </div>
                                                </Link>
                                            </li>
                                        ) : null}

                                        {getType() !== TYPE_REGISTER &&
                                        getType() !== TYPE_SELLER &&
                                        getType() !== TYPE_GENERAL_MANAGER &&
                                        getType() !== TYPE_GENERAL_BRANCH &&
                                        getType() !== TYPE_GENERAL_REGIONAL ? (
                                            <li
                                                className={
                                                    this.props.menuActive ===
                                                    "report-clients-ranking"
                                                        ? "active"
                                                        : ""
                                                }
                                            >
                                                <Link to="/report-clients-ranking">
                                                    <div className="icon">
                                                        <i
                                                            className="fa fa-table"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </div>
                                                    <div className="title">
                                                        Relatório Ranking de
                                                        Clientes
                                                    </div>
                                                </Link>
                                            </li>
                                        ) : null}

                                        {getType() !== TYPE_REGISTER &&
                                        getType() !== TYPE_SELLER &&
                                        getType() !== TYPE_GENERAL_MANAGER &&
                                        getType() !== TYPE_GENERAL_BRANCH &&
                                        getType() !== TYPE_GENERAL_REGIONAL ? (
                                            <li
                                                className={
                                                    this.props.menuActive ===
                                                    "report-all-users"
                                                        ? "active"
                                                        : ""
                                                }
                                            >
                                                <Link to="/report-all-users">
                                                    <div className="icon">
                                                        <i
                                                            className="fa fa-table"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </div>
                                                    <div className="title">
                                                        Relatório Todos os
                                                        Usuários
                                                    </div>
                                                </Link>
                                            </li>
                                        ) : null}
                                        {getType() !== TYPE_REGISTER &&
                                        getType() !== TYPE_SELLER &&
                                        getType() !== TYPE_GENERAL_MANAGER &&
                                        getType() !== TYPE_GENERAL_BRANCH &&
                                        getType() !== TYPE_GENERAL_REGIONAL ? (
                                            <li
                                                className={
                                                    this.props.menuActive ===
                                                    "report-sends-sales"
                                                        ? "active"
                                                        : ""
                                                }
                                            >
                                                <Link to="/report-sents-sales">
                                                    <div className="icon">
                                                        <i
                                                            className="fa  fa-table"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </div>
                                                    <div className="title">
                                                        Relatório Envios x
                                                        Vendas
                                                    </div>
                                                </Link>
                                            </li>
                                        ) : null}
                                        {getType() !== TYPE_REGISTER &&
                                        getType() !== TYPE_SELLER &&
                                        getType() !== TYPE_GENERAL_MANAGER &&
                                        getType() !== TYPE_GENERAL_BRANCH &&
                                        getType() !== TYPE_GENERAL_REGIONAL ? (
                                            <li
                                                className={
                                                    this.props.menuActive ===
                                                    "report-negotiation"
                                                        ? "active"
                                                        : ""
                                                }
                                            >
                                                <Link to="/report-negotiations">
                                                    <div className="icon">
                                                        <i
                                                            className="fa  fa-table"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </div>
                                                    <div className="title">
                                                        Relatório Negociação
                                                    </div>
                                                </Link>
                                            </li>
                                        ) : null}
                                        {getType() !== TYPE_REGISTER &&
                                        getType() !== TYPE_SELLER &&
                                        getType() !== TYPE_GENERAL_MANAGER &&
                                        getType() !== TYPE_GENERAL_BRANCH &&
                                        getType() !== TYPE_GENERAL_REGIONAL ? (
                                            <li
                                                className={
                                                    this.props.menuActive ===
                                                    "importar-precos-express"
                                                        ? "active"
                                                        : ""
                                                }
                                            >
                                                <Link to="/importar-preco">
                                                    <div className="icon">
                                                        <i
                                                            className="fa  fa-download"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </div>
                                                    <div className="title">
                                                        Importar Preços Cartão
                                                        Express
                                                    </div>
                                                </Link>
                                            </li>
                                        ) : null}

                                        {/* {
                                            getType() !== TYPE_REGISTER ?
                                                <li className="dropdown">
                                                    <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                                                        <div className="icon">
                                                            <i className="fa fa-table" aria-hidden="true"></i>
                                                        </div>
                                                        <div className="title">Relatórios</div>
                                                    </a>
                                                    <div className="dropdown-menu">
                                                        <ul>
                                                            <li className="section"><i className="fa fa-file-o" aria-hidden="true"></i> Vendedor</li>
                                                            <li><a href="pages/form.html">Orçamentos</a></li>
                                                            <li className="line"></li>
                                                            <li className="section"><i className="fa fa-file-o" aria-hidden="true"></i> Filial</li>
                                                            <li><a href="pages/login.html">Orçamentos Convertidos em Vendas</a></li>
                                                            <li><a href="pages/login.html">Top Vendedores</a></li>
                                                        </ul>
                                                    </div>
                                                </li>
                                            :
                                                null
                                        } */}
                                    </ul>
                                </div>
                            )}

                            <div className="sidebar-footer"></div>
                        </aside>
                        <div className="app-container">
                            <nav className="navbar navbar-default" id="navbar">
                                <div className="container-fluid">
                                    <div className="navbar-collapse collapse in">
                                        <ul className="nav navbar-nav navbar-mobile">
                                            <li>
                                                <button
                                                    type="button"
                                                    className="sidebar-toggle"
                                                >
                                                    <i className="fa fa-bars"></i>
                                                </button>
                                            </li>
                                            <li className="logo">
                                                <a
                                                    className="navbar-brand"
                                                    href="#"
                                                >
                                                    <span className="highlight">
                                                        Cartão
                                                    </span>{" "}
                                                    Express
                                                </a>
                                            </li>
                                            <li>
                                                <button
                                                    type="button"
                                                    className="navbar-toggle"
                                                >
                                                    <img
                                                        className="profile-img"
                                                        src="assets/images/profile.png"
                                                        alt=""
                                                    />
                                                </button>
                                            </li>
                                        </ul>
                                        <ul className="nav navbar-nav navbar-left">
                                            <li className="navbar-title">
                                                {this.props.pageTitle}
                                            </li>
                                            {this.props.enableSearchBar ? (
                                                <li className="navbar-search hidden-sm">
                                                    <input
                                                        id="search"
                                                        type="text"
                                                        placeholder="Procurar.."
                                                        value={
                                                            this.props
                                                                .searchTerm
                                                        }
                                                        // onBlur={
                                                        //     this.props
                                                        //         .handleSearch
                                                        // }
                                                        onChange={
                                                            this.props
                                                                .searchOnChange
                                                        }
                                                        onKeyDown={(e) =>
                                                            e.key === "Enter"
                                                                ? this.props.handleSearch()
                                                                : null
                                                        }
                                                    />
                                                    <button
                                                        className="btn-search"
                                                        onClick={
                                                            this.props
                                                                .handleSearch
                                                        }
                                                    >
                                                        <i className="fa fa-search"></i>
                                                    </button>
                                                </li>
                                            ) : null}
                                        </ul>
                                        <ul className="nav navbar-nav navbar-right">
                                            <li className="dropdown profile">
                                                <a
                                                    href="#"
                                                    className="dropdown-toggle"
                                                    data-toggle="dropdown"
                                                >
                                                    <img
                                                        className="profile-img"
                                                        src={require("../../assets/images/user-male-icon.png")}
                                                        alt=""
                                                    />
                                                    <div className="title">
                                                        Profile
                                                    </div>
                                                </a>
                                                <div className="dropdown-menu">
                                                    <div className="profile-info">
                                                        <h4 className="username">
                                                            {getUserName()}
                                                        </h4>
                                                    </div>
                                                    <ul className="action">
                                                        <li>
                                                            <a
                                                                href="#"
                                                                style={{
                                                                    color: "#ccc",
                                                                    cursor: "not-allowed",
                                                                }}
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.preventDefault();
                                                                }}
                                                            >
                                                                Configurações
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="#"
                                                                onClick={
                                                                    this.logout
                                                                }
                                                            >
                                                                Sair
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </nav>

                            {this.props.children}

                            <footer className="app-footer">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="footer-copyright">
                                            Copyright ©{" "}
                                            {new Date().getFullYear()} Raio-X do
                                            Mercado.
                                        </div>
                                    </div>
                                </div>
                            </footer>
                        </div>
                    </React.Fragment>
                )}
            </div>
        );
    }
}
