import React, { createContext, useState, useContext } from "react";

const FormResultsContext = createContext({
    month: null,
    year: null,
    changeMonth: () => {},
    changeYear: () => {},
});

const FormResultsProvider = ({ children }) => {
    const [month, setMonth] = useState(new Date().getMonth() + 1);
    const [year, setYear] = useState(new Date().getFullYear());

    const changeMonth = (newMonth) => {
        setMonth(parseInt(newMonth));
    };
    const changeYear = (newYear) => {
        setYear(parseInt(newYear));
    };

    return (
        <FormResultsContext.Provider
            value={{
                month,
                year,
                changeMonth,
                changeYear,
            }}
        >
            {children}
        </FormResultsContext.Provider>
    );
};

function useFormResults() {
    const context = useContext(FormResultsContext);

    return context;
}

export { FormResultsProvider, useFormResults };
