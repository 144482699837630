import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import Layout from "../../components/Layout";

import api from "../../services/api";

import Select from "react-select";

import Swal from "sweetalert2";

const BranchTabloidNew = (props) => {
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState({});
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [tabloid, setTabloid] = useState(null);
    const [branches, setBranches] = useState([]);
    const [branchesSelected, setBranchesSelected] = useState([]);

    useEffect(() => {
        setLoaded(true);
    }, []);

    useEffect(() => {
        async function loadBranches() {
            const responseGetBranches = await api.get("/branch");
            setBranches(
                responseGetBranches.data.map((branch) => ({
                    value: branch._id,
                    label: `${branch.cidade_estado} - ${branch.email}`,
                }))
            );
        }

        loadBranches();
    }, []);

    function handleCancelButton(event) {
        props.history.push("/posts");
    }

    async function handleSubmitForm(event) {
        event.preventDefault();

        setLoaded(false);

        console.log(branchesSelected);

        try {
            const formData = new FormData();
            formData.append(
                "branches",
                branchesSelected.length > 0
                    ? branchesSelected.map((branch) => branch.value)
                    : []
            );
            formData.append("branch_tabloid_file", tabloid);
            formData.append("startDate", startDate);
            formData.append("endDate", endDate);
            const responseCreatePost = await api.post(
                "/branch-tabloid",
                formData
            );

            setLoaded(true);
            if (responseCreatePost.status === 201) {
                setLoaded(true);

                Swal.fire({
                    type: "success",
                    title: "Salvo!",
                    text: "O tabloide foi salvo com sucesso!",
                }).then(() => props.history.push("/contecomigo"));
            } else {
                Swal.fire({
                    type: "error",
                    title: "Oops...",
                    text:
                        "Tivemos um problema ao salvar esse novo tabloide. Contate o suporte!",
                });

                setLoaded(true);
            }
        } catch (e) {
            Swal.fire({
                type: "error",
                title: "Oops...",
                text:
                    "Tivemos um problema ao salvar esse novo tabloid. Contate o suporte!",
            });

            setLoaded(true);
        }
    }

    function selectAllOptions() {
        setBranchesSelected(branches);
    }

    function removeAllOptions() {
        setBranchesSelected([]);
    }

    function selectAllFromState(event) {
        const branchesFiltered = branches.filter(
            (branch, item) =>
                branch.label.indexOf(`-${event.target.value}`) !== -1
        );
        setBranchesSelected(branchesFiltered);
    }

    return (
        <Layout menuActive="contecomigo" pageTitle="Novo Tabloide">
            <div className="row">
                <div className="col-xs-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">Novo Tabloide</div>
                        </div>
                        <div
                            className={`card-body ${loaded ? "" : "__loading"}`}
                        >
                            <div className="loader-container text-center">
                                <div className="icon">
                                    <div className="sk-wave">
                                        <div className="sk-rect sk-rect1"></div>
                                        <div className="sk-rect sk-rect2"></div>
                                        <div className="sk-rect sk-rect3"></div>
                                        <div className="sk-rect sk-rect4"></div>
                                        <div className="sk-rect sk-rect5"></div>
                                    </div>
                                </div>
                                <div className="title">Carregando</div>
                            </div>

                            <form
                                className="form form-horizontal"
                                onSubmit={handleSubmitForm}
                            >
                                <div className="section">
                                    <div className="section-title">
                                        Informações Básicas
                                    </div>
                                    <div className="section-body">
                                        <div className="form-group">
                                            <label className="col-md-3 control-label">
                                                Opções
                                            </label>
                                            <div className="col-md-9">
                                                <button
                                                    className="btn btn-primary"
                                                    type="button"
                                                    onClick={() =>
                                                        selectAllOptions()
                                                    }
                                                >
                                                    Selecionar todas
                                                </button>
                                                <button
                                                    className="btn btn-danger"
                                                    type="button"
                                                    onClick={() =>
                                                        removeAllOptions()
                                                    }
                                                >
                                                    Remover todas
                                                </button>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-md-3 control-label">
                                                Selecione por Estado
                                            </label>
                                            <div className="col-md-9">
                                                <select
                                                    className="form-control"
                                                    onChange={(e) =>
                                                        selectAllFromState(e)
                                                    }
                                                >
                                                    <option value="AC">
                                                        Acre
                                                    </option>
                                                    <option value="AL">
                                                        Alagoas
                                                    </option>
                                                    <option value="AP">
                                                        Amapá
                                                    </option>
                                                    <option value="AM">
                                                        Amazonas
                                                    </option>
                                                    <option value="BA">
                                                        Bahia
                                                    </option>
                                                    <option value="CE">
                                                        Ceará
                                                    </option>
                                                    <option value="DF">
                                                        Distrito Federal
                                                    </option>
                                                    <option value="ES">
                                                        Espírito Santo
                                                    </option>
                                                    <option value="GO">
                                                        Goiás
                                                    </option>
                                                    <option value="MA">
                                                        Maranhão
                                                    </option>
                                                    <option value="MT">
                                                        Mato Grosso
                                                    </option>
                                                    <option value="MS">
                                                        Mato Grosso do Sul
                                                    </option>
                                                    <option value="MG">
                                                        Minas Gerais
                                                    </option>
                                                    <option value="PA">
                                                        Pará
                                                    </option>
                                                    <option value="PB">
                                                        Paraíba
                                                    </option>
                                                    <option value="PR">
                                                        Paraná
                                                    </option>
                                                    <option value="PE">
                                                        Pernambuco
                                                    </option>
                                                    <option value="PI">
                                                        Piauí
                                                    </option>
                                                    <option value="RJ">
                                                        Rio de Janeiro
                                                    </option>
                                                    <option value="RN">
                                                        Rio Grande do Norte
                                                    </option>
                                                    <option value="RS">
                                                        Rio Grande do Sul
                                                    </option>
                                                    <option value="RO">
                                                        Rondônia
                                                    </option>
                                                    <option value="RR">
                                                        Roraima
                                                    </option>
                                                    <option value="SC">
                                                        Santa Catarina
                                                    </option>
                                                    <option value="SP">
                                                        São Paulo
                                                    </option>
                                                    <option value="SE">
                                                        Sergipe
                                                    </option>
                                                    <option value="TO">
                                                        Tocantins
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-md-3 control-label">
                                                Filial
                                            </label>
                                            <div className="col-md-9">
                                                <Select
                                                    isMulti
                                                    placeholder="Selecione as filiais"
                                                    value={branchesSelected}
                                                    options={branches}
                                                    onChange={(
                                                        selectedOptions
                                                    ) => {
                                                        setBranchesSelected(
                                                            selectedOptions
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                "form-group " +
                                                (error.tabloid
                                                    ? "has-error"
                                                    : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Tabloid
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    placeholder=""
                                                    onChange={(e) => {
                                                        setTabloid(
                                                            e.target.files[0]
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-md-3 control-label">
                                                Data de Início
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    placeholder=""
                                                    value={startDate}
                                                    onChange={(e) => {
                                                        const {
                                                            value,
                                                        } = e.target;

                                                        setStartDate(value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-md-3 control-label">
                                                Data de Término
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    placeholder=""
                                                    value={endDate}
                                                    onChange={(e) => {
                                                        const {
                                                            value,
                                                        } = e.target;

                                                        setEndDate(value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-footer">
                                    <div className="form-group">
                                        <div className="col-md-9 col-md-offset-3">
                                            <button
                                                type="submit"
                                                className="btn btn-primary"
                                            >
                                                Salvar
                                            </button>
                                            <button
                                                type="button"
                                                onClick={handleCancelButton}
                                                className="btn btn-default"
                                            >
                                                Cancelar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default withRouter(BranchTabloidNew);
