import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import Layout from "../../components/Layout";

import * as moment from "moment-timezone";

import { apiCRMv3 } from "../../services/api";

import { DateTime } from "luxon";

import Swal from "sweetalert2";
import {
    getType,
    TYPE_GENERAL_BRANCH,
    TYPE_GENERAL_REGIONAL,
} from "../../services/auth";

export const CurrentPromotionList = (props) => {
    const [currentPromotions, setCurrentPromotions] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [loaded, setLoaded] = useState(true);
    const [actualPage, setActualPage] = useState(1);
    const [pages, setPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(100);
    const [promotionFilterByDate, setPromotionFilterByDate] = useState("all");

    useEffect(() => {
        loadCurrentPromotions();
    }, []);

    const loadCurrentPromotions = async (
        pageParam = null,
        itemsPerPageParam = null,
        promotionFilterByDateParam = null
    ) => {
        setLoaded(false);
        let URLGetCurrentPromotions = `/current-promotions?page=${
            pageParam ? pageParam : actualPage
        }&promotionFilterByDate=${
            promotionFilterByDateParam
                ? promotionFilterByDateParam
                : promotionFilterByDate
        }&itemsPerPage=${itemsPerPageParam ? itemsPerPageParam : 100}`;
        if (searchTerm !== "") {
            URLGetCurrentPromotions += `&productCode=${searchTerm}`;
        }
        const returnCurrentPromotions = await apiCRMv3.get(
            URLGetCurrentPromotions
        );
        if (returnCurrentPromotions.status === 500) {
            Swal.fire({
                type: "error",
                title: "Erro!",
                text: "Tivemos um problema ao buscar as promoções vigentes. Contate o suporte!",
            }).then(() => this.props.history.push("/"));
            return;
        }

        setCurrentPromotions(
            returnCurrentPromotions.data.data.currentPromotions
        );
        setPages(
            Math.ceil(
                returnCurrentPromotions.data.data.currentPromotionsCount /
                    (itemsPerPageParam ? itemsPerPageParam : itemsPerPage)
            )
        );
        setLoaded(true);
    };

    const handleDeleteButton = async (currentPromotion) => {
        setLoaded(false);
        Swal.fire({
            title: `Tem certeza que deseja excluir a promoção?`,
            text: "Ao excluir esse registro, a promoção será apagada sem retorno!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim, quero excluir!",
            cancelButtonText: "Cancelar",
        }).then(async function (result) {
            if (result.value) {
                const deleted = await apiCRMv3.delete(
                    `/current-promotions/${currentPromotion.id}`
                );
                if (deleted.status === 204) {
                    setActualPage(1);
                    setSearchTerm("");
                    loadCurrentPromotions(1);
                    Swal.fire(
                        "Excluído!",
                        "A promoção foi excluída com sucesso de todas as filiais!",
                        "success"
                    );
                    setLoaded(true);
                } else {
                    Swal.fire(
                        "Oops...",
                        "Não foi possível excluir a promoção agora. Por favor contate o suporte!",
                        "error"
                    ).then(() => {
                        setLoaded(true);
                    });
                }
            } else {
                setLoaded(true);
                setSearchTerm("");
            }
        });
    };

    const showBranchesOfPromotion = (currentPromotion) => {
        Swal.fire("Filiais", currentPromotion.branches.join(", "), "info");
    };

    const uploadXlsxWithPromotions = async () => {
        try {
            const { value: file } = await Swal.fire({
                title: "Escolha o arquivo do Excel",
                input: "file",
                inputAttributes: {
                    accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    "aria-label": "Envie a planilha de ofertas aqui",
                },
            });
            if (file) {
                console.log(file);
                setLoaded(false);
                const formData = new FormData();
                formData.append("promotions", file);
                const responseCreateByXlsx = await apiCRMv3.post(
                    `/current-promotions/create-by-xlsx`,
                    formData
                );
                await Swal.fire(
                    "Arquivo com promoções enviado!",
                    "",
                    "success"
                );
                if (
                    responseCreateByXlsx.data.data.notImportedPromotions &&
                    responseCreateByXlsx.data.data.notImportedPromotions
                        .length > 0
                ) {
                    let notImportedPromotionsText = `<ul class="list-unstyled text-left">`;
                    for (const notImportedPromotion of responseCreateByXlsx.data
                        .data.notImportedPromotions) {
                        const { currentPromotion, reason } =
                            notImportedPromotion;
                        notImportedPromotionsText += `<li><b>Promoção</b>: ${
                            currentPromotion["DATA_INICIO"]
                                ? DateTime.fromFormat(
                                      currentPromotion["DATA_INICIO"],
                                      "L/d/yy"
                                  ).toFormat("dd/LL/yyyy")
                                : ""
                        } até ${
                            currentPromotion["DATA_FINAL"]
                                ? DateTime.fromFormat(
                                      currentPromotion["DATA_FINAL"],
                                      "L/d/yy"
                                  ).toFormat("dd/LL/yyyy")
                                : ""
                        }<br /><b>Produto</b>: ${
                            currentPromotion["CODIGO_PRODUTO"]
                                ? currentPromotion["CODIGO_PRODUTO"]
                                : ""
                        }<br /><b>Motivo</b>: ${reason}</li><br />`;
                    }
                    notImportedPromotionsText += "</ul>";
                    await Swal.fire({
                        title: "Promoções não importadas",
                        html: notImportedPromotionsText,
                        type: "warning",
                    });
                } else {
                    await Swal.fire(
                        "Importado com sucesso!",
                        "Todas as promoções foram importadas com sucesso!",
                        "success"
                    );
                }
                loadCurrentPromotions(1);
                setLoaded(true);
            }
        } catch (error) {
            setLoaded(true);
            Swal.fire(
                "Ops",
                "Tivemos alguns problemas para salvar as ofertas da planilha enviada. Por favor, contate o suporte!",
                "error"
            );
        }
    };

    return (
        <Layout
            menuActive="current-promotions"
            pageTitle="Promoções Vigentes"
            enableSearchBar={true}
            searchTerm={searchTerm}
            searchOnChange={(e) => {
                const { value } = e.target;
                setSearchTerm(value);
            }}
            handleSearch={() => {
                setActualPage(1);
                loadCurrentPromotions(1);
            }}
        >
            <div className="row">
                <div className="col-xs-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">Promoções Vigentes</div>
                            <ul className="card-action">
                                <li>
                                    <Link
                                        to="/promocoes-vigentes-nova"
                                        className="btn btn-success"
                                        style={{
                                            width: "100%",
                                        }}
                                    >
                                        Nova <i className="fa fa-plus"></i>
                                    </Link>
                                </li>
                                <li>
                                    <a
                                        href={
                                            process.env.PUBLIC_URL +
                                            "/PROMOCOES_VIGENTES_BASE.xlsx"
                                        }
                                        download="PROMOCOES_VIGENTES_BASE"
                                        className="btn btn-primary"
                                        style={{
                                            width: "100%",
                                        }}
                                    >
                                        Download Planilha de Ofertas Base{" "}
                                        <i className="fa fa-download"></i>
                                    </a>
                                </li>
                                <li>
                                    <button
                                        className="btn btn-default"
                                        style={{
                                            width: "100%",
                                        }}
                                        onClick={(e) => {
                                            uploadXlsxWithPromotions();
                                        }}
                                    >
                                        Enviar planilha de ofertas{" "}
                                        <i className="fa fa-upload"></i>
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div
                            className={`card-body table-responsive ${
                                loaded ? "" : "__loading"
                            }`}
                        >
                            <div class="loader-container text-center">
                                <div class="icon">
                                    <div class="sk-wave">
                                        <div class="sk-rect sk-rect1"></div>
                                        <div class="sk-rect sk-rect2"></div>
                                        <div class="sk-rect sk-rect3"></div>
                                        <div class="sk-rect sk-rect4"></div>
                                        <div class="sk-rect sk-rect5"></div>
                                    </div>
                                </div>
                                <div class="title">Carregando</div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-md-6">
                                    <h5>Visualizar promoções:</h5>
                                    <div class="radio-inline">
                                        <label>
                                            <input
                                                type="radio"
                                                name="promotionsFilterByDate"
                                                value="overdue"
                                                checked={
                                                    promotionFilterByDate ===
                                                    "overdue"
                                                }
                                                onChange={(e) => {
                                                    const { value } = e.target;
                                                    setPromotionFilterByDate(
                                                        value
                                                    );
                                                    loadCurrentPromotions(
                                                        1,
                                                        itemsPerPage,
                                                        value
                                                    );
                                                }}
                                            />
                                            Vencidas
                                        </label>
                                    </div>
                                    <div class="radio-inline">
                                        <label>
                                            <input
                                                type="radio"
                                                name="promotionsFilterByDate"
                                                value="current"
                                                checked={
                                                    promotionFilterByDate ===
                                                    "current"
                                                }
                                                onChange={(e) => {
                                                    const { value } = e.target;
                                                    setPromotionFilterByDate(
                                                        value
                                                    );
                                                    loadCurrentPromotions(
                                                        1,
                                                        itemsPerPage,
                                                        value
                                                    );
                                                }}
                                            />
                                            Vigentes
                                        </label>
                                    </div>
                                    <div class="radio-inline">
                                        <label>
                                            <input
                                                type="radio"
                                                name="promotionsFilterByDate"
                                                value="future"
                                                checked={
                                                    promotionFilterByDate ===
                                                    "future"
                                                }
                                                onChange={(e) => {
                                                    const { value } = e.target;
                                                    setPromotionFilterByDate(
                                                        value
                                                    );
                                                    loadCurrentPromotions(
                                                        1,
                                                        itemsPerPage,
                                                        value
                                                    );
                                                }}
                                            />
                                            Futura
                                        </label>
                                    </div>
                                    <div class="radio-inline">
                                        <label>
                                            <input
                                                type="radio"
                                                name="promotionsFilterByDate"
                                                value="all"
                                                checked={
                                                    promotionFilterByDate ===
                                                    "all"
                                                }
                                                onChange={(e) => {
                                                    const { value } = e.target;
                                                    setPromotionFilterByDate(
                                                        value
                                                    );
                                                    loadCurrentPromotions(
                                                        1,
                                                        itemsPerPage,
                                                        value
                                                    );
                                                }}
                                            />
                                            Todas
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-xs-12 col-md-1">
                                    <h5>Itens por página</h5>
                                    <select
                                        value={itemsPerPage}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            setItemsPerPage(Number(value));
                                            loadCurrentPromotions(
                                                1,
                                                Number(value)
                                            );
                                        }}
                                        className="form-control"
                                    >
                                        <option value={10}>10</option>
                                        <option value={20}>20</option>
                                        <option value={50}>50</option>
                                        <option value={100}>100</option>
                                        <option value={500}>500</option>
                                        <option value={1000}>1000</option>
                                    </select>
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-xs-12">
                                    <table className="table table-striped table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th
                                                    style={{
                                                        width: "200px",
                                                    }}
                                                >
                                                    Filiais
                                                </th>
                                                <th>Data Início</th>
                                                <th>Data Fim</th>
                                                <th>Produto</th>
                                                <th>Preço à Vista</th>
                                                <th>Preço Cartão de Crédito</th>
                                                <th>Prêmio</th>
                                                <th>Valor do Price</th>
                                                <th>É Prioridade?</th>
                                                <th>Total Promos.</th>
                                                <th>Opções</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentPromotions.map(
                                                (
                                                    currentPromotion,
                                                    indexCurrentPromotion
                                                ) => {
                                                    return (
                                                        <tr
                                                            key={
                                                                indexCurrentPromotion
                                                            }
                                                        >
                                                            <td
                                                                style={{
                                                                    width: "200px",
                                                                }}
                                                            >
                                                                <div className="col-xs-12">
                                                                    <div className="col-xs-12">
                                                                        <button
                                                                            className="btn btn-xs btn-primary"
                                                                            onClick={() => {
                                                                                showBranchesOfPromotion(
                                                                                    currentPromotion
                                                                                );
                                                                            }}
                                                                            style={{
                                                                                width: "100%",
                                                                            }}
                                                                        >
                                                                            Ver
                                                                            filiais
                                                                            <div className="icon fa fa-view-o"></div>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {moment
                                                                    .tz(
                                                                        currentPromotion.startDate,
                                                                        "America/Sao_Paulo"
                                                                    )
                                                                    .format(
                                                                        "DD/MM/YYYY"
                                                                    )}
                                                            </td>
                                                            <td>
                                                                {moment
                                                                    .tz(
                                                                        currentPromotion.endDate,
                                                                        "America/Sao_Paulo"
                                                                    )
                                                                    .format(
                                                                        "DD/MM/YYYY"
                                                                    )}
                                                            </td>
                                                            <td>
                                                                {
                                                                    currentPromotion
                                                                        .product
                                                                        .name
                                                                }
                                                            </td>
                                                            <td>
                                                                {Number(
                                                                    currentPromotion.sightPrice
                                                                ).toLocaleString(
                                                                    "pt-BR",
                                                                    {
                                                                        minimumFractionDigits: 2,
                                                                        style: "currency",
                                                                        currency:
                                                                            "BRL",
                                                                    }
                                                                )}
                                                            </td>
                                                            <td>
                                                                {Number(
                                                                    currentPromotion.creditCardPrice
                                                                ).toLocaleString(
                                                                    "pt-BR",
                                                                    {
                                                                        minimumFractionDigits: 2,
                                                                        style: "currency",
                                                                        currency:
                                                                            "BRL",
                                                                    }
                                                                )}
                                                            </td>
                                                            <td>
                                                                {Number(
                                                                    currentPromotion.award
                                                                ).toLocaleString(
                                                                    "pt-BR",
                                                                    {
                                                                        minimumFractionDigits: 2,
                                                                        style: "currency",
                                                                        currency:
                                                                            "BRL",
                                                                    }
                                                                )}
                                                            </td>
                                                            <td>
                                                                {Number(
                                                                    currentPromotion.price
                                                                ).toLocaleString(
                                                                    "pt-BR",
                                                                    {
                                                                        minimumFractionDigits: 2,
                                                                        style: "currency",
                                                                        currency:
                                                                            "BRL",
                                                                    }
                                                                )}
                                                            </td>
                                                            <td>
                                                                {currentPromotion.isPriority
                                                                    ? "Sim"
                                                                    : "Não"}
                                                            </td>
                                                            <td>
                                                                {
                                                                    currentPromotion
                                                                        .promotions
                                                                        .length
                                                                }
                                                            </td>
                                                            <td>
                                                                <div className="col-xs-12">
                                                                    <div className="col-xs-12">
                                                                        <Link
                                                                            className="btn btn-xs btn-warning"
                                                                            to={`/promocoes-vigentes/${currentPromotion.id}`}
                                                                            style={{
                                                                                width: "100%",
                                                                            }}
                                                                        >
                                                                            Editar{" "}
                                                                            <div className="icon fa fa-pencil"></div>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xs-12">
                                                                    <div className="col-xs-12">
                                                                        <button
                                                                            className="btn btn-xs btn-danger"
                                                                            onClick={() => {
                                                                                handleDeleteButton(
                                                                                    currentPromotion
                                                                                );
                                                                            }}
                                                                            style={{
                                                                                width: "100%",
                                                                            }}
                                                                        >
                                                                            Excluir{" "}
                                                                            <div className="icon fa fa-trash-o"></div>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {pages !== 0 ? (
                                <div className="row">
                                    <div className="col-xs-12">
                                        <ul className="pagination">
                                            {Array(pages)
                                                .fill()
                                                .map((_, page) => (
                                                    <li
                                                        className={
                                                            page + 1 ===
                                                            actualPage
                                                                ? "active"
                                                                : ""
                                                        }
                                                    >
                                                        <a
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                setActualPage(
                                                                    page + 1
                                                                );
                                                                loadCurrentPromotions(
                                                                    page + 1
                                                                );
                                                            }}
                                                        >
                                                            {page + 1}
                                                        </a>
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};
