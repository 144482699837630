import React, { useState, useEffect, useRef } from "react";

import * as moment from "moment-timezone";

import Layout from "../../components/Layout";

import api, { apiCRMv3 } from "../../services/api";
import { getBranches } from "../../services/auth";

import Select from "react-select";

import Swal from "sweetalert2";

import CurrencyInput from "react-currency-input";

import MultiselectTwoSides from "react-multiselect-two-sides";

const imageMimeType = /image\/(png|jpg|jpeg)/i;

export const CurrentOfferNew = (props) => {
    const [loaded, setLoaded] = useState(false);
    const [branches, setBranches] = useState([]);
    const [selectedBranches, setSelectedBranches] = useState([]);
    const [beginDate, setBeginDate] = useState(moment().format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
    const [currentOfferType, setCurrentOfferType] = useState("SOCIAL_MEDIA");
    const [name, setName] = useState("");
    const [currentOfferProductCode, setCurrentOfferProductCode] = useState("");
    const [currentOfferVtFile, setCurrentOfferVtFile] = useState(null);
    const [currentOfferVtCoverFile, setCurrentOfferVtCoverFile] =
        useState(null);
    const [currentOfferVtDescription, setCurrentOfferVtDescription] =
        useState("");
    const [currentOfferSocialMediaLink, setCurrentOfferSocialMediaLink] =
        useState("");

    // this.state = {
    //     loaded: false,
    //     productOffer: {
    //         _id: "",
    //         product: { value: "", label: "Todas" },
    //         date_offer_init: moment().format("YYYY-MM-DD"),
    //         date_offer_end: moment().format("YYYY-MM-DD"),
    //         price: 0,
    //         total_amount: 0,
    //         interest_free: false,
    //         no_entrance: false,
    //         credit_card: false,
    //         carne: false,
    //         payment_x: 10,
    //         branches: [],
    //     },
    //     branches: [],
    //     products: [],
    // };

    useEffect(() => {
        const load = async () => {
            const returnBranches = await api.get("/branch");
            // const branches = getBranches();
            // let branchesToSelect = [];
            const branchesFiltered = returnBranches.data.filter(
                (value, index, self) =>
                    index ===
                    self.findIndex((t) => t.codigo_loja === value.codigo_loja)
            );
            const newBranches = await branchesFiltered.map((branch) => {
                if (branch.codigo_loja.indexOf(".") !== -1) {
                    return {
                        value: branch.codigo_loja.replace(".", "000"),
                        label: `${branch.codigo_loja.replace(".", "000")} ${
                            branch.cidade_estado
                        }`,
                    };
                } else {
                    if (branch.codigo_loja.indexOf("240") !== -1) {
                        return {
                            value: branch.codigo_loja,
                            label: `${branch.codigo_loja} ${branch.cidade_estado}`,
                        };
                    } else {
                        return {
                            value: `10${branch.codigo_loja}`,
                            label: `10${branch.codigo_loja} ${branch.cidade_estado}`,
                        };
                    }
                }
            });
            setBranches(newBranches);
            setLoaded(true);
        };
        load();
    }, []);

    const handleCancelButton = (event) => {
        Swal.fire({
            title: "Tem certeza que deseja cancelar?",
            text: "Cancelar fará você perder todas as modificações feitas até o momento!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim, quero cancelar!",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.value) {
                Swal.fire(
                    "Cancelado!",
                    "Você irá voltar para a tela de listagem.",
                    "success"
                );

                props.history.push("/produtos-oferta");
            }
        });
    };

    const handleChangeSelectedBranches = (value) => {
        console.log(value);
        setSelectedBranches(value);
    };

    const submitCurrentOfferSocialMedia = async () => {
        try {
            const sendCurrentOfferSocialMedia = {
                name: name,
                link: currentOfferSocialMediaLink,
                branches: selectedBranches,
                beginDate: beginDate,
                endDate: endDate,
            };

            await apiCRMv3.post(
                `/current-offers/create-social-media-offer`,
                sendCurrentOfferSocialMedia
            );

            return true;
        } catch (error) {
            return false;
        }
    };

    const submitCurrentOfferVT = async () => {
        try {
            const formData = new FormData();
            formData.append("name", name);
            formData.append("description", currentOfferVtDescription);
            formData.append("current_offer_vt", currentOfferVtFile);
            formData.append("current_offer_vt_cover", currentOfferVtCoverFile);
            formData.append("beginDate", beginDate);
            formData.append("endDate", endDate);
            formData.append("productCode", currentOfferProductCode);
            for (const selectedBranch of selectedBranches) {
                formData.append("branches[]", selectedBranch);
            }
            console.log(formData);

            await apiCRMv3.post(`/current-offers/create-vt-offer`, formData);
            return true;
        } catch (error) {
            return false;
        }
    };

    const handleSubmitForm = async (event) => {
        event.preventDefault();
        setLoaded(false);
        try {
            let submitOk = false;
            if (currentOfferType === "VT") {
                submitOk = await submitCurrentOfferVT();
            } else if (currentOfferType === "SOCIAL_MEDIA") {
                submitOk = await submitCurrentOfferSocialMedia();
            }
            if (submitOk) {
                Swal.fire({
                    type: "success",
                    title: `Oferta salva!`,
                    text: `A oferta foi salva com sucesso!`,
                }).then(() => props.history.push("/dashboard"));
            } else {
                Swal.fire({
                    type: "error",
                    title: "Oops...",
                    text: `Tivemos um problema ao salvar a oferta. Contate o suporte!`,
                });
                setLoaded(true);
            }
        } catch (e) {
            Swal.fire({
                type: "error",
                title: "Oops...",
                text: `Tivemos um problema ao salvar a oferta. Contate o suporte!`,
            });
            setLoaded(true);
        }
    };

    return (
        <Layout menuActive="registers" pageTitle="Nova Oferta Vigente">
            <div className="row">
                <div className="col-xs-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">
                                Nova Oferta Vigente
                            </div>
                        </div>
                        <div
                            className={`card-body ${loaded ? "" : "__loading"}`}
                        >
                            <div className="loader-container text-center">
                                <div className="icon">
                                    <div className="sk-wave">
                                        <div className="sk-rect sk-rect1"></div>
                                        <div className="sk-rect sk-rect2"></div>
                                        <div className="sk-rect sk-rect3"></div>
                                        <div className="sk-rect sk-rect4"></div>
                                        <div className="sk-rect sk-rect5"></div>
                                    </div>
                                </div>
                                <div className="title">Carregando</div>
                            </div>

                            <form
                                className="form form-horizontal"
                                onSubmit={handleSubmitForm}
                            >
                                <div className="section">
                                    <div className="section-title">
                                        Dados da Oferta
                                    </div>
                                    <div className="section-body">
                                        <div className={"form-group"}>
                                            <label className="col-md-3 control-label">
                                                Nome
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder=""
                                                    value={name}
                                                    onChange={(event) => {
                                                        setName(
                                                            event.target.value
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-offset-3 col-md-9">
                                            <div
                                                class="alert alert-warning"
                                                role="alert"
                                            >
                                                Para filtrar por estado, basta
                                                utilizar, por exemplo: "-PR" ou
                                                "-GO" e clicar na seta para
                                                enviar todas as filiais
                                                filtradas do lado esquerdo para
                                                o direito
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-md-3 control-label">
                                                Filiais
                                            </label>
                                            <div className="col-md-9">
                                                <MultiselectTwoSides
                                                    options={branches}
                                                    value={selectedBranches}
                                                    className="msts_theme_example"
                                                    onChange={
                                                        handleChangeSelectedBranches
                                                    }
                                                    availableHeader="Disponíveis"
                                                    availableFooter={`Disponíveis: ${
                                                        branches.length -
                                                        selectedBranches.length
                                                    }`}
                                                    selectedHeader="Selecionadas"
                                                    selectedFooter={`Selecionadas: ${selectedBranches.length}`}
                                                    labelKey="label"
                                                    showControls
                                                    searchable
                                                />
                                            </div>
                                        </div>
                                        <br />

                                        <div className="form-group">
                                            <label className="col-md-3 control-label">
                                                Data Início
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    placeholder=""
                                                    value={beginDate}
                                                    onChange={(e) => {
                                                        const { value } =
                                                            e.target;

                                                        setBeginDate(value);
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label className="col-md-3 control-label">
                                                Data Fim
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    placeholder=""
                                                    value={endDate}
                                                    onChange={(e) => {
                                                        const { value } =
                                                            e.target;

                                                        setEndDate(value);
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className={"form-group"}>
                                            <label className="col-md-3 control-label">
                                                Tipo
                                            </label>
                                            <div className="col-md-9">
                                                <select
                                                    name="currentOfferType"
                                                    id="currentOfferType"
                                                    className="form-control"
                                                    onChange={(event) => {
                                                        const { value } =
                                                            event.target;
                                                        setCurrentOfferType(
                                                            value
                                                        );
                                                    }}
                                                    value={currentOfferType}
                                                    // disabled={
                                                    //     directMailTypeSelected
                                                    // }
                                                >
                                                    <option value="">
                                                        Escolha um tipo
                                                    </option>
                                                    <option value="VT">
                                                        VT
                                                    </option>
                                                    <option value="SOCIAL_MEDIA">
                                                        Rede Social
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <br />
                                        {currentOfferType === "VT" ? (
                                            <>
                                                <div className={"form-group"}>
                                                    <label className="col-md-3 control-label">
                                                        Código do Produto
                                                    </label>
                                                    <div className="col-md-9">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder=""
                                                            value={
                                                                currentOfferProductCode
                                                            }
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                const {
                                                                    value,
                                                                } =
                                                                    event.target;
                                                                setCurrentOfferProductCode(
                                                                    value
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={"form-group"}>
                                                    <label className="col-md-3 control-label">
                                                        Descrição
                                                    </label>
                                                    <div className="col-md-9">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder=""
                                                            value={
                                                                currentOfferVtDescription
                                                            }
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                const {
                                                                    value,
                                                                } =
                                                                    event.target;
                                                                setCurrentOfferVtDescription(
                                                                    value
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-md-3 control-label">
                                                        Imagem de Capa
                                                    </label>
                                                    <div className="col-md-9">
                                                        <input
                                                            type="file"
                                                            id="image"
                                                            accept=".png, .jpg, .jpeg"
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                const file =
                                                                    event.target
                                                                        .files[0];
                                                                if (
                                                                    !file.type.match(
                                                                        imageMimeType
                                                                    )
                                                                ) {
                                                                    alert(
                                                                        "Tipo da imagem não é válido."
                                                                    );
                                                                    return;
                                                                }
                                                                setCurrentOfferVtCoverFile(
                                                                    file
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-md-3 control-label">
                                                        Vídeo do VT
                                                    </label>
                                                    <div className="col-md-9">
                                                        <input
                                                            type="file"
                                                            id="vt"
                                                            accept=".mp4"
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                const file =
                                                                    event.target
                                                                        .files[0];
                                                                if (
                                                                    !file.type.match(
                                                                        /video\/(mp4)/i
                                                                    )
                                                                ) {
                                                                    alert(
                                                                        "Tipo da imagem não é válido."
                                                                    );
                                                                    return;
                                                                }
                                                                const MIN_FILE_SIZE = 1024; // 1MB
                                                                const MAX_FILE_SIZE = 15360; // 5MB

                                                                const fileSizeKiloBytes =
                                                                    file.size /
                                                                    1024;

                                                                if (
                                                                    fileSizeKiloBytes <
                                                                    MIN_FILE_SIZE
                                                                ) {
                                                                    alert(
                                                                        "O arquivo é muito pequeno."
                                                                    );
                                                                    return;
                                                                }
                                                                if (
                                                                    fileSizeKiloBytes >
                                                                    MAX_FILE_SIZE
                                                                ) {
                                                                    alert(
                                                                        "O tamanho máximo do vídeo é de 10mb."
                                                                    );
                                                                    return;
                                                                }
                                                                setCurrentOfferVtFile(
                                                                    file
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className={"form-group"}>
                                                    <label className="col-md-3 control-label">
                                                        Link
                                                    </label>
                                                    <div className="col-md-9">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder=""
                                                            value={
                                                                currentOfferSocialMediaLink
                                                            }
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                const {
                                                                    value,
                                                                } =
                                                                    event.target;
                                                                setCurrentOfferSocialMediaLink(
                                                                    value
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="form-footer">
                                    <div className="form-group">
                                        <div className="col-md-9 col-md-offset-3">
                                            <button
                                                type="submit"
                                                className="btn btn-primary"
                                            >
                                                Salvar
                                            </button>
                                            <button
                                                type="button"
                                                onClick={handleCancelButton}
                                                className="btn btn-default"
                                            >
                                                Cancelar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};
