import React, { useState, useEffect, useRef } from "react";

import * as moment from "moment-timezone";

import { Link, withRouter } from "react-router-dom";

import Layout from "../../components/Layout";

import { apiCRMv3 } from "../../services/api";
import api from "../../services/api";

import Swal from "sweetalert2";

const ImportPricePerBranch = (props) => {
    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState("");
    const [alertText, setAlertText] = useState("");
    const [alertVariant, setAlertVariant] = useState("");
    const [fileURLCards, setFileURLCards] = useState("");
    const [fileNameCards, setFileNameCards] = useState("");
    const [loaded, setLoaded] = useState(true);

    const branchCode = useRef(null);


    // useEffect(function () {
    //     var date = new Date();

    //     var formatedDate = `${date.getFullYear()}-${
    //         date.getMonth() + 1 < 10
    //             ? "0" + (date.getMonth() + 1)
    //             : date.getMonth() + 1
    //     }-${date.getDate()}`;

    //     dateBeginCard.current.value = formatedDate;
    //     dateEndCard.current.value = formatedDate;
    // }, []);

    async function getBranchCode() {
        try {
            setLoaded(false);
            const responseImportPerBranch = await api.post(
                `/price-list-branches`,
                {
                    branches: [branchCode.current.value],
                }
            );
            
            console.log(branchCode)
            if (
                responseImportPerBranch.data.error ||
                responseImportPerBranch.status === 500
            ) {
                Swal.fire(
                    "Erro!",
                    "Problemas ao Importar Preços. Por favor contate o suporte!",
                    "error"
                );

                setLoaded(true);
            } else {
                Swal.fire(
                    "Importando!",
                    "A Tabela de Preços Está Sendo Importada e Logo Estará Disponível!",
                    "success"
                );

                setLoaded(true);
            }
        } catch (err) {
            console.log(err);
            Swal.fire(
                "Erro!",
                "Problemas ao Importar Preços. Por favor contate o suporte!",
                "error"
            );

            setLoaded(true);
        }
    }

    return (
        <Layout
            menuActive=""
            pageTitle="Importar Preços Por Filial"
        >
            <div className="row">
                <div className="col-xs-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">
                                <small>
                                    <Link
                                        className="label label-default"
                                        to="/dashboard"
                                    >
                                        Dashboard
                                    </Link>
                                </small>{" "}
                                <span className="label label-success">
                                    Importar Preços Por Filial
                                </span>
                            </div>
                        </div>
                        <div
                            className={`card-body table-responsive ${
                                loaded ? "" : "__loading"
                            }`}
                        >
                            <div className="row">
                                <form>
                                    <div className="col-xs-3">
                                        <label htmlFor="dtBegin-filter">
                                            Codigo Filial
                                        </label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="branchCode-filter"
                                            ref={branchCode}
                                        />
                                        <button
                                            className="btn btn-primary btn-large"
                                            type="button"
                                            onClick={(e) => {
                                                getBranchCode();
                                            }}
                                        >
                                            Importar!
                                        </button>
                                        
                                        <p>Após o início da importação, esperar alguns minutos e verificar no aplicativo. Caso não apareça o preço, contate o suporte!</p>
                                        <p>Por favor, não importar para a mesma filial mais de uma vez!</p>
                                    </div>
                                </form>
                            </div>
                            <br />

                            <div className="loader-container text-center">
                                <div className="icon">
                                    <div className="sk-wave">
                                        <div className="sk-rect sk-rect1"></div>
                                        <div className="sk-rect sk-rect2"></div>
                                        <div className="sk-rect sk-rect3"></div>
                                        <div className="sk-rect sk-rect4"></div>
                                        <div className="sk-rect sk-rect5"></div>
                                    </div>
                                </div>
                                <div className="title">Carregando</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default withRouter(ImportPricePerBranch);
