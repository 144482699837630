import React, { useState, useEffect, useRef } from 'react';

import * as moment from 'moment-timezone';

import { Link, withRouter } from 'react-router-dom';

import Layout from '../../components/Layout';

import api from '../../services/api';

const ReportByDate = (props) => {
    const [showAlert, setShowAlert] = useState(false)
    const [alertTitle, setAlertTitle] = useState('')
    const [alertText, setAlertText] = useState('')
    const [alertVariant, setAlertVariant] = useState('')
    const [fileURLCards, setFileURLCards] = useState('')
    const [fileNameCards, setFileNameCards] = useState('')
    const [loaded, setLoaded] = useState(true)
    
    const dateBeginCard = useRef(null);
    const dateEndCard = useRef(null);
    const emailToSend = useRef(null);

    useEffect(function () {
        var date = new Date();

        var formatedDate = `${date.getFullYear()}-${(date.getMonth()+1) < 10 ? '0' + (date.getMonth()+1) : (date.getMonth()+1)}-${date.getDate()}`

        dateBeginCard.current.value = formatedDate
        dateEndCard.current.value = formatedDate
    }, [])

    function onSubmitReportCards (event) {
        event.preventDefault()

        async function getReportCards () {
            try {
                setLoaded(false)
                const responseGetReportCardByDate = await api.post(`/reports/cards-by-date`, {
                    date_begin: dateBeginCard.current.value,
                    date_end: dateEndCard.current.value,
                    emailToSend: emailToSend.current.value
                })
                
                if (responseGetReportCardByDate.data.error || responseGetReportCardByDate.status === 500) {
                    // setShowAlert(true)
                    // setAlertTitle('Problemas ao gerar o relatório.')
                    // setAlertText(responseGetReportCardByDate.data.data.message)
                    // setAlertVariant('danger')
                    alert('Problemas ao gerar o relatório.')

                    setLoaded(true)
                } else {
                    // let fileName = responseGetReportCardByDate.headers['content-disposition'].split(';')[1]
                    // fileName = fileName.slice(fileName.indexOf('"'), fileName.length - 1)

                    // setFileURLCards(window.URL.createObjectURL(new Blob([responseGetReportCardByDate.data])))
                    // setFileNameCards(fileName)

                    alert('Relatório de cartões está sendo gerado. Em alguns minutos chegará em seu e-mail!')
                    // setShowAlert(true)
                    // setAlertTitle('Relatório de cartões gerado com sucesso!')
                    // setAlertText(`Em alguns minutos chegará em seu e-mail!`)
                    // setAlertVariant('success')

                    setLoaded(true)
                }
            } catch (err) {
                console.log(err)
                setShowAlert(true)
                setAlertTitle('Problemas ao gerar o relatório.')
                setAlertText('Por favor contate o suporte!')
                setAlertVariant('danger')

                setLoaded(true)
            }
        }

        getReportCards()  
    }

    return (
        <Layout
            menuActive="dashboard" 
            pageTitle="Relatório por Data"
        >
            <div className="row">
                <div className="col-xs-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">
                                <small><Link className="label label-default" to="/dashboard">Dashboard</Link></small> <span className="label label-success">Relatório por Data</span>
                            </div>
                        </div>
                        <div className={`card-body table-responsive ${loaded ? "" : "__loading"}`}>
                            <div className="row">
                                <form
                                    onSubmit={onSubmitReportCards}
                                >
                                    <div className="col-xs-3">
                                        <label htmlFor="dtBegin-filter">Data Inicial</label>
                                        <input
                                            className="form-control"
                                            type="date"
                                            id="dtBegin-filter"
                                            ref={dateBeginCard}
                                        />
                                    </div>

                                    <div className="col-xs-3">
                                        <label htmlFor="dtEnd-filter">Data Final</label>
                                        <input
                                            className="form-control"
                                            type="date"
                                            id="dtEnd-filter"
                                            ref={dateEndCard}
                                        />
                                    </div>

                                    <div className="col-xs-3">
                                        <label htmlFor="emailToSend">E-mail para envio</label>
                                        <input
                                            className="form-control"
                                            type="email"
                                            id="emailToSend"
                                            ref={emailToSend}
                                        />
                                    </div>

                                    <div className="col-xs-3" style={{position: 'relative', top: '50%', transform: 'translateY(50%)'}}>
                                        <button
                                            className="btn btn-primary btn-large"
                                            type="submit"
                                        >
                                            Gerar!
                                        </button>
                                    </div>
                                </form>
                            </div>

                            {
                                fileURLCards !== "" ?
                                    <a
                                        href={fileURLCards}
                                        download={fileNameCards}
                                    >
                                        <button
                                            className="btn btn-success btn-large"
                                            type="submit"
                                        >
                                            Download!
                                        </button>
                                    </a>
                                : null
                            }
                            
                            <br/>

                            <div className="loader-container text-center">
                                <div className="icon">
                                    <div className="sk-wave">
                                        <div className="sk-rect sk-rect1"></div>
                                        <div className="sk-rect sk-rect2"></div>
                                        <div className="sk-rect sk-rect3"></div>
                                        <div className="sk-rect sk-rect4"></div>
                                        <div className="sk-rect sk-rect5"></div>
                                    </div>
                                </div>
                                <div className="title">Carregando</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default withRouter(ReportByDate)