import React, { useState, useRef, useEffect } from "react";
import { withRouter } from "react-router-dom";

import Layout from "../../components/Layout";

import CurrencyInput from "react-currency-input";
import Select from "react-select";

import api from "../../services/api";

import Swal from "sweetalert2";

const TabloidProjectNewEdit = (props) => {
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState({});
    const [campaignsCover, setCampaignsCover] = useState([]);
    const [campaigns, setCampaigns] = useState([]);
    const [products, setProducts] = useState([]);
    const [productsTabloid, setProductsTabloid] = useState([]);
    const [name, setName] = useState("");
    const [campaignCover, setCampaignCover] = useState("");

    useEffect(() => {
        async function load() {
            try {
                if (props.match.params.tabloidProjectId) {
                    const { tabloidProjectId } = props.match.params;
                    const tabloidProjectToEditResponse = await api.get(
                        `/tabloid-project/${tabloidProjectId}`
                    );
                    const tabloidProjectToEdit =
                        tabloidProjectToEditResponse.data.data;
                    setName(tabloidProjectToEdit.name);
                    setCampaignCover(tabloidProjectToEdit.campaignCover._id);
                    console.log(tabloidProjectToEdit.products);
                    setProductsTabloid(
                        tabloidProjectToEdit.products.map((p) => ({
                            ...p,
                            productSelected: {
                                value: p.product._id,
                                label: p.product.name,
                            },
                            campaignSelected: p.campaign._id,
                        }))
                    );
                }
                const campaignsCoverResponse = await api.get("/campaign-cover");
                const campaignsResponse = await api.get("/campaign");
                const productsResponse = await api.get("/product");
                let productsToSelect = [
                    { value: "", label: "Selecione um produto" },
                ];
                await productsResponse.data.map((product) =>
                    productsToSelect.push({
                        value: product.id,
                        label: product.name,
                        price: product.price,
                    })
                );
                setCampaignsCover(campaignsCoverResponse.data.data);
                setCampaigns(campaignsResponse.data.data);
                setProducts(productsToSelect);
                setLoaded(true);
            } catch (error) {
                setLoaded(true);
                Swal.fire({
                    type: "error",
                    title: "Oops...",
                    text: "Problemas ao carregar o projeto de tabloid. Contate o suporte!",
                });
            }
        }
        load();
    }, []);

    function handleCancelButton(event) {
        if (name !== "") {
            Swal.fire({
                title: "Tem certeza que deseja cancelar?",
                text: "Cancelar fará você perder todas as modificações feitas até o momento!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sim, quero cancelar!",
                cancelButtonText: "Cancelar",
            }).then((result) => {
                if (result.value) {
                    Swal.fire(
                        "Cancelado!",
                        "Você irá voltar para a tela de listagem.",
                        "success"
                    );

                    props.history.push("/projetos-tabloide");
                }
            });
        } else {
            props.history.push("/projetos-tabloide");
        }
    }

    async function handleSubmitForm(event) {
        event.preventDefault();

        setLoaded(false);

        try {
            console.log(productsTabloid);
            if (name === "") {
                Swal.fire({
                    type: "warning",
                    title: "Oops...",
                    text: "Preencha o campo nome!",
                });
                setLoaded(true);
                return;
            }
            let productsTabloidToSend = [...productsTabloid];
            for (const productTabloidToSend of productsTabloidToSend) {
                if (!productTabloidToSend.productSelected) {
                    Swal.fire({
                        type: "error",
                        title: "Oops...",
                        text: "Existe um produto adicionado porém sem produto especificado!",
                    });
                }
                productTabloidToSend.productSelected =
                    productTabloidToSend.productSelected.value;
            }
            const saveTabloidProject = await api.post("/tabloid-project", {
                _id: props.match.params.tabloidProjectId
                    ? props.match.params.tabloidProjectId
                    : null,
                name: name,
                campaignCover: campaignCover,
                tabloidProducts: productsTabloidToSend,
            });
            if (saveTabloidProject.status === 200) {
                setLoaded(true);

                Swal.fire({
                    type: "success",
                    title: "Salvo!",
                    text: "O projeto de tabloide foi salvo com sucesso!",
                }).then(() => props.history.push("/projetos-tabloide"));
            } else {
                Swal.fire({
                    type: "error",
                    title: "Oops...",
                    text: "Tivemos um problema ao salvar esse novo projeto de tabloide. Contate o suporte!",
                });

                setLoaded(true);
            }
        } catch (e) {
            Swal.fire({
                type: "error",
                title: "Oops...",
                text: "Tivemos um problema ao salvar esse novo projeto de tabloide. Contate o suporte!",
            });

            setLoaded(true);
        }
    }

    async function handleAddProduct(event) {
        event.preventDefault();

        const newProductTabloid = {
            productSelected: {},
            campaignSelected: "",
            interest: false,
            entrance: false,
            creditCard: false,
            carne: false,
            sightPrice: 0,
            paymentX: "10",
            pricePayment: 0,
            totalAmount: 0,
        };
        setProductsTabloid([...productsTabloid, newProductTabloid]);
    }

    async function handleRemoveProduct(event, indexProductTabloid) {
        event.preventDefault();
        const resultQuestion = await Swal.fire({
            title: "Tem certeza que deseja remover esse produto?",
            text: "Você perderá esses dados!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim, quero remover!",
            cancelButtonText: "Cancelar",
        });
        if (resultQuestion.value) {
            const newProducts = [...productsTabloid];
            newProducts.splice(indexProductTabloid, 1);
            setProductsTabloid(newProducts);
            Swal.fire(
                "Removido!",
                "Você já pode continuar editando o tabloide.",
                "success"
            );
        }
    }

    return (
        <Layout menuActive="registers" pageTitle="Novo Projeto de Tabloide">
            <div className="row">
                <div className="col-xs-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">
                                Novo Projeto de Tabloide
                            </div>
                        </div>
                        <div
                            className={`card-body ${loaded ? "" : "__loading"}`}
                        >
                            <div className="loader-container text-center">
                                <div className="icon">
                                    <div className="sk-wave">
                                        <div className="sk-rect sk-rect1"></div>
                                        <div className="sk-rect sk-rect2"></div>
                                        <div className="sk-rect sk-rect3"></div>
                                        <div className="sk-rect sk-rect4"></div>
                                        <div className="sk-rect sk-rect5"></div>
                                    </div>
                                </div>
                                <div className="title">Carregando</div>
                            </div>

                            <form
                                className="form form-horizontal"
                                onSubmit={handleSubmitForm}
                            >
                                <div className="section">
                                    <div className="section-title">
                                        Informações Básicas
                                    </div>
                                    <div className="section-body">
                                        <div
                                            className={
                                                "form-group " +
                                                (error.name ? "has-error" : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Nome
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder=""
                                                    value={name}
                                                    onChange={(event) =>
                                                        setName(
                                                            event.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                "form-group " +
                                                (error.url ? "has-error" : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Capa da Campanha (opcional)
                                            </label>
                                            <div className="col-md-9">
                                                <select
                                                    className="form-control"
                                                    value={campaignCover}
                                                    onChange={(event) =>
                                                        setCampaignCover(
                                                            event.target.value
                                                        )
                                                    }
                                                >
                                                    <option value="">
                                                        Escolha
                                                    </option>
                                                    {campaignsCover.map(
                                                        (campaignCoverItem) => (
                                                            <option
                                                                key={
                                                                    campaignCoverItem._id
                                                                }
                                                                value={
                                                                    campaignCoverItem._id
                                                                }
                                                                selected={
                                                                    campaignCoverItem._id ===
                                                                    campaignCover
                                                                        ? true
                                                                        : false
                                                                }
                                                            >
                                                                {
                                                                    campaignCoverItem.name
                                                                }
                                                            </option>
                                                        )
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                        <br />
                                        <table
                                            className="table table-striped table-bordered table-hover"
                                            style={{
                                                width: "100%",
                                            }}
                                        >
                                            <colgroup>
                                                <col
                                                    span="1"
                                                    style={{ width: "20%" }}
                                                />
                                                <col
                                                    span="1"
                                                    style={{ width: "10%" }}
                                                />
                                                <col
                                                    span="1"
                                                    style={{ width: "5%" }}
                                                />
                                                <col
                                                    span="1"
                                                    style={{ width: "5%" }}
                                                />
                                                <col
                                                    span="1"
                                                    style={{ width: "10%" }}
                                                />
                                                <col
                                                    span="1"
                                                    style={{ width: "5%" }}
                                                />
                                                <col
                                                    span="1"
                                                    style={{ width: "10%" }}
                                                />
                                                <col
                                                    span="1"
                                                    style={{ width: "10%" }}
                                                />
                                                <col
                                                    span="1"
                                                    style={{ width: "10%" }}
                                                />
                                                <col
                                                    span="1"
                                                    style={{ width: "10%" }}
                                                />
                                                <col
                                                    span="1"
                                                    style={{ width: "10%" }}
                                                />
                                            </colgroup>
                                            <thead>
                                                <tr>
                                                    <th>Produto</th>
                                                    <th>Campanha</th>
                                                    <th>Sem juros?</th>
                                                    <th>Sem entrada?</th>
                                                    <th>Cartão de Crédito</th>
                                                    <th>Carnê</th>
                                                    <th>Preço à Vista</th>
                                                    <th>Qtd. Parcelas</th>
                                                    <th>Valor Parcela</th>
                                                    <th>Total à Prazo</th>
                                                    <th>Opções</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {productsTabloid.map(
                                                    (
                                                        productTabloid,
                                                        indexProductTabloid
                                                    ) => (
                                                        <tr
                                                            key={
                                                                indexProductTabloid
                                                            }
                                                        >
                                                            <td className="nopadding">
                                                                <Select
                                                                    placeholder="Selecione um produto"
                                                                    value={
                                                                        productTabloid.productSelected
                                                                    }
                                                                    onChange={(
                                                                        selectedOption
                                                                    ) => {
                                                                        const newProductsTabloid =
                                                                            [
                                                                                ...productsTabloid,
                                                                            ];
                                                                        newProductsTabloid[
                                                                            indexProductTabloid
                                                                        ].productSelected =
                                                                            selectedOption;
                                                                        setProductsTabloid(
                                                                            newProductsTabloid
                                                                        );
                                                                    }}
                                                                    options={
                                                                        products
                                                                    }
                                                                />
                                                            </td>
                                                            <td className="nopadding">
                                                                <select
                                                                    className="form-control"
                                                                    value={
                                                                        productTabloid.campaignSelected
                                                                    }
                                                                    onChange={(
                                                                        event
                                                                    ) => {
                                                                        const newProductsTabloid =
                                                                            [
                                                                                ...productsTabloid,
                                                                            ];
                                                                        newProductsTabloid[
                                                                            indexProductTabloid
                                                                        ].campaignSelected =
                                                                            event.target.value;
                                                                        setProductsTabloid(
                                                                            newProductsTabloid
                                                                        );
                                                                    }}
                                                                >
                                                                    <option value="">
                                                                        Escolha
                                                                    </option>
                                                                    {campaigns.map(
                                                                        (
                                                                            product
                                                                        ) => (
                                                                            <option
                                                                                key={
                                                                                    product._id
                                                                                }
                                                                                value={
                                                                                    product._id
                                                                                }
                                                                            >
                                                                                {
                                                                                    product.name
                                                                                }
                                                                            </option>
                                                                        )
                                                                    )}
                                                                </select>
                                                            </td>
                                                            <td className="nopadding">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={
                                                                        productTabloid.interest
                                                                    }
                                                                    onChange={(
                                                                        event
                                                                    ) => {
                                                                        const newProductsTabloid =
                                                                            [
                                                                                ...productsTabloid,
                                                                            ];
                                                                        newProductsTabloid[
                                                                            indexProductTabloid
                                                                        ].interest =
                                                                            event.target.checked;
                                                                        setProductsTabloid(
                                                                            newProductsTabloid
                                                                        );
                                                                    }}
                                                                />
                                                                Sem juros
                                                            </td>
                                                            <td className="nopadding">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={
                                                                        productTabloid.entrance
                                                                    }
                                                                    onChange={(
                                                                        event
                                                                    ) => {
                                                                        const newProductsTabloid =
                                                                            [
                                                                                ...productsTabloid,
                                                                            ];
                                                                        newProductsTabloid[
                                                                            indexProductTabloid
                                                                        ].entrance =
                                                                            event.target.checked;
                                                                        setProductsTabloid(
                                                                            newProductsTabloid
                                                                        );
                                                                    }}
                                                                />
                                                                Sem entrada
                                                            </td>
                                                            <td className="nopadding">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={
                                                                        productTabloid.creditCard
                                                                    }
                                                                    onChange={(
                                                                        event
                                                                    ) => {
                                                                        const newProductsTabloid =
                                                                            [
                                                                                ...productsTabloid,
                                                                            ];
                                                                        newProductsTabloid[
                                                                            indexProductTabloid
                                                                        ].creditCard =
                                                                            event.target.checked;
                                                                        setProductsTabloid(
                                                                            newProductsTabloid
                                                                        );
                                                                    }}
                                                                />
                                                                Cartão de
                                                                Crédito
                                                            </td>
                                                            <td className="nopadding">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={
                                                                        productTabloid.carne
                                                                    }
                                                                    onChange={(
                                                                        event
                                                                    ) => {
                                                                        const newProductsTabloid =
                                                                            [
                                                                                ...productsTabloid,
                                                                            ];
                                                                        newProductsTabloid[
                                                                            indexProductTabloid
                                                                        ].carne =
                                                                            event.target.checked;
                                                                        setProductsTabloid(
                                                                            newProductsTabloid
                                                                        );
                                                                    }}
                                                                />
                                                                Carnê
                                                            </td>
                                                            <td className="nopadding">
                                                                <CurrencyInput
                                                                    className="form-control"
                                                                    decimalSeparator=","
                                                                    thousandSeparator="."
                                                                    value={
                                                                        productTabloid.sightPrice
                                                                    }
                                                                    onChangeEvent={(
                                                                        event,
                                                                        maskedvalue,
                                                                        floatvalue
                                                                    ) => {
                                                                        const newProductsTabloid =
                                                                            [
                                                                                ...productsTabloid,
                                                                            ];
                                                                        newProductsTabloid[
                                                                            indexProductTabloid
                                                                        ].sightPrice =
                                                                            floatvalue;
                                                                        newProductsTabloid[
                                                                            indexProductTabloid
                                                                        ].pricePayment =
                                                                            floatvalue /
                                                                            newProductsTabloid[
                                                                                indexProductTabloid
                                                                            ]
                                                                                .paymentX;
                                                                        newProductsTabloid[
                                                                            indexProductTabloid
                                                                        ].totalAmount =
                                                                            floatvalue;
                                                                        setProductsTabloid(
                                                                            newProductsTabloid
                                                                        );
                                                                    }}
                                                                />
                                                            </td>
                                                            <td className="nopadding">
                                                                <input
                                                                    type="number"
                                                                    className="form-control remove_margin_bottom"
                                                                    value={
                                                                        productTabloid.paymentX
                                                                            ? productsTabloid[
                                                                                  indexProductTabloid
                                                                              ]
                                                                                  .paymentX
                                                                            : "10"
                                                                    }
                                                                    onChange={(
                                                                        event
                                                                    ) => {
                                                                        const newProductsTabloid =
                                                                            [
                                                                                ...productsTabloid,
                                                                            ];
                                                                        newProductsTabloid[
                                                                            indexProductTabloid
                                                                        ].paymentX =
                                                                            event.target.value;
                                                                        if (
                                                                            newProductsTabloid[
                                                                                indexProductTabloid
                                                                            ]
                                                                                .sightPrice
                                                                        ) {
                                                                            newProductsTabloid[
                                                                                indexProductTabloid
                                                                            ].pricePayment =
                                                                                newProductsTabloid[
                                                                                    indexProductTabloid
                                                                                ]
                                                                                    .sightPrice /
                                                                                parseInt(
                                                                                    event
                                                                                        .target
                                                                                        .value
                                                                                );
                                                                        }
                                                                        setProductsTabloid(
                                                                            newProductsTabloid
                                                                        );
                                                                    }}
                                                                />
                                                            </td>
                                                            <td className="nopadding">
                                                                <CurrencyInput
                                                                    className="form-control"
                                                                    decimalSeparator=","
                                                                    thousandSeparator="."
                                                                    value={
                                                                        productTabloid.pricePayment
                                                                    }
                                                                    onChangeEvent={(
                                                                        event,
                                                                        maskedvalue,
                                                                        floatvalue
                                                                    ) => {
                                                                        const newProductsTabloid =
                                                                            [
                                                                                ...productsTabloid,
                                                                            ];
                                                                        newProductsTabloid[
                                                                            indexProductTabloid
                                                                        ].pricePayment =
                                                                            floatvalue;
                                                                        setProductsTabloid(
                                                                            newProductsTabloid
                                                                        );
                                                                    }}
                                                                />
                                                            </td>
                                                            <td className="nopadding">
                                                                <CurrencyInput
                                                                    className="form-control"
                                                                    decimalSeparator=","
                                                                    thousandSeparator="."
                                                                    value={
                                                                        productTabloid.totalAmount
                                                                    }
                                                                    onChangeEvent={(
                                                                        event,
                                                                        maskedvalue,
                                                                        floatvalue
                                                                    ) => {
                                                                        const newProductsTabloid =
                                                                            [
                                                                                ...productsTabloid,
                                                                            ];
                                                                        newProductsTabloid[
                                                                            indexProductTabloid
                                                                        ].totalAmount =
                                                                            floatvalue;
                                                                        if (
                                                                            newProductsTabloid[
                                                                                indexProductTabloid
                                                                            ]
                                                                                .paymentX
                                                                        ) {
                                                                            newProductsTabloid[
                                                                                indexProductTabloid
                                                                            ].pricePayment =
                                                                                floatvalue /
                                                                                parseInt(
                                                                                    newProductsTabloid[
                                                                                        indexProductTabloid
                                                                                    ]
                                                                                        .paymentX
                                                                                );
                                                                        }
                                                                        setProductsTabloid(
                                                                            newProductsTabloid
                                                                        );
                                                                    }}
                                                                />
                                                            </td>
                                                            <td className="nopadding">
                                                                <button
                                                                    className="btn btn-danger"
                                                                    onClick={(
                                                                        e
                                                                    ) =>
                                                                        handleRemoveProduct(
                                                                            e,
                                                                            indexProductTabloid
                                                                        )
                                                                    }
                                                                >
                                                                    Remover
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                        <button
                                            className="btn btn-success"
                                            onClick={(e) => handleAddProduct(e)}
                                        >
                                            Adicionar Produto
                                        </button>
                                    </div>
                                </div>
                                <div className="form-footer">
                                    <div className="form-group">
                                        <div className="col-md-9 col-md-offset-3">
                                            <button
                                                type="submit"
                                                className="btn btn-primary"
                                            >
                                                Salvar
                                            </button>
                                            <button
                                                type="button"
                                                onClick={handleCancelButton}
                                                className="btn btn-default"
                                            >
                                                Cancelar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default withRouter(TabloidProjectNewEdit);
