import React, { useState, useRef, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";

import Layout from "../../components/Layout";

import api from "../../services/api";

import Swal from "sweetalert2";

const CampaignNew = (props) => {
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState({});
    const [isChecked, setIsChecked] = useState(false);
    const nameRef = useRef(null);
    const fileRef = useRef(null);
    // const checkRef = useRef(false);
    

    useEffect(() => {
        setLoaded(true);
    }, []);

    function handleCancelButton(event) {
        if (nameRef.current.value !== "") {
            Swal.fire({
                title: "Tem certeza que deseja cancelar?",
                text:
                    "Cancelar fará você perder todas as modificações feitas até o momento!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sim, quero cancelar!",
                cancelButtonText: "Cancelar",
            }).then((result) => {
                if (result.value) {
                    Swal.fire(
                        "Cancelado!",
                        "Você irá voltar para a tela de listagem.",
                        "success"
                    );

                    props.history.push("/campanhas");
                }
            });
        } else {
            props.history.push("/campanhas");
        }
    }

    const handleOnChange = () => {
        console.log("aqui")
        setIsChecked(true);
    };

    async function handleSubmitForm(event) {
        event.preventDefault();

        setLoaded(false);

        try {
            const formData = new FormData();
            formData.append("name", nameRef.current.value);
            formData.append("image_campaign", fileRef.current.files[0]);
            formData.append("onlyIntentionReinforcement", isChecked)

            const createdCampaign = await api.post("/campaign", formData);

            setLoaded(true);
            if (createdCampaign.status === 201) {
                setLoaded(true);

                Swal.fire({
                    type: "success",
                    title: "Salvo!",
                    text: "A campanha foi salva com sucesso!",
                }).then(() => props.history.push("/campanhas"));
            } else {
                Swal.fire({
                    type: "error",
                    title: "Oops...",
                    text:
                        "Tivemos um problema ao salvar essa nova campanha. Contate o suporte!",
                });

                setLoaded(true);
            }
        } catch (e) {
            Swal.fire({
                type: "error",
                title: "Oops...",
                text:
                    "Tivemos um problema ao salvar essa nova campanha. Contate o suporte!",
            });

            setLoaded(true);
        }
    }

    return (
        <Layout menuActive="registers" pageTitle="Nova Campanha">
            <div className="row">
                <div className="col-xs-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">Nova Campanha</div>
                        </div>
                        <div
                            className={`card-body ${loaded ? "" : "__loading"}`}
                        >
                            <div className="loader-container text-center">
                                <div className="icon">
                                    <div className="sk-wave">
                                        <div className="sk-rect sk-rect1"></div>
                                        <div className="sk-rect sk-rect2"></div>
                                        <div className="sk-rect sk-rect3"></div>
                                        <div className="sk-rect sk-rect4"></div>
                                        <div className="sk-rect sk-rect5"></div>
                                    </div>
                                </div>
                                <div className="title">Carregando</div>
                            </div>

                            <form
                                className="form form-horizontal"
                                onSubmit={handleSubmitForm}
                            >
                                <div className="section">
                                    <div className="section-title">
                                        Informações Básicas
                                    </div>
                                    <div className="section-body">
                                        <div
                                            className={
                                                "form-group " +
                                                (error.name ? "has-error" : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Nome
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder=""
                                                    ref={nameRef}
                                                />
                                            </div>
                                        </div>
                                        
                                        <div
                                            className={
                                                "form-group " +
                                                (error.file ? "has-error" : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Arquivo
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    placeholder=""
                                                    ref={fileRef}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                "form-group " +
                                                (error.isChecked
                                                    ? "has-error"
                                                    : "")
                                        }
                                        >   
                                            <label className="col-md-3 control-label">
                                                Usar Somente Para Reforço da Intenção
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="checkbox"
                                                    className="form-control"
                                                    checked={isChecked}
                                                    onChange={(event) => {
                                                        setIsChecked(!isChecked);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-footer">
                                    <div className="form-group">
                                        <div className="col-md-9 col-md-offset-3">
                                            <button
                                                type="submit"
                                                className="btn btn-primary"
                                            >
                                                Salvar
                                            </button>
                                            <button
                                                type="button"
                                                onClick={handleCancelButton}
                                                className="btn btn-default"
                                            >
                                                Cancelar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default withRouter(CampaignNew);
