import React, { useState, useEffect } from "react";

import { withRouter, Link } from "react-router-dom";

import Layout from "../../components/Layout";

import { apiCRMv3 } from "../../services/api";

import Swal from "sweetalert2";

const DirectMailList = (props) => {
    const [directMails, setDirectMails] = useState([]);
    const [directMailsToShow, setDirectMailsToShow] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [searchText, setSearchText] = useState("");

    useEffect(function () {
        loadList();
    }, []);

    async function loadList() {
        setLoaded(false);

        const responseGetDirectMails = await apiCRMv3.get("/direct-mails");

        setDirectMails(responseGetDirectMails.data.data.directMails);
        setDirectMailsToShow(responseGetDirectMails.data.data.directMails);
        setLoaded(true);
    }

    function handleSearchBar() {
        setLoaded(false);

        const originalDirectMails = directMails;

        if (searchText === "") {
            setDirectMailsToShow(originalDirectMails);
            setLoaded(true);
            return;
        }

        let filteredDirectMails = originalDirectMails.filter(
            (directMail) =>
                directMail.name
                    .toLowerCase()
                    .indexOf(searchText.toLowerCase()) !== -1
        );

        if (filteredDirectMails.length <= 0) {
            filteredDirectMails = originalDirectMails.filter(
                (directMail) =>
                    directMail.name
                        .toLowerCase()
                        .indexOf(searchText.toLowerCase()) !== -1
            );
        }

        setDirectMailsToShow(filteredDirectMails);
        setLoaded(true);
    }

    async function handleDelete(directMailDelete) {
        Swal.fire({
            title: `Tem certeza que deseja excluir a mala direta ${directMailDelete.name}?`,
            text: "Não será possível voltar atrás!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim, quero excluir!",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (!result.value) {
                Swal.fire(
                    "Cancelado!",
                    "Nenhum dado será excluído.",
                    "success"
                );
            } else {
                setLoaded(false);

                try {
                    const responseDeleteDirectMail = await apiCRMv3.delete(
                        `/direct-mails/${directMailDelete.id}`
                    );

                    if (responseDeleteDirectMail.status === 204) {
                        const newDirectMails = directMails.filter(
                            (directMail) =>
                                directMail.id !== directMailDelete.id
                        );
                        setDirectMails(newDirectMails);
                        setDirectMailsToShow(newDirectMails);
                        setLoaded(true);
                    } else {
                        throw new Error("Error");
                    }
                } catch (error) {
                    setLoaded(true);
                    Swal.fire({
                        type: "error",
                        title: "Oops...",
                        text:
                            "Tivemos um problema ao excluir essa mala direta. Contate o suporte!",
                    });
                }
            }
        });
    }

    const getTypeDirectMail = (type) => {
        switch (type) {
            case "AFTER_SALES":
                return "Pós-venda";
                break;

            default:
                return "Mala Direta";
                break;
        }
    };

    return (
        <Layout
            menuActive="direct-mail"
            pageTitle="Lista de Malas Diretas"
            enableSearchBar={true}
            searchTerm={searchText}
            searchOnChange={(e) => setSearchText(e.target.value)}
            handleSearch={handleSearchBar}
        >
            <div className="row">
                <div className="col-xs-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">
                                Lista de Malas Diretas
                            </div>
                            <ul className="card-action">
                                <li>
                                    <Link
                                        to="/malas-diretas/nova"
                                        className="btn btn-sm btn-success"
                                    >
                                        Nova <i className="fa fa-plus"></i>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div
                            className={`card-body ${loaded ? "" : "__loading"}`}
                        >
                            <div className="loader-container text-center">
                                <div className="icon">
                                    <div className="sk-wave">
                                        <div className="sk-rect sk-rect1"></div>
                                        <div className="sk-rect sk-rect2"></div>
                                        <div className="sk-rect sk-rect3"></div>
                                        <div className="sk-rect sk-rect4"></div>
                                        <div className="sk-rect sk-rect5"></div>
                                    </div>
                                </div>
                                <div className="title">Carregando</div>
                            </div>

                            <div className="row">
                                <div className="col-xs-12">
                                    <table className="table table-striped table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th>Nome</th>
                                                <th>Tipo</th>
                                                <th>Opções</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {directMailsToShow.map(
                                                (directMail, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                {
                                                                    directMail.name
                                                                }
                                                            </td>
                                                            <td>
                                                                {getTypeDirectMail(
                                                                    directMail.type
                                                                )}
                                                            </td>
                                                            <td>
                                                                <div className="col-xs-12">
                                                                    <div className="col-xs-12">
                                                                        <button
                                                                            className="btn btn-xs btn-danger"
                                                                            style={{
                                                                                width:
                                                                                    "100%",
                                                                            }}
                                                                            onClick={() =>
                                                                                handleDelete(
                                                                                    directMail
                                                                                )
                                                                            }
                                                                        >
                                                                            Excluir
                                                                            <div className="icon fa fa-trash-o"></div>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default withRouter(DirectMailList);
