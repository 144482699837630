import React, { useState, useEffect, useRef } from "react";

import * as moment from "moment-timezone";

import Layout from "../../components/Layout";

import api, { apiCRMv3 } from "../../services/api";
import { getBranches } from "../../services/auth";

import Select from "react-select";

import Swal from "sweetalert2";

import AsyncSelect from "react-select/async";
import CurrencyInput from "react-currency-input";

import MultiselectTwoSides from "react-multiselect-two-sides";
import {
    R01,
    R02,
    R03,
    R04,
    R05,
    R06,
    R07,
    R08,
    R09,
    R10,
    R11,
    R12,
    R13,
} from "../../services/regionals";

const imageMimeType = /image\/(png|jpg|jpeg)/i;

export const CurrentPromotionEdit = (props) => {
    const [loaded, setLoaded] = useState(false);
    const [branches, setBranches] = useState([]);
    const [selectedBranches, setSelectedBranches] = useState([]);
    const [currentPromotionId, setCurrentPromotionId] = useState(null);
    const [beginDate, setBeginDate] = useState(moment().format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
    const [productSelected, setProductSelected] = useState(null);
    const [sightPrice, setSightPrice] = useState(0);
    const [creditCardPrice, setCreditCardPrice] = useState(0);
    const [award, setAward] = useState(0);
    const [price, setPrice] = useState(0);
    const [isPriority, setIsPriority] = useState(false);
    const [promotions, setPromotions] = useState([]);

    useEffect(() => {
        const load = async () => {
            const returnBranches = await api.get("/branch");
            // const branches = getBranches();
            // let branchesToSelect = [];
            const branchesFiltered = returnBranches.data.filter(
                (value, index, self) =>
                    index ===
                    self.findIndex((t) => t.codigo_loja === value.codigo_loja)
            );
            const newBranches = await branchesFiltered.map((branch) => {
                let value = "";
                let label = "";
                if (branch.codigo_loja.indexOf(".") !== -1) {
                    return {
                        value: branch.codigo_loja.replace(".", "000"),
                        label: branch.codigo_loja.replace(".", "000"),
                    };
                } else {
                    if (branch.codigo_loja.indexOf("240") !== -1) {
                        return {
                            value: branch.codigo_loja,
                            label: branch.codigo_loja,
                        };
                    } else {
                        return {
                            value: `10${branch.codigo_loja}`,
                            label: `10${branch.codigo_loja}`,
                        };
                    }
                }
            });
            setBranches(newBranches);
            const { currentPromotionId } = props.match.params;
            setCurrentPromotionId(currentPromotionId);
            const currentPromotionRequest = await apiCRMv3.get(
                `/current-promotions/${currentPromotionId}`
            );
            const currentPromotion =
                currentPromotionRequest.data.data.currentPromotion;
            setSelectedBranches(currentPromotion.branches);
            setBeginDate(currentPromotion.startDate);
            setEndDate(currentPromotion.endDate);
            setSightPrice(Number(currentPromotion.sightPrice));
            setCreditCardPrice(Number(currentPromotion.creditCardPrice));
            setAward(Number(currentPromotion.award));
            setPrice(Number(currentPromotion.price));
            setIsPriority(currentPromotion.isPriority);
            setProductSelected({
                label: currentPromotion.product.name,
                value: currentPromotion.product.id,
            });
            setPromotions(currentPromotion.promotions);
            setLoaded(true);
        };
        load();
    }, []);

    const handleCancelButton = (event) => {
        Swal.fire({
            title: "Tem certeza que deseja cancelar?",
            text: "Cancelar fará você perder todas as modificações feitas até o momento!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim, quero cancelar!",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.value) {
                Swal.fire(
                    "Cancelado!",
                    "Você irá voltar para a tela de listagem.",
                    "success"
                );

                props.history.push("/produtos-oferta");
            }
        });
    };

    const handleChangeSelectedBranches = (value) => {
        console.log(value);
        setSelectedBranches(value);
    };

    const searchProduct = async (inputValue) => {
        console.log(inputValue);
        const searchProductByNameOrCodeResponse = await apiCRMv3.get(
            `/products/search-by-name-or-code?name=${inputValue}`
        );
        const { products } = searchProductByNameOrCodeResponse.data.data;
        return products.map((product) => ({
            label: product.name,
            value: product.id,
        }));
    };

    const onChangeInputTablePromotions = (e, promotionIndex) => {
        const { name, value, type } = e.target;

        const editData = promotions.map((item, index) => {
            if (index === promotionIndex && name) {
                if (type === "checkbox") {
                    return {
                        ...item,
                        [name]: !item[name],
                    };
                }
                if (type === "number") {
                    return {
                        ...item,
                        [name]: value ? Number(value) : "",
                    };
                }
                return {
                    ...item,
                    [name]: value,
                };
            } else {
                return item;
            }
        });

        setPromotions(editData);
    };

    const addPromotion = (e) => {
        e.preventDefault();
        setPromotions([
            ...promotions,
            {
                carneOrCreditCard: "carne",
                paymentX: 3,
                entrance: false,
                totalAmount: 0,
            },
        ]);
    };

    const removePromotion = (e, indexPromotion) => {
        e.preventDefault();
        setPromotions(
            promotions.filter((item, index) => index !== indexPromotion)
        );
    };

    const regionalSelected = (e, regional) => {
        e.preventDefault();
        let branches = selectedBranches;
        switch (regional) {
            case "R01":
                branches = branches.concat(R01);
                break;
            case "R02":
                branches = branches.concat(R02);
                break;
            case "R03":
                branches = branches.concat(R03);
                break;
            case "R04":
                branches = branches.concat(R04);
                break;
            case "R05":
                branches = branches.concat(R05);
                break;
            case "R06":
                branches = branches.concat(R06);
                break;
            case "R07":
                branches = branches.concat(R07);
                break;
            case "R08":
                branches = branches.concat(R08);
                break;
            case "R09":
                branches = branches.concat(R09);
                break;
            case "R10":
                branches = branches.concat(R10);
                break;
            case "R11":
                branches = branches.concat(R11);
                break;
            case "R12":
                branches = branches.concat(R12);
                break;
            case "R13":
                branches = branches.concat(R13);
                break;

            default:
                break;
        }
        setSelectedBranches(branches);
    };

    const handleSubmitForm = async (event) => {
        event.preventDefault();
        setLoaded(false);
        try {
            if (!productSelected) {
                Swal.fire({
                    type: "error",
                    title: "Ops!",
                    text: "Você não escolheu o produto. É necessário escolher um produto!",
                });
                setLoaded(true);
                return;
            }
            if (selectedBranches.length <= 0) {
                Swal.fire({
                    type: "error",
                    title: "Ops!",
                    text: "Você não selecionou nenhuma filial. É necessário selecionar pelo menos uma filial!",
                });
                setLoaded(true);
                return;
            }
            if (sightPrice === 0 || !sightPrice) {
                Swal.fire({
                    type: "error",
                    title: "Ops!",
                    text: "É necessário especificar um valor à vista!",
                });
                setLoaded(true);
                return;
            }
            if (creditCardPrice === 0 || !creditCardPrice) {
                Swal.fire({
                    type: "error",
                    title: "Ops!",
                    text: "É necessário especificar um valor no cartão de crédito!",
                });
                setLoaded(true);
                return;
            }
            if (promotions.length <= 0) {
                Swal.fire({
                    type: "error",
                    title: "Ops!",
                    text: "Você não adicionou nenhuma promoção para esse produto. É necessário adicionar pelo menos uma promoção para esse produto!",
                });
                setLoaded(true);
                return;
            }
            for (const promotion of promotions) {
                if (promotion.totalAmount === 0 || !promotion.totalAmount) {
                    Swal.fire({
                        type: "error",
                        title: "Ops!",
                        text: "É necessário especificar um valor à prazo na promoção!",
                    });
                    setLoaded(true);
                    return;
                }
                if (
                    promotion.carneOrCreditCard === "" ||
                    !promotion.carneOrCreditCard
                ) {
                    Swal.fire({
                        type: "error",
                        title: "Ops!",
                        text: "É necessário informar se a promoção é no cartão de crédito ou no carnê!",
                    });
                    setLoaded(true);
                    return;
                }
                if (promotion.paymentX === 0 || !promotion.paymentX) {
                    Swal.fire({
                        type: "error",
                        title: "Ops!",
                        text: "É necessário informar a quantidade de parcelas da promoção!",
                    });
                    setLoaded(true);
                    return;
                }
            }
            const sendCurrentPromotion = {
                productId: productSelected.value,
                branches: selectedBranches,
                startDate: beginDate,
                endDate: endDate,
                sightPrice: sightPrice,
                creditCardPrice: creditCardPrice,
                award: award,
                price: price,
                isPriority: isPriority,
                promotions: promotions,
            };
            await apiCRMv3.put(
                `/current-promotions/${currentPromotionId}`,
                sendCurrentPromotion
            );
            Swal.fire({
                type: "success",
                title: `Promoção salva!`,
                text: `A promoção foi salva com sucesso!`,
            }).then(() => props.history.push("/promocoes-vigentes"));
            setLoaded(true);
        } catch (e) {
            Swal.fire({
                type: "error",
                title: "Oops...",
                text: `Tivemos um problema ao salvar a promoção. Contate o suporte!`,
            });
            setLoaded(true);
        }
    };

    return (
        <Layout menuActive="registers" pageTitle="Editar Promoção Vigente">
            <div className="row">
                <div className="col-xs-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">
                                Editar Promoção Vigente
                            </div>
                        </div>
                        <div
                            className={`card-body ${loaded ? "" : "__loading"}`}
                        >
                            <div className="loader-container text-center">
                                <div className="icon">
                                    <div className="sk-wave">
                                        <div className="sk-rect sk-rect1"></div>
                                        <div className="sk-rect sk-rect2"></div>
                                        <div className="sk-rect sk-rect3"></div>
                                        <div className="sk-rect sk-rect4"></div>
                                        <div className="sk-rect sk-rect5"></div>
                                    </div>
                                </div>
                                <div className="title">Carregando</div>
                            </div>

                            <form
                                className="form form-horizontal"
                                onSubmit={handleSubmitForm}
                            >
                                <div className="section">
                                    <div className="section-title">
                                        Dados da Oferta
                                    </div>
                                    <div className="section-body">
                                        <div className="form-group">
                                            <label className="col-md-3 control-label">
                                                Produto
                                            </label>
                                            <div className="col-md-9">
                                                <AsyncSelect
                                                    placeholder="Selecione um produto"
                                                    value={productSelected}
                                                    onChange={(
                                                        selectedOption
                                                    ) => {
                                                        setProductSelected(
                                                            selectedOption
                                                        );
                                                    }}
                                                    loadOptions={searchProduct}
                                                />
                                            </div>
                                        </div>
                                        <br />

                                        <div className="form-group">
                                            <label className="col-md-3 control-label">
                                                Regionais
                                            </label>
                                            <div className="col-md-9">
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={(e) =>
                                                        regionalSelected(
                                                            e,
                                                            "R01"
                                                        )
                                                    }
                                                >
                                                    R01
                                                </button>
                                                <button
                                                    className="btn btn-primary"
                                                    style={{
                                                        marginLeft: 10,
                                                    }}
                                                    onClick={(e) =>
                                                        regionalSelected(
                                                            e,
                                                            "R02"
                                                        )
                                                    }
                                                >
                                                    R02
                                                </button>
                                                <button
                                                    className="btn btn-primary"
                                                    style={{
                                                        marginLeft: 10,
                                                    }}
                                                    onClick={(e) =>
                                                        regionalSelected(
                                                            e,
                                                            "R03"
                                                        )
                                                    }
                                                >
                                                    R03
                                                </button>
                                                <button
                                                    className="btn btn-primary"
                                                    style={{
                                                        marginLeft: 10,
                                                    }}
                                                    onClick={(e) =>
                                                        regionalSelected(
                                                            e,
                                                            "R04"
                                                        )
                                                    }
                                                >
                                                    R04
                                                </button>
                                                <button
                                                    className="btn btn-primary"
                                                    style={{
                                                        marginLeft: 10,
                                                    }}
                                                    onClick={(e) =>
                                                        regionalSelected(
                                                            e,
                                                            "R05"
                                                        )
                                                    }
                                                >
                                                    R05
                                                </button>
                                                <button
                                                    className="btn btn-primary"
                                                    style={{
                                                        marginLeft: 10,
                                                    }}
                                                    onClick={(e) =>
                                                        regionalSelected(
                                                            e,
                                                            "R06"
                                                        )
                                                    }
                                                >
                                                    R06
                                                </button>
                                                <button
                                                    className="btn btn-primary"
                                                    style={{
                                                        marginLeft: 10,
                                                    }}
                                                    onClick={(e) =>
                                                        regionalSelected(
                                                            e,
                                                            "R07"
                                                        )
                                                    }
                                                >
                                                    R07
                                                </button>
                                                <button
                                                    className="btn btn-primary"
                                                    style={{
                                                        marginLeft: 10,
                                                    }}
                                                    onClick={(e) =>
                                                        regionalSelected(
                                                            e,
                                                            "R08"
                                                        )
                                                    }
                                                >
                                                    R08
                                                </button>
                                                <button
                                                    className="btn btn-primary"
                                                    style={{
                                                        marginLeft: 10,
                                                    }}
                                                    onClick={(e) =>
                                                        regionalSelected(
                                                            e,
                                                            "R09"
                                                        )
                                                    }
                                                >
                                                    R09
                                                </button>
                                                <button
                                                    className="btn btn-primary"
                                                    style={{
                                                        marginLeft: 10,
                                                    }}
                                                    onClick={(e) =>
                                                        regionalSelected(
                                                            e,
                                                            "R10"
                                                        )
                                                    }
                                                >
                                                    R10
                                                </button>
                                                <button
                                                    className="btn btn-primary"
                                                    style={{
                                                        marginLeft: 10,
                                                    }}
                                                    onClick={(e) =>
                                                        regionalSelected(
                                                            e,
                                                            "R11"
                                                        )
                                                    }
                                                >
                                                    R11
                                                </button>
                                                <button
                                                    className="btn btn-primary"
                                                    style={{
                                                        marginLeft: 10,
                                                    }}
                                                    onClick={(e) =>
                                                        regionalSelected(
                                                            e,
                                                            "R12"
                                                        )
                                                    }
                                                >
                                                    R12
                                                </button>
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={(e) =>
                                                        regionalSelected(
                                                            e,
                                                            "R13"
                                                        )
                                                    }
                                                >
                                                    R13
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-md-offset-3 col-md-9">
                                            <div
                                                class="alert alert-warning"
                                                role="alert"
                                            >
                                                Para filtrar por estado, basta
                                                utilizar, por exemplo: "-PR" ou
                                                "-GO" e clicar na seta para
                                                enviar todas as filiais
                                                filtradas do lado esquerdo para
                                                o direito
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-md-3 control-label">
                                                Filiais
                                            </label>
                                            <div className="col-md-9">
                                                <MultiselectTwoSides
                                                    options={branches}
                                                    value={selectedBranches}
                                                    className="msts_theme_example"
                                                    onChange={
                                                        handleChangeSelectedBranches
                                                    }
                                                    availableHeader="Disponíveis"
                                                    availableFooter={`Disponíveis: ${
                                                        branches.length -
                                                        selectedBranches.length
                                                    }`}
                                                    selectedHeader="Selecionadas"
                                                    selectedFooter={`Selecionadas: ${selectedBranches.length}`}
                                                    labelKey="label"
                                                    showControls
                                                    searchable
                                                />
                                            </div>
                                        </div>
                                        <br />

                                        <div className="form-group">
                                            <label className="col-md-3 control-label">
                                                Data Início
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    placeholder=""
                                                    value={beginDate}
                                                    onChange={(e) => {
                                                        const { value } =
                                                            e.target;

                                                        setBeginDate(value);
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label className="col-md-3 control-label">
                                                Data Fim
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    placeholder=""
                                                    value={endDate}
                                                    onChange={(e) => {
                                                        const { value } =
                                                            e.target;

                                                        setEndDate(value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-md-3 control-label">
                                                Valor à Vista
                                            </label>
                                            <div className="col-md-9">
                                                <div className="input-group">
                                                    <span className="input-group-addon">
                                                        R$
                                                    </span>
                                                    <CurrencyInput
                                                        className="form-control"
                                                        decimalSeparator=","
                                                        thousandSeparator="."
                                                        value={sightPrice}
                                                        onChangeEvent={(
                                                            event,
                                                            maskedvalue,
                                                            floatvalue
                                                        ) => {
                                                            setSightPrice(
                                                                floatvalue
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-md-3 control-label">
                                                Valor Cartão de Crédito
                                            </label>
                                            <div className="col-md-9">
                                                <div className="input-group">
                                                    <span className="input-group-addon">
                                                        R$
                                                    </span>
                                                    <CurrencyInput
                                                        className="form-control"
                                                        decimalSeparator=","
                                                        thousandSeparator="."
                                                        value={creditCardPrice}
                                                        onChangeEvent={(
                                                            event,
                                                            maskedvalue,
                                                            floatvalue
                                                        ) => {
                                                            setCreditCardPrice(
                                                                floatvalue
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-md-3 control-label">
                                                Valor do Prêmio
                                            </label>
                                            <div className="col-md-9">
                                                <div className="input-group">
                                                    <span className="input-group-addon">
                                                        R$
                                                    </span>
                                                    <CurrencyInput
                                                        className="form-control"
                                                        decimalSeparator=","
                                                        thousandSeparator="."
                                                        value={award}
                                                        onChangeEvent={(
                                                            event,
                                                            maskedvalue,
                                                            floatvalue
                                                        ) => {
                                                            setAward(
                                                                floatvalue
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-md-3 control-label">
                                                Valor do Price
                                            </label>
                                            <div className="col-md-9">
                                                <div className="input-group">
                                                    <span className="input-group-addon">
                                                        R$
                                                    </span>
                                                    <CurrencyInput
                                                        className="form-control"
                                                        decimalSeparator=","
                                                        thousandSeparator="."
                                                        value={price}
                                                        onChangeEvent={(
                                                            event,
                                                            maskedvalue,
                                                            floatvalue
                                                        ) => {
                                                            setPrice(
                                                                floatvalue
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="checkbox">
                                            <input
                                                type="checkbox"
                                                id="isPriority"
                                                checked={isPriority}
                                                onChange={(e) => {
                                                    setIsPriority(!isPriority);
                                                }}
                                            />
                                            <label htmlFor="isPriority">
                                                Prioridade
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="section">
                                    <div className="section-title">
                                        Promoções
                                    </div>
                                    <div className="section-body">
                                        <button
                                            className="btn btn-sm btn-success"
                                            onClick={(e) => {
                                                addPromotion(e);
                                            }}
                                        >
                                            Adicionar Promoção{" "}
                                            <i className="fa fa-plus"></i>
                                        </button>
                                        <table className="table table-striped table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Carnê ou Cartão?</th>
                                                    <th>Qtd. Parcelas</th>
                                                    <th>Entrada?</th>
                                                    <th>Total à Prazo</th>
                                                    <th>Opções</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {promotions.map(
                                                    (
                                                        {
                                                            carneOrCreditCard,
                                                            paymentX,
                                                            entrance,
                                                            totalAmount,
                                                        },
                                                        indexPromotion
                                                    ) => (
                                                        <tr
                                                            key={indexPromotion}
                                                        >
                                                            <td>
                                                                <select
                                                                    name="carneOrCreditCard"
                                                                    id="carneOrCreditCard"
                                                                    className="form-control"
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        onChangeInputTablePromotions(
                                                                            e,
                                                                            indexPromotion
                                                                        )
                                                                    }
                                                                    value={
                                                                        carneOrCreditCard
                                                                    }
                                                                >
                                                                    <option value="">
                                                                        Escolha
                                                                        um tipo
                                                                    </option>
                                                                    <option value="carne">
                                                                        Carnê
                                                                    </option>
                                                                    <option value="creditCard">
                                                                        Cartão
                                                                        de
                                                                        Crédito
                                                                    </option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <input
                                                                    name="paymentX"
                                                                    value={
                                                                        paymentX
                                                                    }
                                                                    type="number"
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        onChangeInputTablePromotions(
                                                                            e,
                                                                            indexPromotion
                                                                        )
                                                                    }
                                                                    className="form-control"
                                                                    placeholder="Quantidade de Parcelas"
                                                                />
                                                            </td>
                                                            <td>
                                                                <div className="checkbox">
                                                                    <input
                                                                        type="checkbox"
                                                                        name="entrance"
                                                                        id={`entrance_${indexPromotion}`}
                                                                        checked={
                                                                            entrance
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            onChangeInputTablePromotions(
                                                                                e,
                                                                                indexPromotion
                                                                            );
                                                                        }}
                                                                    />
                                                                    <label
                                                                        htmlFor={`entrance_${indexPromotion}`}
                                                                    >
                                                                        Tem
                                                                        Entrada
                                                                    </label>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <CurrencyInput
                                                                    className="form-control"
                                                                    decimalSeparator=","
                                                                    thousandSeparator="."
                                                                    name="totalAmount"
                                                                    value={
                                                                        totalAmount
                                                                    }
                                                                    onChangeEvent={(
                                                                        event,
                                                                        maskedvalue,
                                                                        floatvalue
                                                                    ) => {
                                                                        onChangeInputTablePromotions(
                                                                            {
                                                                                target: {
                                                                                    name: "totalAmount",
                                                                                    value: floatvalue,
                                                                                },
                                                                            },
                                                                            indexPromotion
                                                                        );
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <button
                                                                    className="btn btn-danger"
                                                                    onClick={(
                                                                        e
                                                                    ) =>
                                                                        removePromotion(
                                                                            e,
                                                                            indexPromotion
                                                                        )
                                                                    }
                                                                >
                                                                    Excluir
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="form-footer">
                                    <div className="form-group">
                                        <div className="col-md-9 col-md-offset-3">
                                            <button
                                                type="submit"
                                                className="btn btn-primary"
                                            >
                                                Salvar
                                            </button>
                                            <button
                                                type="button"
                                                onClick={handleCancelButton}
                                                className="btn btn-default"
                                            >
                                                Cancelar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};
