import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import Layout from '../../components/Layout';

import * as moment from 'moment-timezone';

import api from '../../services/api';

import Swal from 'sweetalert2';

export default class ProductOfferListChildrens extends Component {
    constructor(props) {
        super(props);

        const dateNow = new Date();

        this.state = {
            productsOffer: [],
            productsOfferToShow: [],
            product_search_term: "",
            dateBegin: new Date(dateNow.getFullYear(), dateNow.getMonth(), 1),
            dateEnd: new Date(dateNow.getFullYear(), dateNow.getMonth() + 1, 0),
            loaded: false
        }

        this.handleSearchProduct = this.handleSearchProduct.bind(this);
    }

    componentWillMount = async function () {
        this.loadProductsOffer();
    }

    loadProductsOffer = async function() {
        this.setState(prevState => ({
            ...prevState,
            loaded: false
        }));

        const { id } = this.props.match.params;

        const returnProductsOffer = await api.get(`/product-offer/${this.state.dateBegin.toISOString()}/${this.state.dateEnd.toISOString()}/0/${id}`);
        
        if (returnProductsOffer.status === 500) {
            Swal.fire({
                type: 'error',
                title: 'Erro!',
                text: 'Tivemos um problema ao buscar os produto em oferta. Contate o suporte!',
            }).then(() => this.props.history.push("/"));
            return;
        }

        this.setState(prevState => ({
            ...prevState,
            loaded: true,
            productsOffer: returnProductsOffer.data.products,
            productsOfferToShow: returnProductsOffer.data.products
        }));

        this.handleSearchProduct();
    }

    handleSearchProduct() {
        this.setState(prevState => ({
            ...prevState,
            loaded: false
        }));

        let originalProductsOffer = this.state.productsOffer;

        let filteredProductsOffer = originalProductsOffer.filter((productOffer) => (productOffer.product.name.toLowerCase().indexOf(this.state.product_search_term.toLowerCase()) !== -1));

        if (filteredProductsOffer.length <= 0) {
            filteredProductsOffer = originalProductsOffer.filter((productOffer) => (productOffer.product.codigo.toLowerCase().indexOf(this.state.product_search_term.toLowerCase()) !== -1));
        }

        this.setState(prevState => ({
            ...prevState,
            productsOfferToShow: filteredProductsOffer,
            loaded: true
        }))
    }

    handleDeleteButton = async function(productOffer) {
        this.setState(prevState => ({
            ...prevState,
            loaded: false
        }));

        const self = this;

        Swal.fire({
            title: `Tem certeza que deseja excluir essa oferta do ${productOffer.product.name} na filial ${productOffer.branch.codigo_loja} - ${productOffer.branch.cidade_estado}?`,
            text: "Ao excluir esse registro, você estará excluindo apenas a oferta desta filial, e não de todo o estado.",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33', 
            confirmButtonText: 'Sim, quero excluir!',
            cancelButtonText: 'Cancelar'
        }).then(async function(result) {
            if (result.value) {
                const deletedProduct = await api.delete(`/product-offer/${productOffer._id}/1`);

                if (deletedProduct.status === 204) {
                    self.loadProductsOffer();

                    self.setState(prevState => ({
                        ...prevState,
                        product_search_term: ""
                    }));

                    Swal.fire(
                        'Excluído!',
                        'A oferta do produto foi excluída com sucesso da filial!',
                        'success'
                    );
                } else {
                    Swal.fire(
                        'Oops...',
                        'Não foi possível excluir a oferta do produto produto agora. Por favor contate o suporte!',
                        'error'
                    ).then(() => {
                        self.setState(prevState => ({
                            ...prevState,
                            loaded: true
                        }));
                    })
                }
            } else {
                self.setState(prevState => ({
                    ...prevState,
                    loaded: true,
                    product_search_term: ""
                }));
            }
        });
    }

    render() {
        let { productsOfferToShow } = this.state;

        return (
            <Layout
                menuActive="registers" 
                pageTitle="Produtos em Oferta por Filial"
                enableSearchBar={true}
                searchTerm={this.state.product_search_term}
                searchOnChange={(e) => this.setState({product_search_term: e.target.value})}
                handleSearch={this.handleSearchProduct}
            >
                <div className="row">
                    <div className="col-xs-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="card-title">
                                    Produtos em Oferta por Filial
                                </div>
                                <ul className="card-action">
                                    <li>
                                        <Link to="/produtos-oferta-nova" className="btn btn-sm btn-success">
                                            Nova <i className="fa fa-plus"></i>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className={`card-body table-responsive ${this.state.loaded ? "" : "__loading"}`}>
                                <div class="loader-container text-center">
                                    <div class="icon">
                                        <div class="sk-wave">
                                            <div class="sk-rect sk-rect1"></div>
                                            <div class="sk-rect sk-rect2"></div>
                                            <div class="sk-rect sk-rect3"></div>
                                            <div class="sk-rect sk-rect4"></div>
                                            <div class="sk-rect sk-rect5"></div>
                                        </div>
                                    </div>
                                    <div class="title">Carregando</div>
                                </div>

                                <div className="row">
                                    <div className="col-xs-12">
                                        <table className="table table-striped table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        Estado
                                                    </th>
                                                    <th>
                                                        Filial
                                                    </th>
                                                    <th>
                                                        Código
                                                    </th>
                                                    <th>
                                                        Nome
                                                    </th>
                                                    <th>
                                                        Data Início
                                                    </th>
                                                    <th>
                                                        Data Fim
                                                    </th>
                                                    <th>
                                                        Preço
                                                    </th>
                                                    <th>
                                                        Preço à Prazo
                                                    </th>
                                                    <th>
                                                        Custo
                                                    </th>
                                                    <th>
                                                        Sem juros?
                                                    </th>
                                                    <th>
                                                        Sem entrada?
                                                    </th>
                                                    <th>
                                                        No Cartão de Crédito?
                                                    </th>
                                                    <th>
                                                        No carnê?
                                                    </th>
                                                    <th>
                                                        Qtd. Parcelas
                                                    </th>
                                                    <th>
                                                        Opções
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    productsOfferToShow.map((productOffer, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>
                                                                    {productOffer.state}
                                                                </td>
                                                                <td>
                                                                    {`${productOffer.branch.codigo_loja} - ${productOffer.branch.cidade_estado}`}
                                                                </td>
                                                                <td>
                                                                    {productOffer.product.codigo}
                                                                </td>
                                                                <td>
                                                                    {productOffer.product.name}
                                                                </td>
                                                                <td>
                                                                    {moment.tz(productOffer.date_offer_init, "America/Sao_Paulo").format('DD/MM/YYYY')}
                                                                </td>
                                                                <td>
                                                                    {moment.tz(productOffer.date_offer_end, "America/Sao_Paulo").format('DD/MM/YYYY')}
                                                                </td>
                                                                <td>
                                                                    {productOffer.price.toLocaleString('pt-BR', { minimumFractionDigits: 2 , style: 'currency', currency: 'BRL' })}
                                                                </td>
                                                                <td>
                                                                    {productOffer.total_amount.toLocaleString('pt-BR', { minimumFractionDigits: 2 , style: 'currency', currency: 'BRL' })}
                                                                </td>
                                                                <td>
                                                                    {productOffer.cost.toLocaleString('pt-BR', { minimumFractionDigits: 2 , style: 'currency', currency: 'BRL' })}
                                                                </td>
                                                                <td>
                                                                    {productOffer.interest_free ? 'Sim' : 'Não'}
                                                                </td>
                                                                <td>
                                                                    {productOffer.no_entrance ? 'Sim' : 'Não'}
                                                                </td>
                                                                <td>
                                                                    {productOffer.credit_card ? 'Sim' : 'Não'}
                                                                </td>
                                                                <td>
                                                                    {productOffer.carne ? 'Sim' : 'Não'}
                                                                </td>
                                                                <td>
                                                                    {productOffer.payment_x}
                                                                </td>
                                                                <td>
                                                                    <div className="col-xs-12">
                                                                        <div className="col-xs-12">
                                                                            <button
                                                                                className="btn btn-xs btn-danger"
                                                                                onClick={() => this.handleDeleteButton(productOffer)}
                                                                                style={{width: '100%'}}
                                                                            >
                                                                                Excluir <div className="icon fa fa-trash-o"></div>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}
