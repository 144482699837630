import React, { useState, useEffect } from "react";

import { Link, withRouter } from "react-router-dom";

import Layout from "../../components/Layout";

import api from "../../services/api";

import Swal from "sweetalert2";

const UserChangePassword = (props) => {
    const [users, setUsers] = useState([]);
    const [usersToShow, setUsersToShow] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [pages, setPages] = useState(0);
    const [actualPage, setActualPage] = useState(1);

    useEffect(function () {
        loadList();
    }, []);

    async function loadList() {
        loadUsers();
    }

    async function loadUsers(page = 1, search = "") {
        setLoaded(false);

        const usersFromAPI = await api.get(
            `/user-per-page/${page}${
                search !== "" ? "?search_text=" + encodeURI(search) : ""
            }`
        );

        setUsers(usersFromAPI.data.users);
        setUsersToShow(usersFromAPI.data.users);
        setPages(Math.trunc(usersFromAPI.data.countUsers / 20));
        setActualPage(page);
        setLoaded(true);
    }

    function handleSearchBar() {
        // setLoaded(false);

        // const originalUsers = users;

        // if (searchText === "") {
        //     setUsersToShow(originalUsers);
        //     setLoaded(true);
        //     return;
        // }

        // let filteredUsers = originalUsers.filter(
        //     (user) =>
        //         user.cpf.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
        // );

        // if (filteredUsers.length <= 0) {
        //     filteredUsers = originalUsers.filter(
        //         (user) =>
        //             user.name
        //                 .toLowerCase()
        //                 .indexOf(searchText.toLowerCase()) !== -1
        //     );
        // }

        // setUsersToShow(filteredUsers);
        // setLoaded(true);
        loadUsers(1, searchText);
    }

    function changePasswordUser(userId) {
        async function callAPIAndUpdate(userId) {
            const passwordAuthorization = prompt("Senha de autorização");

            if (passwordAuthorization === "rxyRXY132") {
                const newPassword = prompt("Nova senha");

                try {
                    const updateUserPasswordAPI = await api.patch(
                        "/account-password",
                        { passwordAuthorization, userId, newPassword }
                    );

                    if (updateUserPasswordAPI.data.status === "ok") {
                        alert("Senha alterada com sucesso!");
                    }
                } catch (error) {
                    alert(
                        "Não foi possível utilizar essa nova senha, tente outra."
                    );
                }
            } else {
                alert("Senha incorreta!");
            }
        }

        callAPIAndUpdate(userId);
    }

    function changeActiveInList(userId, indexUser) {
        async function callAPIAndUpdate(userId, indexUser) {
            const passwordAuthorization = prompt("Senha de autorização");

            if (passwordAuthorization === "rxyRXY132") {
                try {
                    const updateUserPasswordAPI = await api.get(
                        `/users-change-active-in-list/${userId}`
                    );

                    if (updateUserPasswordAPI.status === 200) {
                        loadUsers(actualPage, searchText);
                    }
                } catch (error) {
                    alert(
                        "Não foi possível efetuar o comando. Contate o suporte!"
                    );
                }
            } else {
                alert("Senha incorreta!");
            }
        }

        callAPIAndUpdate(userId, indexUser);
    }

    return (
        <Layout
            menuActive="registers"
            pageTitle="Trocar senha usuário"
            enableSearchBar={true}
            searchTerm={searchText}
            searchOnChange={(e) => setSearchText(e.target.value)}
            handleSearch={handleSearchBar}
        >
            <div className="row">
                <div className="col-xs-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">
                                Trocar senha usuário
                            </div>
                            {/* <ul className="card-action">
                                <li>
                                    <Link
                                        to="/filiais/nova"
                                        className="btn btn-sm btn-success"
                                    >
                                        Nova <i className="fa fa-plus"></i>
                                    </Link>
                                </li>
                            </ul> */}
                        </div>
                        <div
                            className={`card-body ${loaded ? "" : "__loading"}`}
                        >
                            <div class="loader-container text-center">
                                <div class="icon">
                                    <div class="sk-wave">
                                        <div class="sk-rect sk-rect1"></div>
                                        <div class="sk-rect sk-rect2"></div>
                                        <div class="sk-rect sk-rect3"></div>
                                        <div class="sk-rect sk-rect4"></div>
                                        <div class="sk-rect sk-rect5"></div>
                                    </div>
                                </div>
                                <div class="title">Carregando</div>
                            </div>

                            <div className="row">
                                <div className="col-xs-12">
                                    <table className="table table-striped table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th>Nome</th>
                                                <th>CPF</th>
                                                <th>Filial</th>
                                                <th>Telefone</th>
                                                <th>Opções</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {usersToShow.map((user, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{user.name}</td>
                                                        <td>{user.cpf}</td>
                                                        <td>
                                                            {`${user.branch_selected.codigo_loja} - ${user.branch_selected.cidade_estado}`}
                                                        </td>
                                                        <td>{user.phone}</td>
                                                        <td>
                                                            <div className="col-xs-12">
                                                                <div className="col-xs-12">
                                                                    <button
                                                                        className="btn btn-xs btn-primary"
                                                                        style={{
                                                                            width:
                                                                                "100%",
                                                                        }}
                                                                        onClick={() =>
                                                                            changePasswordUser(
                                                                                user._id
                                                                            )
                                                                        }
                                                                    >
                                                                        Trocar
                                                                        Senha{" "}
                                                                        <div className="icon fa fa-pencil"></div>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            {user.activeInList ? (
                                                                <div className="col-xs-12">
                                                                    <div className="col-xs-12">
                                                                        <button
                                                                            className="btn btn-xs btn-danger"
                                                                            style={{
                                                                                width:
                                                                                    "100%",
                                                                            }}
                                                                            onClick={() =>
                                                                                changeActiveInList(
                                                                                    user._id,
                                                                                    index
                                                                                )
                                                                            }
                                                                        >
                                                                            Desabilitar
                                                                            conta
                                                                            no
                                                                            CONTE
                                                                            COMIGO
                                                                            <div className="icon fa fa-pencil"></div>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className="col-xs-12">
                                                                    <div className="col-xs-12">
                                                                        <button
                                                                            className="btn btn-xs btn-success"
                                                                            style={{
                                                                                width:
                                                                                    "100%",
                                                                            }}
                                                                            onClick={() =>
                                                                                changeActiveInList(
                                                                                    user._id,
                                                                                    index
                                                                                )
                                                                            }
                                                                        >
                                                                            Habilitar
                                                                            conta
                                                                            no
                                                                            CONTE
                                                                            COMIGO
                                                                            <div className="icon fa fa-pencil"></div>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {pages !== 0 ? (
                                <div className="row">
                                    <div className="col-xs-12">
                                        <ul className="pagination">
                                            {Array(pages)
                                                .fill()
                                                .map((_, page) => (
                                                    <li
                                                        className={
                                                            page + 1 ===
                                                            actualPage
                                                                ? "active"
                                                                : ""
                                                        }
                                                    >
                                                        <a
                                                            href="#"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                loadUsers(
                                                                    page + 1,
                                                                    searchText
                                                                );
                                                            }}
                                                        >
                                                            {page + 1}
                                                        </a>
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default withRouter(UserChangePassword);
