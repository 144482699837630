import React, { Component } from "react";

import * as moment from "moment-timezone";

import Layout from "../../components/Layout";

import api from "../../services/api";
import { getBranches } from "../../services/auth";

import Select from "react-select";

import Swal from "sweetalert2";

import CurrencyInput from "react-currency-input";

import MultiselectTwoSides from "react-multiselect-two-sides";

export default class ProductOfferNew extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            productOffer: {
                _id: "",
                product: { value: "", label: "Todas" },
                date_offer_init: moment().format("YYYY-MM-DD"),
                date_offer_end: moment().format("YYYY-MM-DD"),
                price: 0,
                total_amount: 0,
                interest_free: false,
                no_entrance: false,
                credit_card: false,
                carne: false,
                payment_x: 10,
                branches: [],
            },
            branches: [],
            products: [],
        };

        this.handleCancelButton = this.handleCancelButton.bind(this);
        this.handleSubmitForm = this.handleSubmitForm.bind(this);
    }

    componentWillMount = async () => {
        const returnBranches = await api.get("/branch");
        const returnProducts = await api.get("/product");

        const branches = getBranches();
        console.log(branches);

        let branchesToSelect = [];
        let productToSelect = [{ value: "", label: "Todas" }];

        await returnBranches.data.map((branch) => {
            console.log(branch);
            if (branches.includes(branch._id)) {
                branchesToSelect.push({
                    value: branch._id,
                    label: `${branch.codigo_loja} - ${branch.cidade_estado}`,
                });
            }
        });

        await returnProducts.data.map((product) =>
            productToSelect.push({
                value: product.id,
                label: `${product.code} - ${product.originalName}`,
            })
        );

        if (
            !this.props.location.state ||
            !this.props.location.state.productOffer
        ) {
            this.setState((prevState) => ({
                ...prevState,
                loaded: true,
                branches: branchesToSelect,
                products: productToSelect,
            }));
            return;
        }

        const { productOffer } = this.props.location.state;

        this.setState((prevState) => ({
            ...prevState,
            loaded: true,
            branches: branchesToSelect,
            products: productToSelect,
            productOffer: {
                _id: productOffer._id,
                product: {
                    value: productOffer.product._id,
                    label: productOffer.product.name,
                },
                date_offer_init: moment(productOffer.date_offer_init).format(
                    "YYYY-MM-DD"
                ),
                date_offer_end: moment(productOffer.date_offer_end).format(
                    "YYYY-MM-DD"
                ),
                price: productOffer.price,
                total_amount: productOffer.total_amount,
                interest_free: productOffer.interest_free,
                no_entrance: productOffer.no_entrance,
                credit_card: productOffer.credit_card,
                carne: productOffer.carne,
                payment_x: productOffer.payment_x,
                branches: productOffer.branches.map((branch) => branch._id),
            },
        }));
    };

    handleCancelButton(event) {
        Swal.fire({
            title: "Tem certeza que deseja cancelar?",
            text: "Cancelar fará você perder todas as modificações feitas até o momento!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim, quero cancelar!",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.value) {
                Swal.fire(
                    "Cancelado!",
                    "Você irá voltar para a tela de listagem.",
                    "success"
                );

                this.props.history.push("/produtos-oferta");
            }
        });
    }

    handleChangeSelectedBranches = (value) => {
        console.log(value);
        this.setState((prevState) => ({
            ...prevState,
            productOffer: {
                ...prevState.productOffer,
                branches: value,
            },
        }));
    };

    handleSubmitForm = async function (event) {
        event.preventDefault();

        this.setState((prevState) => ({
            ...prevState,
            loaded: false,
        }));

        try {
            const sendProductOffer = {
                product: this.state.productOffer.product.value,
                branches: this.state.productOffer.branches,
                date_offer_init: this.state.productOffer.date_offer_init,
                date_offer_end: this.state.productOffer.date_offer_end,
                price: this.state.productOffer.price,
                interest_free: this.state.productOffer.interest_free,
                no_entrance: this.state.productOffer.no_entrance,
                credit_card: this.state.productOffer.credit_card,
                carne: this.state.productOffer.carne,
                payment_x: this.state.productOffer.payment_x,
                total_amount: this.state.productOffer.total_amount,
            };

            const createdProductOffer = await api.post(
                `/product-offer${
                    this.state.productOffer._id
                        ? `/${this.state.productOffer._id}`
                        : ""
                }`,
                sendProductOffer
            );

            if (createdProductOffer.status === 200) {
                Swal.fire({
                    type: "success",
                    title: `Oferta ${
                        this.state.productOffer._id ? "salva!" : "cadastrada!"
                    }`,
                    text: `A oferta do produto foi ${
                        this.state.productOffer._id ? "salva!" : "cadastrada"
                    } com sucesso!`,
                }).then(() => this.props.history.push("/produtos-oferta"));
            } else {
                Swal.fire({
                    type: "error",
                    title: "Oops...",
                    text: `Tivemos um problema ao ${
                        this.state.productOffer._id ? "salva!" : "cadastrada"
                    } a oferta do produto. Contate o suporte!`,
                });

                this.setState((prevState) => ({
                    ...prevState,
                    loaded: true,
                }));
            }
        } catch (e) {
            Swal.fire({
                type: "error",
                title: "Oops...",
                text: `Tivemos um problema ao ${
                    this.state.productOffer._id ? "salva!" : "cadastrada"
                } a oferta do produto. Contate o suporte!`,
            });

            this.setState((prevState) => ({
                ...prevState,
                loaded: true,
            }));
        }
    };

    render() {
        return (
            <Layout
                menuActive="products-offer"
                pageTitle="Nova Oferta de Produto"
            >
                <div className="row">
                    <div className="col-xs-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="card-title">
                                    Nova Oferta de Produto
                                </div>
                            </div>
                            <div
                                className={`card-body ${
                                    this.state.loaded ? "" : "__loading"
                                }`}
                            >
                                <div className="loader-container text-center">
                                    <div className="icon">
                                        <div className="sk-wave">
                                            <div className="sk-rect sk-rect1"></div>
                                            <div className="sk-rect sk-rect2"></div>
                                            <div className="sk-rect sk-rect3"></div>
                                            <div className="sk-rect sk-rect4"></div>
                                            <div className="sk-rect sk-rect5"></div>
                                        </div>
                                    </div>
                                    <div className="title">Carregando</div>
                                </div>

                                <form
                                    className="form form-horizontal"
                                    onSubmit={this.handleSubmitForm}
                                >
                                    <div className="section">
                                        <div className="section-title">
                                            Dados da Oferta
                                        </div>
                                        <div className="section-body">
                                            {/* <div className="form-group">
                                                <label className="col-md-3 control-label">
                                                    Estado
                                                </label>
                                                <div className="col-md-9">
                                                    <select
                                                        className="form-control"
                                                        value={
                                                            this.state
                                                                .productOffer
                                                                .state
                                                        }
                                                        onChange={(e) => {
                                                            const { value } =
                                                                e.target; 

                                                            this.setState(
                                                                (
                                                                    prevState
                                                                ) => ({
                                                                    ...prevState,
                                                                    productOffer:
                                                                        {
                                                                            ...prevState.productOffer,
                                                                            state: value,
                                                                        },
                                                                })
                                                            );
                                                        }}
                                                    >
                                                        <option
                                                            value=""
                                                            selected
                                                        >
                                                            Escolha um estado
                                                        </option>
                                                        <option value="AC">
                                                            Acre
                                                        </option>
                                                        <option value="AL">
                                                            Alagoas
                                                        </option>
                                                        <option value="AP">
                                                            Amapá
                                                        </option>
                                                        <option value="AM">
                                                            Amazonas
                                                        </option>
                                                        <option value="BA">
                                                            Bahia
                                                        </option>
                                                        <option value="CE">
                                                            Ceará
                                                        </option>
                                                        <option value="DF">
                                                            Distrito Federal
                                                        </option>
                                                        <option value="ES">
                                                            Espírito Santo
                                                        </option>
                                                        <option value="GO">
                                                            Goiás
                                                        </option>
                                                        <option value="MA">
                                                            Maranhão
                                                        </option>
                                                        <option value="MT">
                                                            Mato Grosso
                                                        </option>
                                                        <option value="MS">
                                                            Mato Grosso do Sul
                                                        </option>
                                                        <option value="MG">
                                                            Minas Gerais
                                                        </option>
                                                        <option value="PA">
                                                            Pará
                                                        </option>
                                                        <option value="PB">
                                                            Paraíba
                                                        </option>
                                                        <option value="PR">
                                                            Paraná
                                                        </option>
                                                        <option value="PE">
                                                            Pernambuco
                                                        </option>
                                                        <option value="PI">
                                                            Piauí
                                                        </option>
                                                        <option value="RJ">
                                                            Rio de Janeiro
                                                        </option>
                                                        <option value="RN">
                                                            Rio Grande do Norte
                                                        </option>
                                                        <option value="RS">
                                                            Rio Grande do Sul
                                                        </option>
                                                        <option value="RO">
                                                            Rondônia
                                                        </option>
                                                        <option value="RR">
                                                            Roraima
                                                        </option>
                                                        <option value="SC">
                                                            Santa Catarina
                                                        </option>
                                                        <option value="SP">
                                                            São Paulo
                                                        </option>
                                                        <option value="SE">
                                                            Sergipe
                                                        </option>
                                                        <option value="TO">
                                                            Tocantins
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>

                                            <h4>Ou</h4>

                                            <div className="form-group">
                                                <label className="col-md-3 control-label">
                                                    Filial
                                                </label>
                                                <div className="col-md-9">
                                                    <Select
                                                        placeholder="Selecione uma filial"
                                                        value={
                                                            this.state
                                                                .productOffer
                                                                .branch
                                                        }
                                                        onChange={(
                                                            selectedOption
                                                        ) => {
                                                            this.setState(
                                                                (
                                                                    prevState
                                                                ) => ({
                                                                    ...prevState,
                                                                    productOffer:
                                                                        {
                                                                            ...prevState.productOffer,
                                                                            branch: selectedOption,
                                                                        },
                                                                })
                                                            );
                                                        }}
                                                        options={
                                                            this.state.branches
                                                        }
                                                    />
                                                </div>
                                            </div> */}

                                            <div className="col-md-offset-3 col-md-9">
                                                <div
                                                    class="alert alert-warning"
                                                    role="alert"
                                                >
                                                    Para filtrar por estado,
                                                    basta utilizar, por exemplo:
                                                    "-PR" ou "-GO" e clicar na
                                                    seta para enviar todas as
                                                    filiais filtradas do lado
                                                    esquerdo para o direito
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="col-md-3 control-label">
                                                    Filiais
                                                </label>
                                                <div className="col-md-9">
                                                    <MultiselectTwoSides
                                                        options={
                                                            this.state.branches
                                                        }
                                                        value={
                                                            this.state
                                                                .productOffer
                                                                .branches
                                                        }
                                                        className="msts_theme_example"
                                                        onChange={
                                                            this
                                                                .handleChangeSelectedBranches
                                                        }
                                                        availableHeader="Disponíveis"
                                                        availableFooter={`Disponíveis: ${
                                                            this.state.branches
                                                                .length -
                                                            this.state
                                                                .productOffer
                                                                .branches.length
                                                        }`}
                                                        selectedHeader="Selecionadas"
                                                        selectedFooter={`Selecionadas: ${this.state.productOffer.branches.length}`}
                                                        labelKey="label"
                                                        showControls
                                                        searchable
                                                    />
                                                </div>
                                            </div>
                                            <br />

                                            <div className="form-group">
                                                <label className="col-md-3 control-label">
                                                    Produto
                                                </label>
                                                <div className="col-md-9">
                                                    <Select
                                                        placeholder="Selecione um produto"
                                                        value={
                                                            this.state
                                                                .productOffer
                                                                .product
                                                        }
                                                        onChange={(
                                                            selectedOption
                                                        ) => {
                                                            this.setState(
                                                                (
                                                                    prevState
                                                                ) => ({
                                                                    ...prevState,
                                                                    productOffer:
                                                                        {
                                                                            ...prevState.productOffer,
                                                                            product:
                                                                                selectedOption,
                                                                        },
                                                                })
                                                            );
                                                        }}
                                                        options={
                                                            this.state.products
                                                        }
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label className="col-md-3 control-label">
                                                    Data Início
                                                </label>
                                                <div className="col-md-9">
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        placeholder=""
                                                        value={
                                                            this.state
                                                                .productOffer
                                                                .date_offer_init
                                                        }
                                                        onChange={(e) => {
                                                            const { value } =
                                                                e.target;

                                                            this.setState(
                                                                (
                                                                    prevState
                                                                ) => ({
                                                                    ...prevState,
                                                                    productOffer:
                                                                        {
                                                                            ...prevState.productOffer,
                                                                            date_offer_init:
                                                                                value,
                                                                        },
                                                                })
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label className="col-md-3 control-label">
                                                    Data Fim
                                                </label>
                                                <div className="col-md-9">
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        placeholder=""
                                                        value={
                                                            this.state
                                                                .productOffer
                                                                .date_offer_end
                                                        }
                                                        onChange={(e) => {
                                                            const { value } =
                                                                e.target;

                                                            this.setState(
                                                                (
                                                                    prevState
                                                                ) => ({
                                                                    ...prevState,
                                                                    productOffer:
                                                                        {
                                                                            ...prevState.productOffer,
                                                                            date_offer_end:
                                                                                value,
                                                                        },
                                                                })
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label className="col-md-3 control-label">
                                                    Parcelas
                                                </label>
                                                <div className="col-md-9">
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        placeholder=""
                                                        value={
                                                            this.state
                                                                .productOffer
                                                                .payment_x
                                                        }
                                                        onChange={(e) => {
                                                            const { value } =
                                                                e.target;

                                                            this.setState(
                                                                (
                                                                    prevState
                                                                ) => ({
                                                                    ...prevState,
                                                                    productOffer:
                                                                        {
                                                                            ...prevState.productOffer,
                                                                            payment_x:
                                                                                value,
                                                                        },
                                                                })
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label className="col-md-3 control-label">
                                                    Valor
                                                </label>
                                                <div className="col-md-9">
                                                    <div className="input-group">
                                                        <span className="input-group-addon">
                                                            R$
                                                        </span>
                                                        <CurrencyInput
                                                            className="form-control"
                                                            decimalSeparator=","
                                                            thousandSeparator="."
                                                            value={
                                                                this.state
                                                                    .productOffer
                                                                    .price
                                                            }
                                                            onChangeEvent={(
                                                                event,
                                                                maskedvalue,
                                                                floatvalue
                                                            ) => {
                                                                this.setState(
                                                                    (
                                                                        prevState
                                                                    ) => ({
                                                                        ...prevState,
                                                                        productOffer:
                                                                            {
                                                                                ...prevState.productOffer,
                                                                                price: floatvalue,
                                                                            },
                                                                    })
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label className="col-md-3 control-label">
                                                    Valor à Prazo
                                                </label>
                                                <div className="col-md-9">
                                                    <div className="input-group">
                                                        <span className="input-group-addon">
                                                            R$
                                                        </span>
                                                        <CurrencyInput
                                                            className="form-control"
                                                            decimalSeparator=","
                                                            thousandSeparator="."
                                                            value={
                                                                this.state
                                                                    .productOffer
                                                                    .total_amount
                                                            }
                                                            onChangeEvent={(
                                                                event,
                                                                maskedvalue,
                                                                floatvalue
                                                            ) => {
                                                                this.setState(
                                                                    (
                                                                        prevState
                                                                    ) => ({
                                                                        ...prevState,
                                                                        productOffer:
                                                                            {
                                                                                ...prevState.productOffer,
                                                                                total_amount:
                                                                                    floatvalue,
                                                                            },
                                                                    })
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="checkbox">
                                                <input
                                                    type="checkbox"
                                                    id="interest_free"
                                                    checked={
                                                        this.state.productOffer
                                                            .interest_free
                                                    }
                                                    onChange={(e) => {
                                                        const { checked } =
                                                            e.target;

                                                        this.setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                productOffer: {
                                                                    ...prevState.productOffer,
                                                                    interest_free:
                                                                        checked,
                                                                },
                                                            })
                                                        );
                                                    }}
                                                />
                                                <label htmlFor="interest_free">
                                                    Sem Juros?
                                                </label>
                                            </div>

                                            <div className="checkbox">
                                                <input
                                                    type="checkbox"
                                                    id="no_entrance"
                                                    checked={
                                                        this.state.productOffer
                                                            .no_entrance
                                                    }
                                                    onChange={(e) => {
                                                        const { checked } =
                                                            e.target;

                                                        this.setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                productOffer: {
                                                                    ...prevState.productOffer,
                                                                    no_entrance:
                                                                        checked,
                                                                },
                                                            })
                                                        );
                                                    }}
                                                />
                                                <label htmlFor="no_entrance">
                                                    Sem Entrada?
                                                </label>
                                            </div>

                                            <div className="checkbox">
                                                <input
                                                    type="checkbox"
                                                    id="credit_card"
                                                    checked={
                                                        this.state.productOffer
                                                            .credit_card
                                                    }
                                                    onChange={(e) => {
                                                        const { checked } =
                                                            e.target;

                                                        this.setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                productOffer: {
                                                                    ...prevState.productOffer,
                                                                    credit_card:
                                                                        checked,
                                                                },
                                                            })
                                                        );
                                                    }}
                                                />
                                                <label htmlFor="credit_card">
                                                    No Cartão de Crédito?
                                                </label>
                                            </div>

                                            <div className="checkbox">
                                                <input
                                                    type="checkbox"
                                                    id="carne"
                                                    checked={
                                                        this.state.productOffer
                                                            .carne
                                                    }
                                                    onChange={(e) => {
                                                        const { checked } =
                                                            e.target;

                                                        this.setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                productOffer: {
                                                                    ...prevState.productOffer,
                                                                    carne: checked,
                                                                },
                                                            })
                                                        );
                                                    }}
                                                />
                                                <label htmlFor="carne">
                                                    No Carnê?
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-footer">
                                        <div className="form-group">
                                            <div className="col-md-9 col-md-offset-3">
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary"
                                                >
                                                    Salvar
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={
                                                        this.handleCancelButton
                                                    }
                                                    className="btn btn-default"
                                                >
                                                    Cancelar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}
