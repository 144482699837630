import React, { useState, useEffect } from "react";

import axios from "axios";
import * as moment from "moment-timezone";

import { withRouter, Link } from "react-router-dom";

import Layout from "../../components/Layout";

import api from "../../services/api";

import Swal from "sweetalert2";
import { components } from "react-select";

const BranchesTabloidList = (props) => {
    const [branchesTabloid, setBranchesTabloid] = useState([]);
    const [branchesTabloidToShow, setBranchesTabloidToShow] = useState([]);
    const [loaded, setLoaded] = useState(false);
    // const [searchText, setSearchText] = useState("");
    // const [fileURL, setFileURL] = useState("");
    // const [fileName, setFileName] = useState("");

    useEffect(function () {
        loadList();
    }, []);

    async function loadList() {
        setLoaded(false);

        const responseGetBranchesTabloid = await api.get("/branch-tabloid");

        setBranchesTabloid(responseGetBranchesTabloid.data.data);
        setBranchesTabloidToShow(responseGetBranchesTabloid.data.data);
        setLoaded(true);
    }

    // function handleSearchBar() {
    //     setLoaded(false);

    //     const originalPosts = posts;

    //     if (searchText === "") {
    //         setPostsToShow(originalPosts);
    //         setLoaded(true);
    //         return;
    //     }

    //     let filteredPosts = originalPosts.filter(
    //         (post) =>
    //             post.title.toLowerCase().indexOf(searchText.toLowerCase()) !==
    //             -1
    //     );

    //     setPostsToShow(filteredPosts);
    //     setLoaded(true);
    // }

    async function handleDelete(branchTabloidDelete) {
        Swal.fire({
            title: `Tem certeza que deseja excluir o tabloid para essas filiais?`,
            text: "Não será possível voltar atrás!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim, quero excluir!",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (!result.value) {
                Swal.fire(
                    "Cancelado!",
                    "Nenhum dado será excluído.",
                    "success"
                );
            } else {
                setLoaded(false);

                try {
                    const responseDelete = await api.delete(
                        `/branch-tabloid/${branchTabloidDelete._id}`
                    );

                    if (responseDelete.status === 204) {
                        const newBranchesTabloid = branchesTabloid.filter(
                            (branchTabloid) =>
                                branchTabloid._id !== branchTabloidDelete._id
                        );
                        setBranchesTabloid(newBranchesTabloid);
                        setBranchesTabloidToShow(newBranchesTabloid);
                        setLoaded(true);
                    } else {
                        throw "Error";
                    }
                } catch (error) {
                    console.log(error);
                    setLoaded(true);
                    Swal.fire({
                        type: "error",
                        title: "Oops...",
                        text:
                            "Tivemos um problema ao excluir esse tabloide. Contate o suporte!",
                    });
                }
            }
        });
    }

    return (
        <Layout
            menuActive="posts"
            pageTitle="Lista de Posts"
            enableSearchBar={false}
            // searchTerm={searchText}
            // searchOnChange={(e) => setSearchText(e.target.value)}
            // handleSearch={handleSearchBar}
            // onSubmit={onSubmitReportPosts}
        >
            <div className="row">
                <div className="col-xs-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">
                                Lista de Tabloides (Conte Comigo)
                            </div>
                            <ul className="card-action">
                                <li>
                                    <Link
                                        to="/contecomigo/novo"
                                        className="btn btn-sm btn-success"
                                    >
                                        Novo <i className="fa fa-plus"></i>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div
                            className={`card-body ${loaded ? "" : "__loading"}`}
                        >
                            <div className="loader-container text-center">
                                <div className="icon">
                                    <div className="sk-wave">
                                        <div className="sk-rect sk-rect1"></div>
                                        <div className="sk-rect sk-rect2"></div>
                                        <div className="sk-rect sk-rect3"></div>
                                        <div className="sk-rect sk-rect4"></div>
                                        <div className="sk-rect sk-rect5"></div>
                                    </div>
                                </div>
                                <div className="title">Carregando</div>
                            </div>

                            <div className="row">
                                <div className="col-xs-12">
                                    <table className="table table-striped table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th>Data de Início</th>
                                                <th>Data de Término</th>
                                                <th>Qtd. Filiais</th>
                                                <th>Opções</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {branchesTabloidToShow.map(
                                                (branchTabloidItem, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                {moment(
                                                                    branchTabloidItem.startDate
                                                                )
                                                                    .add(
                                                                        1,
                                                                        "day"
                                                                    )
                                                                    .format(
                                                                        "DD/MM/YYYY"
                                                                    )}
                                                            </td>
                                                            <td>
                                                                {moment(
                                                                    branchTabloidItem.endDate
                                                                )
                                                                    .add(
                                                                        1,
                                                                        "day"
                                                                    )
                                                                    .format(
                                                                        "DD/MM/YYYY"
                                                                    )}
                                                            </td>
                                                            <td>
                                                                {
                                                                    branchTabloidItem
                                                                        .branches
                                                                        .length
                                                                }
                                                            </td>
                                                            <td>
                                                                <div className="col-xs-12">
                                                                    <div className="col-xs-12">
                                                                        <button
                                                                            className="btn btn-xs btn-danger"
                                                                            style={{
                                                                                width:
                                                                                    "100%",
                                                                            }}
                                                                            onClick={() =>
                                                                                handleDelete(
                                                                                    branchTabloidItem
                                                                                )
                                                                            }
                                                                        >
                                                                            Excluir
                                                                            <div className="icon fa fa-trash-o"></div>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default withRouter(BranchesTabloidList);
