const R01 = [
    "10002",
    "10045",
    "10070",
    "10186",
    "10187",
    "10190",
    "10191",
    "10192",
    "10196",
    "10250",
    "10271",
    "10285",
    "10320",
    "10321",
    "10322",
    "10325",
    "140002",
    "140003",
    "140004",
    "140005",
    "140006",
    "140007",
    "140008",
    "140009",
];
const R02 = [
    "10022",
    "10027",
    "10034",
    "10044",
    "10046",
    "10062",
    "10078",
    "10079",
    "10082",
    "10116",
    "10141",
    "10184",
    "10261",
    "10272",
    "10281",
    "10290",
    "10291",
    "10305",
    "10306",
    "10311",
    "10313",
    "10336",
    "10347",
    "10350",
    "10355",
    "10367",
    "10377",
];
const R03 = [
    "10003",
    "10004",
    "10024",
    "10033",
    "10036",
    "10040",
    "10048",
    "10071",
    "10073",
    "10076",
    "10098",
    "10128",
    "10154",
    "10172",
    "10234",
    "10270",
    "10282",
    "10284",
    "10289",
    "10299",
    "10300",
    "10309",
    "10323",
    "10338",
    "10342",
    "10351",
];
const R04 = [
    "10043",
    "10084",
    "10089",
    "10093",
    "10118",
    "10129",
    "10130",
    "10147",
    "10149",
    "10158",
    "10159",
    "10171",
    "10258",
    "10263",
    "10304",
    "10310",
    "10312",
    "10316",
    "10324",
    "10337",
    "10340",
    "10341",
];
const R05 = [
    "10005",
    "10006",
    "10007",
    "10008",
    "10016",
    "10020",
    "10030",
    "10032",
    "10039",
    "10041",
    "10047",
    "10049",
    "10051",
    "10081",
    "10101",
    "10112",
    "10132",
    "10134",
    "10197",
    "10247",
    "10255",
    "10262",
    "10287",
    "10301",
    "10327",
    "10345",
    "10376",
];
const R06 = [
    "10013",
    "10015",
    "10018",
    "10023",
    "10029",
    "10061",
    "10064",
    "10100",
    "10111",
    "10123",
    "10124",
    "10135",
    "10170",
    "10202",
    "10226",
    "10237",
    "10248",
    "10280",
    "10302",
    "10314",
    "10315",
    "10328",
    "10329",
    "10335",
    "10343",
    "10344",
    "10392",
];
const R07 = [
    "10055",
    "10075",
    "10102",
    "10103",
    "10127",
    "10139",
    "10140",
    "10157",
    "10200",
    "10218",
    "10219",
    "10239",
    "10240",
    "10259",
    "10278",
    "10297",
    "10317",
    "10326",
    "10332",
    "10358",
    "10374",
    "10387",
    "10388",
    "10389",
];
const R08 = [
    "10021",
    "10026",
    "10037",
    "10053",
    "10057",
    "10065",
    "10066",
    "10083",
    "10090",
    "10113",
    "10117",
    "10120",
    "10199",
    "10205",
    "10206",
    "10207",
    "10236",
    "10238",
    "10256",
    "10279",
    "10295",
    "10296",
    "10334",
    "10339",
    "10381",
    "10393",
];
const R09 = [
    "10072",
    "10080",
    "10114",
    "10136",
    "10137",
    "10145",
    "10153",
    "10160",
    "10165",
    "10166",
    "10167",
    "10169",
    "10178",
    "10179",
    "10193",
    "10194",
    "10195",
    "10211",
    "10215",
    "10233",
    "10235",
    "10292",
    "10318",
    "10353",
    "10359",
    "10368",
    "10382",
    "10383",
    "10386",
];
const R10 = [
    "10229",
    "10230",
    "10231",
    "10232",
    "10241",
    "10242",
    "10243",
    "10246",
    "10249",
    "10253",
    "10254",
    "10264",
    "10273",
    "10274",
    "10275",
    "10276",
    "10277",
    "10319",
    "10346",
    "10349",
    "10357",
    "10369",
    "10370",
    "10371",
    "10372",
    "10373",
    "10378",
    "10380",
];
const R11 = [
    "10009",
    "10010",
    "10011",
    "10012",
    "10014",
    "10017",
    "10019",
    "10052",
    "10056",
    "10087",
    "10088",
    "10095",
    "10107",
    "10119",
    "10126",
    "10131",
    "10133",
    "10142",
    "10144",
    "10183",
    "10185",
    "10214",
    "10224",
    "10286",
    "10288",
    "10293",
    "10394",
];
const R12 = [
    "240001",
    "240003",
    "240004",
    "240005",
    "240006",
    "240007",
    "240009",
    "240010",
    "240011",
    "240012",
    "240014",
    "240015",
    "240016",
    "240017",
    "240018",
    "240019",
    "240020",
];
const R13 = [
    "10035",
    "10054",
    "10059",
    "10060",
    "10074",
    "10086",
    "10091",
    "10092",
    "10094",
    "10097",
    "10104",
    "10110",
    "10148",
    "10151",
    "10181",
    "10209",
    "10220",
    "10221",
    "10257",
    "10260",
    "10348",
    "10384",
    "10385",
    "10390",
];

export { R01, R02, R03, R04, R05, R06, R07, R08, R09, R10, R11, R12, R13 };
