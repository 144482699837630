import React, { useState, useEffect } from "react";

import axios from 'axios';

import { withRouter, Link } from "react-router-dom";

import Layout from "../../components/Layout";

import api from "../../services/api";

import Swal from "sweetalert2";
import { components } from "react-select";

const PostList = (props) => {
    const [posts, setPosts] = useState([]);
    const [postsToShow, setPostsToShow] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [fileURL, setFileURL] = useState("");
    const [fileName, setFileName] = useState("");


    useEffect(function () {
        loadList();
    }, []);

    async function loadList() {
        setLoaded(false);

        const responseGetPosts = await api.get("/post");

        setPosts(responseGetPosts.data.data);
        setPostsToShow(responseGetPosts.data.data);
        setLoaded(true);
    }

    function handleSearchBar() {
        setLoaded(false);

        const originalPosts = posts;

        if (searchText === "") {
            setPostsToShow(originalPosts);
            setLoaded(true);
            return;
        }

        let filteredPosts = originalPosts.filter(
            (post) =>
                post.title.toLowerCase().indexOf(searchText.toLowerCase()) !==
                -1
        );

        setPostsToShow(filteredPosts);
        setLoaded(true);
    }

    async function handleDelete(postDelete) {
        Swal.fire({
            title: `Tem certeza que deseja excluir o post ${postDelete.title}?`,
            text: "Não será possível voltar atrás!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim, quero excluir!",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (!result.value) {
                Swal.fire(
                    "Cancelado!",
                    "Nenhum dado será excluído.",
                    "success"
                );
            } else {
                setLoaded(false);

                try {
                    const responseDeletePost = await api.delete(
                        `/post/${postDelete._id}`
                    );

                    if (responseDeletePost.status === 204) {
                        const newPosts = posts.filter(
                            (post) => post._id !== postDelete._id
                        );
                        setPosts(newPosts);
                        setPostsToShow(newPosts);
                        setLoaded(true);
                    } else {
                        throw "Error";
                    }
                } catch (error) {
                    console.log(error);
                    setLoaded(true);
                    Swal.fire({
                        type: "error",
                        title: "Oops...",
                        text:
                            "Tivemos um problema ao excluir esse post. Contate o suporte!",
                    });
                }
            }
        });
    }

    /* Ponto: 3 */
    function onSubmitReportPosts (event) {
        event.preventDefault()

        async function getReportPosts() {
            try {
                setLoaded(false);
                // Ponto 4
                const responsePostReport = await api.post(
                    `/post/report`,
                    {},
                    {
                        responseType: "blob"
                    }
                );

                if (
                    responsePostReport.data.error ||
                    responsePostReport.status === 500
                ) {
                    // setShowAlert(true);
                    // setAlertTitle("Problemas ao gerar o relatório.");
                    // setAlertText(responsePostReport.data.data.message);
                    // setAlertVariant("danger");
                    alert("Problemas ao gerar o relatório.")

                    setLoaded(true);
                } else {
                    // let fileName = responsePostReport.headers[
                    //     "content-disposition"
                    // ].split(";")[1];
                    // fileName = fileName.slice(
                    //     fileName.indexOf('"'),
                    //     fileName.length - 1
                    // );

                    // Ponto 5
                    setFileURL(
                        window.URL.createObjectURL(
                            new Blob([responsePostReport.data])
                        )
                    );
                    setFileName("relatorio.xlsx");

                    alert("Relatório gerado com sucesso!")
                    alert(`Clique no botão abaixo para fazer download!`)
                    // setShowAlert(true);
                    // setAlertTitle("Relatório gerado com sucesso!");
                    // setAlertText(`Clique no botão abaixo para fazer download!`);
                    // setAlertVariant("success");

                    setLoaded(true);
                }
            } catch (err) {
                console.log(err);
                // setShowAlert(true);
                // setAlertTitle("Problemas ao gerar o relatório.");
                // setAlertText("Por favor contate o suporte!");
                // setAlertVariant("danger");

                setLoaded(true);
            }
        }

        getReportPosts()  
    }

           
    return (
        <Layout
            menuActive="posts"
            pageTitle="Lista de Posts"
            enableSearchBar={true}
            searchTerm={searchText}
            searchOnChange={(e) => setSearchText(e.target.value)}
            handleSearch={handleSearchBar}
            onSubmit={onSubmitReportPosts}
        >
            <div className="row">
                <div className="col-xs-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">Lista de Posts</div>
                            <ul className="card-action">
                                <li>
                                    <Link
                                        to="/posts/novo"
                                        className="btn btn-sm btn-success"
                                    >
                                        Novo <i className="fa fa-plus"></i>
                                    </Link>
                                </li>
                                {/* Ponto: 1 */}
                                <li>
                                    <button
                                        className="btn btn-sm btn-info"
                                        onClick={onSubmitReportPosts}
                                    >
                                        Relatorio
                                    </button>
                                </li>
                                {/* Ponto: 2 */}
                                {fileURL !== "" ? (
                                    <li>
                                        <a
                                            href={fileURL}
                                            download={fileName}
                                        >
                                            <button
                                                className="btn btn-success btn-large"
                                                type="submit"
                                            >
                                                Download!
                                            </button>
                                        </a>
                                    </li>
                            ) : null}
                            </ul>
                        </div>
                        <div
                            className={`card-body ${loaded ? "" : "__loading"}`}
                        >
                            <div className="loader-container text-center">
                                <div className="icon">
                                    <div className="sk-wave">
                                        <div className="sk-rect sk-rect1"></div>
                                        <div className="sk-rect sk-rect2"></div>
                                        <div className="sk-rect sk-rect3"></div>
                                        <div className="sk-rect sk-rect4"></div>
                                        <div className="sk-rect sk-rect5"></div>
                                    </div>
                                </div>
                                <div className="title">Carregando</div>
                            </div>

                            <div className="row">
                                <div className="col-xs-12">
                                    <table className="table table-striped table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th>Título</th>
                                                <th>Visualizações</th>
                                                <th>Curtidas</th>
                                                <th>Comentarios</th>
                                                <th>Opções</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {postsToShow.map((post, likes, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{post.title}</td>
                                                        <td>{post.users.length}</td>
                                                        <td>{post.likes.length}</td>
                                                        <td>{post.comments.length}</td>
                                                        <td>
                                                            <div className="col-xs-12">
                                                                <div className="col-xs-12">
                                                                    <button
                                                                        className="btn btn-xs btn-danger"
                                                                        style={{
                                                                            width:
                                                                                "100%",
                                                                        }}
                                                                        onClick={() =>
                                                                            handleDelete(
                                                                                post
                                                                            )
                                                                        }
                                                                    >
                                                                        Excluir
                                                                        <div className="icon fa fa-trash-o"></div>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default withRouter(PostList);
