import React, { Component } from 'react';

import Layout from '../../components/Layout';

import api from '../../services/api';

import Swal from 'sweetalert2';

export default class ProductNew extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: true,
            cpf: "",
            error: false
        };

        this.handleCancelButton = this.handleCancelButton.bind(this);
        this.handleSubmitForm = this.handleSubmitForm.bind(this);
    }


    handleCancelButton(event) {
        Swal.fire({
            title: 'Tem certeza que deseja cancelar?',
            text: "Cancelar fará você perder todas as modificações feitas até o momento!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33', 
            confirmButtonText: 'Sim, quero cancelar!',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.value) {
                Swal.fire(
                    'Cancelado!',
                    'Você irá voltar para a tela de listagem.',
                    'success'
                );

                this.props.history.push("/cpfs-autorizado");
            }
        });
    }

    handleSubmitForm = async function(event) {
        event.preventDefault();

        this.setState(prevState => ({
            ...prevState,
            loaded: false
        }));

        const createdCPF = await api.post("/cpf-authorized", { cpf: this.state.cpf });


        if (createdCPF.status === 201) {
            Swal.fire({
                type: 'success',
                title: 'Salvo!',
                text: 'O novo CPF foi salvo com sucesso!',
            }).then(() => this.props.history.push("/cpfs-autorizado"));
        } else {
            Swal.fire({
                type: 'error',
                title: 'Oops...',
                text: 'Tivemos um problema ao salvar esse novo CPF. Contate o suporte!',
            });

            this.setState(prevState => ({
                ...prevState,
                loaded: true
            }));
        }
    }

    render() {
        return (
            <Layout
                menuActive="registers" 
                pageTitle="Novo Produto"
            >
                <div className="row">
                    <div className="col-xs-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="card-title">
                                    Novo CPF
                                </div>
                            </div>
                            <div className={`card-body ${this.state.loaded ? "" : "__loading"}`}>
                                <div className="loader-container text-center">
                                    <div className="icon">
                                        <div className="sk-wave">
                                            <div className="sk-rect sk-rect1"></div>
                                            <div className="sk-rect sk-rect2"></div>
                                            <div className="sk-rect sk-rect3"></div>
                                            <div className="sk-rect sk-rect4"></div>
                                            <div className="sk-rect sk-rect5"></div>
                                        </div>
                                    </div>
                                    <div className="title">Carregando</div>
                                </div>

                                <form className="form form-horizontal" onSubmit={this.handleSubmitForm}>
                                    <div className="section">
                                        <div className="section-title">Informações</div>
                                        <div className="section-body">
                                            <div className={"form-group "}>
                                                <label className="col-md-3 control-label">CPF (sem pontos e traço)</label>
                                                <div className="col-md-9">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder=""
                                                        value={this.state.cpf}
                                                        onChange={(e) => {
                                                            const { value } = e.target;

                                                            this.setState(prevState => ({
                                                                ...prevState,
                                                                cpf: value
                                                            }))
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-footer">
                                        <div className="form-group">
                                            <div className="col-md-9 col-md-offset-3">
                                                <button type="submit" className="btn btn-primary">Salvar</button>
                                                <button type="button" onClick={this.handleCancelButton} className="btn btn-default">Cancelar</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </Layout>
        );
    }
}
