import React, { useState, useRef, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";

import Layout from "../../components/Layout";

import { apiCRMv3 } from "../../services/api";

import Swal from "sweetalert2";

const FormNew = (props) => {
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState({});
    const [name, setName] = useState("");
    const [questions, setQuestions] = useState([]);
    const [questionTitle, setQuestionTitle] = useState("");
    const [questionType, setQuestionType] = useState("");
    const [questionAnswers, setQuestionAnswers] = useState([]);
    const [questionAnswerTitle, setQuestionAnswerTitle] = useState("");
    const inputNewAnswer = useRef(null);
    const inputTitleAnswer = useRef(null);

    useEffect(() => {
        setLoaded(true);
    }, []);

    function handleCancelButton(event) {
        if (name !== "") {
            Swal.fire({
                title: "Tem certeza que deseja cancelar?",
                text:
                    "Cancelar fará você perder todas as modificações feitas até o momento!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sim, quero cancelar!",
                cancelButtonText: "Cancelar",
            }).then((result) => {
                if (result.value) {
                    Swal.fire(
                        "Cancelado!",
                        "Você irá voltar para a tela de listagem.",
                        "success"
                    );

                    props.history.push("/formularios");
                }
            });
        } else {
            props.history.push("/formularios");
        }
    }

    async function handleSubmitForm(event) {
        event.preventDefault();

        try {
            if (!name || name === "") {
                alert("Nome do formulário não preenchido.");
                return;
            }

            if (questions.length <= 0) {
                alert("Você precisa adicionar ao menos 1 pergunta.");
                return;
            }

            setLoaded(false);

            const createdForm = await apiCRMv3.post("/forms", {
                name: name,
                questions: questions,
            });

            setLoaded(true);
            if (createdForm.status === 201) {
                setLoaded(true);

                Swal.fire({
                    type: "success",
                    title: "Salvo!",
                    text: "O formulário foi salvo com sucesso!",
                }).then(() => props.history.push("/formularios"));
            } else {
                Swal.fire({
                    type: "error",
                    title: "Oops...",
                    text:
                        "Tivemos um problema ao salvar esse novo formulário. Contate o suporte!",
                });

                setLoaded(true);
            }
        } catch (e) {
            Swal.fire({
                type: "error",
                title: "Oops...",
                text:
                    "Tivemos um problema ao salvar esse novo formulário. Contate o suporte!",
            });

            setLoaded(true);
        }
    }

    const showAnswers = (answers) => {
        return (
            <ul>
                {answers.map((answer, index) => (
                    <li key={index}>{answer.title}</li>
                ))}
            </ul>
        );
    };

    return (
        <Layout menuActive="forms" pageTitle="Novo Formulário de Pesquisa">
            <div className="row">
                <div className="col-xs-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">
                                Nova Formulário de Pesquisa
                            </div>
                        </div>
                        <div
                            className={`card-body ${loaded ? "" : "__loading"}`}
                        >
                            <div className="loader-container text-center">
                                <div className="icon">
                                    <div className="sk-wave">
                                        <div className="sk-rect sk-rect1"></div>
                                        <div className="sk-rect sk-rect2"></div>
                                        <div className="sk-rect sk-rect3"></div>
                                        <div className="sk-rect sk-rect4"></div>
                                        <div className="sk-rect sk-rect5"></div>
                                    </div>
                                </div>
                                <div className="title">Carregando</div>
                            </div>

                            <form
                                className="form form-horizontal"
                                onSubmit={handleSubmitForm}
                            >
                                <div className="section">
                                    <div className="section-title">
                                        Informações Básicas
                                    </div>
                                    <div className="section-body">
                                        <div
                                            className={
                                                "form-group " +
                                                (error.name ? "has-error" : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Nome
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder=""
                                                    value={name}
                                                    onChange={(event) => {
                                                        setName(
                                                            event.target.value
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section">
                                    <div className="section-title">
                                        Nova Pergunta
                                    </div>
                                    <div className="section-body">
                                        <div
                                            className={
                                                "form-group " +
                                                (error.title ? "has-error" : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Título da Pergunta
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder=""
                                                    ref={inputTitleAnswer}
                                                    value={questionTitle}
                                                    onChange={(event) => {
                                                        setQuestionTitle(
                                                            event.target.value
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                "form-group " +
                                                (error.type ? "has-error" : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Tipo da Pergunta
                                            </label>
                                            <div className="col-md-9">
                                                <label className="radio-inline">
                                                    <input
                                                        type="radio"
                                                        onChange={(event) => {
                                                            console.log(event);
                                                            setQuestionType(
                                                                "OBJECTIVE"
                                                            );
                                                        }}
                                                        checked={
                                                            questionType ===
                                                            "OBJECTIVE"
                                                        }
                                                    />
                                                    Objetiva
                                                </label>
                                                <label className="radio-inline">
                                                    <input
                                                        type="radio"
                                                        onChange={(event) => {
                                                            setQuestionType(
                                                                "DISCURSIVE"
                                                            );
                                                        }}
                                                        checked={
                                                            questionType ===
                                                            "DISCURSIVE"
                                                        }
                                                    />
                                                    Discursiva
                                                </label>
                                            </div>
                                        </div>
                                        {questionType === "OBJECTIVE" ? (
                                            <div
                                                style={{
                                                    marginTop: 20,
                                                }}
                                            >
                                                <div
                                                    className={
                                                        "form-group " +
                                                        (error.title
                                                            ? "has-error"
                                                            : "")
                                                    }
                                                >
                                                    <label className="col-md-3 control-label">
                                                        Nova opção (
                                                        <b>
                                                            use tab para
                                                            adicionar
                                                        </b>
                                                        )
                                                    </label>
                                                    <div className="col-md-9">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder=""
                                                            ref={inputNewAnswer}
                                                            value={
                                                                questionAnswerTitle
                                                            }
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                setQuestionAnswerTitle(
                                                                    event.target
                                                                        .value
                                                                );
                                                            }}
                                                            onKeyDown={(
                                                                event
                                                            ) => {
                                                                if (
                                                                    event.keyCode ===
                                                                    9
                                                                ) {
                                                                    setQuestionAnswers(
                                                                        [
                                                                            ...questionAnswers,
                                                                            {
                                                                                title: questionAnswerTitle,
                                                                            },
                                                                        ]
                                                                    );
                                                                    setQuestionAnswerTitle(
                                                                        ""
                                                                    );
                                                                    setTimeout(
                                                                        () => {
                                                                            inputNewAnswer.current.focus();
                                                                        },
                                                                        1000
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className={
                                                        "form-group " +
                                                        (error.title
                                                            ? "has-error"
                                                            : "")
                                                    }
                                                >
                                                    <label className="col-md-3 control-label">
                                                        Respostas (
                                                        <b>
                                                            duplo click na
                                                            resposta para
                                                            excluir
                                                        </b>
                                                        )
                                                    </label>
                                                    <div className="col-md-9">
                                                        <ul>
                                                            {questionAnswers.map(
                                                                (
                                                                    questionAnswer,
                                                                    index
                                                                ) => (
                                                                    <li
                                                                        key={
                                                                            index
                                                                        }
                                                                        onDoubleClick={() => {
                                                                            const oldAnswers = [
                                                                                ...questionAnswers,
                                                                            ];
                                                                            oldAnswers.splice(
                                                                                index,
                                                                                1
                                                                            );
                                                                            setQuestionAnswers(
                                                                                oldAnswers
                                                                            );
                                                                        }}
                                                                    >
                                                                        {
                                                                            questionAnswer.title
                                                                        }
                                                                    </li>
                                                                )
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null}
                                        <div
                                            className={
                                                "form-group " +
                                                (error.type ? "has-error" : "")
                                            }
                                            style={{
                                                marginTop: 40,
                                            }}
                                        >
                                            <label className="col-md-3 control-label"></label>
                                            <div className="col-md-9">
                                                <button
                                                    type="button"
                                                    className="btn btn-success"
                                                    onClick={() => {
                                                        if (
                                                            !questionTitle ||
                                                            questionTitle === ""
                                                        ) {
                                                            alert(
                                                                "Título não preenchido."
                                                            );
                                                            return;
                                                        }
                                                        if (
                                                            !questionType ||
                                                            questionTitle === ""
                                                        ) {
                                                            alert(
                                                                "Tipo não definido."
                                                            );
                                                            return;
                                                        }
                                                        if (
                                                            questionType ===
                                                                "OBJECTIVE" &&
                                                            questionAnswers.length <=
                                                                0
                                                        ) {
                                                            alert(
                                                                "Nenhuma resposta cadastrada."
                                                            );
                                                            return;
                                                        }

                                                        let questionToSave = {
                                                            title: questionTitle,
                                                            type: questionType,
                                                        };
                                                        if (
                                                            questionType ===
                                                            "OBJECTIVE"
                                                        ) {
                                                            questionToSave[
                                                                "answers"
                                                            ] = questionAnswers;
                                                        }
                                                        setQuestions([
                                                            ...questions,
                                                            questionToSave,
                                                        ]);
                                                        setQuestionAnswerTitle(
                                                            ""
                                                        );
                                                        setQuestionTitle("");
                                                        setQuestionType(
                                                            "OBJECTIVE"
                                                        );
                                                        setQuestionAnswers([]);
                                                        inputTitleAnswer.current.focus();
                                                    }}
                                                >
                                                    Adicionar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section">
                                    <div className="section-title">
                                        Perguntas
                                    </div>
                                    <div className="section-body">
                                        {questions.map((question, index) => (
                                            <>
                                                <div
                                                    className="col-md-12"
                                                    style={{
                                                        marginBottom: 20,
                                                    }}
                                                >
                                                    <div className="card card-mini">
                                                        <div className="card-header">
                                                            {index + 1}.{" "}
                                                            {question.title}
                                                        </div>
                                                        <div className="card-body">
                                                            {question.type ===
                                                            "OBJECTIVE" ? (
                                                                showAnswers(
                                                                    question.answers
                                                                )
                                                            ) : (
                                                                <p>
                                                                    Essa
                                                                    pergunta é
                                                                    do tipo
                                                                    discursiva.
                                                                </p>
                                                            )}
                                                            <button
                                                                type="button"
                                                                className="btn btn-danger"
                                                                onClick={() => {
                                                                    const oldQuestions = [
                                                                        ...questions,
                                                                    ];
                                                                    oldQuestions.splice(
                                                                        index,
                                                                        1
                                                                    );
                                                                    setQuestions(
                                                                        oldQuestions
                                                                    );
                                                                }}
                                                            >
                                                                Excluir
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ))}
                                    </div>
                                </div>
                                <div className="form-footer">
                                    <div className="form-group">
                                        <div className="col-md-9 col-md-offset-3">
                                            <button
                                                type="submit"
                                                className="btn btn-primary"
                                            >
                                                Salvar
                                            </button>
                                            <button
                                                type="button"
                                                onClick={handleCancelButton}
                                                className="btn btn-default"
                                            >
                                                Cancelar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default withRouter(FormNew);
