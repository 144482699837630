import React, { useState, useEffect } from "react";

import { withRouter, Link, useParams } from "react-router-dom";

import Layout from "../../components/Layout";

import { apiCRMv3 } from "../../services/api";
import { useFormResults } from "../../hooks/form-results";

import Swal from "sweetalert2";

const FormResultsByClient = (props) => {
    const { formQuestionAnswerId, branchId, isFormQuestion } = useParams();
    const { month, year, changeMonth, changeYear } = useFormResults();
    const [results, setResults] = useState([]);
    const [branch, setBranch] = useState({});
    const [loaded, setLoaded] = useState(false);

    useEffect(
        function () {
            async function loadList() {
                setLoaded(false);

                const responseGetResults = await apiCRMv3.get(
                    `/forms/clients-by-branch/${formQuestionAnswerId}/${branchId}${
                        isFormQuestion === "true"
                            ? `?isFormQuestion=true&month=${month}&year=${year}`
                            : `?month=${month}&year=${year}`
                    }`
                );

                setResults(responseGetResults.data.data.results);
                setBranch(responseGetResults.data.data.branch);
                setLoaded(true);
            }

            loadList();
        },
        [branchId, formQuestionAnswerId, isFormQuestion, month, year]
    );

    const showAnswers = (answers) => {
        return (
            <ul>
                {answers.map((answer, index) => (
                    <li key={index}>{answer.title}</li>
                ))}
            </ul>
        );
    };

    return (
        <Layout
            menuActive="forms"
            pageTitle="Resultados por Filial"
            enableSearchBar={false}
        >
            <div className="row">
                <div className="col-xs-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">
                                Resultados por Filial: <b>{branch.longCode}</b>
                            </div>
                            {/* <ul className="card-action">
                                <li>
                                    <Link
                                        to="/formularios/novo"
                                        className="btn btn-sm btn-success"
                                    >
                                        Novo <i className="fa fa-plus"></i>
                                    </Link>
                                </li>
                            </ul> */}
                        </div>
                        <div
                            className={`card-body ${loaded ? "" : "__loading"}`}
                        >
                            <div className="loader-container text-center">
                                <div className="icon">
                                    <div className="sk-wave">
                                        <div className="sk-rect sk-rect1"></div>
                                        <div className="sk-rect sk-rect2"></div>
                                        <div className="sk-rect sk-rect3"></div>
                                        <div className="sk-rect sk-rect4"></div>
                                        <div className="sk-rect sk-rect5"></div>
                                    </div>
                                </div>
                                <div className="title">Carregando</div>
                            </div>

                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="section">
                                        <div className="section-title">
                                            Clientes
                                        </div>
                                        <div className="section-body">
                                            <div className="row">
                                                <div className="col-xs-12 col-md-1">
                                                    <select
                                                        name="month"
                                                        id="month"
                                                        className="form-control"
                                                        onChange={(event) => {
                                                            const { value } =
                                                                event.target;

                                                            changeMonth(value);
                                                        }}
                                                    >
                                                        <option
                                                            value="1"
                                                            selected={
                                                                month === 1
                                                            }
                                                        >
                                                            Janeiro
                                                        </option>
                                                        <option
                                                            value="2"
                                                            selected={
                                                                month === 2
                                                            }
                                                        >
                                                            Fevereiro
                                                        </option>
                                                        <option
                                                            value="3"
                                                            selected={
                                                                month === 3
                                                            }
                                                        >
                                                            Março
                                                        </option>
                                                        <option
                                                            value="4"
                                                            selected={
                                                                month === 4
                                                            }
                                                        >
                                                            Abril
                                                        </option>
                                                        <option
                                                            value="5"
                                                            selected={
                                                                month === 5
                                                            }
                                                        >
                                                            Maio
                                                        </option>
                                                        <option
                                                            value="6"
                                                            selected={
                                                                month === 6
                                                            }
                                                        >
                                                            Junho
                                                        </option>
                                                        <option
                                                            value="7"
                                                            selected={
                                                                month === 7
                                                            }
                                                        >
                                                            Julho
                                                        </option>
                                                        <option
                                                            value="8"
                                                            selected={
                                                                month === 8
                                                            }
                                                        >
                                                            Agosto
                                                        </option>
                                                        <option
                                                            value="9"
                                                            selected={
                                                                month === 9
                                                            }
                                                        >
                                                            Setembro
                                                        </option>
                                                        <option
                                                            value="10"
                                                            selected={
                                                                month === 10
                                                            }
                                                        >
                                                            Outubro
                                                        </option>
                                                        <option
                                                            value="11"
                                                            selected={
                                                                month === 11
                                                            }
                                                        >
                                                            Novembro
                                                        </option>
                                                        <option
                                                            value="12"
                                                            selected={
                                                                month === 12
                                                            }
                                                        >
                                                            Dezembro
                                                        </option>
                                                    </select>
                                                </div>
                                                <div className="col-xs-12 col-md-1">
                                                    <select
                                                        name="year"
                                                        id="year"
                                                        className="form-control"
                                                        onChange={(event) => {
                                                            const { value } =
                                                                event.target;

                                                            changeYear(value);
                                                        }}
                                                    >
                                                        <option
                                                            value="2021"
                                                            selected={
                                                                year === 2021
                                                            }
                                                        >
                                                            2021
                                                        </option>
                                                        <option
                                                            value="2022"
                                                            selected={
                                                                year === 2022
                                                            }
                                                        >
                                                            2022
                                                        </option>
                                                        <option
                                                            value="2023"
                                                            selected={
                                                                year === 2023
                                                            }
                                                        >
                                                            2023
                                                        </option>
                                                        <option
                                                            value="2024"
                                                            selected={
                                                                year === 2024
                                                            }
                                                        >
                                                            2024
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-xs-12">
                                                    <table className="table table-striped table-bordered table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    Nome Cliente
                                                                </th>
                                                                <th>
                                                                    CPF Cliente
                                                                </th>
                                                                <th>
                                                                    Telefone
                                                                    Cliente
                                                                </th>
                                                                <th>
                                                                    Perfil
                                                                    Cliente
                                                                </th>
                                                                <th>
                                                                    Limite
                                                                    Cliente
                                                                </th>
                                                                <th>
                                                                    Pergunta
                                                                </th>
                                                                <th>
                                                                    Resposta
                                                                </th>
                                                                {/* {isFormQuestion ===
                                                                "true" ? (
                                                                    <th>
                                                                        Resposta
                                                                    </th>
                                                                ) : null} */}
                                                                <th>
                                                                    Vendedor
                                                                </th>
                                                                <th>
                                                                    CPF Vendedor
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {results.map(
                                                                (
                                                                    result,
                                                                    index
                                                                ) => {
                                                                    return (
                                                                        <tr
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            <td>
                                                                                {
                                                                                    result.client_name
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    result.client_cpf
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {`(${result.client_dddPhone}) ${result.client_phone}`}
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    result.client_profile
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {Number(
                                                                                    result.client_availableLimit
                                                                                ).toLocaleString(
                                                                                    "pt-br",
                                                                                    {
                                                                                        style: "currency",
                                                                                        currency:
                                                                                            "BRL",
                                                                                    }
                                                                                )}
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    result.formQuestion_title
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    result.answer
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    result.user_name
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    result.user_cpf
                                                                                }
                                                                            </td>
                                                                            {/* {isFormQuestion ===
                                                                            "true" ? (
                                                                                <td
                                                                                    style={{
                                                                                        width: "50%",
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        result.sa_discursiveAnswer
                                                                                    }
                                                                                </td>
                                                                            ) : null} */}
                                                                        </tr>
                                                                    );
                                                                }
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default withRouter(FormResultsByClient);
