import React, { useState, useRef, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";

import Layout from "../../components/Layout";

import { apiCRMv3 } from "../../services/api";

import Swal from "sweetalert2";

const DirectMailNew = (props) => {
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState({});
    const [name, setName] = useState("");
    const [type, setType] = useState("AFTER_SALES");
    const [useLink, setUseLink] = useState(false);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [positionX, setPositionX] = useState(0);
    const [positionY, setPositionY] = useState(0);
    const fileRef = useRef(null);

    useEffect(() => {
        setLoaded(true);
    }, []);

    function handleCancelButton(event) {
        if (name !== "") {
            Swal.fire({
                title: "Tem certeza que deseja cancelar?",
                text:
                    "Cancelar fará você perder todas as modificações feitas até o momento!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sim, quero cancelar!",
                cancelButtonText: "Cancelar",
            }).then((result) => {
                if (result.value) {
                    Swal.fire(
                        "Cancelado!",
                        "Você irá voltar para a tela de listagem.",
                        "success"
                    );

                    props.history.push("/malas-diretas");
                }
            });
        } else {
            props.history.push("/malas-diretas");
        }
    }

    async function handleSubmitForm(event) {
        event.preventDefault();

        setLoaded(false);

        try {
            const formData = new FormData();
            formData.append("name", name);
            formData.append("type", type);
            formData.append("useLink", useLink);
            formData.append("width", width);
            formData.append("height", height);
            formData.append("positionX", positionX);
            formData.append("positionY", positionY);
            formData.append("file", fileRef.current.files[0]);

            const createdDirectMail = await apiCRMv3.post(
                "/direct-mails",
                formData
            );

            setLoaded(true);
            if (createdDirectMail.status === 201) {
                setLoaded(true);

                Swal.fire({
                    type: "success",
                    title: "Salvo!",
                    text: "A mala direta foi salva com sucesso!",
                }).then(() => props.history.push("/malas-diretas"));
            } else {
                Swal.fire({
                    type: "error",
                    title: "Oops...",
                    text:
                        "Tivemos um problema ao salvar essa nova mala direta. Contate o suporte!",
                });

                setLoaded(true);
            }
        } catch (e) {
            Swal.fire({
                type: "error",
                title: "Oops...",
                text:
                    "Tivemos um problema ao salvar essa nova mala direta. Contate o suporte!",
            });

            setLoaded(true);
        }
    }

    return (
        <Layout menuActive="direct-mail" pageTitle="Nova Mala Direta">
            <div className="row">
                <div className="col-xs-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">Nova Mala Direta</div>
                        </div>
                        <div
                            className={`card-body ${loaded ? "" : "__loading"}`}
                        >
                            <div className="loader-container text-center">
                                <div className="icon">
                                    <div className="sk-wave">
                                        <div className="sk-rect sk-rect1"></div>
                                        <div className="sk-rect sk-rect2"></div>
                                        <div className="sk-rect sk-rect3"></div>
                                        <div className="sk-rect sk-rect4"></div>
                                        <div className="sk-rect sk-rect5"></div>
                                    </div>
                                </div>
                                <div className="title">Carregando</div>
                            </div>

                            <form
                                className="form form-horizontal"
                                onSubmit={handleSubmitForm}
                            >
                                <div className="section">
                                    <div className="section-title">
                                        Informações Básicas
                                    </div>
                                    <div className="section-body">
                                        <div
                                            className={
                                                "form-group " +
                                                (error.name ? "has-error" : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Nome
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder=""
                                                    value={name}
                                                    onChange={(event) => {
                                                        setName(
                                                            event.target.value
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                "form-group " +
                                                (error.name ? "has-error" : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Tipo
                                            </label>
                                            <div className="col-md-9">
                                                <label className="radio-inline">
                                                    <input
                                                        type="radio"
                                                        onChange={(event) => {
                                                            console.log(event);
                                                            setType(
                                                                "AFTER_SALES"
                                                            );
                                                        }}
                                                        checked={
                                                            type ===
                                                            "AFTER_SALES"
                                                        }
                                                    />
                                                    Pós-venda
                                                </label>
                                                {/* <label className="radio-inline">
                                                    <input
                                                        type="radio"
                                                        onChange={(event) => {
                                                            setType("DEFAULT");
                                                        }}
                                                        checked={
                                                            type === "DEFAULT"
                                                        }
                                                    />
                                                    Option 2
                                                </label> */}
                                                {/* <label className="radio-inline"><input type="radio" name="optradio"/>Option 3</label> */}
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                "form-group " +
                                                (error.useLink
                                                    ? "has-error"
                                                    : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Pode usar link?
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="checkbox"
                                                    className="form-control"
                                                    checked={useLink}
                                                    onChange={(event) => {
                                                        setUseLink(!useLink);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                "form-group " +
                                                (error.width ? "has-error" : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Largura da área de click
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    disabled={!useLink}
                                                    type="number"
                                                    className="form-control"
                                                    value={width}
                                                    onChange={(event) => {
                                                        setWidth(
                                                            event.target.value
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                "form-group " +
                                                (error.height
                                                    ? "has-error"
                                                    : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Altura da área de click
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    disabled={!useLink}
                                                    type="number"
                                                    className="form-control"
                                                    value={height}
                                                    onChange={(event) => {
                                                        setHeight(
                                                            event.target.value
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                "form-group " +
                                                (error.positionX
                                                    ? "has-error"
                                                    : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Posição X da área de click
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    disabled={!useLink}
                                                    type="number"
                                                    className="form-control"
                                                    value={positionX}
                                                    onChange={(event) => {
                                                        setPositionX(
                                                            event.target.value
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                "form-group " +
                                                (error.positionY
                                                    ? "has-error"
                                                    : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Posição Y da área de click
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    disabled={!useLink}
                                                    type="number"
                                                    className="form-control"
                                                    value={positionY}
                                                    onChange={(event) => {
                                                        setPositionY(
                                                            event.target.value
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                "form-group " +
                                                (error.file ? "has-error" : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Arquivo
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    placeholder=""
                                                    ref={fileRef}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-footer">
                                    <div className="form-group">
                                        <div className="col-md-9 col-md-offset-3">
                                            <button
                                                type="submit"
                                                className="btn btn-primary"
                                            >
                                                Salvar
                                            </button>
                                            <button
                                                type="button"
                                                onClick={handleCancelButton}
                                                className="btn btn-default"
                                            >
                                                Cancelar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default withRouter(DirectMailNew);
