import React, { useState, useEffect, useRef } from "react";

import * as moment from "moment-timezone";

import { Link, withRouter } from "react-router-dom";

import Layout from "../../components/Layout";

import { apiCRMv3 } from "../../services/api";
import api from "../../services/api";

import Swal from "sweetalert2";

const ReportSentAndSale = (props) => {
    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState("");
    const [alertText, setAlertText] = useState("");
    const [alertVariant, setAlertVariant] = useState("");
    const [fileURLCards, setFileURLCards] = useState("");
    const [fileNameCards, setFileNameCards] = useState("");
    const [loaded, setLoaded] = useState(true);

    const dateBeginCard = useRef(null);
    const dateEndCard = useRef(null);
    const emailToSend = useRef(null);

    useEffect(function () {
        var date = new Date();

        var formatedDate = `${date.getFullYear()}-${
            date.getMonth() + 1 < 10
                ? "0" + (date.getMonth() + 1)
                : date.getMonth() + 1
        }-${date.getDate()}`;

        dateBeginCard.current.value = formatedDate;
        dateEndCard.current.value = formatedDate;
    }, []);

    async function getReportCards() {
        try {
            setLoaded(false);
            const responseGetReportCardByDate = await apiCRMv3.post(
                `/reports/cards-vs-sales`,
                {
                    dateBegin: dateBeginCard.current.value,
                    dateEnd: dateEndCard.current.value,
                    emailToSend: emailToSend.current.value,
                }
            );

            if (
                responseGetReportCardByDate.data.error ||
                responseGetReportCardByDate.status === 500
            ) {
                Swal.fire(
                    "Erro!",
                    "Problemas ao gerar o relatório. Por favor contate o suporte!",
                    "error"
                );

                setLoaded(true);
            } else {
                Swal.fire(
                    "Gerando!",
                    "O relatório de envios está sendo gerado. Em alguns minutos chegará em seu e-mail!",
                    "success"
                );

                setLoaded(true);
            }
        } catch (err) {
            console.log(err);
            Swal.fire(
                "Erro!",
                "Problemas ao gerar o relatório. Por favor contate o suporte!",
                "error"
            );

            setLoaded(true);
        }
    }

    return (
        <Layout
            menuActive=""
            pageTitle="Relatório de Envios e Vendas Cartão Express"
        >
            <div className="row">
                <div className="col-xs-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">
                                <small>
                                    <Link
                                        className="label label-default"
                                        to="/dashboard"
                                    >
                                        Dashboard
                                    </Link>
                                </small>{" "}
                                <span className="label label-success">
                                    Relatório de Envios e Vendas Cartão Express
                                </span>
                            </div>
                        </div>
                        <div
                            className={`card-body table-responsive ${
                                loaded ? "" : "__loading"
                            }`}
                        >
                            <div className="row">
                                <form>
                                    <div className="col-xs-3">
                                        <label htmlFor="dtBegin-filter">
                                            Data Inicial
                                        </label>
                                        <input
                                            className="form-control"
                                            type="date"
                                            id="dtBegin-filter"
                                            ref={dateBeginCard}
                                        />
                                    </div>

                                    <div className="col-xs-3">
                                        <label htmlFor="dtEnd-filter">
                                            Data Final
                                        </label>
                                        <input
                                            className="form-control"
                                            type="date"
                                            id="dtEnd-filter"
                                            ref={dateEndCard}
                                        />
                                    </div>

                                    <div className="col-xs-3">
                                        <label htmlFor="emailToSend">
                                            E-mail Para Envio
                                        </label>
                                        <input
                                            className="form-control"
                                            type="email"
                                            placeholder=""
                                            id="emailToSend"
                                            ref={emailToSend}
                                        />
                                        {/* <p>Telefone para envio deve conter apenas números, sem espaços.</p> */}
                                    </div>

                                    <div
                                        className="col-xs-3"
                                        style={{
                                            position: "relative",
                                            top: "50%",
                                            transform: "translateY(50%)",
                                        }}
                                    >
                                        <button
                                            className="btn btn-primary btn-large"
                                            type="button"
                                            onClick={(e) => {
                                                getReportCards();
                                            }}
                                        >
                                            Gerar relatório!
                                        </button>
                                    </div>
                                    <div
                                        className="col-xs-3"
                                        style={{
                                            position: "relative",
                                            top: "50%",
                                            transform: "translateY(50%)",
                                        }}
                                    >
                                    </div>
                                </form>
                            </div>

                            {fileURLCards !== "" ? (
                                <a href={fileURLCards} download={fileNameCards}>
                                    <button
                                        className="btn btn-success btn-large"
                                        type="submit"
                                    >
                                        Download!
                                    </button>
                                </a>
                            ) : null}

                            <br />

                            <div className="loader-container text-center">
                                <div className="icon">
                                    <div className="sk-wave">
                                        <div className="sk-rect sk-rect1"></div>
                                        <div className="sk-rect sk-rect2"></div>
                                        <div className="sk-rect sk-rect3"></div>
                                        <div className="sk-rect sk-rect4"></div>
                                        <div className="sk-rect sk-rect5"></div>
                                    </div>
                                </div>
                                <div className="title">Carregando</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default withRouter(ReportSentAndSale);
