import React, { useState, useRef, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";

import Layout from "../../components/Layout";

import api from "../../services/api";

import Select from "react-select";

import Swal from "sweetalert2";
import { Component } from "react";

const PostNew = (props) => {
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState({});
    const [title, setTitle] = useState("");
    const [type, setType] = useState("photo");
    const [dtInit, setDtInit] = useState(new Date());
    const [dtEnd, setDtEnd] = useState(new Date());
    const [photo, setPhoto] = useState(null);
    const [link, setLink] = useState(null);
    const [content, setContent] = useState(null);
    const [branches, setBranches] = useState([]);
    const [branchesSelected, setBranchesSelected] = useState([]);

    useEffect(() => {
        async function loadBranches() {
            const responseGetBranches = await api.get("/branch");
            setBranches(
                responseGetBranches.data.map((branch) => ({
                    value: branch._id,
                    label: `${branch.cidade_estado} - ${branch.email}`,
                }))
            );
            setLoaded(true);
        }

        loadBranches();
    }, []);

    function handleCancelButton(event) {
        props.history.push("/posts");
    }

    async function handleSubmitForm(event) {
        event.preventDefault();

        setLoaded(false);

        console.log(branchesSelected);

        try {
            let responseCreatePost;
            if (type === "photo") {
                const formData = new FormData();
                formData.append("title", title);
                formData.append("type", "photo");
                if (branchesSelected.length > 0) {
                    formData.append("allBranches", false);
                } else {
                    formData.append("allBranches", true);
                }
                formData.append(
                    "branches",
                    branchesSelected.length > 0
                        ? branchesSelected.map((branch) => branch.value)
                        : []
                );
                formData.append("photo", photo);
                if (content !== "") {
                    formData.append("content", content);
                }
                formData.append("dtInit", dtInit);
                formData.append("dtEnd", dtEnd);
                responseCreatePost = await api.post("/post", formData);
            } else {
                const dataToSend = {
                    title,
                    allBranches: branchesSelected.length > 0 ? false : true,
                    branches:
                        branchesSelected.length > 0
                            ? branchesSelected.map((branch) => branch.value)
                            : undefined,
                    link: link ? link : undefined,
                    content: content ? content : undefined,
                    type,
                    dtInit,
                    dtEnd,
                };
                responseCreatePost = await api.post("/post", dataToSend);
            }

            setLoaded(true);
            if (responseCreatePost.status === 201) {
                setLoaded(true);

                Swal.fire({
                    type: "success",
                    title: "Salvo!",
                    text: "O post foi salvo com sucesso!",
                }).then(() => props.history.push("/posts"));
            } else {
                Swal.fire({
                    type: "error",
                    title: "Oops...",
                    text: "Tivemos um problema ao salvar esse novo post. Contate o suporte!",
                });

                setLoaded(true);
            }
        } catch (e) {
            Swal.fire({
                type: "error",
                title: "Oops...",
                text: "Tivemos um problema ao salvar esse novo post. Contate o suporte!",
            });

            setLoaded(true);
        }
    }

    return (
        <Layout menuActive="posts" pageTitle="Novo Post">
            <div className="row">
                <div className="col-xs-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">Novo Post</div>
                        </div>
                        <div
                            className={`card-body ${loaded ? "" : "__loading"}`}
                        >
                            <div className="loader-container text-center">
                                <div className="icon">
                                    <div className="sk-wave">
                                        <div className="sk-rect sk-rect1"></div>
                                        <div className="sk-rect sk-rect2"></div>
                                        <div className="sk-rect sk-rect3"></div>
                                        <div className="sk-rect sk-rect4"></div>
                                        <div className="sk-rect sk-rect5"></div>
                                    </div>
                                </div>
                                <div className="title">Carregando</div>
                            </div>

                            <form
                                className="form form-horizontal"
                                onSubmit={handleSubmitForm}
                            >
                                <div className="section">
                                    <div className="section-title">
                                        Informações Básicas
                                    </div>
                                    <div className="section-body">
                                        <div className="form-group">
                                            <label className="col-md-3 control-label">
                                                Filial
                                            </label>
                                            <div className="col-md-9">
                                                <Select
                                                    isMulti
                                                    placeholder="Selecione as filiais"
                                                    value={branchesSelected}
                                                    options={branches}
                                                    onChange={(
                                                        selectedOptions
                                                    ) => {
                                                        setBranchesSelected(
                                                            selectedOptions
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                "form-group " +
                                                (error.name ? "has-error" : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Título
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder=""
                                                    value={title}
                                                    onChange={(e) => {
                                                        const { value } =
                                                            e.target;

                                                        setTitle(value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                "form-group " +
                                                (error.type ? "has-error" : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Tipo
                                            </label>
                                            <div className="col-md-9">
                                                <select
                                                    className="form-control"
                                                    name="type"
                                                    id="type"
                                                    onChange={(e) => {
                                                        const { value } =
                                                            e.target;

                                                        setType(value);
                                                    }}
                                                >
                                                    <option value="photo">
                                                        Foto
                                                    </option>
                                                    {/* <option value="content">
                                                        Texto
                                                    </option> */}
                                                    <option value="link">
                                                        Link
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        {type === "link" ? (
                                            <div
                                                className={
                                                    "form-group " +
                                                    (error.link
                                                        ? "has-error"
                                                        : "")
                                                }
                                                style={{
                                                    marginTop: 20,
                                                }}
                                            >
                                                <label className="col-md-3 control-label">
                                                    Link
                                                </label>
                                                <div className="col-md-9">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder=""
                                                        value={link}
                                                        onChange={(e) => {
                                                            const { value } =
                                                                e.target;

                                                            setLink(value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        ) : null}
                                        {type === "photo" ? (
                                            <div
                                                className={
                                                    "form-group " +
                                                    (error.photo
                                                        ? "has-error"
                                                        : "")
                                                }
                                            >
                                                <label className="col-md-3 control-label">
                                                    Foto
                                                </label>
                                                <div className="col-md-9">
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        placeholder=""
                                                        onChange={(e) => {
                                                            setPhoto(
                                                                e.target
                                                                    .files[0]
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        ) : null}
                                        <div
                                            className={
                                                "form-group " +
                                                (error.content
                                                    ? "has-error"
                                                    : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Texto
                                            </label>
                                            <div className="col-md-9">
                                                <textarea
                                                    className="form-control"
                                                    rows="5"
                                                    onChange={(e) => {
                                                        const { value } =
                                                            e.target;

                                                        setContent(value);
                                                    }}
                                                ></textarea>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-md-3 control-label">
                                                Data de Início
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    placeholder=""
                                                    value={dtInit}
                                                    onChange={(e) => {
                                                        const { value } =
                                                            e.target;

                                                        setDtInit(value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-md-3 control-label">
                                                Data de Término
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    placeholder=""
                                                    value={dtEnd}
                                                    onChange={(e) => {
                                                        const { value } =
                                                            e.target;

                                                        setDtEnd(value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-footer">
                                    <div className="form-group">
                                        <div className="col-md-9 col-md-offset-3">
                                            <button
                                                type="submit"
                                                className="btn btn-primary"
                                            >
                                                Salvar
                                            </button>
                                            <button
                                                type="button"
                                                onClick={handleCancelButton}
                                                className="btn btn-default"
                                            >
                                                Cancelar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default withRouter(PostNew);
