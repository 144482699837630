import React, { Component } from 'react';

import Layout from '../../components/Layout';

import api from '../../services/api';

import Swal from 'sweetalert2';

export default class StateNotification extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            notification: {
                title: "",
                text: "",
                state: "",
            },
            error: {
                title: false,
                text: false,
            }
        };

        this.handleCancelButton = this.handleCancelButton.bind(this);
        this.handleSubmitForm = this.handleSubmitForm.bind(this);
    }

    componentWillMount = async () => {
        // const returnBranches = await api.get("/branch");

        // let branchesToSelect = [{ value: "", label: "Todas" }];

        // await returnBranches.data.map(branch => branchesToSelect.push({ value: branch._id, label: `${branch.codigo_loja} - ${branch.cidade_estado}` }));

        this.setState(prevState => ({
            ...prevState,
            loaded: true,
            // branches: branchesToSelect
        }));
    }

    handleCancelButton(event) {
        Swal.fire({
            title: 'Tem certeza que deseja cancelar?',
            text: "Cancelar fará você perder todas as modificações feitas até o momento!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33', 
            confirmButtonText: 'Sim, quero cancelar!',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.value) {
                Swal.fire(
                    'Cancelado!',
                    'Você irá voltar para a tela de listagem.',
                    'success'
                );

                this.props.history.push("/filiais");
            }
        });
    }

    handleSubmitForm = async function(event) {
        event.preventDefault();

        this.setState(prevState => ({
            ...prevState,
            loaded: false
        }));

        try {
            const sendNotification = {
                title: this.state.notification.title,
                text: this.state.notification.text,
                state: this.state.notification.state,
            };

            const createdNotification = await api.post("/notification/state", sendNotification);

            if (createdNotification.status === 200) {
                Swal.fire({
                    type: 'success',
                    title: 'Enviado!',
                    text: 'A notificação foi enviada com sucesso!',
                }).then(() => this.props.history.push("/"));
            } else {
                Swal.fire({
                    type: 'error',
                    title: 'Oops...',
                    text: 'Tivemos um problema ao enviar a notificação para os vendedores desse estado. Contate o suporte!',
                });

                this.setState(prevState => ({
                    ...prevState,
                    loaded: true
                }));
            }
        } catch (e) {
            Swal.fire({
                type: 'error',
                title: 'Oops...',
                text: 'Tivemos um problema ao enviar a notificação para os vendedores desse estado. Contate o suporte!',
            });

            this.setState(prevState => ({
                ...prevState,
                loaded: true
            }));
        }
    }

    render() {
        return (
            <Layout
                menuActive="notification-state" 
                pageTitle="Notificar Estado"
            >
                <div className="row">
                    <div className="col-xs-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="card-title">
                                    Notificar Estado
                                </div>
                            </div>
                            <div className={`card-body ${this.state.loaded ? "" : "__loading"}`}>
                                <div className="loader-container text-center">
                                    <div className="icon">
                                        <div className="sk-wave">
                                            <div className="sk-rect sk-rect1"></div>
                                            <div className="sk-rect sk-rect2"></div>
                                            <div className="sk-rect sk-rect3"></div>
                                            <div className="sk-rect sk-rect4"></div>
                                            <div className="sk-rect sk-rect5"></div>
                                        </div>
                                    </div>
                                    <div className="title">Carregando</div>
                                </div>

                                <form className="form form-horizontal" onSubmit={this.handleSubmitForm}>
                                    <div className="section">
                                        <div className="section-title">Dados da Notificação</div>
                                        <div className="section-body">
                                            <div className={"form-group " + (this.state.error.title ? 'has-error' : '')}>
                                                <label className="col-md-3 control-label">Título</label>
                                                <div className="col-md-9">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder=""
                                                        value={this.state.notification.title}
                                                        onChange={
                                                            (e) => {
                                                                const value = e.target.value;

                                                                this.setState(prevState => ({
                                                                    ...prevState,
                                                                    notification: {
                                                                        ...prevState.notification,
                                                                        title: value
                                                                    }
                                                                }));
                                                            }
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className={"form-group " + (this.state.error.text ? 'has-error' : '')}>
                                                <label className="col-md-3 control-label">Texto</label>
                                                <div className="col-md-9">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder=""
                                                        value={this.state.notification.text}
                                                        onChange={
                                                            (e) => {
                                                                const value = e.target.value;

                                                                this.setState(prevState => ({
                                                                    ...prevState,
                                                                    notification: {
                                                                        ...prevState.notification,
                                                                        text: value
                                                                    }
                                                                }));
                                                            }
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="col-md-3 control-label">Estado</label>
                                                <div className="col-md-9">
                                                    <select
                                                        className="form-control"
                                                        value={this.state.notification.state}
                                                        onChange={(e) => {
                                                            const { value } = e.target;

                                                            this.setState(prevState => ({
                                                                ...prevState,
                                                                notification: {
                                                                    ...prevState.notification,
                                                                    state: value
                                                                }
                                                            }))
                                                        }}
                                                    >
                                                        <option value="" selected>Escolha um estado</option>
                                                        <option value="AC">Acre</option>
                                                        <option value="AL">Alagoas</option>
                                                        <option value="AP">Amapá</option>
                                                        <option value="AM">Amazonas</option>
                                                        <option value="BA">Bahia</option>
                                                        <option value="CE">Ceará</option>
                                                        <option value="DF">Distrito Federal</option>
                                                        <option value="ES">Espírito Santo</option>
                                                        <option value="GO">Goiás</option>
                                                        <option value="MA">Maranhão</option>
                                                        <option value="MT">Mato Grosso</option>
                                                        <option value="MS">Mato Grosso do Sul</option>
                                                        <option value="MG">Minas Gerais</option>
                                                        <option value="PA">Pará</option>
                                                        <option value="PB">Paraíba</option>
                                                        <option value="PR">Paraná</option>
                                                        <option value="PE">Pernambuco</option>
                                                        <option value="PI">Piauí</option>
                                                        <option value="RJ">Rio de Janeiro</option>
                                                        <option value="RN">Rio Grande do Norte</option>
                                                        <option value="RS">Rio Grande do Sul</option>
                                                        <option value="RO">Rondônia</option>
                                                        <option value="RR">Roraima</option>
                                                        <option value="SC">Santa Catarina</option>
                                                        <option value="SP">São Paulo</option>
                                                        <option value="SE">Sergipe</option>
                                                        <option value="TO">Tocantins</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-footer">
                                        <div className="form-group">
                                            <div className="col-md-9 col-md-offset-3">
                                                <button type="submit" className="btn btn-primary">Enviar</button>
                                                <button type="button" onClick={this.handleCancelButton} className="btn btn-default">Cancelar</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </Layout>
        );
    }
}
