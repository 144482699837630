import React, { useState, useRef, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import * as moment from "moment-timezone";

import Layout from "../../components/Layout";

import api, { apiCRMv3 } from "../../services/api";

import AsyncSelect from "react-select/async";
import Swal from "sweetalert2";
import Autocomplete from "react-autocomplete";
import CurrencyInput from "react-currency-input";
import { AreaSelector } from "@bmunozg/react-image-area";

const imageMimeType = /image\/(png|jpg|jpeg)/i;

const DirectMailCRMNew = (props) => {
	const [loaded, setLoaded] = useState(false);
	const [error, setError] = useState({});
	const [directMailFields, setDirectMailFields] = useState(null);
	const [directMailTypeSelected, setDirectMailTypeSelected] = useState("");
	const [name, setName] = useState("");

	const [showFieldOptions, setShowFieldOptions] = useState(false);
	const [maxAreas, setMaxAreas] = useState(0);

	const [areas, setAreas] = useState([]);
	const [selectedArea, setSelectedArea] = useState(null);
	const [selectedAreaIndex, setSelectedAreaIndex] = useState(null);
	const [file, setFile] = useState(null);
	const [fileDataURL, setFileDataURL] = useState(null);

	useEffect(() => {
		let fileReader,
			isCancel = false;
		if (file) {
			fileReader = new FileReader();
			fileReader.onload = (e) => {
				const { result } = e.target;
				if (result && !isCancel) {
					setFileDataURL(result);
				}
			};
			fileReader.readAsDataURL(file);
		}
		return () => {
			isCancel = true;
			if (fileReader && fileReader.readyState === 1) {
				fileReader.abort();
			}
		};
	}, [file]);

	useEffect(() => {
		const load = async () => {
			try {
				const clientListsResponse = await apiCRMv3.get(
					"/direct-mails/fields-of-direct-mails"
				);
				setDirectMailFields(
					clientListsResponse.data.data.directMailTypeFields
				);
			} catch (error) {
			} finally {
				setLoaded(true);
			}
		};
		load();
	}, []);

	function handleCancelButton(event) {
		Swal.fire({
			title: "Tem certeza que deseja cancelar?",
			text: "Cancelar fará você perder todas as modificações feitas até o momento!",
			type: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Sim, quero cancelar!",
			cancelButtonText: "Cancelar",
		}).then((result) => {
			if (result.value) {
				Swal.fire(
					"Cancelado!",
					"Você irá voltar para a tela de listagem.",
					"success"
				);

				props.history.push("/malas-diretas-crm");
			}
		});
	}

	async function handleSubmitForm(event) {
		event.preventDefault();
		setLoaded(false);
		try {
			const formData = new FormData();
			formData.append("name", name);
			formData.append("type", directMailTypeSelected);
			formData.append("background", file);
			formData.append(
				"fields",
				JSON.stringify(directMailFields[directMailTypeSelected])
			);
			formData.append("fieldsValues", JSON.stringify(areas));
			console.log(formData);

			const createdDirectMail = await apiCRMv3.post(
				"/direct-mails",
				formData
			);

			setLoaded(true);
			if (createdDirectMail.status === 201) {
				setLoaded(true);

				Swal.fire({
					type: "success",
					title: "Salvo!",
					text: "A mala direta foi salva com sucesso!",
				}).then(() => props.history.push("/malas-diretas-crm"));
			} else {
				console.log(createdDirectMail);
				Swal.fire({
					type: "error",
					title: "Oops...",
					text: "Tivemos um problema ao salvar essa nova mala direta. Contate o suporte!",
				});

				setLoaded(true);
			}
		} catch (e) {
			Swal.fire({
				type: "error",
				title: "Oops...",
				text: "Tivemos um problema ao salvar essa nova mala direta. Contate o suporte!",
			});
		} finally {
			setLoaded(true);
		}
	}

	const onChangeHandlerFileBackground = (e) => {
		const file = e.target.files[0];
		if (!file.type.match(imageMimeType)) {
			alert("Image mime type is not valid");
			return;
		}
		setFile(file);
	};

	const onChangeHandler = (areas) => {
		let cleanedAreas = areas.filter((area) => area.fieldName !== undefined);
		console.log(cleanedAreas);
		setAreas(cleanedAreas);
	};

	const customRender = (areaProps) => {
		if (!areaProps.isChanging) {
			return (
				<div
					key={areaProps.areaNumber}
					onClick={() => {
						const indexArea = areas.findIndex(
							(obj) => obj.fieldName === areaProps.fieldName
						);
						if (indexArea === -1) {
							console.log(areas.indexOf(areaProps));
						}
						setShowFieldOptions(true);
						setSelectedArea(areaProps);
						setSelectedAreaIndex(indexArea);
					}}
				>
					<h1>{areaProps.fieldLabel}</h1>
				</div>
			);
		}
	};

	const removeArea = () => {
		const array = areas.filter(
			(area) => area.fieldName !== selectedArea.fieldName
		);
		setAreas(array);
		setShowFieldOptions(false);
		setSelectedArea(null);
		setSelectedAreaIndex(null);
	};

	return (
		<Layout
			menuActive="registers"
			pageTitle="Malas Diretas CRM - Nova Mala Direta"
		>
			<div className="row">
				<div className="col-xs-12">
					<div className="card">
						<div className="card-header">
							<div className="card-title">
								Malas Diretas CRM - Nova Mala Direta
							</div>
						</div>
						<div
							className={`card-body ${loaded ? "" : "__loading"}`}
						>
							<div className="loader-container text-center">
								<div className="icon">
									<div className="sk-wave">
										<div className="sk-rect sk-rect1"></div>
										<div className="sk-rect sk-rect2"></div>
										<div className="sk-rect sk-rect3"></div>
										<div className="sk-rect sk-rect4"></div>
										<div className="sk-rect sk-rect5"></div>
									</div>
								</div>
								<div className="title">Carregando</div>
							</div>

							<form
								className="form form-horizontal"
								onSubmit={handleSubmitForm}
							>
								<div className="section">
									<div className="section-title">
										Informações Básicas
									</div>
									<div className="section-body">
										<div className="row">
											<div
												className={
													"form-group " +
													(error.name
														? "has-error"
														: "")
												}
											>
												<label className="col-md-3 control-label">
													Nome
												</label>
												<div className="col-md-9">
													<input
														type="text"
														className="form-control"
														placeholder=""
														value={name}
														onChange={(event) => {
															setName(
																event.target
																	.value
															);
														}}
													/>
												</div>
											</div>
										</div>
										<div className="row">
											<div
												className={
													"form-group " +
													(error.name
														? "has-error"
														: "")
												}
											>
												<label className="col-md-3 control-label">
													Tipo
												</label>
												<div className="col-md-9">
													<select
														name="directMailType"
														id="directMailTypeId"
														className="form-control"
														onChange={(event) => {
															const { value } =
																event.target;
															setDirectMailTypeSelected(
																value
															);
															setMaxAreas(
																directMailFields[
																	value
																].length
															);
														}}
														disabled={
															directMailTypeSelected
														}
													>
														<option value="">
															Escolha um tipo
														</option>
														<option value="DEFAULT">
															Padrão
														</option>
														<option value="DEBT_NEGOTIATION">
															Negociação de Dívida
														</option>
													</select>
												</div>
											</div>
										</div>
										<br />
										<div className="row">
											<div
												className={
													"form-group " +
													(error.file
														? "has-error"
														: "")
												}
											>
												<label className="col-md-3 control-label">
													Imagem de Fundo
												</label>
												<div className="col-md-9">
													<input
														type="file"
														id="image"
														accept=".png, .jpg, .jpeg"
														onChange={
															onChangeHandlerFileBackground
														}
														disabled={file}
													/>
												</div>
											</div>
										</div>
										<br />
										<div className="row">
											<div
												className="col-md-3"
												style={{
													height: "100%",
												}}
											>
												{directMailFields &&
												directMailTypeSelected
													? directMailFields[
															directMailTypeSelected
													  ].map(
															(
																directMailField,
																indexDirectMailField
															) => (
																<div
																	key={
																		indexDirectMailField
																	}
																>
																	<button
																		onClick={(
																			e
																		) => {
																			e.preventDefault();
																			setAreas(
																				[
																					...areas,
																					{
																						x: 100,
																						y: 100,
																						width: 150,
																						height: 150,
																						unit: "px",
																						isNew: true,
																						isChanging: false,
																						fieldLabel:
																							directMailField.label,
																						fieldName:
																							directMailField.name,
																						fontName:
																							"GothamRoundedMedium",
																						fontSize: 20,
																						fontColor:
																							"#fff",
																					},
																				]
																			);
																		}}
																		className="btn btn-primary"
																		style={{
																			width: "100%",
																		}}
																	>
																		{
																			directMailField.label
																		}
																	</button>
																	<br />
																</div>
															)
													  )
													: null}
												{showFieldOptions ? (
													<>
														<label htmlFor="fontName">
															Fonte
														</label>
														<select
															id="fontName"
															className="form-control"
															onChange={(
																event
															) => {
																const {
																	value,
																} =
																	event.target;
																const areasArray =
																	[...areas];
																const objIndex =
																	areasArray.findIndex(
																		(obj) =>
																			obj.fieldName ===
																			selectedArea.fieldName
																	);
																areasArray[
																	objIndex
																].fontName =
																	value;
																setAreas(
																	areasArray
																);
															}}
														>
															<option value="">
																Selecione uma
																fonte
															</option>
															<option
																selected={
																	areas[
																		selectedAreaIndex
																	]
																		.fontName ===
																	"GothamRoundedMedium"
																}
																value="GothamRoundedMedium"
															>
																Gotham Rounded
																Medium
															</option>
															<option
																selected={
																	areas[
																		selectedAreaIndex
																	]
																		.fontName ===
																	"HelveticaNeueLTStdBlkCn"
																}
																value="HelveticaNeueLTStdBlkCn"
															>
																Helvetica Neue
																LT Standard
																Black Condensed
															</option>
															<option
																selected={
																	areas[
																		selectedAreaIndex
																	]
																		.fontName ===
																	"HelveticaNeueLTStdCn"
																}
																value="HelveticaNeueLTStdCn"
															>
																Helvetica Neue
																LT Standard
																Condensed
															</option>
														</select>
														<br />
														<label htmlFor="fontSize">
															Tamanho da Fonte
														</label>
														<input
															id="fontSize"
															type="number"
															className="form-control"
															value={
																areas[
																	selectedAreaIndex
																].fontSize
															}
															onChange={(
																event
															) => {
																const {
																	value,
																} =
																	event.target;
																const areasArray =
																	[...areas];
																areasArray[
																	selectedAreaIndex
																].fontSize =
																	value;
																setAreas(
																	areasArray
																);
															}}
														/>
														<br />
														<label htmlFor="fontColor">
															Cor da Fonte
														</label>
														<input
															id="fontColor"
															type="text"
															className="form-control"
															value={
																areas[
																	selectedAreaIndex
																].fontColor
															}
															onChange={(
																event
															) => {
																const {
																	value,
																} =
																	event.target;
																const areasArray =
																	[...areas];
																areasArray[
																	selectedAreaIndex
																].fontColor =
																	value;
																setAreas(
																	areasArray
																);
															}}
														/>
														<br />
														<button
															className="btn btn-danger"
															onClick={() => {
																removeArea();
															}}
														>
															Remover campo
														</button>
													</>
												) : null}
											</div>
											<div className="col-md-9">
												<AreaSelector
													areas={areas}
													onChange={onChangeHandler}
													customAreaRenderer={
														customRender
													}
													maxAreas={maxAreas}
												>
													{fileDataURL ? (
														<img
															src={fileDataURL}
															alt="preview"
														/>
													) : null}
												</AreaSelector>
											</div>
										</div>
									</div>
								</div>
								<div className="form-footer">
									<div className="form-group">
										<div className="col-md-9 col-md-offset-3">
											<button
												type="submit"
												className="btn btn-primary"
											>
												Salvar
											</button>
											<button
												type="button"
												onClick={handleCancelButton}
												className="btn btn-default"
											>
												Cancelar
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default withRouter(DirectMailCRMNew);
