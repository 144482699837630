// Configs

export const TOKEN_KEY = "2De7drPV3pp5OqfXLDtxwvER1w9RcStsRna5zuhv";
export const TOKEN_KEY_CRM_V3 = "YJ7gSJoThr0MfAIoqEKznUHciwitQCtYDczuSJts";

// Types

export const TYPE_ADMIN = 1;
export const TYPE_GENERAL_MANAGER = 2;
export const TYPE_GENERAL_REGIONAL = 3;
export const TYPE_GENERAL_BRANCH = 4;
export const TYPE_SELLER = 5;
export const TYPE_REGISTER = 6;
export const TYPE_GAZINBOOK = 7;
export const TYPE_REPORT = 8;
export const TYPE_CONTECOMIGO = 9;
export const TYPE_AFTERSALE = 10;
export const TYPE_CURRENT_PROMOTIONS = 11;

export const setType = (user_type) => {
    localStorage.setItem("user_type", user_type);
};
export const unsetType = () => {
    localStorage.removeItem("user_type");
};
export const getType = () => parseInt(localStorage.getItem("user_type"));

export const setBranches = (branches) => {
    localStorage.setItem("8YbAQEWNk7Y21x3fFR0B", JSON.stringify(branches));
};
export const unsetBranches = () => {
    localStorage.removeItem("8YbAQEWNk7Y21x3fFR0B");
};
export const getBranches = () =>
    JSON.parse(localStorage.getItem("8YbAQEWNk7Y21x3fFR0B"));

// Login functions

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getTokenCRMv3 = () => localStorage.getItem(TOKEN_KEY_CRM_V3);
export const getUserData = () => JSON.parse(localStorage.getItem("user_data"));
export const login = (token, user_data) => {
    localStorage.setItem(TOKEN_KEY, token);
    delete user_data.user_type;
    localStorage.setItem("user_data", JSON.stringify(user_data));
};
export const loginCRMv3 = (token) => {
    localStorage.setItem(TOKEN_KEY_CRM_V3, token);
};
export const logout = () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(TOKEN_KEY_CRM_V3);
    localStorage.removeItem("user_data");
    localStorage.removeItem("8YbAQEWNk7Y21x3fFR0B");
};

// User functions

export const getUserName = () =>
    JSON.parse(localStorage.getItem("user_data")).name;
