import React, { useState, useEffect } from "react";

import { withRouter, Link } from "react-router-dom";

import Layout from "../../components/Layout";

import api from "../../services/api";

import Swal from "sweetalert2";

const TabloidProjectList = (props) => {
    const [tabloidProjects, setTabloidProjects] = useState([]);
    const [tabloidProjectsToShow, setTabloidProjectsToShow] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [searchText, setSearchText] = useState("");

    useEffect(function () {
        loadList();
    }, []);

    async function loadList() {
        setLoaded(false);

        const responseGetTabloidProjects = await api.get("/tabloid-project");

        setTabloidProjects(responseGetTabloidProjects.data.data);
        setTabloidProjectsToShow(responseGetTabloidProjects.data.data);
        setLoaded(true);
    }

    function handleSearchBar() {
        setLoaded(false);

        const originalTabloidProjects = tabloidProjects;

        if (searchText === "") {
            setTabloidProjectsToShow(originalTabloidProjects);
            setLoaded(true);
            return;
        }

        let filteredTabloidProjects = originalTabloidProjects.filter(
            (tabloidProject) =>
                tabloidProject.name
                    .toLowerCase()
                    .indexOf(searchText.toLowerCase()) !== -1
        );

        setTabloidProjectsToShow(filteredTabloidProjects);
        setLoaded(true);
    }

    async function handleDelete(tabloidProjectDelete) {
        Swal.fire({
            title: `Tem certeza que deseja excluir o projeto de tabloide ${tabloidProjectDelete.name}?`,
            text: "Não será possível voltar atrás!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim, quero excluir!",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (!result.value) {
                Swal.fire(
                    "Cancelado!",
                    "Nenhum dado será excluído.",
                    "success"
                );
            } else {
                setLoaded(false);

                try {
                    const responseDeleteTabloidProject = await api.delete(
                        `/tabloid-project/${tabloidProjectDelete._id}`
                    );

                    if (responseDeleteTabloidProject.status === 204) {
                        const newTabloidProjects = tabloidProjects.filter(
                            (tabloidProject) =>
                                tabloidProject._id !== tabloidProjectDelete._id
                        );
                        setTabloidProjects(newTabloidProjects);
                        setTabloidProjectsToShow(newTabloidProjects);
                        setLoaded(true);
                    } else {
                        throw "Error";
                    }
                } catch (error) {
                    console.log(error);
                    setLoaded(true);
                    Swal.fire({
                        type: "error",
                        title: "Oops...",
                        text: "Tivemos um problema ao excluir esse projeto de tabloide. Contate o suporte!",
                    });
                }
            }
        });
    }

    return (
        <Layout
            menuActive="registers"
            pageTitle="Lista de Projetos de Tabloide"
            enableSearchBar={true}
            searchTerm={searchText}
            searchOnChange={(e) => setSearchText(e.target.value)}
            handleSearch={handleSearchBar}
        >
            <div className="row">
                <div className="col-xs-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">
                                Lista de Projetos de Tabloide
                            </div>
                            <ul className="card-action">
                                <li>
                                    <Link
                                        to="/projetos-tabloide/novo"
                                        className="btn btn-sm btn-success"
                                    >
                                        Novo <i className="fa fa-plus"></i>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div
                            className={`card-body ${loaded ? "" : "__loading"}`}
                        >
                            <div className="loader-container text-center">
                                <div className="icon">
                                    <div className="sk-wave">
                                        <div className="sk-rect sk-rect1"></div>
                                        <div className="sk-rect sk-rect2"></div>
                                        <div className="sk-rect sk-rect3"></div>
                                        <div className="sk-rect sk-rect4"></div>
                                        <div className="sk-rect sk-rect5"></div>
                                    </div>
                                </div>
                                <div className="title">Carregando</div>
                            </div>

                            <div className="row">
                                <div className="col-xs-12">
                                    <table className="table table-striped table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th>Nome</th>
                                                <th>Capa Campanha</th>
                                                <th>Quantidade de Produtos</th>
                                                <th>Opções</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tabloidProjectsToShow.map(
                                                (tabloidProject, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                {
                                                                    tabloidProject.name
                                                                }
                                                            </td>
                                                            <td>
                                                                {tabloidProject.campaignCover
                                                                    ? tabloidProject
                                                                          .campaignCover
                                                                          .name
                                                                    : "Sem capa de campanha"}
                                                            </td>
                                                            <td>
                                                                {
                                                                    tabloidProject
                                                                        .products
                                                                        .length
                                                                }
                                                            </td>
                                                            <td>
                                                                <div className="col-xs-12">
                                                                    <div className="col-xs-12">
                                                                        <Link
                                                                            to={`/projetos-tabloide/editar/${tabloidProject._id}`}
                                                                            className="btn btn-xs btn-primary"
                                                                            style={{
                                                                                width: "100%",
                                                                            }}
                                                                        >
                                                                            Editar{" "}
                                                                            <div className="icon fa fa-pencil"></div>
                                                                        </Link>
                                                                    </div>
                                                                    <div className="col-xs-12">
                                                                        <button
                                                                            className="btn btn-xs btn-danger"
                                                                            style={{
                                                                                width: "100%",
                                                                            }}
                                                                            onClick={() =>
                                                                                handleDelete(
                                                                                    tabloidProject
                                                                                )
                                                                            }
                                                                        >
                                                                            Excluir
                                                                            <div className="icon fa fa-trash-o"></div>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default withRouter(TabloidProjectList);
