import React, { Component } from "react";

import Layout from "../../components/Layout";
import api from "../../services/api";
import { getBranches } from "../../services/auth";

import Select from "react-select";

import Swal from "sweetalert2";
import MultiselectTwoSides from "react-multiselect-two-sides";

export default class BranchNotification extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            notification: {
                title: "",
                text: "",
                branches: [],
            },
            error: {
                title: false,
                text: false,
            },
            branches: [],
        };

        this.handleCancelButton = this.handleCancelButton.bind(this);
        this.handleSubmitForm = this.handleSubmitForm.bind(this);
    }

    componentWillMount = async () => {
        const returnBranches = await api.get("/branch");

        let branchesToSelect = [];

        const branches = getBranches();

        await returnBranches.data.map((branch) => {
            console.log(branch);
            if (branches.includes(branch._id)) {
                branchesToSelect.push({
                    value: branch._id,
                    label: `${branch.codigo_loja} - ${branch.cidade_estado}`,
                });
            }
        });

        this.setState((prevState) => ({
            ...prevState,
            loaded: true,
            branches: branchesToSelect,
        }));
    };

    handleCancelButton(event) {
        Swal.fire({
            title: "Tem certeza que deseja cancelar?",
            text: "Cancelar fará você perder todas as modificações feitas até o momento!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim, quero cancelar!",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.value) {
                Swal.fire(
                    "Cancelado!",
                    "Você irá voltar para a tela de listagem.",
                    "success"
                );

                this.props.history.push("/filiais");
            }
        });
    }

    handleSubmitForm = async function (event) {
        event.preventDefault();

        this.setState((prevState) => ({
            ...prevState,
            loaded: false,
        }));

        try {
            const sendNotification = {
                title: this.state.notification.title,
                text: this.state.notification.text,
                branches: this.state.notification.branches,
            };

            const createdNotification = await api.post(
                "/notification",
                sendNotification
            );

            if (createdNotification.status === 200) {
                Swal.fire({
                    type: "success",
                    title: "Enviado!",
                    text: "A notificação foi enviada com sucesso!",
                }).then(() => this.props.history.push("/"));
            } else if (createdNotification.status === 400) {
                Swal.fire({
                    type: "error",
                    title: "Oops...",
                    text: createdNotification.data.status,
                });
            } else {
                Swal.fire({
                    type: "error",
                    title: "Oops...",
                    text: "Tivemos um problema ao enviar a notificação para os vendedores dessa filial. Contate o suporte!",
                });
            }
            this.setState((prevState) => ({
                ...prevState,
                loaded: true,
            }));
        } catch (error) {
            console.error(error);
            if (error.response && error.response.status === 400) {
                Swal.fire({
                    type: "error",
                    title: "Oops...",
                    text: error.response.data.status,
                });
            } else {
                Swal.fire({
                    type: "error",
                    title: "Oops...",
                    text: "Tivemos um problema ao enviar a notificação para os vendedores dessa filial. Contate o suporte!",
                });
            }
            this.setState((prevState) => ({
                ...prevState,
                loaded: true,
            }));
        }
    };

    handleChangeSelectedBranches = (value) => {
        console.log(value);
        this.setState((prevState) => ({
            ...prevState,
            notification: {
                ...prevState.notification,
                branches: value,
            },
        }));
    };

    render() {
        return (
            <Layout
                menuActive="notification-branch"
                pageTitle="Notificar Filial"
            >
                <div className="row">
                    <div className="col-xs-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="card-title">
                                    Notificar Filial
                                </div>
                            </div>
                            <div
                                className={`card-body ${
                                    this.state.loaded ? "" : "__loading"
                                }`}
                            >
                                <div className="loader-container text-center">
                                    <div className="icon">
                                        <div className="sk-wave">
                                            <div className="sk-rect sk-rect1"></div>
                                            <div className="sk-rect sk-rect2"></div>
                                            <div className="sk-rect sk-rect3"></div>
                                            <div className="sk-rect sk-rect4"></div>
                                            <div className="sk-rect sk-rect5"></div>
                                        </div>
                                    </div>
                                    <div className="title">Carregando</div>
                                </div>

                                <form
                                    className="form form-horizontal"
                                    onSubmit={this.handleSubmitForm}
                                >
                                    <div className="section">
                                        <div className="section-title">
                                            Dados da Notificação
                                        </div>
                                        <div className="section-body">
                                            <div
                                                className={
                                                    "form-group " +
                                                    (this.state.error.title
                                                        ? "has-error"
                                                        : "")
                                                }
                                            >
                                                <label className="col-md-3 control-label">
                                                    Título
                                                </label>
                                                <div className="col-md-9">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder=""
                                                        value={
                                                            this.state
                                                                .notification
                                                                .title
                                                        }
                                                        onChange={(e) => {
                                                            const value =
                                                                e.target.value;

                                                            this.setState(
                                                                (
                                                                    prevState
                                                                ) => ({
                                                                    ...prevState,
                                                                    notification:
                                                                        {
                                                                            ...prevState.notification,
                                                                            title: value,
                                                                        },
                                                                })
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    "form-group " +
                                                    (this.state.error.text
                                                        ? "has-error"
                                                        : "")
                                                }
                                            >
                                                <label className="col-md-3 control-label">
                                                    Texto
                                                </label>
                                                <div className="col-md-9">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder=""
                                                        value={
                                                            this.state
                                                                .notification
                                                                .text
                                                        }
                                                        onChange={(e) => {
                                                            const value =
                                                                e.target.value;

                                                            this.setState(
                                                                (
                                                                    prevState
                                                                ) => ({
                                                                    ...prevState,
                                                                    notification:
                                                                        {
                                                                            ...prevState.notification,
                                                                            text: value,
                                                                        },
                                                                })
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    "form-group " +
                                                    (this.state.error.text
                                                        ? "has-error"
                                                        : "")
                                                }
                                            >
                                                <label className="col-md-3 control-label">
                                                    Filiais
                                                </label>
                                                <div className="col-md-9">
                                                    <MultiselectTwoSides
                                                        options={
                                                            this.state.branches
                                                        }
                                                        value={
                                                            this.state
                                                                .notification
                                                                .branches
                                                        }
                                                        className="msts_theme_example"
                                                        onChange={
                                                            this
                                                                .handleChangeSelectedBranches
                                                        }
                                                        availableHeader="Disponíveis"
                                                        availableFooter={`Disponíveis: ${
                                                            this.state.branches
                                                                .length -
                                                            this.state
                                                                .notification
                                                                .branches.length
                                                        }`}
                                                        selectedHeader="Selecionadas"
                                                        selectedFooter={`Selecionadas: ${this.state.notification.branches.length}`}
                                                        labelKey="label"
                                                        showControls
                                                        searchable
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-footer">
                                        <div className="form-group">
                                            <div className="col-md-9 col-md-offset-3">
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary"
                                                >
                                                    Enviar
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={
                                                        this.handleCancelButton
                                                    }
                                                    className="btn btn-default"
                                                >
                                                    Cancelar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}
