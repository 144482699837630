/* eslint-disable react/jsx-no-comment-textnodes */
import React, { Component } from "react";

import * as moment from "moment-timezone";

import { Link } from "react-router-dom";

import Layout from "../../components/Layout";

import api from "../../services/api";

import Swal from "sweetalert2";

import Select from "react-select";

export default class CardsToday extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cards: [],
            cardsToShow: [],
            branchFilter: { value: "", label: "Todas" },
            productFilter: { value: "", label: "Todos" },
            branches: [],
            products: [],
            dtBegin: moment().format("YYYY-MM-DD"),
            dtEnd: moment().format("YYYY-MM-DD"),
            loaded: false,
            page: 1,
            qtdPages: 0,
        };

        this.handleChangeBranch = this.handleChangeBranch.bind(this);
        this.handleChangeProduct = this.handleChangeProduct.bind(this);

        this.handleDateResearchButton = this.handleDateResearchButton.bind(
            this
        );
    }

    componentWillMount = async () => {
        const loadData = async () => {
            const returnProducts = await api.get("/product/dash");
            const returnBranches = await api.get("/branch");

            const dataCards = await api.get(
                `/card/today-dash/${this.state.page}`
            );

            let productsToSelect = [{ value: "", label: "Todos" }];
            let branchesToSelect = [{ value: "", label: "Todas" }];

            await returnProducts.data.map((prod) =>
                prod.have_image
                    ? productsToSelect.push({
                          value: prod.code,
                          label: prod.name,
                      })
                    : null
            );
            await returnBranches.data.map((branch) =>
                branchesToSelect.push({
                    value: branch._id,
                    label: `${branch.codigo_loja} - ${branch.cidade_estado}`,
                })
            );

            this.setState((prevState) => ({
                ...prevState,
                loaded: true,
                cards: dataCards.data.cardsToday,
                cardsToShow: dataCards.data.cardsToday,
                qtdPages: dataCards.data.qtdPages,
                products: productsToSelect,
                branches: branchesToSelect,
            }));
        };

        loadData();
    };

    componentDidMount() {
        // const loadData = async () => {
        //     this.setState((prevState) => ({
        //         ...prevState,
        //     }));
        // }
        // loadData();
    }

    handleDateResearchButton = async () => {
        this.setState((prevState) => ({
            ...prevState,
            loaded: false,
        }));

        let dtInit = moment(this.state.dtBegin).format();
        let dtFinally = moment(this.state.dtEnd).format();
        let dataCards = await api.get(
            `/card/paginated/${dtInit}/${dtFinally}/1`
        );

        this.setState((prevState) => ({
            ...prevState,
            loaded: true,
            cards: dataCards.data.cards,
            cardsToShow: dataCards.data.cards,
            qtdPages: dataCards.data.qtdPages,
            branchFilter: { value: "", label: "Todas" },
            productFilter: { value: "", label: "Todos" },
            loaded: true,
        }));
    };

    handleChangeBranch = (selectedOption) => {
        const { value } = selectedOption;

        this.setState((prevState) => ({
            ...prevState,
            loaded: false,
            branchFilter: selectedOption,
        }));

        let { cardsToShow, cards, productFilter } = this.state;

        let cardsShow;

        if (value === "") {
            cardsShow = cards;

            if (productFilter.value === "") {
                this.setState((prevState) => ({
                    ...prevState,
                    loaded: true,
                    cardsToShow: cardsShow,
                }));

                return;
            }

            this.setState((prevState) => ({
                ...prevState,
                // loaded: true,
                cardsToShow: cardsShow,
            }));

            setTimeout(
                () => this.handleChangeProduct(this.state.productFilter),
                500
            );

            return;
        } else
            cardsShow = cards.filter((item) =>
                item.user.branches.map((e) => e._id).indexOf(value) !== -1
                    ? true
                    : false
            );

        this.setState((prevState) => ({
            ...prevState,
            loaded: true,
            cardsToShow: cardsShow,
        }));
    };

    handleChangeProduct = (selectedOption) => {
        const { value } = selectedOption;

        this.setState((prevState) => ({
            ...prevState,
            loaded: false,
            productFilter: selectedOption,
        }));

        let { cardsToShow, cards, branchFilter } = this.state;

        let cardsShow;

        if (value === "") {
            cardsShow = cards;

            if (branchFilter.value === "") {
                this.setState((prevState) => ({
                    ...prevState,
                    loaded: true,
                    cardsToShow: cardsShow,
                }));

                return;
            }

            this.setState((prevState) => ({
                ...prevState,
                // loaded: true,
                cardsToShow: cardsShow,
            }));

            setTimeout(
                () => this.handleChangeBranch(this.state.branchFilter),
                500
            );

            return;
        } else
            cardsShow = cards.filter((item) => item.product.codigo === value);

        this.setState((prevState) => ({
            ...prevState,
            loaded: true,
            cardsToShow: cardsShow,
        }));
    };

    defineClassLabelUserType = (user_type) => {
        switch (user_type) {
            case 1:
                return "danger";
                break;

            case 2:
                return "warning";
                break;

            case 3:
                return "success";
                break;

            case 4:
                return "primary";
                break;

            case 5:
                return "default";
                break;

            case 6:
                return "default";
                break;

            default:
                return "default";
                break;
        }
    };

    nameLabelUserType = (user_type) => {
        switch (user_type) {
            case 1:
                return "ADMIN";
                break;

            case 2:
                return "GER. GERAL";
                break;

            case 3:
                return "GER. REGIONAL";
                break;

            case 4:
                return "GER. FILIAL";
                break;

            case 5:
                return "VENDEDOR";
                break;

            case 6:
                return "CADASTRADOR";
                break;

            default:
                return "VENDEDOR";
                break;
        }
    };

    changePage(page) {
        const loadData = async (page) => {
            this.setState((prevState) => ({
                ...prevState,
                loaded: false,
            }));

            let dtInit = moment(this.state.dtBegin).format();
            let dtFinally = moment(this.state.dtEnd).format();
            let dataCards = await api.get(
                `/card/paginated/${dtInit}/${dtFinally}/${page}`
            );

            this.setState((prevState) => ({
                ...prevState,
                loaded: true,
                cards: dataCards.data.cards,
                cardsToShow: dataCards.data.cards,
                page: page,
            }));
        };
        loadData(page);
    }

    render() {
        let { cardsToShow, products, branches, dtBegin, dtEnd } = this.state;

        return (
            <Layout menuActive="dashboard" pageTitle="Orçamentos Hoje">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="card-title">
                                    <small>
                                        <Link
                                            className="label label-default"
                                            to="/dashboard"
                                        >
                                            Dashboard
                                        </Link>
                                    </small>{" "}
                                    <span className="label label-success">
                                        Orçamentos hoje
                                    </span>
                                </div>
                            </div>
                            <div
                                className={`card-body table-responsive ${
                                    this.state.loaded ? "" : "__loading"
                                }`}
                            >
                                <div className="row">
                                    <div className="col-xs-4">
                                        <label htmlFor="dtBegin-filter">
                                            Data Inicial
                                        </label>
                                        <input
                                            className="form-control"
                                            type="date"
                                            id="dtBegin-filter"
                                            value={dtBegin}
                                            onChange={(e) => {
                                                const { value } = e.target;

                                                this.setState((prevState) => ({
                                                    ...prevState,
                                                    dtBegin: value,
                                                }));
                                            }}
                                        />
                                    </div>

                                    <div className="col-xs-4">
                                        <label htmlFor="dtEnd-filter">
                                            Data Final
                                        </label>
                                        <input
                                            className="form-control"
                                            type="date"
                                            id="dtEnd-filter"
                                            value={dtEnd}
                                            onChange={(e) => {
                                                const { value } = e.target;

                                                this.setState((prevState) => ({
                                                    ...prevState,
                                                    dtEnd: value,
                                                }));
                                            }}
                                        />
                                    </div>

                                    <div
                                        className="col-xs-4"
                                        style={{
                                            position: "relative",
                                            top: "50%",
                                            transform: "translateY(50%)",
                                        }}
                                    >
                                        <button
                                            className="btn btn-primary btn-large"
                                            onClick={
                                                this.handleDateResearchButton
                                            }
                                        >
                                            Buscar por data
                                        </button>
                                    </div>
                                </div>

                                {/* <div className="row">
                                    <div className="col-xs-4">
                                        <label htmlFor="filial-filter">
                                            Filial
                                        </label>
                                        <Select
                                            id="filial-filter"
                                            placeholder="Todas"
                                            value={this.state.branchFilter}
                                            onChange={this.handleChangeBranch}
                                            options={branches}
                                        />
                                    </div>

                                    <div className="col-xs-4">
                                        <label htmlFor="product-filter">
                                            Produto
                                        </label>
                                        <Select
                                            id="product-filter"
                                            placeholder="Todos"
                                            value={this.state.productFilter}
                                            onChange={this.handleChangeProduct}
                                            options={products}
                                        />
                                    </div>
                                </div> */}

                                <br />

                                <div className="loader-container text-center">
                                    <div className="icon">
                                        <div className="sk-wave">
                                            <div className="sk-rect sk-rect1"></div>
                                            <div className="sk-rect sk-rect2"></div>
                                            <div className="sk-rect sk-rect3"></div>
                                            <div className="sk-rect sk-rect4"></div>
                                            <div className="sk-rect sk-rect5"></div>
                                        </div>
                                    </div>
                                    <div className="title">Carregando</div>
                                </div>

                                <div className="row">
                                    <div className="col-xs-12">
                                        <table className="table table-striped table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Vendedor</th>
                                                    <th>Filial</th>
                                                    <th className="right">
                                                        Produto
                                                    </th>
                                                    <th>Horário Envio</th>
                                                    <th>Forma de Envio</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {cardsToShow.map(
                                                    (card, index) => (
                                                        <tr
                                                            key={index}
                                                            onDoubleClick={(
                                                                e
                                                            ) =>
                                                                this.props.history.push(
                                                                    "/dashboard/cartoes/detalhe",
                                                                    {
                                                                        card,
                                                                        from:
                                                                            "cards",
                                                                    }
                                                                )
                                                            }
                                                        >
                                                            <td>
                                                                {card.user.name}{" "}
                                                                -{" "}
                                                                <span
                                                                    className={`label label-${this.defineClassLabelUserType(
                                                                        card
                                                                            .user
                                                                            .user_type
                                                                    )}`}
                                                                >
                                                                    {this.nameLabelUserType(
                                                                        card
                                                                            .user
                                                                            .user_type
                                                                    )}
                                                                </span>
                                                            </td>
                                                            <td>{`${card.user.branch_selected.codigo_loja} - ${card.user.branch_selected.cidade_estado}`}</td>
                                                            <td className="right">
                                                                {
                                                                    card.product
                                                                        .name
                                                                }
                                                            </td>
                                                            <td className="right">
                                                                {moment
                                                                    .tz(
                                                                        card.created_at,
                                                                        "America/Sao_Paulo"
                                                                    )
                                                                    .format(
                                                                        "DD/MM/YYYY HH:mm"
                                                                    )}
                                                            </td>
                                                            {card.send_to ===
                                                            "whatsapp" ? (
                                                                <td>
                                                                    <span className="badge badge-success badge-icon">
                                                                        <i
                                                                            className="fa fa-whatsapp"
                                                                            aria-hidden="true"
                                                                        ></i>
                                                                        <span>
                                                                            WHATSAPP
                                                                        </span>
                                                                    </span>
                                                                </td>
                                                            ) : null}
                                                            {card.send_to ===
                                                            "sms" ? (
                                                                <td>
                                                                    <span className="badge badge-warning badge-icon">
                                                                        <i
                                                                            className="fa fa-commenting-o"
                                                                            aria-hidden="true"
                                                                        ></i>
                                                                        <span>
                                                                            SMS
                                                                        </span>
                                                                    </span>
                                                                </td>
                                                            ) : null}
                                                            {card.send_to ===
                                                            "email" ? (
                                                                <td>
                                                                    <span className="badge badge-danger badge-icon">
                                                                        <i
                                                                            className="fa fa-envelope-o"
                                                                            aria-hidden="true"
                                                                        ></i>
                                                                        <span>
                                                                            E-MAIL
                                                                        </span>
                                                                    </span>
                                                                </td>
                                                            ) : null}
                                                        </tr>
                                                    )
                                                )}
                                                {/* <tr>
                                                    <td>Leonardo Mello</td>
                                                    <td>020 Sinop - MT</td>
                                                    <td className="right">TV 55'' LED Philips Smart 4K USB HDMI</td>
                                                    <td><span className="badge badge-success badge-icon"><i className="fa fa-whatsapp"
                                                                aria-hidden="true"></i><span>WHATSAPP</span></span></td>
                                                </tr>
                                                <tr>
                                                    <td>Leonardo Mello</td>
                                                    <td>020 Sinop - MT</td>
                                                    <td className="right">Celular Asus Zenfone 5 Selfie Pro 4/128GB Dual</td>
                                                    <td><span className="badge badge-success badge-icon"><i className="fa fa-whatsapp"
                                                                aria-hidden="true"></i><span>WHATSAPP</span></span></td>
                                                    <td><span className="badge badge-danger badge-icon"><i className="fa fa-envelope-o"
                                                                aria-hidden="true"></i><span>E-MAIL</span></span></td>
                                                </tr> */}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {this.state.qtdPages !== 0 ? (
                                    <div className="row">
                                        <div className="col-xs-12">
                                            <ul className="pagination">
                                                {Array(this.state.qtdPages)
                                                    .fill()
                                                    .map((_, page) => (
                                                        <li
                                                            className={
                                                                page + 1 ===
                                                                this.state.page
                                                                    ? "active"
                                                                    : ""
                                                            }
                                                        >
                                                            <a
                                                                href="#"
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.preventDefault();
                                                                    this.changePage(
                                                                        page + 1
                                                                    );
                                                                }}
                                                            >
                                                                {page + 1}
                                                            </a>
                                                        </li>
                                                    ))}
                                            </ul>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}
