import React, { useState, useEffect } from "react";

import { withRouter, Link } from "react-router-dom";

import Layout from "../../components/Layout";

import { apiCRMv3 } from "../../services/api";

import Swal from "sweetalert2";

const FormList = (props) => {
    const [forms, setForms] = useState([]);
    const [formsToShow, setFormsToShow] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [searchText, setSearchText] = useState("");

    useEffect(function () {
        loadList();
    }, []);

    async function loadList() {
        setLoaded(false);

        const responseGetForms = await apiCRMv3.get("/forms");

        setForms(responseGetForms.data.data.forms);
        setFormsToShow(responseGetForms.data.data.forms);
        setLoaded(true);
    }

    function handleSearchBar() {
        setLoaded(false);

        const originalForms = forms;

        if (searchText === "") {
            setFormsToShow(originalForms);
            setLoaded(true);
            return;
        }

        let filteredForms = originalForms.filter(
            (form) =>
                form.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
        );

        if (filteredForms.length <= 0) {
            filteredForms = originalForms.filter(
                (form) =>
                    form.name
                        .toLowerCase()
                        .indexOf(searchText.toLowerCase()) !== -1
            );
        }

        setFormsToShow(filteredForms);
        setLoaded(true);
    }

    async function handleDelete(formDelete) {
        Swal.fire({
            title: `Tem certeza que deseja excluir o formulário ${formDelete.name}?`,
            text: "Não será possível voltar atrás!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim, quero excluir!",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (!result.value) {
                Swal.fire(
                    "Cancelado!",
                    "Nenhum dado será excluído.",
                    "success"
                );
            } else {
                setLoaded(false);

                try {
                    const responseDeleteForm = await apiCRMv3.delete(
                        `/forms/${formDelete.id}`
                    );

                    if (responseDeleteForm.status === 204) {
                        const newForms = forms.filter(
                            (form) => form.id !== formDelete.id
                        );
                        setForms(newForms);
                        setFormsToShow(newForms);
                        setLoaded(true);
                    } else {
                        throw new Error("Error");
                    }
                } catch (error) {
                    setLoaded(true);
                    Swal.fire({
                        type: "error",
                        title: "Oops...",
                        text: "Tivemos um problema ao excluir esse formulário. Contate o suporte!",
                    });
                }
            }
        });
    }

    async function exportXlsxToEmail(form) {
        const emailResult = await Swal.fire({
            title: "Informe o e-mail para envio abaixo:",
            input: "text",
            inputAttributes: {
                autocapitalize: "off",
            },
            showCancelButton: true,
            confirmButtonText: "Próximo",
            showLoaderOnConfirm: true,
            cancelButtonColor: "#d33",
            cancelButtonText: "Cancelar",
        });

        if (!emailResult.value) {
            Swal.fire(
                "Cancelado!",
                "Sua geração do relatório foi cancelada.",
                "error"
            );
        } else {
            const monthResult = await Swal.fire({
                title: "Informe o mês para extração abaixo:",
                input: "select",
                inputOptions: {
                    1: "Janeiro",
                    2: "Fevereiro",
                    3: "Março",
                    4: "Abril",
                    5: "Maio",
                    6: "Junho",
                    7: "Julho",
                    8: "Agosto",
                    9: "Setembro",
                    10: "Outubro",
                    11: "Novembro",
                    12: "Dezembro",
                },
                inputPlaceholder: "Selecione um mês",
                showCancelButton: true,
                inputValidator: function (value) {
                    return new Promise(function (resolve, reject) {
                        if (value !== "") {
                            resolve();
                        } else {
                            resolve(
                                "Você precisa selecionar um mês específico"
                            );
                        }
                    });
                },
                confirmButtonText: "Próximo",
                showLoaderOnConfirm: true,
                cancelButtonColor: "#d33",
                cancelButtonText: "Cancelar",
            });

            if (!monthResult.value) {
                Swal.fire(
                    "Cancelado!",
                    "Sua geração do relatório foi cancelada.",
                    "error"
                );
            } else {
                const yearResult = await Swal.fire({
                    title: "Informe o ano para extração abaixo:",
                    input: "select",
                    inputOptions: {
                        2021: "2021",
                        2022: "2022",
                        2023: "2023",
                        2024: "2024",
                        2025: "2025",
                    },
                    inputPlaceholder: "Selecione um ano",
                    showCancelButton: true,
                    inputValidator: function (value) {
                        return new Promise(function (resolve, reject) {
                            if (value !== "") {
                                resolve();
                            } else {
                                resolve(
                                    "Você precisa selecionar um ano específico"
                                );
                            }
                        });
                    },
                    confirmButtonText: "Exportar",
                    showLoaderOnConfirm: true,
                    cancelButtonColor: "#d33",
                    cancelButtonText: "Cancelar",
                });

                if (!yearResult.value) {
                    Swal.fire(
                        "Cancelado!",
                        "Sua geração do relatório foi cancelada.",
                        "error"
                    );
                } else {
                    setLoaded(false);

                    try {
                        const responseBeginExport = await apiCRMv3.post(
                            `/reports/survey-answers-xlsx/${form.id}`,
                            {
                                emailToSend: emailResult.value,
                                month: monthResult.value,
                                year: yearResult.value,
                            }
                        );

                        setLoaded(true);

                        if (responseBeginExport.status === 200) {
                            Swal.fire({
                                type: "success",
                                title: "Gerando..",
                                text: "Estamos gerando seu relatório e em alguns minutos será enviado para o e-mail fornecido!",
                            });
                        } else {
                            throw new Error("Error");
                        }
                    } catch (error) {
                        setLoaded(true);
                        Swal.fire({
                            type: "error",
                            title: "Oops...",
                            text: "Tivemos um problema ao iniciar a geração do seu relatório. Contate o suporte!",
                        });
                    }
                }
            }
        }
    }

    return (
        <Layout
            menuActive="forms"
            pageTitle="Lista de Formulários"
            enableSearchBar={true}
            searchTerm={searchText}
            searchOnChange={(e) => setSearchText(e.target.value)}
            handleSearch={handleSearchBar}
        >
            <div className="row">
                <div className="col-xs-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">
                                Lista de Formulários
                            </div>
                            <ul className="card-action">
                                <li>
                                    <Link
                                        to="/formularios/novo"
                                        className="btn btn-sm btn-success"
                                    >
                                        Novo <i className="fa fa-plus"></i>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div
                            className={`card-body ${loaded ? "" : "__loading"}`}
                        >
                            <div className="loader-container text-center">
                                <div className="icon">
                                    <div className="sk-wave">
                                        <div className="sk-rect sk-rect1"></div>
                                        <div className="sk-rect sk-rect2"></div>
                                        <div className="sk-rect sk-rect3"></div>
                                        <div className="sk-rect sk-rect4"></div>
                                        <div className="sk-rect sk-rect5"></div>
                                    </div>
                                </div>
                                <div className="title">Carregando</div>
                            </div>

                            <div className="row">
                                <div className="col-xs-12">
                                    <table className="table table-striped table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th>Nome</th>
                                                <th>Qtd. de Perguntas</th>
                                                <th>Opções</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {formsToShow.map((form, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td
                                                            style={{
                                                                width: "40%",
                                                            }}
                                                        >
                                                            {form.name}
                                                        </td>
                                                        <td
                                                            style={{
                                                                width: "40%",
                                                            }}
                                                        >
                                                            {form.questions
                                                                ? form.questions
                                                                      .length
                                                                : 0}
                                                        </td>
                                                        <td
                                                            style={{
                                                                width: "20%",
                                                            }}
                                                        >
                                                            <div className="col-xs-12">
                                                                <div className="col-xs-12">
                                                                    <Link
                                                                        to={`/formularios/resultados/${form.id}`}
                                                                        className="btn btn-xs btn-primary"
                                                                        style={{
                                                                            width: "100%",
                                                                        }}
                                                                    >
                                                                        Ver
                                                                        Resultados{" "}
                                                                        <div className="icon fa fa-list-alt"></div>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                            <div className="col-xs-12">
                                                                <div className="col-xs-12">
                                                                    <button
                                                                        className="btn btn-xs btn-success"
                                                                        style={{
                                                                            width: "100%",
                                                                        }}
                                                                        onClick={() =>
                                                                            exportXlsxToEmail(
                                                                                form
                                                                            )
                                                                        }
                                                                    >
                                                                        Exportar
                                                                        XLSX
                                                                        <div className="icon fa fa-file-excel-o"></div>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className="col-xs-12">
                                                                <div className="col-xs-12">
                                                                    <button
                                                                        className="btn btn-xs btn-danger"
                                                                        style={{
                                                                            width: "100%",
                                                                        }}
                                                                        onClick={() =>
                                                                            handleDelete(
                                                                                form
                                                                            )
                                                                        }
                                                                    >
                                                                        Excluir{" "}
                                                                        <div className="icon fa fa-trash-o"></div>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default withRouter(FormList);
