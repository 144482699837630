import React, { Component } from 'react';

import * as moment from 'moment-timezone';

import { Link } from 'react-router-dom';

import Layout from '../../components/Layout';

import api from '../../services/api';

import Swal from 'sweetalert2';


export default class CPFAuthorizedList extends Component {
    constructor(props) {
        super(props);
        this.deleteCPF = this.deleteCPF.bind(this);

        this.state = {
            cpfs: [],
            loaded: false
        }
    }

    componentWillMount = async () => {
        const returnCPFs = await api.get("/cpf-authorized");

        this.setState(prevState => ({
            ...prevState,
            loaded: true,
            cpfs: returnCPFs.data,
        }));
    }

    componentDidMount = async function () {
        this.setState(prevState => ({
            ...prevState,
            loaded: false
        }));
    }

    async deleteCPF(cpf) {
        this.setState(prevState => ({
            ...prevState,
            loaded: false
        }));

        await api.delete(`/cpf-authorized/${cpf._id}`);

        let cpfs = this.state.cpfs;
        const indexCPF = cpfs.indexOf(cpf);

        cpfs.splice(indexCPF, 1);

        this.setState(prevState => ({
            ...prevState,
            loaded: true,
            cpfs: cpfs,
        }));
    }

    render() {
        let { cpfs } = this.state;

        return (
            <Layout
                menuActive="dashboard" 
                pageTitle="CPFs Autorizados"
            >
                <div className="row">
                    <div className="col-xs-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="card-title">
                                    <small><Link className="label label-default" to="/dashboard">Dashboard</Link></small> <span className="label label-success">CPFs Autorizados</span>
                                </div>
                                <ul className="card-action">
                                    <li>
                                        <Link to="/cpfs-autorizado/novo" className="btn btn-sm btn-success">
                                            Novo <i className="fa fa-plus"></i>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className={`card-body table-responsive ${this.state.loaded ? "" : "__loading"}`}>
                                <div className="loader-container text-center">
                                    <div className="icon">
                                        <div className="sk-wave">
                                            <div className="sk-rect sk-rect1"></div>
                                            <div className="sk-rect sk-rect2"></div>
                                            <div className="sk-rect sk-rect3"></div>
                                            <div className="sk-rect sk-rect4"></div>
                                            <div className="sk-rect sk-rect5"></div>
                                        </div>
                                    </div>
                                    <div className="title">Carregando</div>
                                </div>

                                <div className="row">
                                    <div className="col-xs-12">
                                        <table className="table table-striped table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th className="right">CPF</th>
                                                    <th>Opções</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    cpfs.map((cpfAuthorized, index) => (
                                                        <tr key={index}>
                                                            <td style={{ width: '90%' }}>{cpfAuthorized.cpf}</td>
                                                            <td style={{ width: '10%' }}>
                                                                <div className="col-xs-12">
                                                                    <button
                                                                        className="btn btn-xs btn-danger"
                                                                        onClick={() => this.deleteCPF(cpfAuthorized)}
                                                                        style={{width: '100%'}}
                                                                    >
                                                                        Excluir <div className="icon fa fa-trash-o"></div>
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}
