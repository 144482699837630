import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import Layout from "../../components/Layout";

import * as moment from "moment-timezone";

import { apiCRMv3 } from "../../services/api";

import Swal from "sweetalert2";
import {
    getType,
    TYPE_GENERAL_BRANCH,
    TYPE_GENERAL_REGIONAL,
} from "../../services/auth";

export const CurrentOfferList = (props) => {
    const [currentOffers, setCurrentOffers] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [loaded, setLoaded] = useState(true);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        loadCurrentOffers();
    }, []);

    const loadCurrentOffers = async () => {
        setLoaded(false);
        const { currentOfferType } = props.match.params;
        const returnCurrentOffers = await apiCRMv3.get(
            `/current-offers/get-app?page=${page}&type=${currentOfferType}&allItems=true`
        );
        console.log(returnCurrentOffers);
        if (returnCurrentOffers.status === 500) {
            Swal.fire({
                type: "error",
                title: "Erro!",
                text: "Tivemos um problema ao buscar as ofertas vigentes. Contate o suporte!",
            }).then(() => this.props.history.push("/"));
            return;
        }

        setCurrentOffers(returnCurrentOffers.data.data.currentOffers);
        setTotalPages(returnCurrentOffers.data.data.currentOffersCount / 10);
        setLoaded(true);
    };

    const handleDeleteButton = async (currentOffer) => {
        setLoaded(false);
        Swal.fire({
            title: `Tem certeza que deseja excluir a oferta?`,
            text: "Ao excluir esse registro, a oferta será apagada sem retorno! CUIDADO!!!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim, quero excluir!",
            cancelButtonText: "Cancelar",
        }).then(async function (result) {
            if (result.value) {
                console.log(currentOffer);
                const deletedProduct = await apiCRMv3.delete(
                    `/current-offers/${currentOffer.id}`
                );
                if (deletedProduct.status === 204) {
                    loadCurrentOffers();
                    setSearchTerm("");
                    Swal.fire(
                        "Excluído!",
                        "A oferta foi excluída com sucesso de todas as filiais!",
                        "success"
                    );
                    setLoaded(true);
                } else {
                    Swal.fire(
                        "Oops...",
                        "Não foi possível excluir a oferta agora. Por favor contate o suporte!",
                        "error"
                    ).then(() => {
                        setLoaded(true);
                    });
                }
            } else {
                setLoaded(true);
                setSearchTerm("");
            }
        });
    };

    return (
        <Layout
            menuActive="current-offers"
            pageTitle="Ofertas Vigentes"
            enableSearchBar={true}
            searchTerm={searchTerm}
            searchOnChange={(e) => {
                const { value } = e.target;
                setSearchTerm(value);
            }}
            handleSearch={() => {}}
        >
            <div className="row">
                <div className="col-xs-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">Ofertas Vigentes</div>
                            <ul className="card-action">
                                <li>
                                    <Link
                                        to="/ofertas-vigentes-nova"
                                        className="btn btn-sm btn-success"
                                    >
                                        Nova <i className="fa fa-plus"></i>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div
                            className={`card-body table-responsive ${
                                loaded ? "" : "__loading"
                            }`}
                        >
                            <div class="loader-container text-center">
                                <div class="icon">
                                    <div class="sk-wave">
                                        <div class="sk-rect sk-rect1"></div>
                                        <div class="sk-rect sk-rect2"></div>
                                        <div class="sk-rect sk-rect3"></div>
                                        <div class="sk-rect sk-rect4"></div>
                                        <div class="sk-rect sk-rect5"></div>
                                    </div>
                                </div>
                                <div class="title">Carregando</div>
                            </div>

                            <div className="row">
                                <div className="col-xs-12">
                                    <table className="table table-striped table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th
                                                    style={{
                                                        width: "200px",
                                                    }}
                                                >
                                                    Filiais
                                                </th>
                                                <th>Nome</th>
                                                <th>Tipo</th>
                                                <th>Data Início</th>
                                                <th>Data Fim</th>
                                                <th>Opções</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentOffers
                                                .filter((item) =>
                                                    String(item.name)
                                                        .toLowerCase()
                                                        .includes(
                                                            searchTerm.toLowerCase()
                                                        )
                                                )
                                                .map(
                                                    (
                                                        currentOffer,
                                                        indexCurrentOffer
                                                    ) => {
                                                        return (
                                                            <tr
                                                                key={
                                                                    indexCurrentOffer
                                                                }
                                                                onDoubleClick={(
                                                                    e
                                                                ) =>
                                                                    this.props.history.push(
                                                                        `/produtos-oferta-filial/${currentOffer.id}`
                                                                    )
                                                                }
                                                            >
                                                                <td
                                                                    style={{
                                                                        width: "200px",
                                                                    }}
                                                                >
                                                                    {currentOffer.branches.join(
                                                                        ","
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        currentOffer.name
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {currentOffer.type ===
                                                                    "VT"
                                                                        ? "VT"
                                                                        : "Rede Social"}
                                                                </td>
                                                                <td>
                                                                    {moment
                                                                        .tz(
                                                                            currentOffer.beginDate,
                                                                            "America/Sao_Paulo"
                                                                        )
                                                                        .format(
                                                                            "DD/MM/YYYY"
                                                                        )}
                                                                </td>
                                                                <td>
                                                                    {moment
                                                                        .tz(
                                                                            currentOffer.endDate,
                                                                            "America/Sao_Paulo"
                                                                        )
                                                                        .format(
                                                                            "DD/MM/YYYY"
                                                                        )}
                                                                </td>
                                                                <td>
                                                                    <div className="col-xs-12">
                                                                        <div className="col-xs-12">
                                                                            <button
                                                                                className="btn btn-xs btn-danger"
                                                                                onClick={() => {
                                                                                    handleDeleteButton(
                                                                                        currentOffer
                                                                                    );
                                                                                }}
                                                                                style={{
                                                                                    width: "100%",
                                                                                }}
                                                                            >
                                                                                Excluir{" "}
                                                                                <div className="icon fa fa-trash-o"></div>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};
