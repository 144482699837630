import React, { Component } from "react";

import api, { apiCRMv3 } from "../../services/api";
import {
    login,
    isAuthenticated,
    setType,
    setBranches,
    loginCRMv3,
} from "../../services/auth";

export default class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            login: false,
            error: null,
            cpf: "",
            password: "",
        };

        this.handleClickLogin = this.handleClickLogin.bind(this);

        if (isAuthenticated()) {
            this.props.history.push("/dashboard");
        }
    }

    handleClickLogin = async (e) => {
        e.preventDefault();

        console.log(this.state);

        this.setState((prevState) => ({
            ...prevState,
            login: true,
        }));

        const { cpf, password } = this.state;

        if (!cpf || !password) {
            this.setState({
                error: "Preencha todos os dados para se cadastrar",
            });
            this.setState((prevState) => ({
                ...prevState,
                login: false,
            }));
        } else {
            try {
                const returnToken = await api.post("/login", { cpf, password });
                const returnTokenCRMv3 = await apiCRMv3.post("/login-by-cpf", {
                    cpf,
                    password,
                });
                setType(returnToken.data.user_data.user_type);
                console.log(returnToken.data.user_data.branches);
                setBranches(returnToken.data.user_data.branches);
                login(returnToken.data.token, returnToken.data.user_data);
                loginCRMv3(returnTokenCRMv3.data.data.token);
                this.props.history.push("/dashboard");
            } catch (err) {
                console.log(err);
                this.setState({ error: "Ocorreu um erro ao fazer login." });

                this.setState((prevState) => ({
                    ...prevState,
                    login: false,
                }));
            }
        }
    };

    render() {
        return (
            <React.Fragment>
                <div class="app-container app-login">
                    <div class="flex-center">
                        <div class="app-header"></div>
                        <div class="app-body">
                            <div
                                class="loader-container text-center"
                                style={{
                                    display: this.state.login
                                        ? "block"
                                        : "none",
                                }}
                            >
                                <div class="icon">
                                    <div class="sk-folding-cube">
                                        <div class="sk-cube1 sk-cube"></div>
                                        <div class="sk-cube2 sk-cube"></div>
                                        <div class="sk-cube4 sk-cube"></div>
                                        <div class="sk-cube3 sk-cube"></div>
                                    </div>
                                </div>
                                <div class="title">Fazendo login...</div>
                            </div>
                            <div
                                class="app-block"
                                style={{
                                    display: !this.state.login
                                        ? "block"
                                        : "none",
                                }}
                            >
                                <div class="app-form">
                                    <div class="form-header">
                                        <div class="app-brand">
                                            <span class="highlight">
                                                Cartão
                                            </span>{" "}
                                            Express
                                        </div>
                                    </div>
                                    {this.state.error && (
                                        <p>{this.state.error}</p>
                                    )}
                                    <form onSubmit={this.handleClickLogin}>
                                        <div class="input-group">
                                            <span
                                                class="input-group-addon"
                                                id="basic-addon1"
                                            >
                                                <i
                                                    class="fa fa-user"
                                                    aria-hidden="true"
                                                ></i>
                                            </span>
                                            <input
                                                type="text"
                                                class="form-control"
                                                placeholder="CPF"
                                                aria-describedby="basic-addon1"
                                                onChange={(e) =>
                                                    this.setState({
                                                        cpf: e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                        <div class="input-group">
                                            <span
                                                class="input-group-addon"
                                                id="basic-addon2"
                                            >
                                                <i
                                                    class="fa fa-key"
                                                    aria-hidden="true"
                                                ></i>
                                            </span>
                                            <input
                                                type="password"
                                                class="form-control"
                                                placeholder="Senha"
                                                aria-describedby="basic-addon2"
                                                onChange={(e) =>
                                                    this.setState({
                                                        password:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                        <div class="text-center">
                                            <input
                                                type="submit"
                                                class="btn btn-success btn-submit"
                                                value="Entrar"
                                            />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="app-footer"></div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
