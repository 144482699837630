import React, { Component } from "react";

import * as moment from "moment-timezone";

import { Link } from "react-router-dom";

import Layout from "../../components/Layout";

import api from "../../services/api";

export default class CardDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            card: {},
            loaded: false,
            from: "",
            itemActive: "seller",
        };

        this.handleChangePane = this.handleChangePane.bind(this);
    }

    componentWillMount = async function () {
        if (!this.props.location.state) {
            this.props.history.push("/");
            return;
        }

        this.setState((prevState) => ({
            ...prevState,
            card: this.props.location.state.card,
            from: this.props.location.state.from,
            loaded: true,
        }));
    };

    handleChangePane = (e, pane) => {
        e.preventDefault();

        this.setState((prevState) => ({
            ...prevState,
            itemActive: pane,
        }));
    };

    render() {
        const { card, from } = this.state;
        return (
            <Layout menuActive="dashboard" pageTitle="Detalhes do cartão">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="card-title">
                                    {from === "cards" ? (
                                        <React.Fragment>
                                            <small>
                                                <Link
                                                    className="label label-default"
                                                    to="/dashboard"
                                                >
                                                    Dashboard
                                                </Link>{" "}
                                                >{" "}
                                            </small>{" "}
                                            <small>
                                                <a
                                                    href=""
                                                    className="label label-default"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.props.history.goBack();
                                                    }}
                                                >
                                                    Orçamentos hoje
                                                </a>{" "}
                                                >
                                            </small>{" "}
                                            <span className="label label-success">
                                                Detalhes do cartão
                                            </span>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <small>
                                                <a
                                                    className="label label-default"
                                                    href=""
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.props.history.goBack();
                                                    }}
                                                >
                                                    Dashboard
                                                </a>{" "}
                                                >{" "}
                                            </small>{" "}
                                            <span className="label label-success">
                                                Detalhes do cartão
                                            </span>
                                        </React.Fragment>
                                    )}
                                </div>
                            </div>
                            <div
                                className={`card-body ${
                                    this.state.loaded ? "" : "__loading"
                                }`}
                            >
                                <div className="loader-container text-center">
                                    <div className="icon">
                                        <div className="sk-wave">
                                            <div className="sk-rect sk-rect1"></div>
                                            <div className="sk-rect sk-rect2"></div>
                                            <div className="sk-rect sk-rect3"></div>
                                            <div className="sk-rect sk-rect4"></div>
                                            <div className="sk-rect sk-rect5"></div>
                                        </div>
                                    </div>
                                    <div className="title">Carregando</div>
                                </div>

                                <div className="step">
                                    <ul
                                        className="nav nav-tabs nav-justified"
                                        role="tablist"
                                    >
                                        <li
                                            role="step"
                                            className={`${
                                                this.state.itemActive ===
                                                "seller"
                                                    ? "active"
                                                    : ""
                                            }`}
                                        >
                                            <a
                                                href=""
                                                onClick={(e) =>
                                                    this.handleChangePane(
                                                        e,
                                                        "seller"
                                                    )
                                                }
                                                id="step1-tab"
                                                role="tab"
                                                data-toggle="tab"
                                                aria-controls="home"
                                                aria-expanded="false"
                                            >
                                                <div className="icon fa fa-shopping-cart"></div>
                                                <div className="heading">
                                                    <div className="title">
                                                        Vendedor
                                                    </div>
                                                    <div className="description">
                                                        Informações do Vendedor
                                                        e Filial
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li
                                            role="step"
                                            className={`${
                                                this.state.itemActive ===
                                                "client"
                                                    ? "active"
                                                    : ""
                                            }`}
                                        >
                                            <a
                                                href=""
                                                onClick={(e) =>
                                                    this.handleChangePane(
                                                        e,
                                                        "client"
                                                    )
                                                }
                                                role="tab"
                                                id="step2-tab"
                                                data-toggle="tab"
                                                aria-controls="profile"
                                                aria-expanded="true"
                                            >
                                                <div className="icon fa fa-user"></div>
                                                <div className="heading">
                                                    <div className="title">
                                                        Cliente
                                                    </div>
                                                    <div className="description">
                                                        Informações sobre o
                                                        cliente
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li
                                            role="step"
                                            className={`${
                                                this.state.itemActive ===
                                                "product"
                                                    ? "active"
                                                    : ""
                                            }`}
                                        >
                                            <a
                                                href=""
                                                onClick={(e) =>
                                                    this.handleChangePane(
                                                        e,
                                                        "product"
                                                    )
                                                }
                                                role="tab"
                                                id="step3-tab"
                                                data-toggle="tab"
                                                aria-controls="profile"
                                            >
                                                <div className="icon fa fa-truck"></div>
                                                <div className="heading">
                                                    <div className="title">
                                                        Produto
                                                    </div>
                                                    <div className="description">
                                                        Dados do Produto
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li
                                            role="step"
                                            className={`${
                                                this.state.itemActive === "card"
                                                    ? "active"
                                                    : ""
                                            }`}
                                        >
                                            <a
                                                href=""
                                                onClick={(e) =>
                                                    this.handleChangePane(
                                                        e,
                                                        "card"
                                                    )
                                                }
                                                role="tab"
                                                id="step4-tab"
                                                data-toggle="tab"
                                                aria-controls="profile"
                                                aria-expanded="false"
                                            >
                                                <div className="icon fa fa-credit-card"></div>
                                                <div className="heading">
                                                    <div className="title">
                                                        Dados do Cartão
                                                    </div>
                                                    <div className="description">
                                                        Informações de valores e
                                                        envio
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                    <div className="tab-content">
                                        <div
                                            role="tabpanel"
                                            className={`tab-pane ${
                                                this.state.itemActive ===
                                                "seller"
                                                    ? "active"
                                                    : ""
                                            }`}
                                            id="seller"
                                        >
                                            <div className="section">
                                                <div className="section-title">
                                                    Vendedor
                                                </div>
                                                <div className="section-body">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="control-label">
                                                                    Nome
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    value={
                                                                        card
                                                                            .user
                                                                            .name
                                                                    }
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="control-label">
                                                                    E-mail
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    value={
                                                                        card
                                                                            .user
                                                                            .email
                                                                    }
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="control-label">
                                                                    Telefone
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    value={
                                                                        card
                                                                            .user
                                                                            .phone
                                                                    }
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="control-label">
                                                                    CPF
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    value={
                                                                        card
                                                                            .user
                                                                            .cpf
                                                                    }
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="section">
                                                <div className="section-title">
                                                    Filial
                                                </div>
                                                <div className="section-body">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="control-label">
                                                                    Código
                                                                    Filial
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    value={
                                                                        card
                                                                            .user
                                                                            .branch_selected
                                                                            .codigo_loja
                                                                    }
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="control-label">
                                                                    Cidade -
                                                                    Estado
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    value={
                                                                        card
                                                                            .user
                                                                            .branch_selected
                                                                            .cidade_estado
                                                                    }
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="control-label">
                                                                    CNPJ
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    value={
                                                                        card
                                                                            .user
                                                                            .branch_selected
                                                                            .cnpj
                                                                    }
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="control-label">
                                                                    Telefone
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    value={
                                                                        card
                                                                            .user
                                                                            .branch_selected
                                                                            .phone
                                                                    }
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            role="tabpanel"
                                            className={`tab-pane ${
                                                this.state.itemActive ===
                                                "client"
                                                    ? "active"
                                                    : ""
                                            }`}
                                            id="client"
                                        >
                                            <div className="section">
                                                <div className="section-title">
                                                    Cliente
                                                </div>
                                                <div className="section-body">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="control-label">
                                                                    Nome
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    value={
                                                                        card
                                                                            .client
                                                                            .name
                                                                    }
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="control-label">
                                                                    CPF
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    value={
                                                                        card
                                                                            .client
                                                                            .cpf
                                                                    }
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="control-label">
                                                                    Telefone
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    value={
                                                                        card
                                                                            .client
                                                                            .phone
                                                                    }
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="control-label">
                                                                    E-mail
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    value={
                                                                        card
                                                                            .client
                                                                            .email
                                                                    }
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div class="checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    id="checkbox1"
                                                                    disabled
                                                                    checked={
                                                                        card
                                                                            .client
                                                                            .phone_whatsapp
                                                                    }
                                                                />
                                                                <label for="checkbox1">
                                                                    Possui
                                                                    WhatsApp
                                                                    nesse número
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            role="tabpanel"
                                            className={`tab-pane ${
                                                this.state.itemActive ===
                                                "product"
                                                    ? "active"
                                                    : ""
                                            }`}
                                            id="product"
                                        >
                                            <div className="section">
                                                <div className="section-title">
                                                    Produto
                                                </div>
                                                <div className="section-body">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="control-label">
                                                                    Código
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    value={
                                                                        card
                                                                            .product
                                                                            .codigo
                                                                    }
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="control-label">
                                                                    Nome
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    value={
                                                                        card
                                                                            .product
                                                                            .name
                                                                    }
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="control-label">
                                                                    Preço
                                                                    Original
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    value={card.product.price.toLocaleString(
                                                                        "pt-BR",
                                                                        {
                                                                            minimumFractionDigits: 2,
                                                                            style:
                                                                                "currency",
                                                                            currency:
                                                                                "BRL",
                                                                        }
                                                                    )}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="control-label">
                                                                    Custo
                                                                    Original
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    value={card.product.cost.toLocaleString(
                                                                        "pt-BR",
                                                                        {
                                                                            minimumFractionDigits: 2,
                                                                            style:
                                                                                "currency",
                                                                            currency:
                                                                                "BRL",
                                                                        }
                                                                    )}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="control-label">
                                                                    Linha
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    value={
                                                                        card
                                                                            .product
                                                                            .line
                                                                    }
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="control-label">
                                                                    Marca
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    value={
                                                                        card
                                                                            .product
                                                                            .brand
                                                                    }
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            role="tabpanel"
                                            className={`tab-pane ${
                                                this.state.itemActive === "card"
                                                    ? "active"
                                                    : ""
                                            }`}
                                            id="card"
                                        >
                                            <div className="section">
                                                <div className="section-title">
                                                    Cartão
                                                </div>
                                                <div className="section-body">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="control-label">
                                                                    Validade do
                                                                    Cartão
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    value={moment
                                                                        .tz(
                                                                            card.validity,
                                                                            "America/Sao_Paulo"
                                                                        )
                                                                        .format(
                                                                            "DD/MM/YYYY"
                                                                        )}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="control-label">
                                                                    Envio para o
                                                                </label>
                                                                <ul className="list-unstyled list-inline">
                                                                    <li>
                                                                        <span
                                                                            className={`label label-${
                                                                                card.send_to ===
                                                                                "sms"
                                                                                    ? "success"
                                                                                    : "default"
                                                                            }`}
                                                                        >
                                                                            SMS
                                                                        </span>
                                                                    </li>
                                                                    <li>
                                                                        <span
                                                                            className={`label label-${
                                                                                card.send_to ===
                                                                                "whatsapp"
                                                                                    ? "success"
                                                                                    : "default"
                                                                            }`}
                                                                        >
                                                                            WhatsApp
                                                                        </span>
                                                                    </li>
                                                                    <li>
                                                                        <span
                                                                            className={`label label-${
                                                                                card.send_to ===
                                                                                "email"
                                                                                    ? "success"
                                                                                    : "default"
                                                                            }`}
                                                                        >
                                                                            E-mail
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        {card.sight_price ? (
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label className="control-label">
                                                                        Valor À
                                                                        Vista
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder=""
                                                                        value={card.sight_price.toLocaleString(
                                                                            "pt-BR",
                                                                            {
                                                                                minimumFractionDigits: 2,
                                                                                style:
                                                                                    "currency",
                                                                                currency:
                                                                                    "BRL",
                                                                            }
                                                                        )}
                                                                        disabled
                                                                    />
                                                                </div>
                                                            </div>
                                                        ) : null}
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="control-label">
                                                                    Valor À
                                                                    Prazo
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    value={card.total_amount.toLocaleString(
                                                                        "pt-BR",
                                                                        {
                                                                            minimumFractionDigits: 2,
                                                                            style:
                                                                                "currency",
                                                                            currency:
                                                                                "BRL",
                                                                        }
                                                                    )}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="control-label">
                                                                    Valor
                                                                    Parcela
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    value={card.price_payment.toLocaleString(
                                                                        "pt-BR",
                                                                        {
                                                                            minimumFractionDigits: 2,
                                                                            style:
                                                                                "currency",
                                                                            currency:
                                                                                "BRL",
                                                                        }
                                                                    )}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="control-label">
                                                                    Quantidade
                                                                    Parcelas
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    value={
                                                                        card.payment_x
                                                                    }
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div class="checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    id="checkbox1"
                                                                    disabled
                                                                    checked={
                                                                        card.carne
                                                                    }
                                                                />
                                                                <label for="checkbox1">
                                                                    Pagamento no
                                                                    Carne
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div class="checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    id="checkbox1"
                                                                    disabled
                                                                    checked={
                                                                        card.credit_card
                                                                    }
                                                                />
                                                                <label for="checkbox1">
                                                                    Pagamento no
                                                                    Cartão
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div class="checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    id="checkbox1"
                                                                    disabled
                                                                    checked={
                                                                        !card.entrance
                                                                    }
                                                                />
                                                                <label for="checkbox1">
                                                                    Sem entrada
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div class="checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    id="checkbox1"
                                                                    disabled
                                                                    checked={
                                                                        !card.interest
                                                                    }
                                                                />
                                                                <label for="checkbox1">
                                                                    Sem juros
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}
