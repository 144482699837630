import React, { Component } from "react";

import { Link } from "react-router-dom";

import Layout from "../../components/Layout";

import api from "../../services/api";

import Swal from "sweetalert2";

export default class BranchList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            branchs: [],
            branchsToShow: [],
            branch_search_term: "",
            loaded: false,
        };

        this.handleSearchBar = this.handleSearchBar.bind(this);
    }

    componentWillMount = async function () {
        this.loadList();
    };

    loadList = async function () {
        this.setState((prevState) => ({
            ...prevState,
            loaded: false,
        }));

        const returnBranchs = await api.get("/branch");

        this.setState((prevState) => ({
            ...prevState,
            loaded: true,
            branchs: returnBranchs.data,
            branchsToShow: returnBranchs.data,
        }));
    };

    handleSearchBar() {
        this.setState((prevState) => ({
            ...prevState,
            loaded: false,
        }));

        let originalBranchs = this.state.branchs;

        let filteredBranchs = originalBranchs.filter(
            (branch) =>
                branch.codigo_loja
                    .toLowerCase()
                    .indexOf(this.state.branch_search_term.toLowerCase()) !== -1
        );

        if (filteredBranchs.length <= 0) {
            filteredBranchs = originalBranchs.filter(
                (branch) =>
                    branch.cidade_estado
                        .toLowerCase()
                        .indexOf(
                            this.state.branch_search_term.toLowerCase()
                        ) !== -1
            );
        }

        this.setState((prevState) => ({
            ...prevState,
            branchsToShow: filteredBranchs,
            loaded: true,
        }));
    }

    handleDeleteButton = async function (branch) {
        this.setState((prevState) => ({
            ...prevState,
            loaded: false,
        }));

        const self = this;

        Swal.fire({
            title: `Tem certeza que deseja excluir a filial ${branch.codigo_loja}?`,
            text: "Depois de excluído não terá como recuperar os dados!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim, quero excluir!",
            cancelButtonText: "Cancelar",
        }).then(async function (result) {
            if (result.value) {
                const deletedBranch = await api.delete("/branch/" + branch._id);

                if (deletedBranch.status === 204) {
                    self.loadList();

                    self.setState((prevState) => ({
                        ...prevState,
                        branch_search_term: "",
                    }));

                    Swal.fire(
                        "Excluído!",
                        "A filial foi excluída com sucesso!",
                        "success"
                    );
                } else {
                    Swal.fire(
                        "Oops...",
                        "Não foi possível excluir a filial agora. Por favor contate o suporte!",
                        "error"
                    ).then(() => {
                        self.setState((prevState) => ({
                            ...prevState,
                            loaded: true,
                        }));
                    });
                }
            } else {
                self.setState((prevState) => ({
                    ...prevState,
                    loaded: true,
                    branch_search_term: "",
                }));
            }
        });
    };

    render() {
        let { branchsToShow } = this.state;

        return (
            <Layout
                menuActive="registers"
                pageTitle="Filiais"
                enableSearchBar={true}
                searchTerm={this.state.branch_search_term}
                searchOnChange={(e) =>
                    this.setState({ branch_search_term: e.target.value })
                }
                handleSearch={this.handleSearchBar}
            >
                <div className="row">
                    <div className="col-xs-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="card-title">Filiais</div>
                                <ul className="card-action">
                                    <li>
                                        <Link
                                            to="/filiais/nova"
                                            className="btn btn-sm btn-success"
                                        >
                                            Nova <i className="fa fa-plus"></i>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div
                                className={`card-body ${
                                    this.state.loaded ? "" : "__loading"
                                }`}
                            >
                                <div class="loader-container text-center">
                                    <div class="icon">
                                        <div class="sk-wave">
                                            <div class="sk-rect sk-rect1"></div>
                                            <div class="sk-rect sk-rect2"></div>
                                            <div class="sk-rect sk-rect3"></div>
                                            <div class="sk-rect sk-rect4"></div>
                                            <div class="sk-rect sk-rect5"></div>
                                        </div>
                                    </div>
                                    <div class="title">Carregando</div>
                                </div>

                                <div className="row">
                                    <div className="col-xs-12">
                                        <table className="table table-striped table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Código</th>
                                                    <th>Cidade - Estado</th>
                                                    <th>Endereço</th>
                                                    <th>
                                                        End. Completo (Maps)
                                                    </th>
                                                    <th>Telefone</th>
                                                    <th>Opções</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {branchsToShow.map(
                                                    (branch, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>
                                                                    {
                                                                        branch.codigo_loja
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        branch.cidade_estado
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {`${branch.address} - ${branch.region}`}
                                                                </td>
                                                                <td>
                                                                    {`${
                                                                        branch.addressMaps
                                                                            ? branch.addressMaps
                                                                            : "Não existe"
                                                                    } - ${
                                                                        branch.region
                                                                    }`}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        branch.phone
                                                                    }
                                                                </td>
                                                                <td>
                                                                    <div className="col-xs-12">
                                                                        <div className="col-xs-12">
                                                                            <Link
                                                                                to={`/filiais/editar/${branch._id}`}
                                                                                className="btn btn-xs btn-primary"
                                                                                style={{
                                                                                    width:
                                                                                        "100%",
                                                                                }}
                                                                            >
                                                                                Editar{" "}
                                                                                <div className="icon fa fa-pencil"></div>
                                                                            </Link>
                                                                        </div>
                                                                        <div className="col-xs-12">
                                                                            <button
                                                                                className="btn btn-xs btn-danger"
                                                                                onClick={() =>
                                                                                    this.handleDeleteButton(
                                                                                        branch
                                                                                    )
                                                                                }
                                                                                style={{
                                                                                    width:
                                                                                        "100%",
                                                                                }}
                                                                            >
                                                                                Excluir{" "}
                                                                                <div className="icon fa fa-trash-o"></div>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}
