import React, { useState, useEffect, useContext } from "react";

import { withRouter, Link, useParams } from "react-router-dom";

import Layout from "../../components/Layout";

import { apiCRMv3 } from "../../services/api";

import Swal from "sweetalert2";
import { useFormResults } from "../../hooks/form-results";

const FormResults = (props) => {
    const { formId } = useParams();
    const { month, year, changeMonth, changeYear } = useFormResults();
    const [questionsAndAnswersWithCount, setQuestionsAndAnswersWithCount] =
        useState([]);
    const [form, setForm] = useState({});
    const [loaded, setLoaded] = useState(false);

    useEffect(
        function () {
            async function loadList() {
                setLoaded(false);

                const responseGetForms = await apiCRMv3.get(
                    `/forms/questions-and-answers-with-count/${formId}?month=${month}&year=${year}`
                );

                setQuestionsAndAnswersWithCount(
                    responseGetForms.data.data.results
                );
                setForm(responseGetForms.data.data.form);
                setLoaded(true);
            }

            loadList();
        },
        [formId, month, year]
    );

    return (
        <Layout
            menuActive="forms"
            pageTitle="Resultados do Formulário"
            enableSearchBar={false}
        >
            <div className="row">
                <div className="col-xs-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">
                                Resultados do Formulário: <b>{form.name}</b>
                            </div>
                            {/* <ul className="card-action">
                                <li>
                                    <Link
                                        to="/formularios/novo"
                                        className="btn btn-sm btn-success"
                                    >
                                        Novo <i className="fa fa-plus"></i>
                                    </Link>
                                </li>
                            </ul> */}
                        </div>
                        <div
                            className={`card-body ${loaded ? "" : "__loading"}`}
                        >
                            <div className="loader-container text-center">
                                <div className="icon">
                                    <div className="sk-wave">
                                        <div className="sk-rect sk-rect1"></div>
                                        <div className="sk-rect sk-rect2"></div>
                                        <div className="sk-rect sk-rect3"></div>
                                        <div className="sk-rect sk-rect4"></div>
                                        <div className="sk-rect sk-rect5"></div>
                                    </div>
                                </div>
                                <div className="title">Carregando</div>
                            </div>

                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="section">
                                        <div className="section-title">
                                            Perguntas
                                        </div>
                                        <div className="section-body">
                                            <div className="row">
                                                <div className="col-xs-12 col-md-1">
                                                    <select
                                                        name="month"
                                                        id="month"
                                                        className="form-control"
                                                        onChange={(event) => {
                                                            const { value } =
                                                                event.target;

                                                            changeMonth(value);
                                                        }}
                                                    >
                                                        <option
                                                            value="1"
                                                            selected={
                                                                month === 1
                                                            }
                                                        >
                                                            Janeiro
                                                        </option>
                                                        <option
                                                            value="2"
                                                            selected={
                                                                month === 2
                                                            }
                                                        >
                                                            Fevereiro
                                                        </option>
                                                        <option
                                                            value="3"
                                                            selected={
                                                                month === 3
                                                            }
                                                        >
                                                            Março
                                                        </option>
                                                        <option
                                                            value="4"
                                                            selected={
                                                                month === 4
                                                            }
                                                        >
                                                            Abril
                                                        </option>
                                                        <option
                                                            value="5"
                                                            selected={
                                                                month === 5
                                                            }
                                                        >
                                                            Maio
                                                        </option>
                                                        <option
                                                            value="6"
                                                            selected={
                                                                month === 6
                                                            }
                                                        >
                                                            Junho
                                                        </option>
                                                        <option
                                                            value="7"
                                                            selected={
                                                                month === 7
                                                            }
                                                        >
                                                            Julho
                                                        </option>
                                                        <option
                                                            value="8"
                                                            selected={
                                                                month === 8
                                                            }
                                                        >
                                                            Agosto
                                                        </option>
                                                        <option
                                                            value="9"
                                                            selected={
                                                                month === 9
                                                            }
                                                        >
                                                            Setembro
                                                        </option>
                                                        <option
                                                            value="10"
                                                            selected={
                                                                month === 10
                                                            }
                                                        >
                                                            Outubro
                                                        </option>
                                                        <option
                                                            value="11"
                                                            selected={
                                                                month === 11
                                                            }
                                                        >
                                                            Novembro
                                                        </option>
                                                        <option
                                                            value="12"
                                                            selected={
                                                                month === 12
                                                            }
                                                        >
                                                            Dezembro
                                                        </option>
                                                    </select>
                                                </div>
                                                <div className="col-xs-12 col-md-1">
                                                    <select
                                                        name="year"
                                                        id="year"
                                                        className="form-control"
                                                        onChange={(event) => {
                                                            const { value } =
                                                                event.target;

                                                            changeYear(value);
                                                        }}
                                                    >
                                                        <option
                                                            value="2021"
                                                            selected={
                                                                year === 2021
                                                            }
                                                        >
                                                            2021
                                                        </option>
                                                        <option
                                                            value="2022"
                                                            selected={
                                                                year === 2022
                                                            }
                                                        >
                                                            2022
                                                        </option>
                                                        <option
                                                            value="2023"
                                                            selected={
                                                                year === 2023
                                                            }
                                                        >
                                                            2023
                                                        </option>
                                                        <option
                                                            value="2024"
                                                            selected={
                                                                year === 2024
                                                            }
                                                        >
                                                            2024
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-xs-12">
                                                    <table className="table table-striped table-bordered table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    Pergunta
                                                                </th>
                                                                <th>
                                                                    Resposta
                                                                </th>
                                                                <th>Total</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {questionsAndAnswersWithCount.map(
                                                                (
                                                                    questionAnswer,
                                                                    index
                                                                ) => {
                                                                    return (
                                                                        <tr
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            <td
                                                                                style={{
                                                                                    width: "40%",
                                                                                }}
                                                                            >
                                                                                {
                                                                                    questionAnswer.fq_title
                                                                                }
                                                                            </td>
                                                                            <td
                                                                                style={{
                                                                                    width: "40%",
                                                                                }}
                                                                            >
                                                                                {questionAnswer.fq_type ===
                                                                                "OBJECTIVE" ? (
                                                                                    questionAnswer.fqa_title
                                                                                ) : (
                                                                                    <div className="col-xs-12">
                                                                                        <Link
                                                                                            to={`/formularios/resultados-por-estado/${questionAnswer.fq_id}/true`}
                                                                                            className="btn btn-xs btn-primary"
                                                                                            style={{
                                                                                                width: "100%",
                                                                                            }}
                                                                                        >
                                                                                            Ver
                                                                                            Respostas{" "}
                                                                                            <div className="icon fa fa-list-alt"></div>
                                                                                        </Link>
                                                                                    </div>
                                                                                )}
                                                                            </td>
                                                                            <td
                                                                                style={{
                                                                                    width: "20%",
                                                                                }}
                                                                            >
                                                                                {questionAnswer.fq_type ===
                                                                                "OBJECTIVE" ? (
                                                                                    <Link
                                                                                        to={`/formularios/resultados-por-estado/${questionAnswer.fqa_id}/false`}
                                                                                        className="btn btn-xs btn-primary"
                                                                                        style={{
                                                                                            width: "100%",
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            questionAnswer.total_answers
                                                                                        }
                                                                                    </Link>
                                                                                ) : (
                                                                                    "Resposta Discursiva"
                                                                                )}
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                }
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default withRouter(FormResults);
