import React, { useState, useRef, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";

import Layout from "../../components/Layout";

import api from "../../services/api";

import Swal from "sweetalert2";

const DirectMailTabloidNew = (props) => {
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState({});
    const [name, setName] = useState("");
    const [pageLinks, setPageLinks] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [positionX, setPositionX] = useState(0);
    const [positionY, setPositionY] = useState(0);
    const [url, setURL] = useState("");
    const coverFileRef = useRef(null);
    const pageOneFileRef = useRef(null);
    const pageTwoFileRef = useRef(null);
    const pageThreeFileRef = useRef(null);
    const pageFourFileRef = useRef(null);
    const pageFiveFileRef = useRef(null);
    const pageSixFileRef = useRef(null);
    const pageSevenFileRef = useRef(null);
    const pageEightFileRef = useRef(null);
    const pageNineFileRef = useRef(null);
    const pageTenFileRef = useRef(null);
    const pageElevenFileRef = useRef(null);
    const pageTwelveFileRef = useRef(null);
    const pageThirteenFileRef = useRef(null);
    const pageFourteenFileRef = useRef(null);
    const pageFiveteenFileRef = useRef(null);
    const pageSixteenFileRef = useRef(null);
    const pageSeventeenFileRef = useRef(null);
    const pageEighteenFileRef = useRef(null);
    const pageNineteenFileRef = useRef(null);
    const pageTwentyFileRef = useRef(null);

    useEffect(() => {
        setLoaded(true);
    }, []);

    function handleCancelButton(event) {
        if (name !== "") {
            Swal.fire({
                title: "Tem certeza que deseja cancelar?",
                text: "Cancelar fará você perder todas as modificações feitas até o momento!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sim, quero cancelar!",
                cancelButtonText: "Cancelar",
            }).then((result) => {
                if (result.value) {
                    Swal.fire(
                        "Cancelado!",
                        "Você irá voltar para a tela de listagem.",
                        "success"
                    );

                    props.history.push("/mala-direta-tabloides");
                }
            });
        } else {
            props.history.push("/mala-direta-tabloides");
        }
    }

    async function handleSubmitForm(event) {
        event.preventDefault();

        setLoaded(false);

        if (name === "" || !name) {
            Swal.fire({
                type: "error",
                title: "Oops...",
                text: "O nome deve ser preenchido",
            });
            setLoaded(true);
            return;
        }

        if (!coverFileRef.current.files[0]) {
            Swal.fire({
                type: "error",
                title: "Oops...",
                text: "É necessário enviar a capa",
            });
            setLoaded(true);
            return;
        }

        if (!pageOneFileRef.current.files[0]) {
            Swal.fire({
                type: "error",
                title: "Oops...",
                text: "É necessário enviar a primeira página",
            });
            setLoaded(true);
            return;
        }

        try {
            const formData = new FormData();
            formData.append("name", name);
            formData.append("cover-file", coverFileRef.current.files[0]);
            formData.append("page-one-file", pageOneFileRef.current.files[0]);
            if (pageTwoFileRef.current.files[0]) {
                formData.append(
                    "page-two-file",
                    pageTwoFileRef.current.files[0]
                );
            }
            if (pageThreeFileRef.current.files[0]) {
                formData.append(
                    "page-three-file",
                    pageThreeFileRef.current.files[0]
                );
            }
            if (pageFourFileRef.current.files[0]) {
                formData.append(
                    "page-four-file",
                    pageFourFileRef.current.files[0]
                );
            }
            if (pageFiveFileRef.current.files[0]) {
                formData.append(
                    "page-five-file",
                    pageFiveFileRef.current.files[0]
                );
            }
            if (pageSixFileRef.current.files[0]) {
                formData.append(
                    "page-six-file",
                    pageSixFileRef.current.files[0]
                );
            }
            if (pageSevenFileRef.current.files[0]) {
                formData.append(
                    "page-seven-file",
                    pageSevenFileRef.current.files[0]
                );
            }
            if (pageEightFileRef.current.files[0]) {
                formData.append(
                    "page-eight-file",
                    pageEightFileRef.current.files[0]
                );
            }
            if (pageNineFileRef.current.files[0]) {
                formData.append(
                    "page-nine-file",
                    pageNineFileRef.current.files[0]
                );
            }
            if (pageTenFileRef.current.files[0]) {
                formData.append(
                    "page-ten-file",
                    pageTenFileRef.current.files[0]
                );
            }
            if (pageElevenFileRef.current.files[0]) {
                formData.append(
                    "page-eleven-file",
                    pageElevenFileRef.current.files[0]
                );
            }
            if (pageTwelveFileRef.current.files[0]) {
                formData.append(
                    "page-twelve-file",
                    pageTwelveFileRef.current.files[0]
                );
            }
            if (pageThirteenFileRef.current.files[0]) {
                formData.append(
                    "page-thirteen-file",
                    pageThirteenFileRef.current.files[0]
                );
            }
            if (pageFourteenFileRef.current.files[0]) {
                formData.append(
                    "page-fourteen-file",
                    pageFourteenFileRef.current.files[0]
                );
            }
            if (pageFiveteenFileRef.current.files[0]) {
                formData.append(
                    "page-fiveteen-file",
                    pageFiveteenFileRef.current.files[0]
                );
            }
            if (pageSixteenFileRef.current.files[0]) {
                formData.append(
                    "page-sixteen-file",
                    pageSixteenFileRef.current.files[0]
                );
            }
            if (pageSeventeenFileRef.current.files[0]) {
                formData.append(
                    "page-seventeen-file",
                    pageSeventeenFileRef.current.files[0]
                );
            }
            if (pageEighteenFileRef.current.files[0]) {
                formData.append(
                    "page-eighteen-file",
                    pageEighteenFileRef.current.files[0]
                );
            }
            if (pageNineteenFileRef.current.files[0]) {
                formData.append(
                    "page-nineteen-file",
                    pageNineteenFileRef.current.files[0]
                );
            }
            if (pageTwentyFileRef.current.files[0]) {
                formData.append(
                    "page-twenty-file",
                    pageTwentyFileRef.current.files[0]
                );
            }

            const createdDirectMailTabloid = await api.post(
                "/direct-mail-tabloid",
                formData
            );

            setLoaded(true);
            if (createdDirectMailTabloid.status === 201) {
                if (pageLinks.length > 0) {
                    const createdPageLinksDirectMailTabloid = await api.patch(
                        `/direct-mail-tabloid/${createdDirectMailTabloid.data.data.newDirectMailTabloid._id}`,
                        {
                            pageLinks: pageLinks,
                        }
                    );

                    if (createdPageLinksDirectMailTabloid.status === 201) {
                        setLoaded(true);

                        Swal.fire({
                            type: "success",
                            title: "Salvo!",
                            text: "A mala direta tabloide foi salva com sucesso!",
                        }).then(() =>
                            props.history.push("/mala-direta-tabloides")
                        );
                    }
                } else {
                    setLoaded(true);

                    Swal.fire({
                        type: "success",
                        title: "Salvo!",
                        text: "A mala direta tabloide foi salva com sucesso!",
                    }).then(() => props.history.push("/mala-direta-tabloides"));
                }
            } else {
                Swal.fire({
                    type: "error",
                    title: "Oops...",
                    text: "Tivemos um problema ao salvar essa nova mala direta tabloide. Contate o suporte!",
                });

                setLoaded(true);
            }
        } catch (e) {
            Swal.fire({
                type: "error",
                title: "Oops...",
                text: "Tivemos um problema ao salvar essa nova mala direta tabloide. Contate o suporte!",
            });

            setLoaded(true);
        }
    }

    function handleAddPageLink(e) {
        e.preventDefault();
        if (pageNumber === 0 || !pageNumber) {
            Swal.fire({
                type: "error",
                title: "Oops...",
                text: "A página não pode ser 0 (zero).",
            });
            return;
        }
        if (width === 0 || !width) {
            Swal.fire({
                type: "error",
                title: "Oops...",
                text: "A largura não pode ser 0 (zero).",
            });
            return;
        }
        if (height === 0 || !height) {
            Swal.fire({
                type: "error",
                title: "Oops...",
                text: "A altura não pode ser 0 (zero).",
            });
            return;
        }
        if (url === "" || !url) {
            Swal.fire({
                type: "error",
                title: "Oops...",
                text: "A URL não pode ser vazia.",
            });
            return;
        }

        const newPageLinks = pageLinks.concat([
            {
                page: pageNumber,
                positionX: positionX,
                positionY: positionY,
                width: width,
                height: height,
                url: url,
            },
        ]);

        setPageLinks(newPageLinks);
        setPageNumber(0);
        setWidth(0);
        setHeight(0);
        setPositionX(0);
        setPositionY(0);
        setURL("");
    }

    function handleRemovePageLink(e, indexPageLink) {
        e.preventDefault();

        Swal.fire({
            title: "Tem certeza que deseja remover esse link?",
            text: "Cuidado ao remover os links!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim, quero remover!",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.value) {
                Swal.fire(
                    "Removido!",
                    "Esse link foi removido. Continue cadastrando sua Mala Direta Tabloide!",
                    "success"
                );

                const copyPageLinks = [...pageLinks];
                copyPageLinks.splice(indexPageLink, 1);
                setPageLinks(copyPageLinks);
            }
        });
    }

    return (
        <Layout
            menuActive="direct-mail-tabloid"
            pageTitle="Nova Mala Direta Tabloide"
        >
            <div className="row">
                <div className="col-xs-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">
                                Nova Mala Direta Tabloide
                            </div>
                        </div>
                        <div
                            className={`card-body ${loaded ? "" : "__loading"}`}
                        >
                            <div className="loader-container text-center">
                                <div className="icon">
                                    <div className="sk-wave">
                                        <div className="sk-rect sk-rect1"></div>
                                        <div className="sk-rect sk-rect2"></div>
                                        <div className="sk-rect sk-rect3"></div>
                                        <div className="sk-rect sk-rect4"></div>
                                        <div className="sk-rect sk-rect5"></div>
                                    </div>
                                </div>
                                <div className="title">Carregando</div>
                            </div>

                            <form
                                className="form form-horizontal"
                                onSubmit={handleSubmitForm}
                            >
                                <div className="section">
                                    <div className="section-title">Imagens</div>
                                    <div className="section-body">
                                        <div
                                            className={
                                                "form-group " +
                                                (error.name ? "has-error" : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Nome
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder=""
                                                    value={name}
                                                    onChange={(event) => {
                                                        setName(
                                                            event.target.value
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                "form-group " +
                                                (error.file ? "has-error" : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Capa
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    placeholder=""
                                                    ref={coverFileRef}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                "form-group " +
                                                (error.file ? "has-error" : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Página 1 (obrigatório)
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    placeholder=""
                                                    ref={pageOneFileRef}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                "form-group " +
                                                (error.file ? "has-error" : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Página 2 (opcional)
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    placeholder=""
                                                    ref={pageTwoFileRef}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                "form-group " +
                                                (error.file ? "has-error" : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Página 3 (opcional)
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    placeholder=""
                                                    ref={pageThreeFileRef}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                "form-group " +
                                                (error.file ? "has-error" : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Página 4 (opcional)
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    placeholder=""
                                                    ref={pageFourFileRef}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                "form-group " +
                                                (error.file ? "has-error" : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Página 5 (opcional)
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    placeholder=""
                                                    ref={pageFiveFileRef}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                "form-group " +
                                                (error.file ? "has-error" : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Página 6 (opcional)
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    placeholder=""
                                                    ref={pageSixFileRef}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                "form-group " +
                                                (error.file ? "has-error" : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Página 7 (opcional)
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    placeholder=""
                                                    ref={pageSevenFileRef}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                "form-group " +
                                                (error.file ? "has-error" : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Página 8 (opcional)
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    placeholder=""
                                                    ref={pageEightFileRef}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                "form-group " +
                                                (error.file ? "has-error" : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Página 9 (opcional)
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    placeholder=""
                                                    ref={pageNineFileRef}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                "form-group " +
                                                (error.file ? "has-error" : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Página 10 (opcional)
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    placeholder=""
                                                    ref={pageTenFileRef}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                "form-group " +
                                                (error.file ? "has-error" : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Página 11 (opcional)
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    placeholder=""
                                                    ref={pageElevenFileRef}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                "form-group " +
                                                (error.file ? "has-error" : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Página 12 (opcional)
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    placeholder=""
                                                    ref={pageTwelveFileRef}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                "form-group " +
                                                (error.file ? "has-error" : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Página 13 (opcional)
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    placeholder=""
                                                    ref={pageThirteenFileRef}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                "form-group " +
                                                (error.file ? "has-error" : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Página 14 (opcional)
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    placeholder=""
                                                    ref={pageFourteenFileRef}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                "form-group " +
                                                (error.file ? "has-error" : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Página 15 (opcional)
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    placeholder=""
                                                    ref={pageFiveteenFileRef}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                "form-group " +
                                                (error.file ? "has-error" : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Página 16 (opcional)
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    placeholder=""
                                                    ref={pageSixteenFileRef}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                "form-group " +
                                                (error.file ? "has-error" : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Página 17 (opcional)
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    placeholder=""
                                                    ref={pageSeventeenFileRef}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                "form-group " +
                                                (error.file ? "has-error" : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Página 18 (opcional)
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    placeholder=""
                                                    ref={pageEighteenFileRef}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                "form-group " +
                                                (error.file ? "has-error" : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Página 19 (opcional)
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    placeholder=""
                                                    ref={pageNineteenFileRef}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                "form-group " +
                                                (error.file ? "has-error" : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Página 20 (opcional)
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    placeholder=""
                                                    ref={pageTwentyFileRef}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section">
                                    <div className="section-title">Links</div>
                                    <div className="section-body">
                                        <div
                                            className={
                                                "form-group " +
                                                (error.width ? "has-error" : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Página
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    value={pageNumber}
                                                    onChange={(event) => {
                                                        setPageNumber(
                                                            event.target.value
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                "form-group " +
                                                (error.width ? "has-error" : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Largura da área de click
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    value={width}
                                                    onChange={(event) => {
                                                        setWidth(
                                                            event.target.value
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                "form-group " +
                                                (error.height
                                                    ? "has-error"
                                                    : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Altura da área de click
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    value={height}
                                                    onChange={(event) => {
                                                        setHeight(
                                                            event.target.value
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                "form-group " +
                                                (error.positionX
                                                    ? "has-error"
                                                    : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Posição X da área de click
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    value={positionX}
                                                    onChange={(event) => {
                                                        setPositionX(
                                                            event.target.value
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                "form-group " +
                                                (error.positionY
                                                    ? "has-error"
                                                    : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Posição Y da área de click
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    value={positionY}
                                                    onChange={(event) => {
                                                        setPositionY(
                                                            event.target.value
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                "form-group " +
                                                (error.url ? "has-error" : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                URL
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={url}
                                                    onChange={(event) => {
                                                        setURL(
                                                            event.target.value
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <button
                                            className="btn btn-primary"
                                            onClick={(e) =>
                                                handleAddPageLink(e)
                                            }
                                        >
                                            Adicionar Link
                                        </button>
                                        <table className="table table-striped table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Página</th>
                                                    <th>Posição X</th>
                                                    <th>Posição Y</th>
                                                    <th>Largura</th>
                                                    <th>Altura</th>
                                                    <th>URL</th>
                                                    <th>Opções</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {pageLinks.map(
                                                    (pageLink, index) => (
                                                        <tr key={index}>
                                                            <td>
                                                                {pageLink.page}
                                                            </td>
                                                            <td>
                                                                {
                                                                    pageLink.positionX
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    pageLink.positionY
                                                                }
                                                            </td>
                                                            <td>
                                                                {pageLink.width}
                                                            </td>
                                                            <td>
                                                                {
                                                                    pageLink.height
                                                                }
                                                            </td>
                                                            <td>
                                                                {pageLink.url}
                                                            </td>
                                                            <td>
                                                                <div className="col-xs-12">
                                                                    <div className="col-xs-12">
                                                                        <button
                                                                            className="btn btn-xs btn-danger"
                                                                            style={{
                                                                                width: "100%",
                                                                            }}
                                                                            onClick={(
                                                                                e
                                                                            ) =>
                                                                                handleRemovePageLink(
                                                                                    e,
                                                                                    index
                                                                                )
                                                                            }
                                                                        >
                                                                            Remove
                                                                            <div className="icon fa fa-trash-o"></div>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="form-footer">
                                    <div className="form-group">
                                        <div className="col-md-9 col-md-offset-3">
                                            <button
                                                type="submit"
                                                className="btn btn-success"
                                            >
                                                Salvar
                                            </button>
                                            <button
                                                type="button"
                                                onClick={handleCancelButton}
                                                className="btn btn-default"
                                            >
                                                Cancelar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default withRouter(DirectMailTabloidNew);
