import React, { useState, useRef, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";

import Layout from "../../components/Layout";

import api from "../../services/api";

import Swal from "sweetalert2";

const LearnMoreEditWithUpload = (props) => {
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState({});
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [formUrl, setFormUrl] = useState("");
    const [category, setCategory] = useState("");
    const coverImageFileRef = useRef(null);
    const videoFileRef = useRef(null);

    useEffect(() => {
        async function load() {
            const { learnMoreId } = props.match.params;
            const learnMore = await api.get(`/learn-more/${learnMoreId}`);
            setTitle(learnMore.data.data.learnMore.title);
            setDescription(learnMore.data.data.learnMore.description);
            setFormUrl(learnMore.data.data.learnMore.formUrl);
            setCategory(learnMore.data.data.learnMore.category);
            setLoaded(true);
        }
        load();
    }, []);

    function handleCancelButton(event) {
        if (title !== "") {
            Swal.fire({
                title: "Tem certeza que deseja cancelar?",
                text: "Cancelar fará você perder todas as modificações feitas até o momento!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sim, quero cancelar!",
                cancelButtonText: "Cancelar",
            }).then((result) => {
                if (result.value) {
                    Swal.fire(
                        "Cancelado!",
                        "Você irá voltar para a tela de listagem.",
                        "success"
                    );

                    props.history.push("/aprenda-mais");
                }
            });
        } else {
            props.history.push("/aprenda-mais");
        }
    }

    async function handleSubmitForm(event) {
        event.preventDefault();
        setLoaded(false);
        try {
            const { learnMoreId } = props.match.params;
            const formData = new FormData();
            formData.append("title", title);
            formData.append("description", description);
            formData.append("formUrl", formUrl);
            formData.append("category", category);
            formData.append(
                "cover_image_learn_more",
                coverImageFileRef.current.files[0]
            );
            formData.append("video_learn_more", videoFileRef.current.files[0]);
            const updatedLearnMore = await api.put(
                `/learn-more/${learnMoreId}/with-upload`,
                formData
            );
            setLoaded(true);
            if (updatedLearnMore.status === 200) {
                setLoaded(true);
                Swal.fire({
                    type: "success",
                    title: "Salvo!",
                    text: "O vídeo do aprenda mais foi atualizado com sucesso!",
                }).then(() => props.history.push("/aprenda-mais"));
            } else {
                Swal.fire({
                    type: "error",
                    title: "Oops...",
                    text: "Tivemos um problema ao atualizar esse vídeo do aprenda mais. Contate o suporte!",
                });
                setLoaded(true);
            }
        } catch (e) {
            Swal.fire({
                type: "error",
                title: "Oops...",
                text: "Tivemos um problema ao atualizar esse vídeo do aprenda mais. Contate o suporte!",
            });
            setLoaded(true);
        }
    }

    return (
        <Layout
            menuActive="registers"
            pageTitle="Aprenda Mais - Editar Dados do Vídeo"
        >
            <div className="row">
                <div className="col-xs-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">
                                Aprenda Mais - Editar Dados do Vídeo
                            </div>
                        </div>
                        <div
                            className={`card-body ${loaded ? "" : "__loading"}`}
                        >
                            <div className="loader-container text-center">
                                <div className="icon">
                                    <div className="sk-wave">
                                        <div className="sk-rect sk-rect1"></div>
                                        <div className="sk-rect sk-rect2"></div>
                                        <div className="sk-rect sk-rect3"></div>
                                        <div className="sk-rect sk-rect4"></div>
                                        <div className="sk-rect sk-rect5"></div>
                                    </div>
                                </div>
                                <div className="title">Carregando</div>
                            </div>

                            <form
                                className="form form-horizontal"
                                onSubmit={handleSubmitForm}
                            >
                                <div className="section">
                                    <div className="section-title">
                                        Informações Básicas
                                    </div>
                                    <div className="section-body">
                                        <div
                                            className={
                                                "form-group " +
                                                (error.name ? "has-error" : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Título
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder=""
                                                    value={title}
                                                    onChange={(e) =>
                                                        setTitle(e.target.value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                "form-group " +
                                                (error.name ? "has-error" : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Breve Descrição
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder=""
                                                    value={description}
                                                    onChange={(e) =>
                                                        setDescription(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                "form-group " +
                                                (error.name ? "has-error" : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                URL do Formulário de Pesquisa
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder=""
                                                    value={formUrl}
                                                    onChange={(e) =>
                                                        setFormUrl(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                "form-group " +
                                                (error.name ? "has-error" : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Categoria do Vídeo
                                            </label>
                                            <div className="col-md-9">
                                                <select
                                                    name="category"
                                                    id="category"
                                                    className="form-control"
                                                    onChange={(event) => {
                                                        const { value } =
                                                            event.target;

                                                        setCategory(value);
                                                    }}
                                                >
                                                    <option
                                                        value="produtos_mix"
                                                        selected={
                                                            category ===
                                                            "produtos_mix"
                                                        }
                                                    >
                                                        Produtos do mix
                                                    </option>
                                                    <option
                                                        value="lancamentos"
                                                        selected={
                                                            category ===
                                                            "lancamentos"
                                                        }
                                                    >
                                                        Lançamentos
                                                    </option>
                                                    <option
                                                        value="funcionalidades"
                                                        selected={
                                                            category ===
                                                            "funcionalidades"
                                                        }
                                                    >
                                                        Funcionalidades
                                                    </option>
                                                    <option
                                                        value="tecnicas_venda"
                                                        selected={
                                                            category ===
                                                            "tecnicas_venda"
                                                        }
                                                    >
                                                        Técnicas de venda
                                                    </option>
                                                    <option
                                                        value="diversos"
                                                        selected={
                                                            category ===
                                                            "diversos"
                                                        }
                                                    >
                                                        Diversos
                                                    </option>
                                                    <option
                                                        value="gerentes"
                                                        selected={
                                                            category ===
                                                            "gerentes"
                                                        }
                                                    >
                                                        Gerentes
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <br />
                                        <div
                                            className={
                                                "form-group " +
                                                (error.file ? "has-error" : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Capa do Vídeo
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    placeholder=""
                                                    ref={coverImageFileRef}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                "form-group " +
                                                (error.file ? "has-error" : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                Vídeo
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    placeholder=""
                                                    ref={videoFileRef}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-footer">
                                    <div className="form-group">
                                        <div className="col-md-9 col-md-offset-3">
                                            <button
                                                type="submit"
                                                className="btn btn-primary"
                                            >
                                                Salvar
                                            </button>
                                            <button
                                                type="button"
                                                onClick={handleCancelButton}
                                                className="btn btn-default"
                                            >
                                                Cancelar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default withRouter(LearnMoreEditWithUpload);
