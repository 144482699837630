import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import { isAuthenticated } from "./services/auth";

import Dashboard from "./screens/Dashboard";
import Login from "./screens/Login";
import Error404 from "./screens/Error404";

// Products

import ProductList from "./screens/ProductList";
import ProductNew from "./screens/ProductNew";
import ProductEdit from "./screens/ProductEdit";

// Branch

import BranchList from "./screens/BranchList";
import BranchNew from "./screens/BranchNew";
import BranchEdit from "./screens/BranchEdit";
import BranchNotification from "./screens/BranchNotification";
import StateNotification from "./screens/StateNotification";

// Dashboard

import CardsToday from "./screens/CardsToday";
import DistinctProductsToday from "./screens/DistinctProductsToday";

// Card

import CardDetail from "./screens/CardDetail";
import NewCard from "./screens/NewCard";

// ProductsOffer

import ProductOfferList from "./screens/ProductOfferList";
import ProductOfferListChildrens from "./screens/ProductOfferListChildrens";
import ProductOfferNew from "./screens/ProductOfferNew";

// Clients

import ClientsList from "./screens/ClientsList";

// CPFs Autorizados

import CPFAuthorizedList from "./screens/CPFAuthorizedList";
import CPFAuthorizedNew from "./screens/CPFAuthorizedNew";

// Relatórios

import ReportByDate from "./screens/ReportByDate";
import ReportAllUsers from "./screens/ReportAllUsers";
import ReportRankingProducts from "./screens/ReportRankingProducts";
import ReportClientsRanking from "./screens/ReportClientsRanking";
import ReportAllSentByDate from "./screens/ReportAllSentByDate";

// Usuários

import UserChangePassword from "./screens/UserChangePassword";

// Campanhas

import CampaignList from "./screens/CampaignList";
import CampaignNew from "./screens/CampaignNew";

// Anúncios

import AdList from "./screens/AdList";
import AdNew from "./screens/AdNew";

// Posts

import PostList from "./screens/PostList";
import PostNew from "./screens/PostNew";

// Branch Tabloid
import BranchTabloidList from "./screens/BranchTabloidList";
import BranchTabloidNew from "./screens/BranchTabloidNew";

// Direct Mail

import DirectMailList from "./screens/DirectMailList";
import DirectMailNew from "./screens/DirectMailNew";

// Forms

import FormList from "./screens/FormList";
import FormNew from "./screens/FormNew";
import FormResults from "./screens/FormResults";
import FormResultsByState from "./screens/FormResultsByState";
import FormResultsByBranch from "./screens/FormResultsByBranch";
import FormResultsByClient from "./screens/FormResultsByClient";

// Direct Mail Tabloid

import DirectMailTabloidList from "./screens/DirectMailTabloidList";
import DirectMailTabloidNew from "./screens/DirectMailTabloidNew";

// Background Music

import BackgroundMusicList from "./screens/BackgroundMusicList";
import BackgroundMusicNew from "./screens/BackgroundMusicNew";

// Campaign Cover

import CampaignCoverList from "./screens/CampaignCoverList";
import CampaignCoverNew from "./screens/CampaignCoverNew";

// Vignette

import VignetteList from "./screens/VignetteList";
import VignetteNew from "./screens/VignetteNew";

// User

import ActivateUser from "./screens/ActivateUser";

// Tabloid Project
import TabloidProjectList from "./screens/TabloidProjectList";
import TabloidProjectNewEdit from "./screens/TabloidProjectNewEdit";

// Square Art Campaign

import SquareArtCampaignList from "./screens/SquareArtCampaignList";
import SquareArtCampaignNew from "./screens/SquareArtCampaignNew";
import SquareArtCampaignEdit from "./screens/SquareArtCampaignEdit";

// Square Art Ads

import SquareArtAdsList from "./screens/SquareArtAdsList";
import SquareArtAdsNew from "./screens/SquareArtAdsNew";
import SquareArtAdsEdit from "./screens/SquareArtAdsEdit";
import ReportSentsSale from "./screens/ReportSentsSale";

// Learn More

import LearnMoreList from "./screens/LearnMoreList";
import LearnMoreNew from "./screens/LearnMoreNew";
import LearnMoreEdit from "./screens/LearnMoreEdit";
import LearnMoreEditWithUpload from "./screens/LearnMoreEditWithUpload";
import ClientListDirectMailTabloidOrProductList from "./screens/ClientListDirectMailTabloidOrProductList";
import ClientListDirectMailTabloidOrProductNew from "./screens/ClientListDirectMailTabloidOrProductNew";
import importPricePerBranch from "./screens/importPricePerBranch";
import DirectMailCRMList from "./screens/DirectMailCRMList";
import DirectMailCRMNew from "./screens/DirectMailCRMNew";
import { CurrentOfferList } from "./screens/CurrentOfferList";
import { CurrentOfferNew } from "./screens/CurrentOfferNew";
import ReportDebtNegotiation from "./screens/ReportDebtNegotiation";
import { CurrentPromotionList } from "./screens/CurrentPromotionList";
import { CurrentPromotionNew } from "./screens/CurrentPromotionNew";
import { CurrentPromotionEdit } from "./screens/CurrentPromotionEdit";

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            isAuthenticated() ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{ pathname: "/", state: { from: props.location } }}
                />
            )
        }
    />
);

const Routes = () => (
    <BrowserRouter>
        <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/" component={Login} />
            {/* Dashboard */}
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <PrivateRoute
                exact
                path="/dashboard/cartoes/hoje"
                component={CardsToday}
            />
            <PrivateRoute
                exact
                path="/dashboard/cartoes/detalhe"
                component={CardDetail}
            />
            <PrivateRoute
                exact
                path="/dashboard/produtos/hoje"
                component={DistinctProductsToday}
            />
            {/* Rotas produtos */}
            <PrivateRoute exact path="/produtos" component={ProductList} />
            <PrivateRoute exact path="/produtos/novo" component={ProductNew} />
            <PrivateRoute
                exact
                path="/produtos/editar/:id"
                component={ProductEdit}
            />
            {/* Rotas Filiais */}
            <PrivateRoute exact path="/filiais" component={BranchList} />
            <PrivateRoute exact path="/filiais/nova" component={BranchNew} />
            <PrivateRoute
                exact
                path="/filiais/editar/:id"
                component={BranchEdit}
            />
            <PrivateRoute
                exact
                path="/notificar-filial"
                component={BranchNotification}
            />
            <PrivateRoute
                exact
                path="/notificar-estado"
                component={StateNotification}
            />
            {/* Cartão */}
            <PrivateRoute exact path="/novo-cartao" component={NewCard} />
            {/* Produtos Oferta */}
            <PrivateRoute
                exact
                path="/produtos-oferta"
                component={ProductOfferList}
            />
            <PrivateRoute
                exact
                path="/produtos-oferta-filial/:id"
                component={ProductOfferListChildrens}
            />
            <PrivateRoute
                exact
                path="/produtos-oferta-nova"
                component={ProductOfferNew}
            />
            {/* Clientes */}
            <PrivateRoute exact path="/clientes/hoje" component={ClientsList} />
            {/* CPFs Autorizados */}
            <PrivateRoute
                exact
                path="/cpfs-autorizado"
                component={CPFAuthorizedList}
            />
            <PrivateRoute
                exact
                path="/cpfs-autorizado/novo"
                component={CPFAuthorizedNew}
            />
            {/* Relatórios */}
            <PrivateRoute
                exact
                path="/report-by-date"
                component={ReportByDate}
            />
            <PrivateRoute
                exact
                path="/report-all-users"
                component={ReportAllUsers}
            />
            <PrivateRoute
                exact
                path="/report-ranking-products"
                component={ReportRankingProducts}
            />
            <PrivateRoute
                exact
                path="/report-sents-sales"
                component={ReportSentsSale}
            />
            <PrivateRoute
                exact
                path="/report-negotiations"
                component={ReportDebtNegotiation}
            />
            <PrivateRoute
                exact
                path="/report-clients-ranking"
                component={ReportClientsRanking}
            />
            <PrivateRoute
                exact
                path="/report-all-sent-by-date"
                component={ReportAllSentByDate}
            />
            {/* User */}
            <PrivateRoute
                exact
                path="/user-change-password"
                component={UserChangePassword}
            />
            <PrivateRoute
                exact
                path="/ativar-usuario-por-cpf"
                component={ActivateUser}
            />
            {/* Campanhas */}
            <PrivateRoute exact path="/campanhas" component={CampaignList} />
            <PrivateRoute
                exact
                path="/campanhas/nova"
                component={CampaignNew}
            />
            {/* Anúncios */}
            <PrivateRoute exact path="/anuncios" component={AdList} />
            <PrivateRoute exact path="/anuncios/novo" component={AdNew} />
            {/* Posts */}
            <PrivateRoute exact path="/posts" component={PostList} />
            <PrivateRoute exact path="/posts/novo" component={PostNew} />
            {/* Branches Tabloid */}
            <PrivateRoute
                exact
                path="/contecomigo"
                component={BranchTabloidList}
            />
            <PrivateRoute
                exact
                path="/contecomigo/novo"
                component={BranchTabloidNew}
            />
            {/* Direct Mail */}
            <PrivateRoute
                exact
                path="/malas-diretas"
                component={DirectMailList}
            />
            <PrivateRoute
                exact
                path="/malas-diretas/nova"
                component={DirectMailNew}
            />
            {/* Form */}
            <PrivateRoute exact path="/formularios" component={FormList} />
            <PrivateRoute exact path="/formularios/novo" component={FormNew} />
            <PrivateRoute
                exact
                path="/formularios/resultados/:formId"
                component={FormResults}
            />
            <PrivateRoute
                exact
                path="/formularios/resultados-por-estado/:formQuestionAnswerId/:isFormQuestion"
                component={FormResultsByState}
            />
            <PrivateRoute
                exact
                path="/formularios/resultados-por-filial/:formQuestionAnswerId/:stateId/:isFormQuestion"
                component={FormResultsByBranch}
            />
            <PrivateRoute
                exact
                path="/formularios/resultados-clientes-por-filial/:formQuestionAnswerId/:branchId/:isFormQuestion"
                component={FormResultsByClient}
            />
            {/* Direct Mail Tabloid */}
            <PrivateRoute
                exact
                path="/mala-direta-tabloides"
                component={DirectMailTabloidList}
            />
            <PrivateRoute
                exact
                path="/mala-direta-tabloides/nova"
                component={DirectMailTabloidNew}
            />
            {/* Músicas de Fundo */}
            <PrivateRoute
                exact
                path="/musicas-fundo"
                component={BackgroundMusicList}
            />
            <PrivateRoute
                exact
                path="/musicas-fundo/nova"
                component={BackgroundMusicNew}
            />
            {/* Capas das Campanhas */}
            <PrivateRoute
                exact
                path="/capas-campanhas"
                component={CampaignCoverList}
            />
            <PrivateRoute
                exact
                path="/capas-campanhas/nova"
                component={CampaignCoverNew}
            />
            {/* Vinhetas */}
            <PrivateRoute exact path="/vinhetas" component={VignetteList} />
            <PrivateRoute exact path="/vinhetas/nova" component={VignetteNew} />
            {/* Projetos de Tabloide */}
            <PrivateRoute
                exact
                path="/projetos-tabloide"
                component={TabloidProjectList}
            />
            <PrivateRoute
                exact
                path="/projetos-tabloide/novo"
                component={TabloidProjectNewEdit}
            />
            <PrivateRoute
                exact
                path="/projetos-tabloide/editar/:tabloidProjectId"
                component={TabloidProjectNewEdit}
            />
            {/* Arte Quadrada */}
            <PrivateRoute
                exact
                path="/arte-quadrada-campanhas"
                component={SquareArtCampaignList}
            />
            <PrivateRoute
                exact
                path="/arte-quadrada-campanhas/nova"
                component={SquareArtCampaignNew}
            />
            <PrivateRoute
                exact
                path="/arte-quadrada-campanhas/editar/:squareArtCampaignId"
                component={SquareArtCampaignEdit}
            />
            {/* Arte Quadrada Anuncios */}
            <PrivateRoute
                exact
                path="/arte-quadrada-anuncios"
                component={SquareArtAdsList}
            />
            <PrivateRoute
                exact
                path="/arte-quadrada-anuncios/novo"
                component={SquareArtAdsNew}
            />
            <PrivateRoute
                exact
                path="/arte-quadrada-anuncios/editar/:squareArtAdsId"
                component={SquareArtAdsEdit}
            />
            {/* Learn More */}
            <PrivateRoute
                exact
                path="/aprenda-mais"
                component={LearnMoreList}
            />
            <PrivateRoute
                exact
                path="/aprenda-mais/novo"
                component={LearnMoreNew}
            />
            <PrivateRoute
                exact
                path="/aprenda-mais/editar/:learnMoreId"
                component={LearnMoreEdit}
            />
            <PrivateRoute
                exact
                path="/aprenda-mais/editar/:learnMoreId/com-upload"
                component={LearnMoreEditWithUpload}
            />
            {/* Client List Direct Mail Tabloid or Product */}
            <PrivateRoute
                exact
                path="/funil-vendas"
                component={ClientListDirectMailTabloidOrProductList}
            />
            <PrivateRoute
                exact
                path="/funil-vendas/novo"
                component={ClientListDirectMailTabloidOrProductNew}
            />
            {/*Importar Preços*/}
            <PrivateRoute
                exact
                path="/importar-preco"
                component={importPricePerBranch}
            />
            {/* Direct Mail CRM */}
            <PrivateRoute
                exact
                path="/malas-diretas-crm"
                component={DirectMailCRMList}
            />
            <PrivateRoute
                exact
                path="/malas-diretas-crm/nova"
                component={DirectMailCRMNew}
            />
            {/* Current Offer */}
            <PrivateRoute
                exact
                path="/ofertas-vigentes/:currentOfferType"
                component={CurrentOfferList}
            />
            <PrivateRoute
                exact
                path="/ofertas-vigentes-nova"
                component={CurrentOfferNew}
            />
            {/* Current Promotions */}
            <PrivateRoute
                exact
                path="/promocoes-vigentes"
                component={CurrentPromotionList}
            />
            <PrivateRoute
                exact
                path="/promocoes-vigentes-nova"
                component={CurrentPromotionNew}
            />
            <PrivateRoute
                exact
                path="/promocoes-vigentes/:currentPromotionId"
                component={CurrentPromotionEdit}
            />
            <Route path="*" component={Error404} />
        </Switch>
    </BrowserRouter>
);

export default Routes;
