import React, { useState, useEffect, useRef } from 'react';

import * as moment from 'moment-timezone';

import { Link, withRouter } from 'react-router-dom';

import Layout from '../../components/Layout';

import api from '../../services/api';

const ReportRankingProducts = (props) => {
    const [showAlert, setShowAlert] = useState(false)
    const [alertTitle, setAlertTitle] = useState('')
    const [alertText, setAlertText] = useState('')
    const [alertVariant, setAlertVariant] = useState('')
    const [fileURLRanking, setFileURLRanking] = useState('')
    const [fileNameRanking, setFileNameRanking] = useState('')
    const [loaded, setLoaded] = useState(true)
    
    const dateBeginRanking = useRef(null);
    const dateEndRanking = useRef(null);

    useEffect(function () {
        var date = new Date();

        var formatedDate = `${date.getFullYear()}-${(date.getMonth()+1) < 10 ? '0' + (date.getMonth()+1) : (date.getMonth()+1)}-${date.getDate()}`

        dateBeginRanking.current.value = formatedDate
        dateEndRanking.current.value = formatedDate
    }, [])

    function onSubmitReportRankingProducts (event) {
        event.preventDefault()

        async function getReportRankingProducts () {
            try {
                setLoaded(false)
                const responseGetReportRankingProducts = await api.get(`/reports/ranking-products/${dateBeginRanking.current.value}/${dateEndRanking.current.value}`, {
                    responseType:"blob"
                });
                
                if (responseGetReportRankingProducts.data.error || responseGetReportRankingProducts.status === 500) {
                    // setShowAlert(true)
                    // setAlertTitle('Problemas ao gerar o relatório.')
                    // setAlertText(responseGetReportCardByDate.data.data.message)
                    // setAlertVariant('danger')
                    alert('Problemas ao gerar o relatório.')

                    setLoaded(true)
                } else {
                    console.log(responseGetReportRankingProducts)                
                    
                     setFileURLRanking(window.URL.createObjectURL(new Blob([responseGetReportRankingProducts.data])))
                     setFileNameRanking("Relatorio-Ranking.xlsx")

                    // alert('Relatório de cartões está sendo gerado!')

                    setShowAlert(true)
                    setAlertTitle('Relatório de Ranking gerado com sucesso!')
                    setAlertVariant('success')

                    setLoaded(true)
                }
            } catch (err) {
                console.log(err)
                setShowAlert(true)
                setAlertTitle('Problemas ao gerar o relatório.')
                setAlertText('Por favor contate o suporte!')
                setAlertVariant('danger')

                setLoaded(true)
            }
        }

        getReportRankingProducts()  
    }

    return (
        <Layout
            menuActive="dashboard" 
            pageTitle="Relatório de Ranking de Produtos por Data"
        >
            <div className="row">
                <div className="col-xs-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">
                                <small><Link className="label label-default" to="/dashboard">Dashboard</Link></small> <span className="label label-success">Relatório de Ranking de Produtos por Data</span>
                            </div>
                        </div>
                        <div className={`card-body table-responsive ${loaded ? "" : "__loading"}`}>
                            <div className="row">
                                <form
                                    onSubmit={onSubmitReportRankingProducts}
                                >
                                    <div className="col-xs-3">
                                        <label htmlFor="dtBegin-filter">Data Inicial</label>
                                        <input
                                            className="form-control"
                                            type="date"
                                            id="dtBegin-filter"
                                            ref={dateBeginRanking}
                                        />
                                    </div>

                                    <div className="col-xs-3">
                                        <label htmlFor="dtEnd-filter">Data Final</label>
                                        <input
                                            className="form-control"
                                            type="date"
                                            id="dtEnd-filter"
                                            ref={dateEndRanking}
                                        />
                                    </div>

                                    <div className="col-xs-3" style={{position: 'relative', top: '50%', transform: 'translateY(50%)'}}>
                                        
                                        <button
                                            className="btn btn-primary btn-large"
                                            type="submit"
                                            
                                        >
                                            Gerar!
                                        </button>
                                    </div>
                                </form>
                            </div>

                            {fileURLRanking !== "" ? 
                                <a
                                    href={fileURLRanking}
                                    download={fileNameRanking}
                                >
                                    <button
                                        className="btn btn-success btn-large"
                                        type="submit"
                                        
                                    >
                                        Download!
                                    </button>
                                </a>
                             : null}
                            
                            <br/>

                            <div className="loader-container text-center">
                                <div className="icon">
                                    <div className="sk-wave">
                                        <div className="sk-rect sk-rect1"></div>
                                        <div className="sk-rect sk-rect2"></div>
                                        <div className="sk-rect sk-rect3"></div>
                                        <div className="sk-rect sk-rect4"></div>
                                        <div className="sk-rect sk-rect5"></div>
                                    </div>
                                </div>
                                <div className="title">Carregando</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default withRouter(ReportRankingProducts)