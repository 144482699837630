import axios from "axios";
import { getToken, getTokenCRMv3, logout } from "./auth";

const api = axios.create({
    baseURL: "https://cartaoexpress.rxy.com.br/api",
    // baseURL: "https://cartaoexpress.rxy.com.br/api",
    // baseURL: "http://127.0.0.1:3050/",
});

const apiCRMv3 = axios.create({
    baseURL: "https://painelcrm.rxy.com.br/api/v3",
    // baseURL: "https://painelcrm.rxy.com.br/api/v3",
    // baseURL: "http://127.0.0.1:4050/",
});

api.interceptors.request.use(async (config) => {
    const token = getToken();
    if (token) {
        config.headers["x-access-token"] = token;
    }
    return config;
});

apiCRMv3.interceptors.request.use(async (config) => {
    if (getTokenCRMv3() !== null) {
        config.headers["Authorization"] = `Bearer ${getTokenCRMv3()}`;
    } else {
        config.headers["x-api-key"] = "964b9057-c0d4-4621-b834-3735b7502864";
    }
    return config;
});

apiCRMv3.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response.status === 401) {
            logout();
        }
        return error;
    }
);

export default api;

export { apiCRMv3 };
