import React, { useState, useEffect } from "react";

import { withRouter, Link } from "react-router-dom";

import Layout from "../../components/Layout";

import api from "../../services/api";

import Swal from "sweetalert2";

const SquareArtAdsList = (props) => {
    const [ads, setAds] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [searchText, setSearchText] = useState("");

    useEffect(function () {
        loadList();
    }, []);

    async function loadList() {
        setLoaded(false);

        const responseGetAds = await api.get("/square-art/ad");

        setAds(responseGetAds.data.data);
        setLoaded(true);
    }

    async function handleDelete(adsDelete) {
        Swal.fire({
            title: `Tem certeza que deseja excluir o anúncio ${adsDelete.name}?`,
            text: "Não será possível voltar atrás!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim, quero excluir!",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (!result.value) {
                Swal.fire(
                    "Cancelado!",
                    "Nenhum dado será excluído.",
                    "success"
                );
            } else {
                setLoaded(false);

                try {
                    const responseDeleteAds = await api.delete(
                        `/square-art/ad/${adsDelete._id}`
                    );

                    if (responseDeleteAds.status === 204) {
                        const newAds = ads.filter(
                            (ads) => ads._id !== adsDelete._id
                        );
                        setAds(newAds);
                        setLoaded(true);
                    } else {
                        throw "Error";
                    }
                } catch (error) {
                    setLoaded(true);
                    Swal.fire({
                        type: "error",
                        title: "Oops...",
                        text: "Tivemos um problema ao excluir esse anúncio. Contate o suporte!",
                    });
                }
            }
        });
    }

    return (
        <Layout
            menuActive="registers"
            pageTitle="Arte Quadrada - Lista de Anúncios"
            enableSearchBar={true}
            searchTerm={searchText}
            searchOnChange={(e) => setSearchText(e.target.value)}
            // handleSearch={handleSearchBar}
        >
            <div className="row">
                <div className="col-xs-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">
                                Arte Quadrada - Lista de Anúncios
                            </div>
                            <ul className="card-action">
                                <li>
                                    <Link
                                        to="/arte-quadrada-anuncios/novo"
                                        className="btn btn-sm btn-success"
                                    >
                                        Nova <i className="fa fa-plus"></i>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div
                            className={`card-body ${loaded ? "" : "__loading"}`}
                        >
                            <div className="loader-container text-center">
                                <div className="icon">
                                    <div className="sk-wave">
                                        <div className="sk-rect sk-rect1"></div>
                                        <div className="sk-rect sk-rect2"></div>
                                        <div className="sk-rect sk-rect3"></div>
                                        <div className="sk-rect sk-rect4"></div>
                                        <div className="sk-rect sk-rect5"></div>
                                    </div>
                                </div>
                                <div className="title">Carregando</div>
                            </div>

                            <div className="row">
                                <div className="col-xs-12">
                                    <table className="table table-striped table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th>Nome</th>
                                                <th>Opções</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {ads
                                                .filter(
                                                    (ad) =>
                                                        ad.name
                                                            .toLowerCase()
                                                            .indexOf(
                                                                searchText.toLowerCase()
                                                            ) !== -1
                                                )
                                                .map((ad, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                {ad.name}
                                                            </td>
                                                            <td>
                                                                <div className="col-xs-12">
                                                                    <div className="col-xs-12">
                                                                        <Link
                                                                            className="btn btn-xs btn-warning"
                                                                            style={{
                                                                                width: "100%",
                                                                            }}
                                                                            to={`/arte-quadrada-anuncios/editar/${ad._id}`}
                                                                        >
                                                                            Editar
                                                                            <div className="icon fa fa-pencil-square-o"></div>
                                                                        </Link>
                                                                        <button
                                                                            className="btn btn-xs btn-danger"
                                                                            style={{
                                                                                width: "100%",
                                                                            }}
                                                                            onClick={() =>
                                                                                handleDelete(
                                                                                    ad
                                                                                )
                                                                            }
                                                                        >
                                                                            Excluir
                                                                            <div className="icon fa fa-trash-o"></div>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default withRouter(SquareArtAdsList);
