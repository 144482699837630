import React, { useState, useEffect, useRef } from "react";

import * as moment from "moment-timezone";

import { Link, withRouter } from "react-router-dom";

import Layout from "../../components/Layout";

import api from "../../services/api";

const ReportAllUsers = props => {
    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState("");
    const [alertText, setAlertText] = useState("");
    const [alertVariant, setAlertVariant] = useState("");
    const [fileURLAllUsers, setFileURLAllUsers] = useState("");
    const [fileNameAllUsers, setFileNameAllUsers] = useState("");
    const [loaded, setLoaded] = useState(true);

    useEffect(function() {}, []);

    function onSubmitReportCards(event) {
        event.preventDefault();

        async function getReportAllUsers() {
            try {
                setLoaded(false);
                const responseGetReportAllUsers = await api.get(
                    `/reports/all-users`,
                    {
                        responseType: "blob"
                    }
                );

                if (
                    responseGetReportAllUsers.data.error ||
                    responseGetReportAllUsers.status === 500
                ) {
                    setShowAlert(true);
                    setAlertTitle("Problemas ao gerar o relatório.");
                    setAlertText(responseGetReportAllUsers.data.data.message);
                    setAlertVariant("danger");

                    setLoaded(true);
                } else {
                    let fileName = responseGetReportAllUsers.headers[
                        "content-disposition"
                    ].split(";")[1];
                    fileName = fileName.slice(
                        fileName.indexOf('"'),
                        fileName.length - 1
                    );

                    setFileURLAllUsers(
                        window.URL.createObjectURL(
                            new Blob([responseGetReportAllUsers.data])
                        )
                    );
                    setFileNameAllUsers(fileName);

                    setShowAlert(true);
                    setAlertTitle("Relatório de usuários gerado com sucesso!");
                    setAlertText(`Clique no botão abaixo para fazer download!`);
                    setAlertVariant("success");

                    setLoaded(true);
                }
            } catch (err) {
                console.log(err);
                setShowAlert(true);
                setAlertTitle("Problemas ao gerar o relatório.");
                setAlertText("Por favor contate o suporte!");
                setAlertVariant("danger");

                setLoaded(true);
            }
        }

        getReportAllUsers();
    }

    return (
        <Layout
            menuActive="dashboard"
            pageTitle="Relatório de Todos os Usuários"
        >
            <div className="row">
                <div className="col-xs-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">
                                <small>
                                    <Link
                                        className="label label-default"
                                        to="/dashboard"
                                    >
                                        Dashboard
                                    </Link>{" "}
                                    >
                                </small>{" "}
                                <span className="label label-success">
                                    Relatório de Todos os Usuários
                                </span>
                            </div>
                        </div>
                        <div
                            className={`card-body table-responsive ${
                                loaded ? "" : "__loading"
                            }`}
                        >
                            <div className="row">
                                <form onSubmit={onSubmitReportCards}>
                                    <div className="col-xs-4">
                                        <button
                                            className="btn btn-primary btn-large"
                                            type="submit"
                                        >
                                            Gerar!
                                        </button>
                                    </div>
                                </form>
                            </div>

                            {fileURLAllUsers !== "" ? (
                                <a
                                    href={fileURLAllUsers}
                                    download={fileNameAllUsers}
                                >
                                    <button
                                        className="btn btn-success btn-large"
                                        type="submit"
                                    >
                                        Download!
                                    </button>
                                </a>
                            ) : null}

                            <br />

                            <div className="loader-container text-center">
                                <div className="icon">
                                    <div className="sk-wave">
                                        <div className="sk-rect sk-rect1"></div>
                                        <div className="sk-rect sk-rect2"></div>
                                        <div className="sk-rect sk-rect3"></div>
                                        <div className="sk-rect sk-rect4"></div>
                                        <div className="sk-rect sk-rect5"></div>
                                    </div>
                                </div>
                                <div className="title">Carregando</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default withRouter(ReportAllUsers);
