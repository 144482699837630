import React, { useState, useRef, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";

import Layout from "../../components/Layout";

import { apiCRMv3 } from "../../services/api";

import Swal from "sweetalert2";

const ActivateUser = (props) => {
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState({});
    const cpfRef = useRef(null);

    useEffect(() => {
        setLoaded(true);
    }, []);

    function handleCancelButton(event) {
        if (cpfRef.current.value !== "") {
            Swal.fire({
                title: "Tem certeza que deseja cancelar?",
                text: "Cancelar fará você perder todas as modificações feitas até o momento!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sim, quero cancelar!",
                cancelButtonText: "Cancelar",
            }).then((result) => {
                if (result.value) {
                    Swal.fire(
                        "Cancelado!",
                        "Você irá voltar para a tela de listagem.",
                        "success"
                    );

                    props.history.push("/dashboard");
                }
            });
        } else {
            props.history.push("/dashboard");
        }
    }

    async function handleSubmitForm(event) {
        event.preventDefault();

        setLoaded(false);

        try {
            const activateUser = await apiCRMv3.post(
                "/user/activate-user-by-cpf",
                {
                    cpf: cpfRef.current.value,
                }
            );

            setLoaded(true);
            if (activateUser.status === 200) {
                setLoaded(true);

                Swal.fire({
                    type: "success",
                    title: "Ativado!",
                    text: `O vendedor ${activateUser.data.data.userName} foi ativado com sucesso!`,
                }).then(() => props.history.push("/dashboard"));
            } else if (activateUser.status === 404) {
                setLoaded(true);
                Swal.fire({
                    type: "error",
                    title: "Oops...",
                    text: "Não encontramos um vendedor com esse CPF!",
                });
            } else {
                setLoaded(true);
                Swal.fire({
                    type: "error",
                    title: "Oops...",
                    text: "Tivemos um problema em ativar esse vendedor. Contate o suporte!",
                });
            }
        } catch (e) {
            setLoaded(true);
            Swal.fire({
                type: "error",
                title: "Oops...",
                text: "Tivemos um problema em ativar esse vendedor. Contate o suporte!",
            });
        }
    }

    return (
        <Layout menuActive="registers" pageTitle="Ativar Usuário">
            <div className="row">
                <div className="col-xs-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">Ativar Usuário</div>
                        </div>
                        <div
                            className={`card-body ${loaded ? "" : "__loading"}`}
                        >
                            <div className="loader-container text-center">
                                <div className="icon">
                                    <div className="sk-wave">
                                        <div className="sk-rect sk-rect1"></div>
                                        <div className="sk-rect sk-rect2"></div>
                                        <div className="sk-rect sk-rect3"></div>
                                        <div className="sk-rect sk-rect4"></div>
                                        <div className="sk-rect sk-rect5"></div>
                                    </div>
                                </div>
                                <div className="title">Carregando</div>
                            </div>

                            <form
                                className="form form-horizontal"
                                onSubmit={handleSubmitForm}
                            >
                                <div className="section">
                                    <div className="section-title">
                                        Informações Básicas
                                    </div>
                                    <div className="section-body">
                                        <div
                                            className={
                                                "form-group " +
                                                (error.name ? "has-error" : "")
                                            }
                                        >
                                            <label className="col-md-3 control-label">
                                                CPF (sem pontos e traços)
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder=""
                                                    ref={cpfRef}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-footer">
                                    <div className="form-group">
                                        <div className="col-md-9 col-md-offset-3">
                                            <button
                                                type="submit"
                                                className="btn btn-primary"
                                            >
                                                Ativar
                                            </button>
                                            <button
                                                type="button"
                                                onClick={handleCancelButton}
                                                className="btn btn-default"
                                            >
                                                Cancelar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default withRouter(ActivateUser);
