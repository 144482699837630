import React, { Component } from "react";

import Layout from "../../components/Layout";

import api from "../../services/api";

import { hasCharacters } from "../../utils/functions";

import CurrencyInput from "react-currency-input";

import Swal from "sweetalert2";

export default class ProductNew extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: true,
            product: {
                name: "",
                codigo: "",
                modelo: "",
                nome_divulgacao: "",
                carac_1: "",
                carac_2: "",
                carac_3: "",
                carac_4: "",
                price: null,
                cost: null,
                line: "",
                brand: "",
                have_image: false,
                have_video: false,
                video_link: "",
            },
            error: {
                name: false,
                codigo: false,
                modelo: false,
                nome_divulgacao: false,
                carac_1: false,
                carac_2: false,
                carac_3: false,
                carac_4: false,
                price: false,
                cost: false,
                line: false,
                brand: false,
                have_image: false,
                have_video: false,
                video_link: false,
            },
        };

        this.handleOnChangeName = this.handleOnChangeName.bind(this);
        this.handleCancelButton = this.handleCancelButton.bind(this);
        this.handleSubmitForm = this.handleSubmitForm.bind(this);
    }

    handleOnChangeName(e) {
        const value = e.target.value;

        this.setState((prevState) => ({
            ...prevState,
            product: {
                ...prevState.product,
                name: value,
            },
            error: {
                ...prevState.error,
                name: false,
            },
        }));
    }

    handleCancelButton(event) {
        const emptyObject = {
            name: "",
            codigo: "",
            modelo: "",
            nome_divulgacao: "",
            carac_1: "",
            carac_2: "",
            carac_3: "",
            carac_4: "",
            price: null,
            cost: null,
            line: "",
            brand: "",
            have_image: false,
            have_video: false,
            video_link: "",
        };

        if (
            JSON.stringify(this.state.product) !== JSON.stringify(emptyObject)
        ) {
            Swal.fire({
                title: "Tem certeza que deseja cancelar?",
                text:
                    "Cancelar fará você perder todas as modificações feitas até o momento!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sim, quero cancelar!",
                cancelButtonText: "Cancelar",
            }).then((result) => {
                if (result.value) {
                    Swal.fire(
                        "Cancelado!",
                        "Você irá voltar para a tela de listagem.",
                        "success"
                    );

                    this.props.history.push("/produtos");
                }
            });
        } else {
            this.props.history.push("/produtos");
        }
    }

    handleSubmitForm = async function (event) {
        event.preventDefault();

        this.setState((prevState) => ({
            ...prevState,
            loaded: false,
        }));

        const createdProduct = await api.post("/product", this.state.product);

        console.log(createdProduct);

        if (createdProduct.status === 201) {
            Swal.fire({
                type: "success",
                title: "Salvo!",
                text: "O novo produto foi salvo com sucesso!",
            }).then(() => this.props.history.push("/produtos"));
        } else {
            Swal.fire({
                type: "error",
                title: "Oops...",
                text:
                    "Tivemos um problema ao salvar esse novo produto. Contate o suporte!",
            });

            this.setState((prevState) => ({
                ...prevState,
                loaded: true,
            }));
        }
    };

    render() {
        return (
            <Layout menuActive="registers" pageTitle="Novo Produto">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="card-title">Novo Produto</div>
                            </div>
                            <div
                                className={`card-body ${
                                    this.state.loaded ? "" : "__loading"
                                }`}
                            >
                                <div className="loader-container text-center">
                                    <div className="icon">
                                        <div className="sk-wave">
                                            <div className="sk-rect sk-rect1"></div>
                                            <div className="sk-rect sk-rect2"></div>
                                            <div className="sk-rect sk-rect3"></div>
                                            <div className="sk-rect sk-rect4"></div>
                                            <div className="sk-rect sk-rect5"></div>
                                        </div>
                                    </div>
                                    <div className="title">Carregando</div>
                                </div>

                                <form
                                    className="form form-horizontal"
                                    onSubmit={this.handleSubmitForm}
                                >
                                    <div className="section">
                                        <div className="section-title">
                                            Informações
                                        </div>
                                        <div className="section-body">
                                            <div
                                                className={
                                                    "form-group " +
                                                    (this.state.error.name
                                                        ? "has-error"
                                                        : "")
                                                }
                                            >
                                                <label className="col-md-3 control-label">
                                                    Nome
                                                </label>
                                                <div className="col-md-9">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder=""
                                                        value={
                                                            this.state.product
                                                                .name
                                                        }
                                                        onChange={
                                                            this
                                                                .handleOnChangeName
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    "form-group " +
                                                    (this.state.error.codigo
                                                        ? "has-error"
                                                        : "")
                                                }
                                            >
                                                <label className="col-md-3 control-label">
                                                    Código
                                                </label>
                                                <div className="col-md-9">
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        placeholder=""
                                                        onChange={(e) => {
                                                            const value =
                                                                e.target.value;
                                                            if (
                                                                !hasCharacters(
                                                                    value
                                                                )
                                                            ) {
                                                                this.setState(
                                                                    (
                                                                        prevState
                                                                    ) => ({
                                                                        ...prevState,
                                                                        error: {
                                                                            ...prevState.error,
                                                                            codigo: false,
                                                                        },
                                                                    })
                                                                );
                                                            } else {
                                                                this.setState(
                                                                    (
                                                                        prevState
                                                                    ) => ({
                                                                        ...prevState,
                                                                        error: {
                                                                            ...prevState.error,
                                                                            codigo: true,
                                                                        },
                                                                    })
                                                                );
                                                            }

                                                            this.setState(
                                                                (
                                                                    prevState
                                                                ) => ({
                                                                    ...prevState,
                                                                    product: {
                                                                        ...prevState.product,
                                                                        codigo: value,
                                                                    },
                                                                })
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    "form-group " +
                                                    (this.state.error.modelo
                                                        ? "has-error"
                                                        : "")
                                                }
                                            >
                                                <label className="col-md-3 control-label">
                                                    Modelo
                                                </label>
                                                <div className="col-md-9">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder=""
                                                        value={
                                                            this.state.product
                                                                .modelo
                                                        }
                                                        onChange={(e) => {
                                                            const {
                                                                value,
                                                            } = e.target;

                                                            this.setState(
                                                                (
                                                                    prevState
                                                                ) => ({
                                                                    ...prevState,
                                                                    product: {
                                                                        ...prevState.product,
                                                                        modelo: value,
                                                                    },
                                                                })
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    "form-group " +
                                                    (this.state.error.price
                                                        ? "has-error"
                                                        : "")
                                                }
                                            >
                                                <label className="col-md-3 control-label">
                                                    Preço
                                                </label>
                                                <div className="col-md-9">
                                                    <div className="input-group">
                                                        <span className="input-group-addon">
                                                            R$
                                                        </span>
                                                        <CurrencyInput
                                                            className="form-control"
                                                            decimalSeparator=","
                                                            thousandSeparator="."
                                                            value={
                                                                this.state
                                                                    .product
                                                                    .price
                                                            }
                                                            onChangeEvent={(
                                                                event,
                                                                maskedvalue,
                                                                floatvalue
                                                            ) => {
                                                                this.setState(
                                                                    (
                                                                        prevState
                                                                    ) => ({
                                                                        ...prevState,
                                                                        product: {
                                                                            ...prevState.product,
                                                                            price: floatvalue,
                                                                        },
                                                                    })
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    "form-group " +
                                                    (this.state.error.cost
                                                        ? "has-error"
                                                        : "")
                                                }
                                            >
                                                <label className="col-md-3 control-label">
                                                    Custo
                                                </label>
                                                <div className="col-md-9">
                                                    <div className="input-group">
                                                        <span className="input-group-addon">
                                                            R$
                                                        </span>
                                                        <CurrencyInput
                                                            className="form-control"
                                                            decimalSeparator=","
                                                            thousandSeparator="."
                                                            value={
                                                                this.state
                                                                    .product
                                                                    .cost
                                                            }
                                                            onChangeEvent={(
                                                                event,
                                                                maskedvalue,
                                                                floatvalue
                                                            ) => {
                                                                this.setState(
                                                                    (
                                                                        prevState
                                                                    ) => ({
                                                                        ...prevState,
                                                                        product: {
                                                                            ...prevState.product,
                                                                            cost: floatvalue,
                                                                        },
                                                                    })
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="section">
                                        <div className="section-title">
                                            Divulgação
                                        </div>
                                        <div className="section-body">
                                            <div
                                                className={
                                                    "form-group " +
                                                    (this.state.error
                                                        .nome_divulgacao
                                                        ? "has-error"
                                                        : "")
                                                }
                                            >
                                                <label className="col-md-3 control-label">
                                                    Nome Divulgação
                                                </label>
                                                <div className="col-md-9">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={
                                                            this.state.product
                                                                .nome_divulgacao
                                                        }
                                                        onChange={(e) => {
                                                            const {
                                                                value,
                                                            } = e.target;

                                                            this.setState(
                                                                (
                                                                    prevState
                                                                ) => ({
                                                                    ...prevState,
                                                                    product: {
                                                                        ...prevState.product,
                                                                        nome_divulgacao: value,
                                                                    },
                                                                })
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    "form-group " +
                                                    (this.state.error.carac_1
                                                        ? "has-error"
                                                        : "")
                                                }
                                            >
                                                <label className="col-md-3 control-label">
                                                    Característica 1
                                                </label>
                                                <div className="col-md-9">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={
                                                            this.state.product
                                                                .carac_1
                                                        }
                                                        onChange={(e) => {
                                                            const {
                                                                value,
                                                            } = e.target;

                                                            this.setState(
                                                                (
                                                                    prevState
                                                                ) => ({
                                                                    ...prevState,
                                                                    product: {
                                                                        ...prevState.product,
                                                                        carac_1: value,
                                                                    },
                                                                })
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    "form-group " +
                                                    (this.state.error.carac_2
                                                        ? "has-error"
                                                        : "")
                                                }
                                            >
                                                <label className="col-md-3 control-label">
                                                    Característica 2
                                                </label>
                                                <div className="col-md-9">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={
                                                            this.state.product
                                                                .carac_2
                                                        }
                                                        onChange={(e) => {
                                                            const {
                                                                value,
                                                            } = e.target;

                                                            this.setState(
                                                                (
                                                                    prevState
                                                                ) => ({
                                                                    ...prevState,
                                                                    product: {
                                                                        ...prevState.product,
                                                                        carac_2: value,
                                                                    },
                                                                })
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    "form-group " +
                                                    (this.state.error.carac_3
                                                        ? "has-error"
                                                        : "")
                                                }
                                            >
                                                <label className="col-md-3 control-label">
                                                    Característica 3
                                                </label>
                                                <div className="col-md-9">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={
                                                            this.state.product
                                                                .carac_3
                                                        }
                                                        onChange={(e) => {
                                                            const {
                                                                value,
                                                            } = e.target;

                                                            this.setState(
                                                                (
                                                                    prevState
                                                                ) => ({
                                                                    ...prevState,
                                                                    product: {
                                                                        ...prevState.product,
                                                                        carac_3: value,
                                                                    },
                                                                })
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    "form-group " +
                                                    (this.state.error.carac_4
                                                        ? "has-error"
                                                        : "")
                                                }
                                            >
                                                <label className="col-md-3 control-label">
                                                    Característica 4
                                                </label>
                                                <div className="col-md-9">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={
                                                            this.state.product
                                                                .carac_4
                                                        }
                                                        onChange={(e) => {
                                                            const {
                                                                value,
                                                            } = e.target;

                                                            this.setState(
                                                                (
                                                                    prevState
                                                                ) => ({
                                                                    ...prevState,
                                                                    product: {
                                                                        ...prevState.product,
                                                                        carac_4: value,
                                                                    },
                                                                })
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    "form-group " +
                                                    (this.state.error.line
                                                        ? "has-error"
                                                        : "")
                                                }
                                            >
                                                <label className="col-md-3 control-label">
                                                    Linha
                                                </label>
                                                <div className="col-md-9">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={
                                                            this.state.product
                                                                .line
                                                        }
                                                        onChange={(e) => {
                                                            const {
                                                                value,
                                                            } = e.target;

                                                            this.setState(
                                                                (
                                                                    prevState
                                                                ) => ({
                                                                    ...prevState,
                                                                    product: {
                                                                        ...prevState.product,
                                                                        line: value,
                                                                    },
                                                                })
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    "form-group " +
                                                    (this.state.error.brand
                                                        ? "has-error"
                                                        : "")
                                                }
                                            >
                                                <label className="col-md-3 control-label">
                                                    Marca
                                                </label>
                                                <div className="col-md-9">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={
                                                            this.state.product
                                                                .brand
                                                        }
                                                        onChange={(e) => {
                                                            const {
                                                                value,
                                                            } = e.target;

                                                            this.setState(
                                                                (
                                                                    prevState
                                                                ) => ({
                                                                    ...prevState,
                                                                    product: {
                                                                        ...prevState.product,
                                                                        brand: value,
                                                                    },
                                                                })
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="checkbox">
                                                <input
                                                    type="checkbox"
                                                    id="checkbox1"
                                                    checked={
                                                        this.state.product
                                                            .have_image
                                                    }
                                                    onChange={(e) => {
                                                        const {
                                                            checked,
                                                        } = e.target;

                                                        this.setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                product: {
                                                                    ...prevState.product,
                                                                    have_image: checked,
                                                                },
                                                            })
                                                        );
                                                    }}
                                                />
                                                <label htmlFor="checkbox1">
                                                    Possui foto do produto para
                                                    utilização no cartão?
                                                </label>
                                            </div>
                                            <div className="checkbox">
                                                <input
                                                    type="checkbox"
                                                    id="checkbox2"
                                                    checked={
                                                        this.state.product
                                                            .have_video
                                                    }
                                                    onChange={(e) => {
                                                        const {
                                                            checked,
                                                        } = e.target;

                                                        this.setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                product: {
                                                                    ...prevState.product,
                                                                    have_video: checked,
                                                                },
                                                            })
                                                        );
                                                    }}
                                                />
                                                <label htmlFor="checkbox2">
                                                    Possui link de vídeo do
                                                    produto para utilização no
                                                    cartão?
                                                </label>
                                            </div>
                                            <div
                                                className={
                                                    "form-group " +
                                                    (this.state.error.video_link
                                                        ? "has-error"
                                                        : "")
                                                }
                                            >
                                                <label className="col-md-3 control-label">
                                                    Link do Vídeo
                                                </label>
                                                <div className="col-md-9">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={
                                                            this.state.product
                                                                .video_link
                                                        }
                                                        onChange={(e) => {
                                                            const {
                                                                value,
                                                            } = e.target;

                                                            this.setState(
                                                                (
                                                                    prevState
                                                                ) => ({
                                                                    ...prevState,
                                                                    product: {
                                                                        ...prevState.product,
                                                                        video_link: value,
                                                                    },
                                                                })
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="checkbox">
                                                <input
                                                    type="checkbox"
                                                    id="checkboxPrime"
                                                    checked={
                                                        this.state.product.prime
                                                    }
                                                    onChange={(e) => {
                                                        const {
                                                            checked,
                                                        } = e.target;

                                                        this.setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                product: {
                                                                    ...prevState.product,
                                                                    prime: checked,
                                                                },
                                                            })
                                                        );
                                                    }}
                                                />
                                                <label htmlFor="checkboxPrime">
                                                    Produto prime?
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-footer">
                                        <div className="form-group">
                                            <div className="col-md-9 col-md-offset-3">
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary"
                                                >
                                                    Salvar
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={
                                                        this.handleCancelButton
                                                    }
                                                    className="btn btn-default"
                                                >
                                                    Cancelar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}
