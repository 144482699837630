import React, { Component } from "react";

import { Link } from "react-router-dom";

import Layout from "../../components/Layout";

import api from "../../services/api";

import Swal from "sweetalert2";

export default class ProductList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            products: [],
            productsToShow: [],
            product_search_term: "",
            filterHaveImage: false,
            loaded: false,
            page: 1,
            qtdPages: 0,
        };

        this.handleSearchProduct = this.handleSearchProduct.bind(this);
    }

    componentWillMount = async function () {
        const filterHaveImageStorageStringify = window.localStorage.getItem(
            "filterHaveImage"
        );

        if (filterHaveImageStorageStringify !== null) {
            const filterHaveImageStorageDecoded = JSON.parse(
                filterHaveImageStorageStringify
            );

            this.setState((prevState) => ({
                ...prevState,
                filterHaveImage: filterHaveImageStorageDecoded.value,
            }));

            this.loadProducts(1, filterHaveImageStorageDecoded.value);
        } else {
            this.loadProducts(1);
        }
    };

    loadProducts = async function (page = 1, have_image = false, search = "") {
        this.setState((prevState) => ({
            ...prevState,
            loaded: false,
        }));

        const returnProducts = await api.get(
            `/product/dash-paginated/${page}/${have_image ? "1" : "0"}${
                search !== "" ? "?search_text=" + encodeURI(search) : ""
            }`
        );

        this.setState((prevState) => ({
            ...prevState,
            loaded: true,
            products: returnProducts.data.products,
            productsToShow: returnProducts.data.products,
            qtdPages: returnProducts.data.qtdPages
                ? returnProducts.data.qtdPages
                : prevState.qtdPages,
            page: page,
        }));

        // this.handleSearchProduct();
    };

    handleSearchProduct() {
        // alert(this.state.product_search_term);
        this.loadProducts(
            1,
            this.state.filterHaveImage,
            this.state.product_search_term
        );
        // this.setState(prevState => ({
        //     ...prevState,
        //     loaded: false
        // }));

        // let originalProducts = this.state.products;

        // if (this.state.filterHaveImage) {
        //     originalProducts = originalProducts.filter((product) => product.have_image);
        // }

        // let filteredProducts = originalProducts.filter((product) => (product.name.toLowerCase().indexOf(this.state.product_search_term.toLowerCase()) !== -1));

        // if (filteredProducts.length <= 0) {
        //     filteredProducts = originalProducts.filter((product) => (product.code.toLowerCase().indexOf(this.state.product_search_term.toLowerCase()) !== -1));
        // }

        // this.setState(prevState => ({
        //     ...prevState,
        //     productsToShow: filteredProducts,
        //     loaded: true
        // }))
    }

    handleDeleteButton = async function (product) {
        this.setState((prevState) => ({
            ...prevState,
            loaded: false,
        }));

        const self = this;

        Swal.fire({
            title: `Tem certeza que deseja excluir ${product.originalName}?`,
            text: "Depois de excluído não terá como recuperar os dados!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim, quero excluir!",
            cancelButtonText: "Cancelar",
        }).then(async function (result) {
            if (result.value) {
                const deletedProduct = await api.delete(
                    "/product/" + product.id
                );

                if (deletedProduct.status === 204) {
                    self.loadProducts();

                    self.setState((prevState) => ({
                        ...prevState,
                        product_search_term: "",
                    }));

                    Swal.fire(
                        "Excluído!",
                        "O produto foi excluído com sucesso!",
                        "success"
                    );
                } else {
                    Swal.fire(
                        "Oops...",
                        "Não foi possível excluir o produto agora. Por favor contate o suporte!",
                        "error"
                    ).then(() => {
                        self.setState((prevState) => ({
                            ...prevState,
                            loaded: true,
                        }));
                    });
                }
            } else {
                self.setState((prevState) => ({
                    ...prevState,
                    loaded: true,
                    product_search_term: "",
                }));
            }
        });
    };

    render() {
        let { productsToShow } = this.state;

        return (
            <Layout
                menuActive="registers"
                pageTitle="Produtos"
                enableSearchBar={true}
                searchTerm={this.state.product_search_term}
                searchOnChange={(e) =>
                    this.setState({ product_search_term: e.target.value })
                }
                handleSearch={this.handleSearchProduct}
            >
                <div className="row">
                    <div className="col-xs-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="card-title">Produtos</div>
                                <ul className="card-action">
                                    <li>
                                        <Link
                                            to="/produtos/novo"
                                            className="btn btn-sm btn-success"
                                        >
                                            Novo <i className="fa fa-plus"></i>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div
                                className={`card-body ${
                                    this.state.loaded ? "" : "__loading"
                                }`}
                            >
                                <div class="loader-container text-center">
                                    <div class="icon">
                                        <div class="sk-wave">
                                            <div class="sk-rect sk-rect1"></div>
                                            <div class="sk-rect sk-rect2"></div>
                                            <div class="sk-rect sk-rect3"></div>
                                            <div class="sk-rect sk-rect4"></div>
                                            <div class="sk-rect sk-rect5"></div>
                                        </div>
                                    </div>
                                    <div class="title">Carregando</div>
                                </div>

                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="checkbox">
                                            <input
                                                type="checkbox"
                                                id="checkbox1"
                                                checked={
                                                    this.state.filterHaveImage
                                                }
                                                onChange={(e) => {
                                                    const {
                                                        checked,
                                                    } = e.target;

                                                    window.localStorage.setItem(
                                                        "filterHaveImage",
                                                        JSON.stringify({
                                                            value: checked,
                                                        })
                                                    );

                                                    this.setState(
                                                        (prevState) => ({
                                                            ...prevState,
                                                            filterHaveImage: checked,
                                                        })
                                                    );

                                                    this.loadProducts(
                                                        1,
                                                        checked,
                                                        this.state
                                                            .product_search_term
                                                    );
                                                }}
                                            />
                                            <label htmlFor="checkbox1">
                                                Mostrar somente produtos com
                                                imagem
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-xs-12">
                                        <table className="table table-striped table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Código</th>
                                                    <th>Nome</th>
                                                    <th>Nome Divulgação</th>
                                                    <th>Opções</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {productsToShow.map(
                                                    (product, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>
                                                                    {
                                                                        product.code
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        product.originalName
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        product.marketingName
                                                                    }
                                                                </td>
                                                                <td>
                                                                    <div className="col-xs-12">
                                                                        <div className="col-xs-12">
                                                                            <Link
                                                                                to={`/produtos/editar/${product.id}`}
                                                                                className="btn btn-xs btn-primary"
                                                                                style={{
                                                                                    width:
                                                                                        "100%",
                                                                                }}
                                                                            >
                                                                                Editar{" "}
                                                                                <div className="icon fa fa-pencil"></div>
                                                                            </Link>
                                                                            {/* <button className="btn btn-xs btn-primary" style={{width: '100%'}}>
                                                                                Editar <div className="icon fa fa-pencil"></div>
                                                                            </button> */}
                                                                        </div>
                                                                        <div className="col-xs-12">
                                                                            <button
                                                                                className="btn btn-xs btn-danger"
                                                                                onClick={() =>
                                                                                    this.handleDeleteButton(
                                                                                        product
                                                                                    )
                                                                                }
                                                                                style={{
                                                                                    width:
                                                                                        "100%",
                                                                                }}
                                                                            >
                                                                                Excluir{" "}
                                                                                <div className="icon fa fa-trash-o"></div>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {this.state.qtdPages !== 0 ? (
                                    <div className="row">
                                        <div className="col-xs-12">
                                            <ul className="pagination">
                                                {Array(this.state.qtdPages)
                                                    .fill()
                                                    .map((_, page) => (
                                                        <li
                                                            className={
                                                                page + 1 ===
                                                                this.state.page
                                                                    ? "active"
                                                                    : ""
                                                            }
                                                        >
                                                            <a
                                                                href="#"
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.preventDefault();
                                                                    this.loadProducts(
                                                                        page +
                                                                            1,
                                                                        this
                                                                            .state
                                                                            .filterHaveImage,
                                                                        this
                                                                            .state
                                                                            .product_search_term
                                                                    );
                                                                }}
                                                            >
                                                                {page + 1}
                                                            </a>
                                                        </li>
                                                    ))}
                                            </ul>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}
