import React, { useState, useEffect } from "react";

import { withRouter, Link } from "react-router-dom";

import Layout from "../../components/Layout";

import api from "../../services/api";

import Swal from "sweetalert2";

const AdList = (props) => {
    const [ads, setAds] = useState([]);
    const [adsToShow, setAdsToShow] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [searchText, setSearchText] = useState("");

    useEffect(function () {
        loadList();
    }, []);

    async function loadList() {
        setLoaded(false);

        const responseGetAds = await api.get("/ad");

        setAds(responseGetAds.data.data);
        setAdsToShow(responseGetAds.data.data);
        setLoaded(true);
    }

    function handleSearchBar() {
        setLoaded(false);

        const originalAds = ads;

        if (searchText === "") {
            setAdsToShow(originalAds);
            setLoaded(true);
            return;
        }

        let filteredAds = originalAds.filter(
            (ad) =>
                ad.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
        );

        setAdsToShow(filteredAds);
        setLoaded(true);
    }

    async function handleDelete(adDelete) {
        Swal.fire({
            title: `Tem certeza que deseja excluir o anúncio ${adDelete.name}?`,
            text: "Não será possível voltar atrás!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim, quero excluir!",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (!result.value) {
                Swal.fire(
                    "Cancelado!",
                    "Nenhum dado será excluído.",
                    "success"
                );
            } else {
                setLoaded(false);

                try {
                    const responseDeleteAd = await api.delete(
                        `/ad/${adDelete._id}`
                    );

                    if (responseDeleteAd.status === 204) {
                        const newAds = ads.filter(
                            (ad) => ad._id !== adDelete._id
                        );
                        setAds(newAds);
                        setAdsToShow(newAds);
                        setLoaded(true);
                    } else {
                        throw "Error";
                    }
                } catch (error) {
                    console.log(error);
                    setLoaded(true);
                    Swal.fire({
                        type: "error",
                        title: "Oops...",
                        text:
                            "Tivemos um problema ao excluir esse anúncio. Contate o suporte!",
                    });
                }
            }
        });
    }

    return (
        <Layout
            menuActive="registers"
            pageTitle="Lista de Anúncios"
            enableSearchBar={true}
            searchTerm={searchText}
            searchOnChange={(e) => setSearchText(e.target.value)}
            handleSearch={handleSearchBar}
        >
            <div className="row">
                <div className="col-xs-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">Lista de Anúncios</div>
                            <ul className="card-action">
                                <li>
                                    <Link
                                        to="/anuncios/novo"
                                        className="btn btn-sm btn-success"
                                    >
                                        Novo <i className="fa fa-plus"></i>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div
                            className={`card-body ${loaded ? "" : "__loading"}`}
                        >
                            <div className="loader-container text-center">
                                <div className="icon">
                                    <div className="sk-wave">
                                        <div className="sk-rect sk-rect1"></div>
                                        <div className="sk-rect sk-rect2"></div>
                                        <div className="sk-rect sk-rect3"></div>
                                        <div className="sk-rect sk-rect4"></div>
                                        <div className="sk-rect sk-rect5"></div>
                                    </div>
                                </div>
                                <div className="title">Carregando</div>
                            </div>

                            <div className="row">
                                <div className="col-xs-12">
                                    <table className="table table-striped table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th>Nome</th>
                                                <th>URL</th>
                                                <th>Opções</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {adsToShow.map((ad, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{ad.name}</td>
                                                        <td>
                                                            {ad.url
                                                                ? ad.url
                                                                : "Sem link"}
                                                        </td>
                                                        <td>
                                                            <div className="col-xs-12">
                                                                <div className="col-xs-12">
                                                                    <button
                                                                        className="btn btn-xs btn-danger"
                                                                        style={{
                                                                            width:
                                                                                "100%",
                                                                        }}
                                                                        onClick={() =>
                                                                            handleDelete(
                                                                                ad
                                                                            )
                                                                        }
                                                                    >
                                                                        Excluir
                                                                        <div className="icon fa fa-trash-o"></div>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default withRouter(AdList);
