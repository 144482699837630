import React from "react";
import Routes from "./routes";
import { FormResultsProvider } from "./hooks/form-results";

import "./assets/css/vendor.css";
import "./assets/css/flat-admin.css";
import "./assets/css/theme/blue.css";
import "./assets/css/style.css";

const App = () => (
    <FormResultsProvider>
        <Routes />;
    </FormResultsProvider>
);
export default App;
