import React, { Component } from "react";

import Layout from "../../components/Layout";

import api from "../../services/api";

import { hasCharacters } from "../../utils/functions";

import InputMask from "react-input-mask";

import Swal from "sweetalert2";

export default class BranchNew extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: true,
            branch: {
                codigo_loja: "",
                cidade_estado: "",
                cnpj: "",
                email: "",
                address: "",
                addressMaps: "",
                region: "",
                phone: "",
            },
            error: {
                codigo_loja: false,
                cidade_estado: false,
                cnpj: false,
                email: false,
                address: false,
                addressMaps: false,
                region: false,
                phone: false,
            },
        };

        this.handleCancelButton = this.handleCancelButton.bind(this);
        this.handleSubmitForm = this.handleSubmitForm.bind(this);
    }

    handleCancelButton(event) {
        const emptyObject = {
            codigo_loja: "",
            cidade_estado: "",
            cnpj: "",
            email: "",
            address: "",
            addressMaps: "",
            region: "",
            phone: "",
        };

        if (JSON.stringify(this.state.branch) !== JSON.stringify(emptyObject)) {
            Swal.fire({
                title: "Tem certeza que deseja cancelar?",
                text:
                    "Cancelar fará você perder todas as modificações feitas até o momento!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sim, quero cancelar!",
                cancelButtonText: "Cancelar",
            }).then((result) => {
                if (result.value) {
                    Swal.fire(
                        "Cancelado!",
                        "Você irá voltar para a tela de listagem.",
                        "success"
                    );

                    this.props.history.push("/filiais");
                }
            });
        } else {
            this.props.history.push("/filiais");
        }
    }

    handleSubmitForm = async function (event) {
        event.preventDefault();

        this.setState((prevState) => ({
            ...prevState,
            loaded: false,
        }));

        try {
            const createdbranch = await api.post("/branch", this.state.branch);

            console.log(createdbranch);

            if (createdbranch.status === 201) {
                Swal.fire({
                    type: "success",
                    title: "Salvo!",
                    text: "A filial foi salva com sucesso!",
                }).then(() => this.props.history.push("/filiais"));
            } else {
                Swal.fire({
                    type: "error",
                    title: "Oops...",
                    text:
                        "Tivemos um problema ao salvar essa nova filial. Contate o suporte!",
                });

                this.setState((prevState) => ({
                    ...prevState,
                    loaded: true,
                }));
            }
        } catch (e) {
            Swal.fire({
                type: "error",
                title: "Oops...",
                text:
                    "Tivemos um problema ao salvar essa nova filial. Contate o suporte!",
            });

            this.setState((prevState) => ({
                ...prevState,
                loaded: true,
            }));
        }
    };

    render() {
        return (
            <Layout menuActive="registers" pageTitle="Nova Filial">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="card-title">Novo Filial</div>
                            </div>
                            <div
                                className={`card-body ${
                                    this.state.loaded ? "" : "__loading"
                                }`}
                            >
                                <div className="loader-container text-center">
                                    <div className="icon">
                                        <div className="sk-wave">
                                            <div className="sk-rect sk-rect1"></div>
                                            <div className="sk-rect sk-rect2"></div>
                                            <div className="sk-rect sk-rect3"></div>
                                            <div className="sk-rect sk-rect4"></div>
                                            <div className="sk-rect sk-rect5"></div>
                                        </div>
                                    </div>
                                    <div className="title">Carregando</div>
                                </div>

                                <form
                                    className="form form-horizontal"
                                    onSubmit={this.handleSubmitForm}
                                >
                                    <div className="section">
                                        <div className="section-title">
                                            Informações Básicas
                                        </div>
                                        <div className="section-body">
                                            <div
                                                className={
                                                    "form-group " +
                                                    (this.state.error
                                                        .codigo_loja
                                                        ? "has-error"
                                                        : "")
                                                }
                                            >
                                                <label className="col-md-3 control-label">
                                                    Código
                                                </label>
                                                <div className="col-md-9">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder=""
                                                        value={
                                                            this.state.branch
                                                                .codigo_loja
                                                        }
                                                        onChange={(e) => {
                                                            const value =
                                                                e.target.value;
                                                            if (
                                                                !hasCharacters(
                                                                    value
                                                                )
                                                            ) {
                                                                this.setState(
                                                                    (
                                                                        prevState
                                                                    ) => ({
                                                                        ...prevState,
                                                                        error: {
                                                                            ...prevState.error,
                                                                            codigo_loja: false,
                                                                        },
                                                                    })
                                                                );
                                                            } else {
                                                                this.setState(
                                                                    (
                                                                        prevState
                                                                    ) => ({
                                                                        ...prevState,
                                                                        error: {
                                                                            ...prevState.error,
                                                                            codigo_loja: true,
                                                                        },
                                                                    })
                                                                );
                                                            }

                                                            this.setState(
                                                                (
                                                                    prevState
                                                                ) => ({
                                                                    ...prevState,
                                                                    branch: {
                                                                        ...prevState.branch,
                                                                        codigo_loja: value,
                                                                    },
                                                                })
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    "form-group " +
                                                    (this.state.error.cnpj
                                                        ? "has-error"
                                                        : "")
                                                }
                                            >
                                                <label className="col-md-3 control-label">
                                                    CNPJ
                                                </label>
                                                <div className="col-md-9">
                                                    <InputMask
                                                        mask="99.999.999/9999-99"
                                                        className="form-control"
                                                        maskChar={null}
                                                        value={
                                                            this.state.branch
                                                                .cnpj
                                                        }
                                                        onChange={(e) => {
                                                            const {
                                                                value,
                                                            } = e.target;

                                                            this.setState(
                                                                (
                                                                    prevState
                                                                ) => ({
                                                                    ...prevState,
                                                                    branch: {
                                                                        ...prevState.branch,
                                                                        cnpj: value,
                                                                    },
                                                                })
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="section">
                                        <div className="section-title">
                                            Contato e Localização
                                        </div>
                                        <div className="section-body">
                                            <div
                                                className={
                                                    "form-group " +
                                                    (this.state.error.address
                                                        ? "has-error"
                                                        : "")
                                                }
                                            >
                                                <label className="col-md-3 control-label">
                                                    Endereço
                                                </label>
                                                <div className="col-md-9">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        maxLength="24"
                                                        value={
                                                            this.state.branch
                                                                .address
                                                        }
                                                        onChange={(e) => {
                                                            const {
                                                                value,
                                                            } = e.target;

                                                            this.setState(
                                                                (
                                                                    prevState
                                                                ) => ({
                                                                    ...prevState,
                                                                    branch: {
                                                                        ...prevState.branch,
                                                                        address: value,
                                                                    },
                                                                })
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    "form-group " +
                                                    (this.state.error
                                                        .addressMaps
                                                        ? "has-error"
                                                        : "")
                                                }
                                            >
                                                <label className="col-md-3 control-label">
                                                    Endereço Completo (G. Maps)
                                                </label>
                                                <div className="col-md-9">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={
                                                            this.state.branch
                                                                .addressMaps
                                                        }
                                                        onChange={(e) => {
                                                            const {
                                                                value,
                                                            } = e.target;

                                                            this.setState(
                                                                (
                                                                    prevState
                                                                ) => ({
                                                                    ...prevState,
                                                                    branch: {
                                                                        ...prevState.branch,
                                                                        addressMaps: value,
                                                                    },
                                                                })
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    "form-group " +
                                                    (this.state.error.region
                                                        ? "has-error"
                                                        : "")
                                                }
                                            >
                                                <label className="col-md-3 control-label">
                                                    Bairro
                                                </label>
                                                <div className="col-md-9">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        maxLength="8"
                                                        value={
                                                            this.state.branch
                                                                .region
                                                        }
                                                        onChange={(e) => {
                                                            const {
                                                                value,
                                                            } = e.target;

                                                            this.setState(
                                                                (
                                                                    prevState
                                                                ) => ({
                                                                    ...prevState,
                                                                    branch: {
                                                                        ...prevState.branch,
                                                                        region: value,
                                                                    },
                                                                })
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    "form-group " +
                                                    (this.state.error
                                                        .cidade_estado
                                                        ? "has-error"
                                                        : "")
                                                }
                                            >
                                                <label className="col-md-3 control-label">
                                                    Cidade - Estado
                                                </label>
                                                <div className="col-md-9">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder=""
                                                        value={
                                                            this.state.branch
                                                                .cidade_estado
                                                        }
                                                        onChange={(e) => {
                                                            const {
                                                                value,
                                                            } = e.target;

                                                            this.setState(
                                                                (
                                                                    prevState
                                                                ) => ({
                                                                    ...prevState,
                                                                    branch: {
                                                                        ...prevState.branch,
                                                                        cidade_estado: value,
                                                                    },
                                                                })
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    "form-group " +
                                                    (this.state.error.email
                                                        ? "has-error"
                                                        : "")
                                                }
                                            >
                                                <label className="col-md-3 control-label">
                                                    E-mail
                                                </label>
                                                <div className="col-md-9">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={
                                                            this.state.branch
                                                                .email
                                                        }
                                                        onChange={(e) => {
                                                            const {
                                                                value,
                                                            } = e.target;

                                                            this.setState(
                                                                (
                                                                    prevState
                                                                ) => ({
                                                                    ...prevState,
                                                                    branch: {
                                                                        ...prevState.branch,
                                                                        email: value,
                                                                    },
                                                                })
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    "form-group " +
                                                    (this.state.error.phone
                                                        ? "has-error"
                                                        : "")
                                                }
                                            >
                                                <label className="col-md-3 control-label">
                                                    Telefone
                                                </label>
                                                <div className="col-md-9">
                                                    <InputMask
                                                        mask="(99) 9999-9999"
                                                        className="form-control"
                                                        maskChar={null}
                                                        value={
                                                            this.state.branch
                                                                .phone
                                                        }
                                                        onChange={(e) => {
                                                            const {
                                                                value,
                                                            } = e.target;

                                                            this.setState(
                                                                (
                                                                    prevState
                                                                ) => ({
                                                                    ...prevState,
                                                                    branch: {
                                                                        ...prevState.branch,
                                                                        phone: value,
                                                                    },
                                                                })
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-footer">
                                        <div className="form-group">
                                            <div className="col-md-9 col-md-offset-3">
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary"
                                                >
                                                    Salvar
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={
                                                        this.handleCancelButton
                                                    }
                                                    className="btn btn-default"
                                                >
                                                    Cancelar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}
