import React, { Component } from 'react';

import Layout from '../../components/Layout';

import api from '../../services/api';

import CurrencyInput from 'react-currency-input';
import InputMask from 'react-input-mask';

import Select from 'react-select';

import Swal from 'sweetalert2';
import * as moment from 'moment';

export default class NewCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            card: {
                interest: false,
                entrance: false,
                credit_card: false,
                carne: false,
                limit: false,
                validity: moment().format("YYYY-MM-DD"),
                limit_value: 0,
                total_amount: 0,
                price_payment: 0,
                sight_price: 0,
                payment_x: "10",
                send_to: "whatsapp",
                client: {
                    name: '',
                    cpf: '',
                    phone: '',
                    email: '',
                    phone_whatsapp: false
                },
                branch: null,
            },
            products: [],
            branches: [],
            placeHolderText: "Carregando lista de produtos...",
            selectedProduct: "",
            productSelected: { value: "", label: "Selecione um produto" },
            branchSelected: { value: "", label: "Selecione uma filial" },
            loaded: false
        };

        this.handleSubmitForm = this.handleSubmitForm.bind(this);
        this.handleCancelButton = this.handleCancelButton.bind(this);
    }

    componentWillMount = async () => {
        const products = await api.get("/product");
        const branches = await api.get("/branch");

        let productsToSelect = [{ value: "", label: "Selecione um produto" }];
        let branchesToSelect = [{ value: "", label: "Selecione uma filial" }];

        await products.data.map(product => productsToSelect.push({ value: product.id, label: product.name, price: product.price }));
        await branches.data.map(branch => branchesToSelect.push({ value: branch._id, label: `${branch.codigo_loja} - ${branch.cidade_estado}` }));

        this.setState(prevState => ({
            ...prevState,
            loaded: true,
            products: productsToSelect,
            branches: branchesToSelect,
        }));
    }

    handleCancelButton(event) {
        Swal.fire({
            title: 'Tem certeza que deseja cancelar?',
            text: "Cancelar fará você perder todas as modificações feitas até o momento!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33', 
            confirmButtonText: 'Sim, quero cancelar!',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.value) {
                Swal.fire(
                    'Cancelado!',
                    'Você irá voltar para a tela de listagem.',
                    'success'
                );

                this.props.history.push("/produtos");
            }
        });
    }

    handleSubmitForm = async function(event) {
        event.preventDefault();

        this.setState(prevState => ({
            ...prevState,
            loaded: false
        }));

        let cardToSend = {
            ...this.state.card
        }
        cardToSend.product_id = this.state.productSelected.value;
        cardToSend.entrance = !this.state.card.entrance;
        cardToSend.validity = moment(cardToSend.validity).format("DD/MM/YYYY");

        const createCard = await api.post("/card", cardToSend);

        // console.log(createCard);

        if (createCard.status === 200) {
            Swal.fire({
                type: 'success',
                title: 'Salvo!',
                text: 'Cartão enviado com sucesso!',
            }).then(() => window.location.reload());
        } else {
            Swal.fire({
                type: 'error',
                title: 'Oops...',
                text: 'Tivemos um problema enviar o cartão. Contate o suporte!',
            });

            this.setState(prevState => ({
                ...prevState,
                loaded: true
            }));
        }
    }

    render() {
        return (
            <Layout
                menuActive="new-card" 
                pageTitle="Novo Cartão"
            >
                <div className="row">
                    <div className="col-xs-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="card-title">
                                    Novo Cartão
                                </div>
                            </div>
                            <div className={`card-body ${this.state.loaded ? "" : "__loading"}`}>
                                <div className="loader-container text-center">
                                    <div className="icon">
                                        <div className="sk-wave">
                                            <div className="sk-rect sk-rect1"></div>
                                            <div className="sk-rect sk-rect2"></div>
                                            <div className="sk-rect sk-rect3"></div>
                                            <div className="sk-rect sk-rect4"></div>
                                            <div className="sk-rect sk-rect5"></div>
                                        </div>
                                    </div>
                                    <div className="title">Carregando</div>
                                </div>

                                <form className="form form-horizontal" onSubmit={this.handleSubmitForm}>
                                    <div className="section">
                                        <div className="section-title">Informações</div>
                                        <div className="section-body">

                                            <div className="form-group">
                                                <label className="col-md-3 control-label">Produto</label>
                                                <div className="col-md-9">
                                                    <Select
                                                        placeholder="Selecione um produto"
                                                        value={this.state.productSelected}
                                                        onChange={selectedOption => {

                                                            this.setState(prevState => ({
                                                                ...prevState,
                                                                productSelected: selectedOption,
                                                                card: {
                                                                    ...prevState.card,
                                                                    price_payment: (selectedOption.price / this.state.card.payment_x),
                                                                    total_amount: selectedOption.price,
                                                                    sight_price: selectedOption.price
                                                                }
                                                            }));
                                                        }}
                                                        options={this.state.products}
                                                    />
                                                </div>
                                            </div>
                                            <br/>
                                            <div className="form-group">
                                                <label className="col-md-3 control-label">Filial</label>
                                                <div className="col-md-9">
                                                    <Select
                                                        placeholder="Selecione uma filial"
                                                        value={this.state.branchSelected}
                                                        onChange={selectedOption => {
                                                            this.setState(prevState => ({
                                                                ...prevState,
                                                                branchSelected: selectedOption,
                                                                card: {
                                                                    ...prevState.card,
                                                                    branch: selectedOption.value
                                                                }
                                                            }));
                                                        }}
                                                        options={this.state.branches}
                                                    />
                                                </div>
                                            </div>

                                            <div className="checkbox">
                                                <input
                                                    type="checkbox"
                                                    id="without_interest"
                                                    checked={this.state.card.interest}
                                                    onChange={(e) => {
                                                        const { checked } = e.target;

                                                        this.setState(prevState => ({
                                                            ...prevState,
                                                            card: {
                                                                ...prevState.card,
                                                                interest: checked
                                                            }
                                                        }))
                                                    }}
                                                />
                                                <label htmlFor="without_interest">
                                                    Sem Juros?
                                                </label>
                                            </div>
                                            
                                            <div className="checkbox">
                                                <input
                                                    type="checkbox"
                                                    id="without_entrance"
                                                    checked={this.state.card.entrance}
                                                    onChange={(e) => {
                                                        const { checked } = e.target;

                                                        this.setState(prevState => ({
                                                            ...prevState,
                                                            card: {
                                                                ...prevState.card,
                                                                entrance: checked
                                                            }
                                                        }))
                                                    }}
                                                />
                                                <label htmlFor="without_entrance">
                                                    Sem Entrada?
                                                </label>
                                            </div>

                                            <div className="checkbox">
                                                <input
                                                    type="checkbox"
                                                    id="credit_card"
                                                    checked={this.state.card.credit_card}
                                                    onChange={(e) => {
                                                        const { checked } = e.target;

                                                        this.setState(prevState => ({
                                                            ...prevState,
                                                            card: {
                                                                ...prevState.card,
                                                                credit_card: checked
                                                            }
                                                        }))
                                                    }}
                                                />
                                                <label htmlFor="credit_card">
                                                    No cartão de crédito?
                                                </label>
                                            </div>

                                            <div className="checkbox">
                                                <input
                                                    type="checkbox"
                                                    id="carne"
                                                    checked={this.state.card.carne}
                                                    onChange={(e) => {
                                                        const { checked } = e.target;

                                                        this.setState(prevState => ({
                                                            ...prevState,
                                                            card: {
                                                                ...prevState.card,
                                                                carne: checked
                                                            }
                                                        }))
                                                    }}
                                                />
                                                <label htmlFor="carne">
                                                    No carnê?
                                                </label>
                                            </div>

                                            <div className="form-group">
                                                <label className="col-md-3 control-label">Valor à vista</label>
                                                <div className="col-md-9">
                                                    <div className="input-group">
                                                        <span className="input-group-addon">R$</span>
                                                        <CurrencyInput
                                                            className="form-control"
                                                            decimalSeparator=","
                                                            thousandSeparator="."
                                                            value={this.state.card.sight_price}
                                                            onChangeEvent={(event, maskedvalue, floatvalue) => {
                                                                this.setState(prevState => ({
                                                                    ...prevState,
                                                                    card: {
                                                                        ...prevState.card,
                                                                        sight_price: floatvalue
                                                                    }
                                                                }))
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        
                                            <div className="form-group">
                                                <label className="col-md-3 control-label">Parcelas</label>
                                                <div className="col-md-9">
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        placeholder=""
                                                        value={this.state.card.payment_x}
                                                        onChange={(e) => {
                                                            const { value } = e.target;

                                                            this.setState(prevState => ({
                                                                ...prevState,
                                                                card: {
                                                                    ...prevState.card,
                                                                    payment_x: value
                                                                }
                                                            }))
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label className="col-md-3 control-label">Preço parcela</label>
                                                <div className="col-md-9">
                                                    <div className="input-group">
                                                        <span className="input-group-addon">R$</span>
                                                        <CurrencyInput
                                                            className="form-control"
                                                            decimalSeparator=","
                                                            thousandSeparator="."
                                                            value={this.state.card.price_payment}
                                                            onChangeEvent={(event, maskedvalue, floatvalue) => {
                                                                this.setState(prevState => ({
                                                                    ...prevState,
                                                                    card: {
                                                                        ...prevState.card,
                                                                        price_payment: floatvalue
                                                                    }
                                                                }))
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label className="col-md-3 control-label">Total a prazo</label>
                                                <div className="col-md-9">
                                                    <div className="input-group">
                                                        <span className="input-group-addon">R$</span>
                                                        <CurrencyInput
                                                            className="form-control"
                                                            decimalSeparator=","
                                                            thousandSeparator="."
                                                            value={this.state.card.total_amount}
                                                            onChangeEvent={(event, maskedvalue, floatvalue) => {
                                                                this.setState(prevState => ({
                                                                    ...prevState,
                                                                    card: {
                                                                        ...prevState.card,
                                                                        total_amount: floatvalue
                                                                    }
                                                                }))
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label className="col-md-3 control-label">Validade da Oferta</label>
                                                <div className="col-md-9">
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        placeholder=""
                                                        value={this.state.card.validity}
                                                        onChange={(e) => {
                                                            const { value } = e.target;

                                                            this.setState(prevState => ({
                                                                ...prevState,
                                                                card: {
                                                                    ...prevState.card,
                                                                    validity: value
                                                                }
                                                            }))
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label className="col-md-3 control-label">Enviar para:</label>
                                                <div className="col-md-9">
                                                    <select
                                                        className="form-control"
                                                        value={this.state.card.send_to}
                                                        onChange={(e) => {
                                                            const { value } = e.target;

                                                            this.setState(prevState => ({
                                                                ...prevState,
                                                                card: {
                                                                    ...prevState.card,
                                                                    send_to: value
                                                                }
                                                            }))
                                                        }}
                                                    >
                                                        <option value="whatsapp" selected>WhatsApp</option>
                                                        <option value="sms">SMS</option>
                                                        <option value="email">E-mail</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="section">
                                        <div className="section-title">Cliente</div>
                                        <div className="section-body">
                                            <div className="form-group">
                                                <label className="col-md-3 control-label">Nome</label>
                                                <div className="col-md-9">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder=""
                                                        value={this.state.card.client.name}
                                                        onChange={(e) => {
                                                            const { value } = e.target;

                                                            this.setState(prevState => ({
                                                                ...prevState,
                                                                card: {
                                                                    ...prevState.card,
                                                                    client: {
                                                                        ...prevState.card.client,
                                                                        name: value
                                                                    }
                                                                }
                                                            }))
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label className="col-md-3 control-label">CPF</label>
                                                <div className="col-md-9">
                                                    <InputMask
                                                        mask="999.999.999-99"
                                                        className="form-control"
                                                        maskChar={null}
                                                        value={this.state.card.client.cpf}
                                                        onChange={(e) => {
                                                            const { value } = e.target;

                                                            this.setState(prevState => ({
                                                                ...prevState,
                                                                card: {
                                                                    ...prevState.card,
                                                                    client: {
                                                                        ...prevState.card.client,
                                                                        cpf: value
                                                                    }
                                                                }
                                                            }))
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label className="col-md-3 control-label">Telefone</label>
                                                <div className="col-md-9">
                                                    <InputMask
                                                        mask="(99) 99999-9999"
                                                        className="form-control"
                                                        maskChar={null}
                                                        value={this.state.card.client.phone}
                                                        onChange={(e) => {
                                                            const { value } = e.target;

                                                            this.setState(prevState => ({
                                                                ...prevState,
                                                                card: {
                                                                    ...prevState.card,
                                                                    client: {
                                                                        ...prevState.card.client,
                                                                        phone: value
                                                                    }
                                                                }
                                                            }))
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label className="col-md-3 control-label">E-mail</label>
                                                <div className="col-md-9">
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        placeholder=""
                                                        value={this.state.card.client.email}
                                                        onChange={(e) => {
                                                            const { value } = e.target;

                                                            this.setState(prevState => ({
                                                                ...prevState,
                                                                card: {
                                                                    ...prevState.card,
                                                                    client: {
                                                                        ...prevState.card.client,
                                                                        email: value
                                                                    }
                                                                }
                                                            }))
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-footer">
                                        <div className="form-group">
                                            <div className="col-md-9 col-md-offset-3">
                                                <button type="submit" className="btn btn-success">Enviar</button>
                                                <button type="button" onClick={this.handleCancelButton} className="btn btn-default">Cancelar</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </Layout>
        );
    }
}
