import React, { Component } from "react";

import { Link } from "react-router-dom";

import ctPointLabels from "../../utils/ChartistUtil";
import ChartistGraph from "react-chartist";
import LoadingOverlay from "react-loading-overlay";

import * as moment from "moment-timezone";

import {
    getType,
    TYPE_REGISTER,
    getUserName,
    TYPE_SELLER,
    TYPE_GAZINBOOK,
    TYPE_CONTECOMIGO,
    TYPE_AFTERSALE,
    TYPE_CURRENT_PROMOTIONS,
} from "../../services/auth";

import api from "../../services/api";

import Layout from "../../components/Layout";

export default class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dataGraph: {
                labels: [
                    "00:00",
                    "01:00",
                    "02:00",
                    "03:00",
                    "04:00",
                    "05:00",
                    "06:00",
                    "07:00",
                    "08:00",
                    "09:00",
                    "10:00",
                    "11:00",
                    "12:00",
                    "13:00",
                    "14:00",
                    "15:00",
                    "16:00",
                    "17:00",
                    "18:00",
                    "19:00",
                    "20:00",
                    "21:00",
                    "22:00",
                    "23:00",
                ],
                series: [],
            },
            cardsToday: [],
            cardsTodayCount: 0,
            distinctProducts: 0,
            newClientsToday: 0,
            latestCards: [],
            latestProducts: [],
            loaders: 0,
            loader: {
                chart: false,
                cardsInfo: false,
                latestCards: false,
                distinctProducts: false,
            },
        };

        this.loadChart = this.loadChart.bind(this);
        this.loadCardsInfo = this.loadCardsInfo.bind(this);
        this.loadLatestCards = this.loadLatestCards.bind(this);
        this.loadLatestProducts = this.loadLatestProducts.bind(this);

        this.reloadButton = this.reloadButton.bind(this);
    }

    componentWillMount() {
        if (
            getType() !== TYPE_REGISTER &&
            getType() !== TYPE_SELLER &&
            getType() !== TYPE_GAZINBOOK
        ) {
            this.loadChart();
            this.loadCardsInfo();
            this.loadLatestCards();
            this.loadLatestProducts();
        } else {
            this.setState((prevState) => ({
                ...prevState,
                loader: {
                    ...prevState.loader,
                    cardsInfo: true,
                    chart: true,
                },
            }));
        }
    }

    loadChart() {
        this.setState((prevState) => ({
            ...prevState,
            loaders:
                prevState.loaders === 5
                    ? prevState.loaders - 1
                    : prevState.loaders,
        }));

        api.get("/card/group-hour")
            .then(async (dataChart) => {
                let seriesChart = [];

                await dataChart.data.hours.map((hour) => {
                    seriesChart.push(hour.count);
                });

                this.setState((prevState) => ({
                    ...prevState,
                    dataGraph: {
                        ...prevState.dataGraph,
                        series: [seriesChart],
                    },
                    // loaders: prevState.loaders + 1
                    loader: {
                        ...prevState.loader,
                        chart: true,
                    },
                }));
            })
            .catch((err) => {
                console.log(err);
            });
    }

    loadCardsInfo = async () => {
        this.setState((prevState) => ({
            ...prevState,
            loaders:
                prevState.loaders === 5
                    ? prevState.loaders - 2
                    : prevState.loaders,
        }));

        let dataCards = await api.get(`/card/today-count-dash`);
        let dataClients = await api.get("/clients/today-count");

        this.setState((prevState) => ({
            ...prevState,
            // cardsToday: dataCards.data,
            cardsTodayCount: dataCards.data.qtdCardsToday,
            newClientsToday: dataClients.data.clientsCount,
            loader: {
                ...prevState.loader,
                cardsInfo: true,
            },
        }));
    };

    loadLatestCards = async () => {
        this.setState((prevState) => ({
            ...prevState,
            loaders:
                prevState.loaders === 5
                    ? prevState.loaders - 1
                    : prevState.loaders,
        }));

        let dtToday = moment().format();

        let dataLatestCard = await api.get(
            `/card/latest-sended/${dtToday}/${dtToday}`
        );

        this.setState((prevState) => ({
            ...prevState,
            latestCards: dataLatestCard.data,
            loader: {
                ...prevState.loader,
                latestCards: true,
            },
        }));
    };

    loadLatestProducts = async () => {
        this.setState((prevState) => ({
            ...prevState,
            loaders:
                prevState.loaders === 5
                    ? prevState.loaders - 1
                    : prevState.loaders,
        }));

        let dtToday = moment().format();

        let dataProducts = await api.get(
            `/card/products-distinct/${dtToday}/${dtToday}/1`
        );

        this.setState((prevState) => ({
            ...prevState,
            distinctProducts: dataProducts.data.qtdProductsTotal,
            latestProducts: dataProducts.data.products,
            loader: {
                ...prevState.loader,
                distinctProducts: true,
            },
        }));
    };

    reloadButton(e, button) {
        e.preventDefault();

        if (button === "chart") this.loadChart();

        if (button === "cards_info") this.loadCardsInfo();

        if (button === "latest_cards") this.loadLatestCards();

        if (button === "latest_prods") this.loadLatestProducts();
    }

    render() {
        // var data = {
        //     labels: ["00:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00", ],
        //     series: [[0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 10, 18, 20, 12, 8, 19, 16, 20, 3, 0, 0, 0, 0, 0]]
        // };

        var options = {
            axisX: {
                position: "center",
            },
            axisY: {
                offset: 0,
                showLabel: false,
                labelInterpolationFnc: function labelInterpolationFnc(value) {
                    return value / 1000 + "k";
                },
            },
            chartPadding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            },
            height: 250,
            high: 4000,
            showArea: true,
            stackBars: true,
            fullWidth: true,
            lineSmooth: false,
            plugins: [
                ctPointLabels({
                    textAnchor: "middle",
                    //   labelInterpolationFnc: function labelInterpolationFnc(value) {
                    //     return '$' + parseInt(value / 1000) + 'k';
                    //   }
                }),
            ],
        };

        var type = "Line";

        return (
            <LoadingOverlay
                active={
                    this.state.loader.chart !== true &&
                    this.state.loader.cardsInfo !== true
                }
                spinner
                text="Preparando gráfico e box de informações..."
            >
                <Layout menuActive="dashboard" pageTitle="Dashboard">
                    {getType() === TYPE_REGISTER ||
                    getType() === TYPE_GAZINBOOK ||
                    getType() === TYPE_CONTECOMIGO ||
                    getType() === TYPE_AFTERSALE ||
                    getType() === TYPE_CURRENT_PROMOTIONS ? (
                        <div className="row">
                            <div className="col-xs-12">
                                <div className="card card-mini">
                                    <div className="card-header">
                                        <div className="card-title">
                                            <h1>Olá, {getUserName()}</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <React.Fragment>
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="card card-banner card-chart card-green no-br">
                                        <div className="card-header">
                                            <div className="card-title">
                                                <div className="title">
                                                    Horário de envio de
                                                    orçamentos -{" "}
                                                    <small>
                                                        Clique no gráfico para
                                                        mais detalhes
                                                    </small>
                                                </div>
                                            </div>
                                            <ul className="card-action">
                                                <li>
                                                    <a
                                                        href=""
                                                        onClick={(e) =>
                                                            this.reloadButton(
                                                                e,
                                                                "chart"
                                                            )
                                                        }
                                                    >
                                                        <i className="fa fa-refresh"></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="card-body">
                                            <Link
                                                to={{
                                                    pathname:
                                                        "/dashboard/cartoes/hoje",
                                                    state: {
                                                        cards: this.state
                                                            .cardsToday,
                                                    },
                                                }}
                                            >
                                                <ChartistGraph
                                                    data={this.state.dataGraph}
                                                    options={options}
                                                    type={type}
                                                />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <Link
                                    to={{
                                        pathname: "/dashboard/cartoes/hoje",
                                    }}
                                >
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                        <a className="card card-banner card-blue-light">
                                            <div className="card-body">
                                                <i className="icon fa fa-shopping-basket fa-4x"></i>
                                                <div className="content">
                                                    <div
                                                        className="title"
                                                        style={{
                                                            textAlign: "right",
                                                        }}
                                                    >
                                                        Orçamentos hoje
                                                        <br />
                                                        <small>
                                                            Clique para mais
                                                            detalhes
                                                        </small>
                                                    </div>
                                                    <div className="value">
                                                        <span className="sign"></span>
                                                        {this.state
                                                            .cardsTodayCount ===
                                                        0 ? (
                                                            <small>...</small>
                                                        ) : (
                                                            this.state
                                                                .cardsTodayCount
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </Link>
                                <Link
                                    to={{
                                        pathname: "/dashboard/produtos/hoje",
                                        state: {
                                            products: this.state.latestProducts,
                                        },
                                    }}
                                >
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                        <a className="card card-banner card-green-light">
                                            <div className="card-body">
                                                <i className="icon fa fa-cube fa-4x"></i>
                                                <div className="content">
                                                    <div
                                                        className="title"
                                                        style={{
                                                            textAlign: "right",
                                                        }}
                                                    >
                                                        Produtos diferentes
                                                        usados hoje
                                                        <br />
                                                        <small>
                                                            Clique para mais
                                                            detalhes
                                                        </small>
                                                    </div>
                                                    <div className="value">
                                                        <span className="sign"></span>
                                                        {this.state
                                                            .distinctProducts ===
                                                        0 ? (
                                                            <small>...</small>
                                                        ) : (
                                                            this.state
                                                                .distinctProducts
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </Link>
                                <Link
                                    to={{
                                        pathname: "/clientes/hoje",
                                        state: {
                                            qtdClientsToday:
                                                this.state.newClientsToday,
                                        },
                                    }}
                                >
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                        <a className="card card-banner card-yellow-light">
                                            <div className="card-body">
                                                <i className="icon fa fa-user-plus fa-4x"></i>
                                                <div className="content">
                                                    <div
                                                        className="title"
                                                        style={{
                                                            textAlign: "right",
                                                        }}
                                                    >
                                                        Novos Clientes hoje
                                                        <br />
                                                        <small>
                                                            Clique para mais
                                                            detalhes
                                                        </small>
                                                    </div>
                                                    <div className="value">
                                                        <span className="sign"></span>
                                                        {this.state
                                                            .newClientsToday ===
                                                        0 ? (
                                                            <small>...</small>
                                                        ) : (
                                                            this.state
                                                                .newClientsToday
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </Link>
                            </div>

                            <br />

                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="card card-mini">
                                        <div className="card-header">
                                            <div className="card-title">
                                                Últimos Envios -{" "}
                                                <small>
                                                    Duplo clique na linha para
                                                    ver mais detalhes
                                                </small>
                                            </div>
                                            <ul className="card-action">
                                                <li>
                                                    <a
                                                        href=""
                                                        onClick={(e) =>
                                                            this.reloadButton(
                                                                e,
                                                                "latest_cards"
                                                            )
                                                        }
                                                    >
                                                        <i className="fa fa-refresh"></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div
                                            className={`card-body no-padding table-responsive ${
                                                this.state.loader.latestCards
                                                    ? ""
                                                    : "__loading"
                                            }`}
                                        >
                                            <div className="loader-container text-center">
                                                <div className="icon">
                                                    <div className="sk-wave">
                                                        <div className="sk-rect sk-rect1"></div>
                                                        <div className="sk-rect sk-rect2"></div>
                                                        <div className="sk-rect sk-rect3"></div>
                                                        <div className="sk-rect sk-rect4"></div>
                                                        <div className="sk-rect sk-rect5"></div>
                                                    </div>
                                                </div>
                                                <div className="title">
                                                    Carregando
                                                </div>
                                            </div>

                                            <table className="table card-table">
                                                <thead>
                                                    <tr>
                                                        <th>Vendedor</th>
                                                        <th>Filial</th>
                                                        <th className="right">
                                                            Produto
                                                        </th>
                                                        <th>Horário Envio</th>
                                                        <th>Forma de Envio</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.latestCards.map(
                                                        (card, index) => (
                                                            <tr
                                                                key={index}
                                                                onDoubleClick={(
                                                                    e
                                                                ) =>
                                                                    this.props.history.push(
                                                                        "/dashboard/cartoes/detalhe",
                                                                        {
                                                                            card,
                                                                            from: "home",
                                                                        }
                                                                    )
                                                                }
                                                            >
                                                                <td>
                                                                    {
                                                                        card
                                                                            .user
                                                                            .name
                                                                    }
                                                                </td>
                                                                <td>{`${card.user.branch_selected.codigo_loja} - ${card.user.branch_selected.cidade_estado}`}</td>
                                                                <td className="right">
                                                                    {
                                                                        card
                                                                            .product
                                                                            .name
                                                                    }
                                                                </td>
                                                                <td className="right">
                                                                    {moment
                                                                        .tz(
                                                                            card.created_at,
                                                                            "America/Sao_Paulo"
                                                                        )
                                                                        .format(
                                                                            "DD/MM/YYYY HH:mm"
                                                                        )}
                                                                </td>
                                                                {card.send_to ===
                                                                "whatsapp" ? (
                                                                    <td>
                                                                        <span className="badge badge-success badge-icon">
                                                                            <i
                                                                                className="fa fa-whatsapp"
                                                                                aria-hidden="true"
                                                                            ></i>
                                                                            <span>
                                                                                WHATSAPP
                                                                            </span>
                                                                        </span>
                                                                    </td>
                                                                ) : null}
                                                                {card.send_to ===
                                                                "sms" ? (
                                                                    <td>
                                                                        <span className="badge badge-warning badge-icon">
                                                                            <i
                                                                                className="fa fa-commenting-o"
                                                                                aria-hidden="true"
                                                                            ></i>
                                                                            <span>
                                                                                SMS
                                                                            </span>
                                                                        </span>
                                                                    </td>
                                                                ) : null}
                                                                {card.send_to ===
                                                                "email" ? (
                                                                    <td>
                                                                        <span className="badge badge-danger badge-icon">
                                                                            <i
                                                                                className="fa fa-envelope-o"
                                                                                aria-hidden="true"
                                                                            ></i>
                                                                            <span>
                                                                                E-MAIL
                                                                            </span>
                                                                        </span>
                                                                    </td>
                                                                ) : null}
                                                            </tr>
                                                        )
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="card card-mini">
                                        <div className="card-header">
                                            <div className="card-title">
                                                Produtos diferentes utilizados
                                                hoje
                                            </div>
                                            <ul className="card-action">
                                                <li>
                                                    <a
                                                        href=""
                                                        onClick={(e) =>
                                                            this.reloadButton(
                                                                e,
                                                                "latest_prods"
                                                            )
                                                        }
                                                    >
                                                        <i className="fa fa-refresh"></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div
                                            className={`card-body no-padding table-responsive ${
                                                this.state.loader
                                                    .distinctProducts
                                                    ? ""
                                                    : "__loading"
                                            }`}
                                        >
                                            <div className="loader-container text-center">
                                                <div className="icon">
                                                    <div className="sk-wave">
                                                        <div className="sk-rect sk-rect1"></div>
                                                        <div className="sk-rect sk-rect2"></div>
                                                        <div className="sk-rect sk-rect3"></div>
                                                        <div className="sk-rect sk-rect4"></div>
                                                        <div className="sk-rect sk-rect5"></div>
                                                    </div>
                                                </div>
                                                <div className="title">
                                                    Carregando
                                                </div>
                                            </div>
                                            <table className="table card-table">
                                                <thead>
                                                    <tr>
                                                        <th>Produto</th>
                                                        <th>Linha</th>
                                                        <th>Marca</th>
                                                        <th className="right">
                                                            Qtd. Cartões
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.latestProducts.map(
                                                        (
                                                            productItem,
                                                            index
                                                        ) => (
                                                            <tr>
                                                                <td>
                                                                    {
                                                                        productItem
                                                                            .product[0][0]
                                                                            .name
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        productItem
                                                                            .product[0][0]
                                                                            .line
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        productItem
                                                                            .product[0][0]
                                                                            .brand
                                                                    }
                                                                </td>
                                                                <td className="right">
                                                                    {
                                                                        productItem.count
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                    <tr
                                                        onClick={(e) =>
                                                            this.props.history.push(
                                                                "/dashboard/produtos/hoje"
                                                            )
                                                        }
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <td>
                                                            <b>
                                                                Clique aqui para
                                                                ver mais
                                                            </b>
                                                        </td>
                                                        <td></td>
                                                        <td></td>
                                                        <td className="right"></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </Layout>
            </LoadingOverlay>
        );
    }
}
