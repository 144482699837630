import React, { Component } from "react";

import * as moment from "moment-timezone";

import { Link } from "react-router-dom";

import Layout from "../../components/Layout";

import api from "../../services/api";

import Select from "react-select";

export default class DistinctProductsToday extends Component {
    constructor(props) {
        super(props);

        this.state = {
            products: [],
            productsToShow: [],
            brandFilter: { value: "", label: "Todas" },
            lineFilter: { value: "", label: "Todos" },
            lines: [],
            brands: [],
            dtBegin: moment().format("YYYY-MM-DD"),
            dtEnd: moment().format("YYYY-MM-DD"),
            loaded: false,
            page: 1,
            qtdPages: 0,
        };

        this.handleChangeLine = this.handleChangeLine.bind(this);
        this.handleChangeBrand = this.handleChangeBrand.bind(this);

        this.handleDateResearchButton = this.handleDateResearchButton.bind(
            this
        );
    }

    componentWillMount = async () => {
        if (!this.props.location.state) {
            this.props.history.push("/");
            return;
        }

        const returnLines = await api.get("/product/lines");
        const returnBrands = await api.get("/product/brands");
        const dataProducts = await api.get(
            `/card/products-distinct/${this.state.dtBegin}/${this.state.dtBegin}/1`
        );

        const linesToMap = returnLines.data.lines.sort(function (a, b) {
            if (a < b) {
                return -1;
            }
            if (a > b) {
                return 1;
            }
            return 0;
        });

        const brandsToMap = returnBrands.data.brands.sort(function (a, b) {
            if (a < b) {
                return -1;
            }
            if (a > b) {
                return 1;
            }
            return 0;
        });

        let linesToSelect = [{ value: "", label: "Todas" }];
        let brandsToSelect = [{ value: "", label: "Todas" }];

        await linesToMap.map((line) =>
            linesToSelect.push({ value: line, label: line })
        );
        await brandsToMap.map((brand) =>
            brandsToSelect.push({ value: brand, label: brand })
        );

        this.setState((prevState) => ({
            ...prevState,
            loaded: true,
            products: dataProducts.data.products,
            productsToShow: dataProducts.data.products,
            qtdPages: dataProducts.data.qtdPages,
            lines: linesToSelect,
            brands: brandsToSelect,
        }));
    };

    componentDidMount = async function () {
        this.setState((prevState) => ({
            ...prevState,
        }));
    };

    handleDateResearchButton = async () => {
        this.setState((prevState) => ({
            ...prevState,
            loaded: false,
        }));

        let dtInit = moment(this.state.dtBegin).format();
        let dtFinally = moment(this.state.dtEnd).format();
        let dataProducts = await api.get(
            `/card/products-distinct/${dtInit}/${dtFinally}/1`
        );

        this.setState((prevState) => ({
            ...prevState,
            loaded: true,
            products: dataProducts.data.products,
            productsToShow: dataProducts.data.products,
            brandFilter: { value: "", label: "Todas" },
            lineFilter: { value: "", label: "Todos" },
            qtdPages: dataProducts.data.qtdPages,
            page: 1,
        }));
    };

    handleChangeLine = (selectedOption) => {
        const { value } = selectedOption;

        this.setState((prevState) => ({
            ...prevState,
            loaded: false,
            lineFilter: selectedOption,
        }));

        let { productsToShow, products, brandFilter } = this.state;

        let productsShow;

        if (value === "") {
            productsShow = products;

            if (brandFilter.value === "") {
                this.setState((prevState) => ({
                    ...prevState,
                    loaded: true,
                    productsToShow: productsShow,
                }));

                return;
            }

            this.setState((prevState) => ({
                ...prevState,
                // loaded: true,
                productsToShow: productsShow,
            }));

            setTimeout(
                () => this.handleChangeBrand(this.state.brandFilter),
                500
            );

            return;
        } else
            productsShow = products.filter(
                (prod) => prod.product[0][0].line === value
            );

        this.setState((prevState) => ({
            ...prevState,
            loaded: true,
            productsToShow: productsShow,
        }));
    };

    handleChangeBrand = (selectedOption) => {
        const { value } = selectedOption;

        this.setState((prevState) => ({
            ...prevState,
            loaded: false,
            brandFilter: selectedOption,
        }));

        let { productsToShow, products, lineFilter } = this.state;

        let productsShow;

        if (value === "") {
            productsShow = products;

            if (lineFilter.value === "") {
                this.setState((prevState) => ({
                    ...prevState,
                    loaded: true,
                    productsToShow: productsShow,
                }));

                return;
            }

            this.setState((prevState) => ({
                ...prevState,
                // loaded: true,
                productsToShow: productsShow,
            }));

            setTimeout(() => this.handleChangeLine(this.state.lineFilter), 500);

            return;
        } else
            productsShow = products.filter(
                (prod) => prod.product[0][0].brand === value
            );

        this.setState((prevState) => ({
            ...prevState,
            loaded: true,
            productsToShow: productsShow,
        }));
    };

    changePage(page) {
        const loadData = async (page) => {
            this.setState((prevState) => ({
                ...prevState,
                loaded: false,
            }));

            let dtInit = moment(this.state.dtBegin).format();
            let dtFinally = moment(this.state.dtEnd).format();
            let dataProducts = await api.get(
                `/card/products-distinct/${dtInit}/${dtFinally}/${page}`
            );

            this.setState((prevState) => ({
                ...prevState,
                loaded: true,
                products: dataProducts.data.products,
                productsToShow: dataProducts.data.products,
                page: page,
            }));
        };
        loadData(page);
    }

    render() {
        let { productsToShow, lines, brands, dtBegin, dtEnd } = this.state;

        return (
            <Layout
                menuActive="dashboard"
                pageTitle="Produtos diferentes utilizados hoje"
            >
                <div className="row">
                    <div className="col-xs-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="card-title">
                                    <small>
                                        <Link
                                            className="label label-default"
                                            to="/dashboard"
                                        >
                                            Dashboard
                                        </Link>{" "}
                                    </small>{" "}
                                    <span className="label label-success">
                                        Produtos diferentes utilizados hoje
                                    </span>
                                </div>
                            </div>
                            <div
                                className={`card-body ${
                                    this.state.loaded ? "" : "__loading"
                                }`}
                            >
                                <div className="row">
                                    <div className="col-xs-4">
                                        <label htmlFor="dtBegin-filter">
                                            Data Inicial
                                        </label>
                                        <input
                                            className="form-control"
                                            type="date"
                                            id="dtBegin-filter"
                                            value={dtBegin}
                                            onChange={(e) => {
                                                const { value } = e.target;

                                                this.setState((prevState) => ({
                                                    ...prevState,
                                                    dtBegin: value,
                                                }));
                                            }}
                                        />
                                    </div>

                                    <div className="col-xs-4">
                                        <label htmlFor="dtEnd-filter">
                                            Data Final
                                        </label>
                                        <input
                                            className="form-control"
                                            type="date"
                                            id="dtEnd-filter"
                                            value={dtEnd}
                                            onChange={(e) => {
                                                const { value } = e.target;

                                                this.setState((prevState) => ({
                                                    ...prevState,
                                                    dtEnd: value,
                                                }));
                                            }}
                                        />
                                    </div>

                                    <div
                                        className="col-xs-4"
                                        style={{
                                            position: "relative",
                                            top: "50%",
                                            transform: "translateY(50%)",
                                        }}
                                    >
                                        <button
                                            className="btn btn-primary btn-large"
                                            onClick={
                                                this.handleDateResearchButton
                                            }
                                        >
                                            Buscar por data
                                        </button>
                                    </div>
                                </div>
                                {/* <div className="row">
                                    <div className="col-xs-4">
                                        <label htmlFor="line-filter">
                                            Linha
                                        </label>
                                        <Select
                                            id="line-filter"
                                            placeholder="Todas"
                                            value={this.state.lineFilter}
                                            onChange={this.handleChangeLine}
                                            options={lines}
                                        />
                                    </div>

                                    <div className="col-xs-4">
                                        <label htmlFor="brand-filter">
                                            Marca
                                        </label>
                                        <Select
                                            id="brand-filter"
                                            placeholder="Todas"
                                            value={this.state.brandFilter}
                                            onChange={this.handleChangeBrand}
                                            options={brands}
                                        />
                                    </div>
                                </div> */}

                                <br />

                                <div className="loader-container text-center">
                                    <div className="icon">
                                        <div className="sk-wave">
                                            <div className="sk-rect sk-rect1"></div>
                                            <div className="sk-rect sk-rect2"></div>
                                            <div className="sk-rect sk-rect3"></div>
                                            <div className="sk-rect sk-rect4"></div>
                                            <div className="sk-rect sk-rect5"></div>
                                        </div>
                                    </div>
                                    <div className="title">Carregando</div>
                                </div>

                                <div className="row">
                                    <div className="col-xs-12">
                                        <table className="table table-striped table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Produto</th>
                                                    <th>Linha</th>
                                                    <th>Marca</th>
                                                    <th className="right">
                                                        Qtd. Cartões
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {productsToShow.map(
                                                    (productItem, index) => (
                                                        // <tr key={index} onDoubleClick={(e) => this.props.history.push("/dashboard/cartoes/detalhe", { card, from: "cards" })}>
                                                        <tr key={index}>
                                                            <td>
                                                                {
                                                                    productItem
                                                                        .product[0][0]
                                                                        .name
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    productItem
                                                                        .product[0][0]
                                                                        .line
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    productItem
                                                                        .product[0][0]
                                                                        .brand
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    productItem.count
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {this.state.qtdPages !== 0 ? (
                                    <div className="row">
                                        <div className="col-xs-12">
                                            <ul className="pagination">
                                                {Array(this.state.qtdPages)
                                                    .fill()
                                                    .map((_, page) => (
                                                        <li
                                                            className={
                                                                page + 1 ===
                                                                this.state.page
                                                                    ? "active"
                                                                    : ""
                                                            }
                                                        >
                                                            <a
                                                                href="#"
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.preventDefault();
                                                                    this.changePage(
                                                                        page + 1
                                                                    );
                                                                }}
                                                            >
                                                                {page + 1}
                                                            </a>
                                                        </li>
                                                    ))}
                                            </ul>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}
