import React, { useState, useEffect } from "react";

import { withRouter, Link } from "react-router-dom";

import Layout from "../../components/Layout";

import api from "../../services/api";

import Swal from "sweetalert2";

const DirectMailTabloidList = (props) => {
    const [directMailTabloids, setDirectMailTabloids] = useState([]);
    const [directMailTabloidsToShow, setDirectMailTabloidsToShow] = useState(
        []
    );
    const [loaded, setLoaded] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [base64PDFPreview, setBase64PDFPreview] = useState(null);

    useEffect(function () {
        loadList();
    }, []);

    async function loadList() {
        setLoaded(false);

        const responseGetDirectMailTabloids = await api.get(
            "/direct-mail-tabloid"
        );

        setDirectMailTabloids(
            responseGetDirectMailTabloids.data.data.directMailTabloids
        );
        setDirectMailTabloidsToShow(
            responseGetDirectMailTabloids.data.data.directMailTabloids
        );
        setLoaded(true);
    }

    function handleSearchBar() {
        setLoaded(false);

        const originalDirectMailTabloids = directMailTabloids;

        if (searchText === "") {
            setDirectMailTabloidsToShow(originalDirectMailTabloids);
            setLoaded(true);
            return;
        }

        let filteredDirectMailTabloids = originalDirectMailTabloids.filter(
            (directMailTabloid) =>
                directMailTabloid.name
                    .toLowerCase()
                    .indexOf(searchText.toLowerCase()) !== -1
        );

        if (filteredDirectMailTabloids.length <= 0) {
            filteredDirectMailTabloids = originalDirectMailTabloids.filter(
                (directMailTabloid) =>
                    directMailTabloid.name
                        .toLowerCase()
                        .indexOf(searchText.toLowerCase()) !== -1
            );
        }

        setDirectMailTabloidsToShow(filteredDirectMailTabloids);
        setLoaded(true);
    }

    async function handleDelete(directMailTabloidDelete) {
        Swal.fire({
            title: `Tem certeza que deseja excluir a mala direta tabloide: ${directMailTabloidDelete.name}?`,
            text: "Não será possível voltar atrás!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim, quero excluir!",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (!result.value) {
                Swal.fire(
                    "Cancelado!",
                    "Nenhum dado será excluído.",
                    "success"
                );
            } else {
                setLoaded(false);

                try {
                    const responseDeleteDirectMailTabloid = await api.delete(
                        `/direct-mail-tabloid/${directMailTabloidDelete._id}`
                    );

                    if (responseDeleteDirectMailTabloid.status === 204) {
                        const newDirectMailTabloids = directMailTabloids.filter(
                            (directMailTabloid) =>
                                directMailTabloid._id !==
                                directMailTabloidDelete._id
                        );
                        setDirectMailTabloids(newDirectMailTabloids);
                        setDirectMailTabloidsToShow(newDirectMailTabloids);
                        setLoaded(true);
                    } else {
                        throw new Error("Error");
                    }
                } catch (error) {
                    setLoaded(true);
                    Swal.fire({
                        type: "error",
                        title: "Oops...",
                        text: "Tivemos um problema ao excluir essa mala direta tabloide. Contate o suporte!",
                    });
                }
            }
        });
    }

    async function handlePreview(directMailTabloid) {
        const nameResult = await Swal.fire({
            title: "Informe o nome para preview abaixo:",
            input: "text",
            inputAttributes: {
                autocapitalize: "off",
            },
            showCancelButton: true,
            confirmButtonText: "Gerar",
            showLoaderOnConfirm: true,
            cancelButtonColor: "#d33",
            cancelButtonText: "Cancelar",
        });

        if (!nameResult.value) {
            Swal.fire("Cancelado!", "Sua geração do foi cancelada.", "error");
        } else {
            setLoaded(false);

            const responsePreview = await api.post(
                "/direct-mail-tabloid/preview",
                {
                    clientName: nameResult.value,
                    directMailTabloidId: directMailTabloid._id,
                }
            );

            setBase64PDFPreview(responsePreview.data.data.base64PDF);

            setLoaded(true);
        }
    }

    return (
        <Layout
            menuActive="direct-mail-tabloid"
            pageTitle="Lista de Malas Diretas Tabloides"
            enableSearchBar={true}
            searchTerm={searchText}
            searchOnChange={(e) => setSearchText(e.target.value)}
            handleSearch={handleSearchBar}
        >
            <div className="row">
                <div className="col-xs-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">
                                Lista de Malas Diretas Tabloides
                            </div>
                            <ul className="card-action">
                                <li>
                                    <Link
                                        to="/mala-direta-tabloides/nova"
                                        className="btn btn-sm btn-success"
                                    >
                                        Nova <i className="fa fa-plus"></i>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div
                            className={`card-body ${loaded ? "" : "__loading"}`}
                        >
                            <div className="loader-container text-center">
                                <div className="icon">
                                    <div className="sk-wave">
                                        <div className="sk-rect sk-rect1"></div>
                                        <div className="sk-rect sk-rect2"></div>
                                        <div className="sk-rect sk-rect3"></div>
                                        <div className="sk-rect sk-rect4"></div>
                                        <div className="sk-rect sk-rect5"></div>
                                    </div>
                                </div>
                                <div className="title">Carregando</div>
                            </div>

                            <div className="row">
                                <div className="col-xs-12">
                                    {base64PDFPreview ? (
                                        <a
                                            className="btn btn-success"
                                            download="preview.pdf"
                                            href={`data:application/pdf;base64,${base64PDFPreview}`}
                                        >
                                            Download Preview
                                        </a>
                                    ) : null}
                                    <table className="table table-striped table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th>Nome</th>
                                                <th>Opções</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {directMailTabloidsToShow.map(
                                                (directMailTabloid, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                {
                                                                    directMailTabloid.name
                                                                }
                                                            </td>
                                                            <td>
                                                                <div className="col-xs-12">
                                                                    <div className="col-xs-12">
                                                                        <button
                                                                            className="btn btn-xs btn-primary"
                                                                            style={{
                                                                                width: "100%",
                                                                            }}
                                                                            onClick={() =>
                                                                                handlePreview(
                                                                                    directMailTabloid
                                                                                )
                                                                            }
                                                                        >
                                                                            Preview
                                                                            <div className="icon fa fa-eye"></div>
                                                                        </button>
                                                                        <button
                                                                            className="btn btn-xs btn-danger"
                                                                            style={{
                                                                                width: "100%",
                                                                            }}
                                                                            onClick={() =>
                                                                                handleDelete(
                                                                                    directMailTabloid
                                                                                )
                                                                            }
                                                                        >
                                                                            Excluir
                                                                            <div className="icon fa fa-trash-o"></div>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default withRouter(DirectMailTabloidList);
